"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";

import OrderLine from "@/shared/lib/client-sdk/models/order-line";

class DepositBatch extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "DepositBatch";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/deposit-batches"
    );
  }

  getApiPathForCreate() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/deposit-batches"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/deposit-batches/" +
      this.id
    );
  }

  getAdminPath() {
    return "/deposit-batches/" + this.id;
  }

  get searchResultTitle() {
    return this.flagName;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  get relationships() {
    return {
      orderLines: OrderLine,
    };
  }
}

export default DepositBatch;
