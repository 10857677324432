var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-combobox',{staticClass:"api-entity-search-text",class:{ plain: _vm.plain },attrs:{"items":_vm.searchResults,"loading":_vm.searchRequestHandler.isBusy,"loader-height":"3","search-input":_vm.query,"no-filter":"","hide-details":_vm.hideDetails,"item-text":"searchResultTitle","item-value":"id","color":_vm.dark ? _vm.$colors.penChecksLight : _vm.$colors.penChecksTeal,"item-color":_vm.dark ? _vm.$colors.penChecksLight : _vm.$colors.penChecksTeal,"rules":_vm.required ? _vm.selectRules : [],"label":_vm.label,"disabled":_vm.loadingInitialValue || _vm.disabled,"placeholder":_vm.placeholder,"prepend-icon":_vm.prependIcon,"clearable":_vm.clearable,"chips":_vm.multiple,"menu-props":{ offsetY: true, maxWidth: _vm.menuMaxWidth, nudgeTop: -2 },"multiple":_vm.multiple,"dense":_vm.dense},on:{"update:searchInput":function($event){_vm.query=$event},"update:search-input":function($event){_vm.query=$event},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('enter', _vm.query)}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('div',{class:{
          'pc-api-entity-search-item': true,
          'pc-api-entity-search-item-with-chip':
            data.item.searchResultCreatedAt || data.item.searchResultStatus,
        }},[_c('span',{attrs:{"title":_vm.extractTextFromHtml(
              _vm.$options.filters.highlightQuery(
                data.item.searchResultTitle,
                _vm.query
              )
            )}},[_c('v-list-item-title',[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.$options.filters.highlightQuery(
                  data.item.searchResultTitle,
                  _vm.query
                )
              )}})])],1),(data.item.searchResultSubtitle)?_c('v-list-item-subtitle',_vm._l((_vm.asArray(
              data.item.searchResultSubtitle
            )),function(subtitle,$index){return _c('span',{key:$index,attrs:{"title":_vm.extractTextFromHtml(
                _vm.$options.filters.highlightQuery(subtitle, _vm.query)
              )}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.highlightQuery(subtitle, _vm.query))}})])}),0):_vm._e()],1),_c('v-spacer'),(data.item.searchResultCreatedAt || data.item.searchResultStatus)?_c('div',[(data.item.searchResultCreatedAt)?_c('v-list-item-action-text',[_vm._v(" "+_vm._s(_vm.$options.filters.formatDate(data.item.searchResultCreatedAt))+" ")]):_vm._e(),(data.item.searchResultStatus)?_c('pc-status-chip',{attrs:{"x-small":""},model:{value:(data.item.searchResultStatus),callback:function ($$v) {_vm.$set(data.item, "searchResultStatus", $$v)},expression:"data.item.searchResultStatus"}}):_vm._e()],1):_vm._e()]}}]),model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}})
}
var staticRenderFns = []

export { render, staticRenderFns }