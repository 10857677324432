<template>
  <div class="scrolling-prompts-item">
    <h3>{{ title }}</h3>

    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    title: {
      type: String,
      required: true,
    },
  },

  data: () => {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},
};
</script>

<style lang="scss">
.scrolling-prompts-item {
  margin-bottom: 50px;
}
</style>
