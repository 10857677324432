"use strict";

class SearchResult {
  constructor() {
    this.page = 1;
    this.resultsPerPage = 10;
    this.totalPages = 1;
    this.totalResults = 0;
    this.sortBy = null;
    this.sortDirection = null;

    this.results = [];
  }

  loadSearchResponse(response, modelClass) {
    this.results = [];

    for (var i = 0; i < response.result.length; i++) {
      var result = response.result[i];
      this.results.push(new modelClass(result));
    }

    this.page = response.collection ? response.collection.page : 1;
    this.resultsPerPage = response.collection
      ? response.collection.resultsPerPage
      : 25;
    this.totalPages = response.collection ? response.collection.totalPages : 1;
    this.totalResults = response.collection
      ? response.collection.totalResults
      : this.results.length;
    this.sortBy = response.collection ? response.collection.sortBy : null;
    this.sortDirection = response.collection
      ? response.collection.sortDirection
      : null;
  }

  clone() {
    var newSearchResult = new SearchResult();

    newSearchResult.page = this.page;
    newSearchResult.resultsPerPage = this.resultsPerPage;
    newSearchResult.totalPages = this.totalPages;
    newSearchResult.totalResults = this.totalResults;
    newSearchResult.sortBy = this.sortBy;
    newSearchResult.sortDirection = this.sortDirection;

    newSearchResult.results = this.results.slice();

    return newSearchResult;
  }

  static RESULT_FIELD_DATA_TYPES() {
    return {
      TEXT: "text",
      NUMBER: "number",
      BOOLEAN: "boolean",
      DATE: "date",
      TIME: "time",
      DATE_TIME: "dateTime",
      CHILD_OBJECT: "childObject",
      ID: "id",
      UUID: "uuid",
      CODE: "code",
      CURRENCY_AMOUNT: "currencyAmount",
      CURRENCY_CHANGE: "currencyChange",
      PERCENTAGE: "percentage",
      EMAIL_ADDRESS: "emailAddress",
      PHONE_NUMBER: "phoneNumber",
      STATUS: "status",
      IMAGE_URL: "imageUrl",
      LINK_URL: "linkUrl",
    };
  }
}

export default SearchResult;
