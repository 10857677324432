"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import $ from "jquery";

class OrderLineDocument extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "OrderLineDocument";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-line-documents"
    );
  }

  getApiPathForCreate() {
    console.error("Cannot currently create order line documents.");
  }

  getApiPathForRefreshUpdateAndDelete() {
    console.error("Cannot currently update an order line document.");
  }

  getAdminPath() {
    return "/order-lines/" + this.orderLineId;
  }

  get searchResultTitle() {
    return this.id;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  // If the API returns a relationship as a sub-object, this tells the base model which class to use for them when it loads it in.
  get relationships() {
    return {};
  }

  reprint(sendMethod, becomesNonResponsiveParticipantOn, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/order-line-documents/" +
      this.id +
      "/reprint";

    var requestData = {
      sendMethod: sendMethod,
      becomesNonResponsiveParticipantAtEndOfDayOn:
        becomesNonResponsiveParticipantOn,
    };

    ApiRequest.send(
      "POST",
      requestUrl,
      requestData,
      OrderLineDocument
    ).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  cancel(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/order-line-documents/" +
      this.id +
      "/cancel";

    ApiRequest.send("POST", requestUrl, null, OrderLineDocument).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }
}

export default OrderLineDocument;
