"use strict";

import BaseModelList from "./base-model-list";

class EntityNotesList extends BaseModelList {
  constructor(parentModel) {
    super(parentModel);
  }

  getModelClassName() {
    return "EntityNote";
  }

  getApiPathForSearch() {
    return (
      this.parentModel
        .getApiPathForRefreshUpdateAndDelete()
        .replace("/public/", "/private/") + "/notes"
    );
  }

  getApiPathForAdd() {
    return (
      this.parentModel
        .getApiPathForRefreshUpdateAndDelete()
        .replace("/public/", "/private/") + "/notes"
    );
  }

  getApiPathForRefreshUpdateAndDelete(entityNote) {
    return entityNote.getApiPathForRefreshUpdateAndDelete();
  }
}

export default EntityNotesList;
