"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import $ from "jquery";

class OrderLineDetailRecurringService extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "OrderLineDetailRecurringService";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-lines"
    );
  }

  getApiPathForCreate() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/orders/" +
      this.orderId +
      "/order-lines"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-line-detail-recurring-service/" +
      this.id
    );
  }

  getAdminPath() {
    return "/orders/" + this.id;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultTitle() {
    return this.id;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  calculateGrossDistributionAmount() {
    return Math.addAmounts(
      this.traditionalPreTaxAmount,
      this.traditionalPostTaxAmount
    );
  }

  calculatedNumberOfPaymentsPerYear() {
    var numberOfPayments = 1;
    numberOfPayments =
      this.paymentFrequencyCode == "onceEveryWeek" ? 52 : numberOfPayments;
    numberOfPayments =
      this.paymentFrequencyCode == "onceEveryTwoWeeks" ? 26 : numberOfPayments;
    numberOfPayments =
      this.paymentFrequencyCode == "twiceEveryMonth" ? 24 : numberOfPayments;
    numberOfPayments =
      this.paymentFrequencyCode == "onceEveryMonth" ? 12 : numberOfPayments;
    numberOfPayments =
      this.paymentFrequencyCode == "onceEveryQuarter" ? 4 : numberOfPayments;
    numberOfPayments =
      this.paymentFrequencyCode == "twiceEveryYear" ? 2 : numberOfPayments;

    return numberOfPayments;
  }

  calculatedAnnualGrossDistributionAmount() {
    var grossDistributionAmount = this.calculateGrossDistributionAmount();
    var numberOfPaymentsPerYear = this.calculatedNumberOfPaymentsPerYear();

    return Math.safeCurrencyValue(
      grossDistributionAmount * numberOfPaymentsPerYear
    );
  }

  calculateTaxesForPayeeWithDateOfBirth(payeeDateOfBirth, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/tax-calculator";

    var requestBody = {
      serviceCode: "recurringBenefits",
      stateCode: this.taxAuthorityStateCode,
      distributionReasonCode: this.distributionReasonCode,
      distributionTypeCode: "cashLumpSum",
      payeeDateOfBirth: payeeDateOfBirth,
      isNonResidentAlien: false,
      retirementAccountTypeCode: "traditional",
      taxableAmount: this.traditionalPreTaxAmount,
      taxFilingStatusCode: this.taxFilingStatusCode,
      annualCountOfRecurringDistributions:
        this.calculatedNumberOfPaymentsPerYear(),
    };

    ApiRequest.send("POST", requestUrl, requestBody).onComplete(
      function (response) {
        if ($.isFunction(callback)) callback(response.result);
      }.bind(this)
    );
  }
}

export default OrderLineDetailRecurringService;
