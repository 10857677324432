import Vue from "vue";
import Vuex from "vuex";
import User from "@/shared/lib/client-sdk/models/user";
import $ from "jquery";
import SystemPropertiesModule from "@/shared/store/modules/system-properties.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authenticatedUser: null,
    masterAccount: null,
    sideNavCollapsed: false,
    appVersion: null,
    searchMethod: "all",
  },

  mutations: {
    setAuthenticatedUser(state, user) {
      if (state.authenticatedUser != user) state.authenticatedUser = user;
    },
    removeAuthenticatedUser(state) {
      state.authenticatedUser = null;
    },
    setMasterAccount(state, masterAccount) {
      state.masterAccount = masterAccount;
    },
    setSideNavCollapsed(state, sideNavCollapsed) {
      state.sideNavCollapsed = sideNavCollapsed;
    },
    setSearchMethod(state, searchMethod) {
      state.searchMethod = searchMethod;
    },
    setAppVersion(state, appVersion) {
      state.appVersion = appVersion;
    },
  },
  actions: {
    // this.$store.dispatch('refreshAuthenticatedUser', function(user) {});
    // Refreshes the authenticted user, and sets the state
    refreshAuthenticatedUser(context, callback) {
      User.fetchAuthenticatedUser(
        function (user, amplifyAppVersion) {
          if (!user) {
            context.commit("removeAuthenticatedUser");
          } else {
            context.commit("setAuthenticatedUser", user);
          }

          if (amplifyAppVersion) {
            context.commit("setAppVersion", amplifyAppVersion);
          }

          if ($.isFunction(callback)) callback(user);
        }.bind(this)
      );
    },
  },
  modules: {
    systemProperties: SystemPropertiesModule,
  },
});
