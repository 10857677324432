import Vue from "vue";
import VueRouter from "vue-router";
import SpecialTaxNotice from "@/my-benefit-election/components/notices/special-tax-notice";

Vue.use(VueRouter);

const routes = [
  {
    path: "/stn",
    name: "specialTaxNotice",
    component: SpecialTaxNotice,
  },
  // For anything other than the special tax notice (which requires no
  // authentication), redirect to my.penchecks.com so that we can share
  // cookies/authentication with auth.penchecks.com
  {
    path: "*",
    beforeEnter: () => {
      const subDomain = window.amplifyRuntimeConfig.get("VUE_APP_SUBDOMAIN");
      const requestProtocol = window.amplifyRuntimeConfig.get(
        "VUE_APP_REQUEST_PROTOCOL"
      );

      window.location.href = `${requestProtocol}://my.${subDomain}penchecks.com`;
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
