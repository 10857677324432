<template>
  <v-alert
    v-if="!simpleMode && errorMessages.length > 0"
    border="left"
    elevation="2"
    colored-border
    dense
    type="error"
  >
    <div v-for="errorMessage in errorMessages" v-bind:key="errorMessage">
      <p class="error-paragraph-style" v-html="errorMessage"></p>
    </div>
  </v-alert>
  <div v-else-if="simpleMode && errorMessages.length > 0">
    <div v-for="errorMessage in errorMessages" v-bind:key="errorMessage">
      <p
        class="error-paragraph-style error-color-style"
        v-html="errorMessage"
      ></p>
    </div>
  </div>
</template>

<script>
import ApiResponse from "@/shared/lib/client-sdk/api-response";
import $ from "jquery";

export default {
  name: "PcErrorAlertReskinned",

  components: {},

  props: {
    apiResponse: {
      type: ApiResponse,
    },
    errors: {
      type: Array,
    },
    error: {
      type: String,
    },
    simpleMode: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    errorMessages: function () {
      var messages = [];

      if (this.apiResponse) {
        if (!this.apiResponse.successful) {
          if (this.apiResponse.result && this.apiResponse.result.errors) {
            for (var key in this.apiResponse.result.errors) {
              var errors = this.apiResponse.result.errors[key];

              if ($.type(errors) == "array") {
                for (let i = 0; i < errors.length; i++) {
                  if (errors[i] && errors[i].trim != "") {
                    messages.push(errors[i]);
                  }
                }
              } else if ($.type(errors) == "string") {
                messages.push(errors);
              }
            }
          } else if (
            this.apiResponse.message &&
            this.apiResponse.message.trim() != ""
          ) {
            messages.push(this.apiResponse.message);
          }
        }
      } else if (this.errors) {
        if ($.isArray(this.errors)) {
          for (let i = 0; i < this.errors.length; i++) {
            messages.push(this.errors[i]);
          }
        } else {
          messages.push(this.errors);
        }
      } else if (this.error) {
        messages.push(this.error);
      }

      return messages;
    },
  },
};
</script>

<style lang="scss">
p.error-paragraph-style {
  margin: 0px !important;
  text-align: left;
}

p.error-color-style {
  color: red !important;
  font-size: small;
}
</style>
