<template>
  <div v-if="show">
    <v-badge
      v-if="isActionRequired"
      :color="$colors.penChecksOrange"
      dot
      left
      orverlap
      offset-x="10"
      offset-y="5"
    >
      <v-chip small link :color="backgroundColor" class="mr-2" @click="clicked">
        <span :style="'color:' + textColor">{{ label }}</span>
      </v-chip>
    </v-badge>
    <v-chip
      v-else
      small
      link
      :color="backgroundColor"
      class="mr-2"
      @click="clicked"
    >
      <span :style="'color:' + textColor">{{ label }}</span>
    </v-chip>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    isActionRequired: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    isSelected: {
      type: Boolean,
    },
  },

  data: function () {
    return {};
  },

  computed: {
    backgroundColor: function () {
      return this.isSelected
        ? this.$colors.penChecksTeal
        : this.$colors.penChecksLightTeal;
    },
    textColor: function () {
      return this.isSelected ? this.$colors.white : this.$colors.penChecksTeal;
    },
  },

  methods: {
    clicked: function (event) {
      this.$emit("click", event);
    },
  },

  mounted: function () {},
};
</script>

<style lang="scss"></style>
