<template>
  <div v-if="this.type == undefined">
    <div v-if="this.size && this.size == 16">
      <checkmark-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <checkmark-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <checkmark-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <checkmark-32 :class="iconColorClass" />
    </div>
    <div v-else><checkmark-16 :class="iconColorClass" /></div>
  </div>
  <div v-else-if="this.type == 'filled'">
    <div v-if="this.size && this.size == 16">
      <checkmark-filled-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <checkmark-filled-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <checkmark-filled-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <checkmark-filled-32 :class="iconColorClass" />
    </div>
    <div v-else><checkmark-filled-16 :class="iconColorClass" /></div>
  </div>
  <div v-else-if="this.type == 'filled-error'">
    <div v-if="this.size && this.size == 16">
      <checkmark-filled-error-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <checkmark-filled-error-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <checkmark-filled-error-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <checkmark-filled-error-32 :class="iconColorClass" />
    </div>
    <div v-else><checkmark-filled-error-16 :class="iconColorClass" /></div>
  </div>
  <div v-else-if="this.type == 'filled-warning'">
    <div v-if="this.size && this.size == 16">
      <checkmark-filled-warning-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <checkmark-filled-warning-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <checkmark-filled-warning-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <checkmark-filled-warning-32 :class="iconColorClass" />
    </div>
    <div v-else><checkmark-filled-warning-16 :class="iconColorClass" /></div>
  </div>
  <div v-else-if="this.type == 'outline'">
    <div v-if="this.size && this.size == 16">
      <checkmark-outline-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <checkmark-outline-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <checkmark-outline-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <checkmark-outline-32 :class="iconColorClass" />
    </div>
    <div v-else><checkmark-outline-16 :class="iconColorClass" /></div>
  </div>
  <div v-else-if="this.type == 'outline-error'">
    <div v-if="this.size && this.size == 16">
      <checkmark-outline-error-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <checkmark-outline-error-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <checkmark-outline-error-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <checkmark-outline-error-32 :class="iconColorClass" />
    </div>
    <div v-else><checkmark-outline-error-16 :class="iconColorClass" /></div>
  </div>
  <div v-else-if="this.type == 'outline-warning'">
    <div v-if="this.size && this.size == 16">
      <checkmark-outline-warning-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <checkmark-outline-warning-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <checkmark-outline-warning-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <checkmark-outline-warning-32 :class="iconColorClass" />
    </div>
    <div v-else><checkmark-outline-warning-16 :class="iconColorClass" /></div>
  </div>
</template>

<script>
import {
  Checkmark16,
  Checkmark20,
  Checkmark24,
  Checkmark32,
  CheckmarkFilled16,
  CheckmarkFilled20,
  CheckmarkFilled24,
  CheckmarkFilled32,
  CheckmarkFilledError16,
  CheckmarkFilledError20,
  CheckmarkFilledError24,
  CheckmarkFilledError32,
  CheckmarkFilledWarning16,
  CheckmarkFilledWarning20,
  CheckmarkFilledWarning24,
  CheckmarkFilledWarning32,
  CheckmarkOutline16,
  CheckmarkOutline20,
  CheckmarkOutline24,
  CheckmarkOutline32,
  CheckmarkOutlineError16,
  CheckmarkOutlineError20,
  CheckmarkOutlineError24,
  CheckmarkOutlineError32,
  CheckmarkOutlineWarning16,
  CheckmarkOutlineWarning20,
  CheckmarkOutlineWarning24,
  CheckmarkOutlineWarning32,
} from "@carbon/icons-vue";

export default {
  name: "PcCheckmarkIcon",

  components: {
    "checkmark-16": Checkmark16,
    "checkmark-20": Checkmark20,
    "checkmark-24": Checkmark24,
    "checkmark-32": Checkmark32,
    "checkmark-filled-16": CheckmarkFilled16,
    "checkmark-filled-20": CheckmarkFilled20,
    "checkmark-filled-24": CheckmarkFilled24,
    "checkmark-filled-32": CheckmarkFilled32,
    "checkmark-filled-error-16": CheckmarkFilledError16,
    "checkmark-filled-error-20": CheckmarkFilledError20,
    "checkmark-filled-error-24": CheckmarkFilledError24,
    "checkmark-filled-error-32": CheckmarkFilledError32,
    "checkmark-filled-warning-16": CheckmarkFilledWarning16,
    "checkmark-filled-warning-20": CheckmarkFilledWarning20,
    "checkmark-filled-warning-24": CheckmarkFilledWarning24,
    "checkmark-filled-warning-32": CheckmarkFilledWarning32,
    "checkmark-outline-16": CheckmarkOutline16,
    "checkmark-outline-20": CheckmarkOutline20,
    "checkmark-outline-24": CheckmarkOutline24,
    "checkmark-outline-32": CheckmarkOutline32,
    "checkmark-outline-error-16": CheckmarkOutlineError16,
    "checkmark-outline-error-20": CheckmarkOutlineError20,
    "checkmark-outline-error-24": CheckmarkOutlineError24,
    "checkmark-outline-error-32": CheckmarkOutlineError32,
    "checkmark-outline-warning-16": CheckmarkOutlineWarning16,
    "checkmark-outline-warning-20": CheckmarkOutlineWarning20,
    "checkmark-outline-warning-24": CheckmarkOutlineWarning24,
    "checkmark-outline-warning-32": CheckmarkOutlineWarning32,
  },

  props: {
    size: {
      type: [Number, String],
      validator: function (value) {
        return [16, "16", 20, "20", 24, "24", 32, "32"].includes(value);
      },
      required: false,
      default: 16,
    },
    type: {
      type: String,
      required: false,
      default: undefined,
      validator: function (value) {
        return [
          undefined,
          "filled",
          "filled-error",
          "filled-warning",
          "outline",
          "outline-error",
          "outline-warning",
        ].includes(value);
      },
    },
    iconColorClass: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
