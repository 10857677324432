<template>
  <pc-tooltip :disabled="!tooltipText" :value="tooltipText ? tooltipText : ''">
    <div class="pc-status-detail">
      <span v-if="detail" :style="dotStyle" :class="dotClasses"></span>

      <span v-if="detail" class="pc-status-detail-text">{{
        detailFormattedText | sentenceCase
      }}</span>
    </div>
  </pc-tooltip>
</template>
<script>
import PcTooltip from "./PcTooltip.vue";
export default {
  components: { PcTooltip },
  props: {
    status: {
      default: undefined,
    },
    detail: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    dotStyle: function () {
      let dotMustBeRed =
        this.status == "complete" &&
        ["stopped", "returned"].includes(this.detail);
      let colorCode = dotMustBeRed
        ? this.$colors.penChecksRed
        : this.$getStatusTextColor(this.status);
      let dotColor = "background-color: " + colorCode + ";";
      return dotColor;
    },
    tooltipText: function () {
      let tooltipText = undefined;
      let detailHasTooltip = this.detail && tooltipTexts[this.detail];

      if (detailHasTooltip) tooltipText = tooltipTexts[this.detail];

      return tooltipText;
    },
    detailFormattedText: function () {
      let detailText = "";

      if (this.detail) detailText = this.detail.replace("thirty", "30");

      return detailText;
    },
    dotClasses: function () {
      let dotClasses = "pc-status-detail-dot ";
      const twinklingSubstatuses = ["uploading", "submitting", "initiating"];
      let twinkleClassIsNeeded = twinklingSubstatuses.includes(this.detail);

      if (twinkleClassIsNeeded)
        dotClasses = dotClasses + "pc-status-detail-dot-twinkling";

      return dotClasses;
    },
  },
};

const tooltipTexts = {
  waitingForAssets: "Processing will begin once assets are received",
  thirtyDayWait: "Participant elected to wait 30 days before processing",
  defaultedToIra: "Participant did not respond and was defaulted into an IRA",
  defaultedToTsa: "Participant did not respond and was defaulted into a TSA",
  waitingForApproval: " Waiting for approval by Trust department",
  waitingToPay: "One or more payments ",
};
</script>
<style lang="scss">
.pc-status-detail {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  height: min-content;
  width: auto;
}

.pc-status-detail-dot {
  border-radius: 50%;
  height: 8px;
  min-width: 8px;
  padding: 0;
  width: 8px;
  display: inline-block;
}

.pc-status-detail-text {
  padding-left: 8px;
  color: $penChecksDarkGray;
  font-size: 14px;
}

.pc-status-detail-dot-twinkling {
  animation-name: twinkle;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
}

@keyframes twinkle {
  50% {
    transform: scale(0.5);
    opacity: 0.5;
  }
}
</style>
