"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import $ from "jquery";
import Participant from "@/shared/lib/client-sdk/models/participant";

class Payment extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "Payment";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/payments"
    );
  }

  getApiPathForCreate() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/payments"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/payments/" +
      this.id
    );
  }

  getAdminPath() {
    return "/payments/" + this.id;
  }

  getClientPath() {
    return (
      "/" +
      this.masterAccountId +
      "/orders/" +
      this.orderId +
      "#participants:" +
      this.orderLineId
    );
  }

  get relationships() {
    return {
      participant: Participant,
    };
  }

  get searchResultTitle() {
    return "Payable To: " + this.payableTo;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    var subtitle = [];

    if (this.forBenefitOf) {
      subtitle.push("For Benefit Of: " + this.forBenefitOf + "<br />");
    }

    subtitle.push(this.referenceNumber + "<br />");
    subtitle.push(this.effectiveDate);

    return subtitle;
  }

  get searchResultCreatedAt() {
    return this.createdAt;
  }

  get searchResultStatus() {
    return this.status;
  }

  cancel(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/payments/" +
      this.id +
      "/cancel";

    ApiRequest.send("POST", requestUrl, null, Payment).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }
}

export default Payment;
