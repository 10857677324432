"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";

class MailDeliveryMethod extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "MailDeliveryMethod";
  }

  static getApiPathForSearch() {
    var path =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/delivery-methods";

    return path;
  }

  getApiPathForCreate() {
    console.error("Cannot create delivery methods.");
  }

  getApiPathForRefreshUpdateAndDelete() {
    console.error("Cannot update delivery methods.");
  }

  getAdminPath() {
    console.error("Cannot go to a delivery method.");
  }

  get searchResultTitle() {
    return this.displayName;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return this.code;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  get relationships() {
    return {};
  }
}

export default MailDeliveryMethod;
