"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import ApiRequest from "@/shared/lib/client-sdk/api-request";

class InboundParsedJohnHancockFile extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "InboundParsedJohnHancockFile";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/participants/inbound-parsed-john-hancock-files"
    );
  }

  getApiPathForCreate() {
    console.error("Cannot create file.");
  }

  getApiPathForRefreshUpdateAndDelete() {
    console.error("Cannot manipulate file.");
  }

  getAdminPath() {
    return "/participants/" + this.participantId + "#order-lines";
  }

  static changeStatusAutoParticipants(
    selectedAutoParticipantIds,
    status,
    callback
  ) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/participants/change-status-auto-participants";

    var requestData = {
      selectedAutoParticipantIds: selectedAutoParticipantIds,
      status: status,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          callback(response.result, null);
        } else {
          callback(null, response);
        }
      }.bind(this)
    );
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultTitle() {
    return null;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }
}

export default InboundParsedJohnHancockFile;
