<template>
  <v-card
    outlined
    :style="
      selected
        ? (isParticipantPortalField
            ? 'border: 1px solid '
            : 'border: 2px solid ') + selectedColor
        : ''
    "
    class="select-card mb-5"
    @click.native="$emit('click', $event)"
    :id="title ? title.toCamelCase() + 'SelectCard' : undefined"
  >
    <v-card-title class="pb-0">
      <span class="select-card-title"> {{ title }} </span>
      <v-tooltip v-if="$slots.tooltip" bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on" class="pb-1 pl-2">
            <pc-icon
              name="pc-help"
              :size="16"
              :color="$colors.penChecksTeal"
            ></pc-icon>
          </span>
        </template>
        <span><slot name="tooltip"></slot></span>
      </v-tooltip>
      <a @click.stop="$emit('deselect', $event)">
        <v-avatar
          v-if="selected"
          :color="selectedColor"
          :size="24"
          style="position: absolute; right: 6px; top: 6px"
        >
          <pc-icon
            :light="isParticipantPortalField"
            name="pc-checkmark"
            :color="$colors.white"
            :size="13"
          ></pc-icon>
        </v-avatar>
      </a>
    </v-card-title>
    <v-card-text>
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PcSelectCardReskinned",

  components: {},

  props: {
    title: {
      type: String,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    isParticipantPortalField: {
      type: Boolean,
      default: false,
    },
    selectedColor: {
      type: String,
      default: "#98B83C", //$colors.penChecksGreen
    },
  },

  data: function () {
    return {
      hovering: false,
    };
  },

  computed: {},

  methods: {},

  mounted: function () {},
};
</script>

<style lang="scss" scoped>
.select-card {
  cursor: pointer;
  border-radius: 6px !important;
}

.select-card-title {
  color: $penChecksDark;
}
</style>
