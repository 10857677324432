"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";

class InvoiceLine extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "InvoiceLine";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/invoice-lines"
    );
  }

  getApiPathForCreate() {
    console.error("Cannot create invoice lines manually.");
  }

  getApiPathForRefreshUpdateAndDelete() {
    console.error("Cannot update invoice lines individually.");
  }

  getAdminPath() {
    return "/invoices/" + this.invoiceId;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultTitle() {
    return this.id;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }
}

export default InvoiceLine;
