"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import $ from "jquery";

class NationalRegistryAccount extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "NationalRegistryAccount";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/national-registry/accounts"
    );
  }

  getApiPathForCreate() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/national-registry/accounts"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/national-registry/accounts/" +
      this.id
    );
  }

  getAdminPath() {
    return "/national-registry/accounts/" + this.id;
  }

  isMissingAccountCreationData() {
    return (
      this["companyName"] == null ||
      this["contactAddressLine1"] == null ||
      this["contactCity"] == null ||
      this["contactPostalCode"] == null
    );
  }

  static importNationalRegistryParticipants(
    file,
    nationalRegistryAccountId,
    callback
  ) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/national-registry/accounts/import-national-registry-participants/" +
      nationalRegistryAccountId;

    let formData = new FormData();
    formData.append("file", file);

    ApiRequest.upload(requestUrl, formData).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  checkUploadStatus(nationalRegistryAccountId, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/national-registry/accounts/" +
      nationalRegistryAccountId +
      "/upload-status";

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }
}

export default NationalRegistryAccount;
