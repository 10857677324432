"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";

class DepositBatchOrderLine extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "DepositBatchOrderLine";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/deposit-batch-order-lines"
    );
  }

  getApiPathForCreate() {
    console.error(
      "Cannot create a deposit batch order line directly, it is created as part of a deposit batch."
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    console.error("Cannot update a deposit batch order line.");
  }

  getAdminPath() {
    return "/order-lines/" + this.orderLineId;
  }

  get searchResultTitle() {
    return null;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }
}

export default DepositBatchOrderLine;
