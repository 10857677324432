<template>
  <v-text-field
    v-model="formattedDate"
    :rules="required ? dateRules : minimunDateRules"
    :label="label + (required ? '' : ' (optional)')"
    :validate-on-blur="true"
    :placeholder="placeholder"
    :disabled="disabled"
    :clearable="clearable"
    maxlength="10"
    :dense="dense"
    :color="$colors.penChecksTeal"
    @blur="blur"
    @keyup="textChanged"
    :suffix="suffix"
    @keyup.enter="$emit('enter', $event)"
    @focus="placeholder = 'MM/DD/YYYY'"
  ></v-text-field>
</template>
<script>
var moment = require("moment");

export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
    },
    beforeToday: {
      type: Boolean,
    },
    afterToday: {
      type: Boolean,
    },
    dateOfBirth: {
      type: Boolean,
    },
    returnSlashed: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    showAge: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
  },

  data: function () {
    return {
      formattedDate: null,
      placeholder: "",

      dateRules: [
        () => {
          if (this.required && !this.formattedDate)
            return "Please enter a value.";
          return true;
        },
        () => {
          if (
            this.formattedDate &&
            this.dateOfBirth &&
            this.isValid &&
            (moment(this.properDateValue).diff(moment(), "days") >= 0 ||
              moment(this.properDateValue).diff(moment(), "years") < -130)
          )
            return "Please enter a valid date of birth.";
          return true;
        },
        () => {
          if (
            this.formattedDate &&
            this.beforeToday &&
            this.isValid &&
            moment(this.properDateValue).diff(moment(), "days") >= 0
          )
            return "Date must be in the past.";
          return true;
        },
        () => {
          if (
            this.formattedDate &&
            this.afterToday &&
            this.isValid &&
            moment(this.properDateValue).diff(moment(), "days") <= 0
          )
            return "Date must be in the future.";
          return true;
        },
        () => {
          if (this.formattedDate && !this.isValid) {
            this.formattedDate = null;
            return "Please enter a valid date.";
          }
          return true;
        },
      ],

      minimunDateRules: [
        () => {
          if (this.formattedDate && !this.isValid) {
            this.formattedDate = null;
            return "Please enter a valid date.";
          }
          return true;
        },
      ],
    };
  },

  watch: {
    formattedDate() {
      this.$emit("input", this.properDateValue);
    },
    value() {
      if (this.value != this.properDateValue) {
        this.formattedDate = this.value;
        this.formatText();
      }
    },
  },

  computed: {
    suffix: function () {
      if (
        this.showAge &&
        this.isValid &&
        moment(this.properDateValue).diff(moment(), "days") < 0
      ) {
        return Date.yearsAgo(this.formattedDate, true) + " yrs";
      }
      return null;
    },
    properDateValue: function () {
      if (!this.isValid) return null;

      var value = this.returnSlashed
        ? Date.toDateStringWithSlashes(this.formattedDate)
        : Date.toIsoDateString(this.formattedDate);
      if (value.length == 10) return value;

      return null;
    },

    isValid: function () {
      if (this.formattedDate == null || this.formattedDate.trim() == "")
        return false;

      var isoString = Date.toIsoDateString(this.formattedDate);

      if (isoString && isoString.length == 10)
        return moment(isoString).isValid();

      return false;
    },
  },

  methods: {
    textChanged: function (e) {
      var selectionStart = e.target.selectionStart;
      var selectionEnd = e.target.selectionEnd;
      var value = e.target.value;

      if (this.formattedDate != null && this.formattedDate.trim() == "") {
        this.formattedDate = null;
        return;
      }

      var isPasting = (e.ctrlKey || e.metaKey) && e.key.toLowerCase() == "v";

      // Dont format if not at end of string (or pasting)
      if (
        !isPasting &&
        (selectionStart != selectionEnd || selectionStart < value.length)
      ) {
        return;
      }

      if (e.keyCode != 8) {
        // keycode 8 = backspace
        this.formatText();
      }
    },
    blur: function () {
      this.$emit("blur");
      this.placeholder = "";

      if (this.formattedDate && this.formattedDate.length == 8) {
        var start = this.formattedDate.substr(0, 6);

        var year = this.formattedDate.substr(6, 2);
        var intYear = parseInt(year);
        var currentYear = parseInt(new Date().getFullYear() - 2000);

        if (intYear > currentYear) {
          this.formattedDate = start + "19" + year;
        } else {
          this.formattedDate = start + "20" + year;
        }
      }

      this.formatText();
    },
    formatText: function () {
      if (this.formattedDate == null || this.formattedDate.trim() == "") return;

      var numbersOnly = this.formattedDate.replace(/[^\d/]/g, "");

      var pieces = numbersOnly.split("/");
      var month = pieces[0];
      var day = pieces.length > 1 ? pieces[1] : null;
      var year = pieces.length > 2 ? pieces[2] : null;

      if (month.length > 2) {
        day = month.substr(2);
        month = month.substr(0, 2);
      }

      if (day && day.length > 2) {
        year = day.substr(2);
        day = day.substr(0, 2);
      }

      if (day !== null && month && parseInt(month) != 0) {
        month = parseInt(month).toString().padStart(2, "0").substr(0, 2);
      }
      if (year !== null && day && parseInt(day) != 0) {
        day = parseInt(day).toString().padStart(2, "0").substr(0, 2);
      }

      this.formattedDate = month;

      if (month.length == 2 || day) this.formattedDate += "/";

      if (day) {
        this.formattedDate += day;

        if (day.length == 2 || year) this.formattedDate += "/";

        if (year) {
          this.formattedDate += year;
        }
      }
    },
  },

  mounted: function () {
    if (this.value) {
      this.formattedDate = Date.toDateStringWithSlashes(this.value);
    }

    this.formatText();
  },
};
</script>

<style lang="scss"></style>
