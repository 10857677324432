"use strict";

import $ from "jquery";

class ApiResponse {
  constructor(successful, responseData, httpStatus, resultClass, error = null) {
    var message = error;

    if (
      responseData &&
      $.isPlainObject(responseData) &&
      Object.prototype.hasOwnProperty.call(responseData, "status") &&
      Object.prototype.hasOwnProperty.call(responseData.status, "message")
    )
      message = responseData.status.message;

    var result =
      responseData &&
      Object.prototype.hasOwnProperty.call(responseData, "result")
        ? responseData.result
        : null;
    if (result && resultClass) {
      if ($.isArray(result)) {
        var arrayResult = [];
        for (var i = 0; i < result.length; i++) {
          var objectElement = new resultClass(result[i]);
          arrayResult.push(objectElement);
        }
        result = arrayResult;
      } else {
        result = new resultClass(result);
      }
    }

    this.successful = successful; // true/false
    this.message = message; // if there is an error message
    this.httpStatus = httpStatus; // the http status (eg. "200")
    this.result = result; // Whatever the result is from the api
    this.data = responseData; // the full response data object
  }
}

export default ApiResponse;
