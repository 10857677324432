<template>
  <div class="pc-menu-button-container">
    <span v-if="label" class="pc-menu-button-label">
      {{ label }}
    </span>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          outlined
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
          class="pc-menu-button"
        >
          {{ selectedText }}
          <pc-icon
            size="9"
            name="pc-scroll-down"
            class="pc-menu-button-chevron"
          ></pc-icon>
        </v-btn>
      </template>
      <v-list dense class="pc-menu-button-list">
        <v-list-item
          :color="$colors.penChecksDarkGray"
          v-for="(item, $index) in items"
          :key="item.value"
          :disabled="!!item.disabled"
          @click="clickedIndex($index)"
          :class="{
            'pc-menu-button-selected-item': $index == selectedIndex,
            'pc-menu-button-item': true,
          }"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
          <v-list-item-action class="pc-menu-button-checkmark">
            <pc-icon
              v-show="$index == selectedIndex"
              name="pc-checkmark"
              size="16"
              outline
              :color="$colors.penChecksTeal"
            ></pc-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    label: {},
    items: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    defaultTitle: {
      type: String,
      required: false,
    },
    isButtonTitleStatic: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      selectedIndex: 0,
    };
  },
  watch: {
    value: function () {
      if (this.value != this.selectedValue)
        this.selectedIndex = this.items.findIndex((x) => x.value == this.value);
    },
  },
  computed: {
    selectedText: function () {
      if (this.isButtonTitleStatic) {
        return this.defaultTitle;
      }

      if (
        this.items.length > 0 &&
        this.selectedIndex >= 0 &&
        this.selectedIndex < this.items.length
      )
        return this.items[this.selectedIndex]["text"];

      return this.defaultTitle ? this.defaultTitle : "None";
    },
    selectedValue: function () {
      if (
        this.items.length > 0 &&
        this.selectedIndex >= 0 &&
        this.selectedIndex < this.items.length
      )
        return this.items[this.selectedIndex]["value"];

      return null;
    },
  },
  methods: {
    clickedIndex($index) {
      this.selectedIndex = $index;
      this.$emit("input", this.selectedValue);
      this.$emit("change", this.selectedValue);
    },
  },
  mounted: function () {
    if (this.value) {
      this.selectedIndex = this.items.findIndex((x) => x.value == this.value);
    } else {
      this.clickedIndex(0);
    }
  },
};
</script>

<style lang="scss">
.pc-menu-button-container {
  display: inline-block;
}

.v-btn.pc-menu-button {
  color: $penChecksTeal;
  height: 32px !important;
  min-width: 64px !important;
}

.v-btn.pc-menu-button:hover {
  background-color: #ebf3f5 !important;
}

.v-btn.pc-menu-button:active {
  background-color: #d8e8ec !important;
}

.pc-menu-button-label {
  margin-right: 12px;
}

.pc-menu-button-list {
  min-width: 200px;
  padding: 0px !important;

  .v-list-item__title {
    color: $penChecksDarkGray;
  }
}

.pc-menu-button-checkmark {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 16px !important ;
  margin: 0px !important;
}

.pc-menu-button-selected-item {
  background-color: #ebf3f5 !important;
}

.pc-menu-button-chevron {
  padding-left: 8px !important;
}

.pc-menu-buttom-item:hover {
  background-color: $penChecksLight !important;
}

.pc-menu-buttom-item:active {
  background-color: $penChecksLightTeal !important;
}

.pc-menu-button-item {
  .v-list-item__title {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
}
</style>
