var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"in-place-editor",class:{
      editing: _vm.isEditing,
      dirty: _vm.value.isDirty(_vm.field),
      saveError: _vm.error && _vm.error !== '',
      saving: _vm.isSaving,
      saved: _vm.isSaved,
    },on:{"mouseover":function($event){_vm.isHovering = true},"mouseleave":function($event){_vm.isHovering = false}}},[(_vm.type == 'text')?_c('v-text-field',{attrs:{"append-icon":_vm.currentIcon,"loading":_vm.isSaving ? 'warning' : null,"error":_vm.error,"color":_vm.$colors.penChecksTeal,"hint":_vm.error,"label":_vm.label,"dense":_vm.dense},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.enterPressed()}},model:{value:(_vm.value[_vm.field]),callback:function ($$v) {_vm.$set(_vm.value, _vm.field, $$v)},expression:"value[field]"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }