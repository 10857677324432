<template>
  <v-checkbox
    class="pc-checkbox"
    :class="{ 'align-top': alignTop }"
    :dense="dense"
    :color="color"
    v-model="isChecked"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :id="id"
    :hide-details="hideDetails"
    @mouseup="$emit('click')"
  >
    <template v-slot:label v-if="hasSlot('label') || (description && label)">
      <template v-if="hasSlot('label')">
        <slot name="label"></slot>
      </template>
      <template v-else>
        <div class="pc-checkbox-label-container">
          <div class="pc-checkbox-label">{{ label }}</div>
          <div class="pc-checkbox-description">
            {{ description }}
          </div>
        </div>
      </template>
    </template>
  </v-checkbox>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    color: {
      type: String,
      default: "#3D8A9E",
    },
    description: {
      type: String,
    },
    mustBeChecked: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
    hint: {
      type: String,
      default: "This checkbox must be checked",
    },
    alignTop: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      rules: [
        () => {
          if (this.mustBeChecked && !this.isChecked) return this.hint;
          return true;
        },
      ],
      isHovering: false,
      isChecked: false,
    };
  },
  methods: {
    hasSlot(name) {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
  },
  mounted: function () {
    this.isChecked = this.value;
  },
  watch: {
    value: function () {
      this.isChecked = this.value;
      if (this.isChecked != this.value) this.$emit("change");
    },
    isChecked: function () {
      this.$emit("input", this.isChecked);
      if (this.isChecked != this.value) this.$emit("change");
    },
  },
};
</script>

<style lang="scss" scoped>
.pc-checkbox {
  display: flex !important;
  align-self: flex-start;
  width: max-content;
}

.pc-checkbox-label {
  padding-top: 18px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: $penChecksDarkGray;
  display: block;
}

.pc-checkbox-description {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: $penChecksMediumGray;
  display: block;
}

.pc-checkbox-label-container {
  margin-left: 4px;
  text-align: left;
}

.pc-checkbox.v-input--is-disabled {
  .pc-checkbox-label,
  .pc-checkbox-description {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.38);
  }
}

.align-top {
  ::v-deep .v-input__slot {
    align-items: flex-start;
  }
}
</style>
