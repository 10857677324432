<template>
  <div class="pc-dropdown-container">
    <div v-if="showHeader" class="header-style">
      <span v-if="required && !isAccountHolderField" class="error-text">*</span
      >{{ header }}
    </div>
    <v-menu v-model="fieldsMenu" bottom offset-y nudge-bottom="8">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          outlined
          :ripple="false"
          v-bind="attrs"
          v-on="on"
          :width="width"
          :disabled="disabled"
          :class="!fieldsMenu ? 'pc-dropdown' : 'pc-dropdown-clicked'"
          :id="id"
        >
          <span
            v-if="setInitialValue && selectedIndex < 0"
            class="select-initial-style"
            >Select</span
          >
          <v-row class="pl-0 ml-0" justify="center" align="center">
            <v-col class="pl-0 ml-4" align="left">
              <span v-if="selectedIndex >= 0" class="selected-item-style">{{
                showText ? selectedText : value
              }}</span>
            </v-col>
            <v-col align="right" justify="right" class="pr-2 chevron-top">
              <img
                v-if="!fieldsMenu"
                height="16px"
                src="@/participant/assets/img/chevron-up.svg"
              />
              <img
                v-if="fieldsMenu"
                height="16px"
                src="@/participant/assets/img/chevron-down.svg"
              />
            </v-col>
          </v-row>
        </v-btn>
      </template>

      <v-list
        dense
        :width="width"
        :height="height"
        class="pc-dropdown-list v-list-new"
      >
        <v-list-item
          id="no-background-hover"
          v-for="(item, $index) in items"
          :key="item.value"
          :disabled="!!item.disabled"
          :ripple="false"
          @click="clickedIndex($index)"
          :class="{
            'pc-dropdown-selected-item': $index == selectedIndex,
            'pc-dropdown-item': true,
          }"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
          <v-list-item-action class="pc-dropdown-checkmark">
            <pc-checkmark-icon
              v-show="$index == selectedIndex"
              icon-color-class="checkmark-color"
            />
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import PcCheckmarkIcon from "@/shared/components/design-system/PcCheckmarkIcon.vue";
import PcChevronIcon from "@/shared/components/design-system/PcChevronIcon.vue";
export default {
  components: { PcCheckmarkIcon, PcChevronIcon },
  props: {
    value: {},
    id: {
      type: String,
      default: "no-background-hover",
    },
    header: {
      type: String,
      default: "",
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
    width: {
      type: [String, Number],
      default: 256,
    },
    height: {
      type: [String, Number],
      default: 260,
    },
    showText: {
      type: Boolean,
      default: true,
    },
    setInitialValue: {
      type: Boolean,
      default: false,
    },
    isAccountHolderField: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      selectedIndex: 0,
      fieldsMenu: false,
    };
  },
  watch: {
    value: function () {
      if (this.value != this.selectedValue)
        this.selectedIndex = this.items.findIndex((x) => x.value == this.value);
    },
  },
  computed: {
    selectedText: function () {
      if (
        this.items.length > 0 &&
        this.selectedIndex >= 0 &&
        this.selectedIndex < this.items.length
      )
        return this.items[this.selectedIndex]["text"];
    },
    selectedValue: function () {
      if (
        this.items.length > 0 &&
        this.selectedIndex >= 0 &&
        this.selectedIndex < this.items.length
      )
        return this.items[this.selectedIndex]["value"];

      return null;
    },
  },
  methods: {
    clickedIndex($index) {
      this.selectedIndex = $index;
      this.$emit("input", this.selectedValue);
      this.$emit("change", this.selectedValue);
    },
  },
  mounted: function () {
    if (this.value) {
      this.selectedIndex = this.items.findIndex((x) => x.value == this.value);
    } else {
      this.setInitialValue ? this.clickedIndex(-1) : this.clickedIndex(0);
    }
  },
};
</script>

<style lang="scss">
.pc-dropdown-container {
  display: inline-block;
}

.v-menu__content {
  box-shadow: 0px 1px 2px 0px rgba(119, 119, 119, 0.1),
    0px 8px 16px 0px rgba(119, 119, 119, 0.16),
    0px 8px 16px 0px rgba(0, 117, 219, 0.04);
  border-radius: 6px !important;
}

.v-btn.pc-dropdown-clicked {
  color: $neutral60;
  height: 40px !important;

  padding-left: 0px !important;
  margin-left: 0px !important;
}

.v-btn--outlined.pc-dropdown-clicked {
  border-color: $white;
  border-radius: 6px;
  box-shadow: 0 0 0 3px $primary10;
}

.v-btn.pc-dropdown {
  color: $neutral60;
  height: 40px !important;

  padding-left: 0px !important;
  margin-left: 0px !important;
}

.v-btn--outlined.pc-dropdown {
  border-color: $white;
  box-shadow: 0 0 0 1px $neutral10;
  border-radius: 6px;
}

.v-btn.pc-dropdown:hover {
  background-color: $white !important;
}

.v-btn.pc-dropdown:active {
  background-color: $white !important;
}

.pc-dropdown-list {
  padding-left: 8px !important;
  padding-right: 8px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;

  .v-list-item__title {
    color: $neutral60 !important;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px !important;
    letter-spacing: 0.25px;
  }

  .v-list-item:hover {
    background-color: $primary00 !important;
    border-radius: 6px;
  }

  .v-list-item {
    padding: 0 8px;
  }
}

.pc-dropdown-checkmark {
  padding-top: 6px !important;
  padding-bottom: 0px !important;
  padding-left: 16px !important ;
  margin: 0px !important;
}

svg.checkmark-color {
  fill: $neutral90 !important;
}

.chevron-top {
  padding-top: 15px !important;
}

.selected-item-style {
  color: $neutral60 !important;
  text-transform: none;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
}

.select-initial-style {
  color: $neutral40;
  text-transform: none;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  padding-left: 16px !important;
}

.move-right {
  float: right !important;
}
.move-left {
  float: left !important;
}

.pc-dropdown-chevron {
  padding-left: 8px !important;
}

.pc-dropdown-item {
  .v-list-item__title {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
}

#no-background-hover::before {
  background-color: transparent !important;
}

.v-list-new {
  overflow-y: auto;
}

.header-style {
  margin-left: 10px;
  margin-bottom: 8px;
  font-family: "Open Sans", sans-serif;
  color: $neutral70;
  font-size: 12px;
  font-weight: 600;
}

.error-text {
  color: $error50;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
</style>
