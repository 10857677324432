<template>
  <v-app id="my-benefit-election-app">
    <router-view class="ma-0 pa-0"></router-view>
  </v-app>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss"></style>
