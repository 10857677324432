import Account from "@/shared/lib/client-sdk/models/account";
import DepositBatch from "@/shared/lib/client-sdk/models/deposit-batch";
import DocumentBatch from "@/shared/lib/client-sdk/models/document-batch";
import BankTransaction from "@/shared/lib/client-sdk/models/bank-transaction";
import Invoice from "@/shared/lib/client-sdk/models/invoice";
import Order from "@/shared/lib/client-sdk/models/order";
import OrderLine from "@/shared/lib/client-sdk/models/order-line";
import Participant from "@/shared/lib/client-sdk/models/participant";
import Payment from "@/shared/lib/client-sdk/models/payment";
import PaymentBatch from "@/shared/lib/client-sdk/models/payment-batch";
import Plan from "@/shared/lib/client-sdk/models/plan";
import PlanCustodian from "@/shared/lib/client-sdk/models/plan-custodian";
import PlanTrustee from "@/shared/lib/client-sdk/models/plan-trustee";
import User from "@/shared/lib/client-sdk/models/user";
import TaxRefund from "@/shared/lib/client-sdk/models/tax-refund";
import Campaign from "@/shared/lib/client-sdk/models/campaign";
import $ from "jquery";

// This file will extract route objects
class RouteObjects {
  constructor(router) {
    Object.defineProperty(this, "router", { value: router });

    router.beforeEach((to, from, next) => {
      this._refreshedCallbacks = {};
      next();
    });

    router.afterEach((to, from) => {
      this.routeChanged(to, from);
    });

    this._routeParamSuffixClassMap = {
      accountuuid: Account,
      subaccountuuid: Account,
      depositBatchUuid: DepositBatch,
      documentBatchUuid: DocumentBatch,
      bankTransactionUuid: BankTransaction,
      taxRefundUuid: TaxRefund,
      invoiceUuid: Invoice,
      orderuuid: Order,
      bulkOrderUuid: Order,
      orderLineuuid: OrderLine,
      participantuuid: Participant,
      paymentuuid: Payment,
      paymentBatchUuid: PaymentBatch,
      planuuid: Plan,
      planCustodianuuid: PlanCustodian,
      planTrusteeuuid: PlanTrustee,
      useruuid: User,
      campaignUuid: Campaign,
    };

    this._objects = {};

    this._refreshedCallbacks = {};
    this._allRefreshedCallback = null;
  }

  _debug(message, param) {
    // var name = error ? "error" : "warn";
    if (param && this._objects[param] && this._objects[param].constructor) {
      // console[name]("route-object.js", message, '[' + param + '] ' + this._objects[param].constructor.name + '.id=' + this._objects[param].id)
    } else {
      // console[name]("route-object.js", message, Object.keys(this._objects))
    }
  }

  _error(message, param) {
    this._debug(message, param, true);
  }

  // Called whenever the route changes
  routeChanged(to) {
    if (this.router && this.router.app && this.router.app.$urlHash) {
      this.router.app.$urlHash.changeDetected();
    }

    Object.defineProperty(to.params, "getObject", {
      value: this.get.bind(this),
    });
    Object.defineProperty(to.params, "refreshAllObjects", {
      value: this.refresh.bind(this),
    });
    Object.defineProperty(to.params, "onObjectsReady", {
      value: this.ready.bind(this),
    });

    // Delete any objects that are no longer in the route
    for (let key in this._objects) {
      if (!Object.prototype.hasOwnProperty.call(to.params, key)) {
        this._debug("DELETING ROUTE OBJECT", key);
        delete this._objects[key];
      }
    }

    // Refresh any new no objects
    for (let key in to.params) {
      const paramName = key;
      const paramValue = to.params[key];

      const modelClass = this._getModelClassFromRouteParamName(paramName);

      if (!modelClass) continue;

      // If we don't already have the object, or the id has changed, then build a new object
      if (
        !Object.prototype.hasOwnProperty.call(this._objects, paramName) ||
        this._objects[paramName].id != paramValue
      ) {
        this._objects[paramName] = new modelClass(paramValue, () => {
          var success =
            this._objects[paramName] &&
            this._objects[paramName].hasBeenRefreshedFromApiAtLeastOnce();

          if (success) {
            this._routeObjectRefreshed(paramName);
          } else {
            // this._error("FAILED TO LOAD ROUTE OBJECT - REDIRECTING TO ROOT", paramName)
            // this.router.replace("/")
          }
        });

        this._debug("INITIALIZING & REFRESHING ROUTE OBJECT", paramName);
      }
    }
  }

  // Refresh all our route objects
  refresh(callback) {
    if (Object.keys(this._objects).length == 0) {
      if ($.isFunction(callback)) callback();

      return;
    }

    this._debug("REFRESHING ALL ROUTE OBJECTS");

    this._allRefreshedCallback = callback;

    for (var key in this._objects) {
      const object = this._objects[key];

      if (object.isRefreshing) continue;

      this._debug("REFRESHING ROUTE OBJECT", key);

      object.refresh(
        function () {
          this._routeObjectRefreshed(key);
        }.bind(this)
      );
    }
  }

  ready(callback) {
    if (!this._areAnyObjectsRefreshing()) {
      if ($.isFunction(callback)) callback();

      return;
    }

    this._allRefreshedCallback = callback;
  }

  _getModelClassFromRouteParamName(routeParamName) {
    for (var suffix in this._routeParamSuffixClassMap) {
      const modelClass = this._routeParamSuffixClassMap[suffix];
      if (routeParamName.toLowerCase().endsWith(suffix.toLowerCase()))
        return modelClass;
    }
  }

  _routeObjectRefreshed(paramName) {
    this._debug("REFRESHED ROUTE OBJECT", paramName);

    const callbackList = this._refreshedCallbacks[paramName];
    if ($.isArray(callbackList)) {
      for (var i = 0; i < callbackList.length; i++) {
        const callback = callbackList[i];

        if ($.isFunction(callback)) callback(this._objects[paramName]);
      }

      delete this._refreshedCallbacks[paramName];
    }

    if (!this._areAnyObjectsRefreshing()) {
      this._debug("ALL ROUTE OBJECTS DONE REFRESHING");

      if (
        this._allRefreshedCallback != null &&
        $.isFunction(this._allRefreshedCallback)
      ) {
        this._allRefreshedCallback();
        this._allRefreshedCallback = null;
      }
    }

    // TODO: If the route object is done refreshing, but it's invalid, then 404
  }

  _areAnyObjectsRefreshing() {
    for (var key in this._objects) {
      const object = this._objects[key];

      if (object.isRefreshing) return true;
    }

    return false;
  }

  get(routeParamName, callback) {
    if (!Object.prototype.hasOwnProperty.call(this._objects, routeParamName))
      return;

    if (callback && $.isFunction(callback)) {
      // If the object isn't currently refreshing, just return it right away.
      if (!this._objects[routeParamName].isRefreshing) {
        callback(this._objects[routeParamName]);
      } else {
        if (typeof this._refreshedCallbacks[routeParamName] == "undefined")
          this._refreshedCallbacks[routeParamName] = [];

        this._refreshedCallbacks[routeParamName].push(callback);
      }
    }
  }
}

export default RouteObjects;
