"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";

class Fee extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "Fee";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") + "/public/fees"
    );
  }

  getApiPathForCreate() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") + "/private/fees"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/fees/" +
      this.id
    );
  }

  getAdminPath() {
    console.error("Cannot go to a fee.");
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultTitle() {
    return this.displayName;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }
}

export default Fee;
