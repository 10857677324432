<template>
  <div
    class="in-place-editor"
    v-bind:class="{
      editing: isEditing,
      dirty: value.isDirty(field),
      saveError: error && error !== '',
      saving: isSaving,
      saved: isSaved,
    }"
    @mouseover="isHovering = true"
    @mouseleave="isHovering = false"
  >
    <v-text-field
      v-if="type == 'text'"
      :append-icon="currentIcon"
      :loading="isSaving ? 'warning' : null"
      v-model="value[field]"
      :error="error"
      :color="$colors.penChecksTeal"
      :hint="error"
      :label="label"
      v-on:keyup.prevent.enter="enterPressed()"
      :dense="dense"
    >
    </v-text-field>
  </div>
</template>

<script>
import BaseModel from "@/shared/lib/client-sdk/base-model";
import $ from "jquery";

export default {
  components: {},

  props: {
    value: {
      type: BaseModel,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },

  data: () => {
    return {
      isEditing: false,
      isSaving: false,
      isSaved: false,
      isHovering: false,
      error: null,
    };
  },

  computed: {
    currentIcon: function () {
      if (this.isSaving) return "fas fa-spinner-third fa-spin";
      if (this.isSaved) return "fas fa-check-circle";
      if (this.error) return "fas fa-exclamation-circle";
      if (this.isHovering && !this.isEditing) return "fas fa-pen";

      return null;
    },
  },

  methods: {
    makeClean: function () {
      this.error = null;
      this.value.revertToOriginal(this.field);
    },

    forceFocus: function () {
      this.isEditing = true;
      this.$nextTick(
        function () {
          $(this.$el).find("input").first().focus();
        }.bind(this)
      );
    },
    forceBlur: function () {
      this.isEditing = false;
      this.$nextTick(
        function () {
          $(this.$el).find("input").first().blur();
        }.bind(this)
      );
    },
    cancel: function () {
      if (this.isEditing && !this.isSaving) {
        this.forceBlur();
        this.error = null;
        this.value.revertToOriginal(this.field);
      }
    },

    enterPressed: function () {
      this.save();
    },
    save: function () {
      if (this.isEditing && !this.isSaving) {
        this.forceBlur();
        this.error = null;

        if (this.value.isDirty(this.field)) {
          this.isSaving = true;
          this.value.updateAttribute(
            this.field,
            this.value[this.field],
            this._saved.bind(this)
          );
        }
      }
    },
    _saved: function (response) {
      this.isSaving = false;
      this.error = null;

      if (response.successful) {
        this.isSaved = true;

        setTimeout(
          function () {
            this.isSaved = false;
          }.bind(this),
          3000
        );
      } else {
        this.error = response.status.message;
        this.forceFocus();
      }
    },
  },

  mounted() {
    // Document level click handler
    $(document).on(
      "click",
      function (event) {
        if (event.target === this.$el || this.$el.contains(event.target)) {
          this.forceFocus();
          this.makeClean();
        } else {
          // If you click off the element, it will try and save
          this.save();
        }
      }.bind(this)
    );

    // Document level key listner. Escape = cancel
    $(document).keyup(
      function (e) {
        if (e.keyCode == 27) this.cancel();
      }.bind(this)
    );
  },
};
</script>

<style lang="scss">
.in-place-editor:not(.editing) {
  cursor: pointer;

  input,
  .v-input__slot {
    cursor: pointer;
  }

  // This gets rid of the underline under the textbox when you aren't in edit mode
  .v-text-field > .v-input__control > .v-input__slot:before,
  .v-text-field > .v-input__control > .v-input__slot:after {
    content: none;
  }
}

// This shows the underline back when you hover over the element
.in-place-editor:not(.editing):hover {
  .v-text-field > .v-input__control > .v-input__slot:before,
  .v-text-field > .v-input__control > .v-input__slot:after {
    content: "";
  }
}

.in-place-editor.saving i {
  color: $penChecksOrange;
}
.in-place-editor.saved i {
  color: $penChecksGreen;
}
.in-place-editor.saveError i {
  color: $penChecksRed;
}
</style>
