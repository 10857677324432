"use strict";

import BaseModelList from "./base-model-list";
import ApiRequest from "@/shared/lib/client-sdk/api-request.js";

class EntityDocumentsList extends BaseModelList {
  constructor(parentModel) {
    super(parentModel);
  }

  getModelClassName() {
    return "EntityDocument";
  }

  getApiPathForSearch() {
    return (
      this.parentModel
        .getApiPathForRefreshUpdateAndDelete()
        .replace("/public/", "/private/") + "/documents"
    );
  }

  getApiPathForAdd() {
    return (
      this.parentModel
        .getApiPathForRefreshUpdateAndDelete()
        .replace("/public/", "/private/") + "/documents"
    );
  }

  getApiPathForRefreshUpdateAndDelete(entityDocument) {
    return entityDocument.getApiPathForRefreshUpdateAndDelete();
  }

  add(document, callback) {
    this.currentOperation = "adding";

    var requestUrl = this.getApiPathForAdd();

    let formData = new FormData();
    formData.append("file", document);

    ApiRequest.upload(requestUrl, formData).onComplete(
      function () {
        this.refresh(callback, "adding");
      }.bind(this)
    );
  }
}

export default EntityDocumentsList;
