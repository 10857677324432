<template>
  <!-- cache-items is REQUIRED when loading additional options asynchronously and using the multiple prop -->
  <v-combobox
    class="api-entity-search"
    :class="{ plain: plain }"
    v-model="selectedValue"
    :items="searchResults"
    :loading="searchRequestHandler.isBusy"
    loader-height="3"
    :search-input.sync="query"
    no-filter
    :hide-details="hideDetails"
    item-text="searchResultTitle"
    item-value="id"
    :color="dark ? $colors.penChecksLight : $colors.penChecksTeal"
    :item-color="dark ? $colors.penChecksLight : $colors.penChecksTeal"
    :rules="required ? selectRules : []"
    :label="label"
    :disabled="loadingInitialValue || disabled"
    :placeholder="placeholder"
    :prepend-icon="prependIcon"
    :clearable="clearable"
    :chips="multiple"
    :menu-props="{ offsetY: true, maxWidth: menuMaxWidth, nudgeTop: -2 }"
    :multiple="multiple"
    :dense="dense"
    :id="idPrefix"
    @keydown.enter="$emit('enter', query)"
  >
    <!-- Only used for adding an ID to the list  -->
    <template v-slot:prepend-item v-if="idPrefix">
      <div :id="idPrefix + 'MenuList'"></div>
    </template>

    <template v-slot:item="data">
      <div
        :class="{
          'pc-api-entity-search-item': true,
          'pc-api-entity-search-item-with-chip':
            data.item.searchResultCreatedAt || data.item.searchResultStatus,
        }"
      >
        <span
          :title="
            extractTextFromHtml(
              $options.filters.highlightQuery(
                data.item.searchResultTitle,
                query
              )
            )
          "
        >
          <v-list-item-title>
            <span
              v-html="
                $options.filters.highlightQuery(
                  data.item.searchResultTitle,
                  query
                )
              "
            ></span>
          </v-list-item-title>
        </span>

        <v-list-item-subtitle v-if="data.item.searchResultSubtitle">
          <span
            v-for="(subtitle, $index) in asArray(
              data.item.searchResultSubtitle
            )"
            :key="$index"
            :title="
              extractTextFromHtml(
                $options.filters.highlightQuery(subtitle, query)
              )
            "
          >
            <span
              v-html="$options.filters.highlightQuery(subtitle, query)"
            ></span>
          </span>
        </v-list-item-subtitle>
      </div>
      <v-spacer></v-spacer>

      <div
        v-if="data.item.searchResultCreatedAt || data.item.searchResultStatus"
      >
        <v-list-item-action-text v-if="data.item.searchResultCreatedAt">
          {{
            $options.filters.formatDate(data.item.searchResultCreatedAt)
          }} </v-list-item-action-text
        ><br />
        <pc-status-chip
          v-if="data.item.searchResultStatus"
          v-model="data.item.searchResultStatus"
          x-small
        ></pc-status-chip>
      </div>
    </template>
  </v-combobox>
</template>

<script>
import $ from "jquery";
import SearchRequestHandler from "@/shared/lib/client-sdk/search/search-request-handler";

export default {
  components: {},

  props: {
    value: {},
    label: {
      type: String,
      default: "Search",
    },
    placeholder: {
      type: String,
      default: "Start typing to search...",
    },
    entityType: {
      type: String,
      required: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    noEmptySearches: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    customSearchRequestHandler: {
      type: SearchRequestHandler,
    },
    fullObjects: {
      type: Boolean,
      default: false,
    },
    plain: {
      type: Boolean,
      default: false,
    },
    maxWidth: {},
    autoWidth: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
    },
    filters: {},
    idPrefix: {
      type: String,
      default: undefined,
    },
  },

  data: function () {
    return {
      menuMaxWidth: "auto",
      initialized: false,
      loadingInitialValue: false,
      initialSearchRequestHandler: this.customSearchRequestHandler
        ? this.customSearchRequestHandler.clone()
        : new SearchRequestHandler(this.entityType),
      searchRequestHandler: this.customSearchRequestHandler
        ? this.customSearchRequestHandler
        : new SearchRequestHandler(this.entityType),
      query: "",
      typingTimer: null,
      selectedValue: null,
      selectRules: [(v) => !!v || "Please enter " + this.label.toLowerCase()],
      resizeObserver: new ResizeObserver(resizeObserverHandler.bind(this)),
    };
  },

  watch: {
    query(value) {
      if (!this.initialized) return;

      if (this.noEmptySearches && (!value || value == "")) return;

      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(() => {
        this.searchRequestHandler.cancel();
        this.searchRequestHandler.generalFilter = value;
        this.searchRequestHandler.search();
      }, 700);
    },
    selectedValue(newValue) {
      if (!this.multiple) {
        this.$emit("select", newValue);
        if (newValue && newValue.id) {
          this.$emit("input", newValue.id);
        } else {
          this.$emit("input", null);
        }
      } else {
        var newItems = [];
        var ids = [];
        for (var i = 0; i < newValue.length; i++) {
          if (typeof newValue[i] == "object") {
            newItems.push(newValue[i]);
            ids.push(newValue[i].id);
          }
        }

        if (newItems.length != this.selectedValue.length)
          this.selectedValue = newItems;

        this.$emit("select", newItems);
        this.$emit("input", ids);
      }
    },
    value(newValue) {
      this.value = newValue;
    },
    fullObjects() {
      this.initialSearchRequestHandler.returnObjects = this.fullObjects;
      this.searchRequestHandler.returnObjects = this.fullObjects;
    },
    filters() {
      this.refreshFilters();
      this.searchRequestHandler.search();
    },
  },

  computed: {
    searchResults: function () {
      return this.searchRequestHandler.searchResult.results;
    },
  },

  methods: {
    asArray(value) {
      return $.isArray(value) ? value : [value];
    },
    refreshFilters() {
      var filters = this.filters;

      this.searchRequestHandler.clearFilters();
      this.searchRequestHandler.clearPrivateFilters();

      if (filters)
        this.searchRequestHandler.privateSearchFilters = filters.split("&");
    },
    setResizeObserver: function () {
      this.resizeObserver.observe(this.$el);
    },
    unsetResizeObserver: function () {
      this.resizeObserver.disconnect();
    },
    extractTextFromHtml: function (text) {
      let textWithoutHtml = "";
      if (text && typeof text == "string")
        textWithoutHtml = text.replace(/<[^>]+>/g, "");
      return textWithoutHtml;
    },
  },

  beforeDestroy: function () {
    this.unsetResizeObserver();
  },

  mounted: function () {
    this.refreshFilters();

    this.setResizeObserver();

    this.initialSearchRequestHandler.privateSearchFilters =
      this.searchRequestHandler.privateSearchFilters;
    this.initialSearchRequestHandler.returnObjects = this.fullObjects;
    this.searchRequestHandler.returnObjects = this.fullObjects;

    if (this.value && (!$.isArray(this.value) || this.value.length > 0)) {
      this.loadingInitialValue = true;

      if ($.isArray(this.value) && this.value.length > 0) {
        this.selectedValue = [];
        var selectedIds = [];
        for (var i = 0; i < this.value.length; i++) {
          this.selectedValue.push({
            id: this.value[i],
            searchResultTitle: "Loading...",
          });

          selectedIds.push(this.value[i]);
        }

        // load pre-selected items
        this.initialSearchRequestHandler.addPrivateFilter(
          "Id",
          "isOneOf",
          "[" + selectedIds.join(",") + "]"
        );
      } else if (!$.isArray(this.value)) {
        this.selectedValue = {
          id: this.value,
          searchResultTitle: "Loading...",
        };

        // load pre-selected items
        this.initialSearchRequestHandler.addPrivateFilter(
          "Id",
          "isOneOf",
          this.selectedValue.id
        );
      }

      this.initialSearchRequestHandler.search((result) => {
        this.loadingInitialValue = false;
        if (result.results.length == 1 && !this.multiple) {
          this.selectedValue = result.results[0];
          this.$emit("input", this.selectedValue.id);
        } else if (result.results.length >= 1 && this.multiple) {
          var filtered = [];
          this.value.forEach((includeValue) => {
            var item = result.results.find((x) => x.id == includeValue);
            if (item) filtered.push(item);
          });

          this.selectedValue = filtered;
        }

        if (!this.noEmptySearches) {
          this.searchRequestHandler.search(() => {
            this.initialized = true;
          });
        } else {
          this.initialized = true;
        }
      });
    } else if (!this.noEmptySearches) {
      this.searchRequestHandler.search(() => {
        this.initialized = true;
      });
    } else {
      this.initialized = true;
    }
  },
};

const resizeObserverHandler = function (entries) {
  this.menuMaxWidth = "auto";
  for (let entry of entries) {
    if (entry.contentRect && entry.contentRect.width && !this.autoWidth) {
      this.menuMaxWidth = entry.contentRect.width;
    }
  }
};
</script>

<style lang="scss">
.highlight-search-term {
  background-color: #ffff00 !important;
}

.api-entity-search {
  &.plain {
    .v-input__icon--append {
      display: none;
    }
  }
}

.pc-api-entity-search-item-with-chip {
  max-width: 80%;
}

.pc-api-entity-search-item {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
