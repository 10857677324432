<template>
  <center>
    <v-card
      :height="dynamicHeight"
      outlined
      class="mb-1"
      style="overflow-y: scroll; overflow-x: hidden"
    >
      <v-overlay :value="isLoading" :opacity="0.8" absolute style="z-index: 15">
        <div style="text-align: center">
          <div
            style="
              width: 100px;
              height: 100px;
              border-radius: 50%;
              margin: auto;
              padding-top: 13px;
              background-color: #000000;
            "
          >
            <v-progress-circular indeterminate size="72"></v-progress-circular>
          </div>
        </div>
      </v-overlay>
      <v-card-text>
        <pdf
          v-for="i in totalPages"
          :key="i"
          :src="fileLoader"
          :page="i"
          @loaded="isLoading = false"
        ></pdf>
      </v-card-text>
    </v-card>
  </center>
</template>

<script>
import pdf from "vue-pdf";

export default {
  components: {
    pdf,
  },

  props: {
    fileId: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: "500px",
    },
    heightAutoResize: {
      type: Boolean,
    },
    heightOffset: {
      type: Number,
      default: 0,
    },
  },

  data: function () {
    return {
      isLoading: true,
      dynamicHeight: this.height,
      totalPages: null,
      fileLoader: pdf.createLoadingTask(
        window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
          "/public/files/" +
          this.fileId +
          "/view"
      ),
    };
  },

  methods: {
    recalculateHeight() {
      if (!this.heightAutoResize) {
        return this.dynamicHeight;
      }
      var windowHeight = window.innerHeight;

      this.dynamicHeight = windowHeight - this.heightOffset + "px";
    },
  },

  watch: {
    heightOffset() {
      this.recalculateHeight();
    },
  },

  mounted: function () {
    this.fileLoader.promise.then((pdf) => {
      this.totalPages = pdf.numPages;
    });

    window.addEventListener("resize", () => this.recalculateHeight());
  },
};
</script>

<style lang="scss"></style>
