<template>
  <!-- use v-if="show" to init the modal when it is opened (v-if over v-show since that removes the modal from the DOM and adds it again on show) -->
  <v-dialog
    v-if="show"
    v-model="showing"
    :max-width="maxWidth"
    :persistent="persistent"
    :content-class="
      'singleton-modal ' + (draggable ? ' draggable ' : '') + contentClass
    "
    scrollable
    :class="modalClasses"
  >
    <v-card
      :height="height"
      :class="{ 'pc-modal-card': true, 'pc-modal-spaced-columns': spacedCols }"
    >
      <v-toolbar
        :color="$colors.penChecksTeal"
        elevation="0"
        :height="capSize"
        style="overflow: hidden"
      >
        <v-spacer></v-spacer>
        <v-toolbar-title
          :id="setId('Title')"
          :style="'color: ' + $colors.white"
        >
          {{ title }}
          <div v-if="subtitle" class="pc-modal-subtitle">{{ subtitle }}</div>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <pc-button
          text
          style="'position: absolute; right: 5px; top: 10px;"
          @click="close"
          class="pa-0"
          small
          id="closeButton"
        >
          <pc-icon
            name="pc-close-x"
            :color="$colors.white"
            size="18"
            light
          ></pc-icon>
        </pc-button>
      </v-toolbar>

      <v-card-text style="max-height: 500px; padding: 0px 40px 20px 40px">
        <pc-error-alert
          :api-response="apiResponse"
          :errors="errors"
          :error="error"
        ></pc-error-alert>

        <slot></slot>
      </v-card-text>

      <v-card-actions class="pc-modal-actions">
        <v-spacer></v-spacer>
        <div>
          <slot name="modal-buttons"></slot>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiResponse from "@/shared/lib/client-sdk/api-response";

export default {
  name: "PcCappedModal",

  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },

    draggable: {
      type: Boolean,
    },
    apiResponse: {
      type: ApiResponse,
    },
    errors: {
      type: Array,
    },
    error: {
      type: String,
    },
    size: {
      type: String,
      default: "normal",
    },
    idPrefix: {
      type: String,
    },
    height: {
      type: [String, Number],
      default: 500,
    },
    centerTitle: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: String,
      default: undefined,
    },
    spacedCols: {
      type: Boolean,
      default: false,
    },
    modalClasses: {
      type: String,
      default: "",
    },
    capSize: {
      type: Number,
      defult: 50,
    },
  },

  data: function () {
    return {
      maxWidth: 600,
      showing: false,
    };
  },

  watch: {
    show: function () {
      this.showing = this.show;
    },
    showing: function () {
      if (!this.showing) {
        this.$emit("close");
      }
    },
    size: function () {
      this.setModalSize();
    },
  },

  methods: {
    close() {
      this.$emit("close");
      this.$emit("click:close");
    },
    setId: function (idSufix) {
      return this.idPrefix && idSufix ? this.idPrefix + idSufix : undefined;
    },
    setModalSize: function () {
      switch (this.size) {
        case "small":
          this.maxWidth = 450;
          break;
        case "large":
          this.maxWidth = 750;
          break;
        case "xlarge":
          this.maxWidth = 950;
          break;
        case "xxlarge":
          this.maxWidth = 2048;
          break;
        default:
          this.maxWidth = 600;
      }
    },
  },

  computed: {},

  mounted: function () {
    this.showing = this.show;
    this.setModalSize();
  },
};
</script>

<style lang="scss">
.singleton-modal.draggable {
  .v-card__title {
    cursor: all-scroll !important;
  }
}

.singleton-modal {
  .v-card__title.pc-modal-title-with-subtitle {
    display: inline-block !important;
  }

  .v-card__title > .pc-modal-subtitle {
    font-size: 14px;
    margin-top: -10px;
  }
}

.pc-modal-card {
  .v-card__text {
    padding-left: 18px !important;
    padding-right: 18px !important;
    padding-bottom: 5px !important;
    max-height: 600px !important;
  }
}

.pc-modal-actions {
  padding-right: 17px;
  padding-bottom: 17px;
}

.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}

.pc-modal-spaced-columns {
  .col {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}

.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}

.v-input--expand .v-input__slot {
  .v-label {
    display: block;
    flex: 1;
    font-size: 20px;
  }
}
</style>
