<template>
  <div>
    <v-col cols="2">
      <pc-api-entity-search
        label="Master Account"
        entity-type="Account"
        v-model="values['masterAccountId']"
        :custom-search-request-handler="masterAccountSearchHandler"
        @select="selectMasterAccount"
        no-empty-searches
      ></pc-api-entity-search>
    </v-col>
    <v-col cols="2">
      <pc-api-entity-search
        label="Subaccount"
        entity-type="Account"
        v-model="values['subaccountId']"
        :custom-search-request-handler="subaccountSearchHandler"
        @select="selectSubaccount"
        no-empty-searches
      ></pc-api-entity-search>
    </v-col>
    <v-col cols="2">
      <pc-api-entity-search
        label="Plan"
        entity-type="Plan"
        v-model="values['planId']"
        :custom-search-request-handler="planSearchHandler"
        @select="selectPlan"
        no-empty-searches
      ></pc-api-entity-search>
    </v-col>
    <v-col cols="2">
      <pc-api-entity-search
        label="Order"
        entity-type="Order"
        v-model="values['orderId']"
        :custom-search-request-handler="orderSearchHandler"
        @select="selectOrder"
        no-empty-searches
      ></pc-api-entity-search>
    </v-col>
    <v-col cols="2">
      <pc-api-entity-search
        label="Order Line"
        entity-type="OrderLine"
        v-model="values['orderLineId']"
        :custom-search-request-handler="orderLineSearchHandler"
        @select="selectOrderLine"
        no-empty-searches
      ></pc-api-entity-search>
    </v-col>
    <v-col cols="2">
      <pc-api-entity-search
        label="Payment Instruction"
        entity-type="PaymentInstruction"
        v-model="values['paymentInstructionId']"
        :custom-search-request-handler="paymentInstructionSearchHandler"
        @select="selectPaymentInstruction"
        no-empty-searches
      ></pc-api-entity-search>
    </v-col>
  </div>
</template>
<script>
import SearchRequestHandler from "@/shared/lib/client-sdk/search/search-request-handler";

export default {
  props: {
    value: {
      type: Object, // Should be an object with keys {masterAccountId: '', subaccountId: '', planId: '', orderId: '', orderLineId: '', paymentInstructionId: ''}
      default: function () {
        return {};
      },
    },

    cols: {
      type: Number,
      default: 2,
    },

    masterAccount: {
      type: Boolean,
    },
    subaccount: {
      type: Boolean,
    },
    plan: {
      type: Boolean,
    },
    order: {
      type: Boolean,
    },
    orderLine: {
      type: Boolean,
    },
    paymentInstruction: {
      type: Boolean,
    },
  },

  watch: {
    values: {
      handler: function () {
        this.$emit("input", this.values);
      },
      deep: true,
    },
  },

  data: function () {
    return {
      changeKey: "key1",
      values: {
        masterAccountId: null,
        masterAccount: null,
        subaccountId: null,
        subaccount: null,
        planId: null,
        plan: null,
        orderId: null,
        order: null,
        orderLineId: null,
        orderLine: null,
        paymentInstructionId: null,
        paymentInstruction: null,
      },
      totalInitalized: 0,
      initializationComplete: false,

      masterAccountSearchHandler: new SearchRequestHandler("Account"),
      subaccountSearchHandler: new SearchRequestHandler("Account"),
      planSearchHandler: new SearchRequestHandler("Plan"),
      orderSearchHandler: new SearchRequestHandler("Order"),
      orderLineSearchHandler: new SearchRequestHandler("OrderLine"),
      paymentInstructionSearchHandler: new SearchRequestHandler(
        "PaymentInstruction"
      ),
    };
  },

  methods: {
    refresh: function () {
      this.changeKey = this.changeKey == "key1" ? "key2" : "key1";
    },

    selectMasterAccount: function (model) {
      this.values["masterAccountId"] =
        typeof model.id == "undefined" ? null : model.id;
      this.values["masterAccount"] = this.values["masterAccountId"]
        ? model
        : null;

      this.subaccountSearchHandler.clearFilters();
      this.subaccountSearchHandler.addPrivateFilter(
        "IsSubaccount",
        "equals",
        "true"
      );
      this.planSearchHandler.clearFilters();
      this.orderSearchHandler.clearFilters();
      this.orderLineSearchHandler.clearFilters();
      this.paymentInstructionSearchHandler.clearFilters();

      if (model.id) {
        if (
          !this.values["subaccount"] ||
          !this.values["subaccount"].masterAccount ||
          this.values["subaccount"].masterAccount.id != model.id
        )
          this.clearFrom("subaccount");

        this.subaccountSearchHandler.addPrivateFilter(
          "MasterAccount",
          "equals",
          this.values["masterAccountId"]
        );
        this.planSearchHandler.addPrivateFilter(
          "AccountId",
          "equals",
          this.values["masterAccountId"]
        );
        this.orderSearchHandler.addPrivateFilter(
          "AccountId",
          "equals",
          this.values["masterAccountId"]
        );
        this.orderLineSearchHandler.addPrivateFilter(
          "AccountId",
          "equals",
          this.values["masterAccountId"]
        );
        this.paymentInstructionSearchHandler.addPrivateFilter(
          "AccountId",
          "equals",
          this.values["masterAccountId"]
        );
      }
    },
    selectSubaccount: function (model) {
      this.values["subaccountId"] =
        typeof model.id == "undefined" ? null : model.id;
      this.values["subaccount"] = this.values["subaccountId"] ? model : null;

      this.planSearchHandler.clearFilters();
      this.orderSearchHandler.clearFilters();
      this.orderLineSearchHandler.clearFilters();
      this.paymentInstructionSearchHandler.clearFilters();

      if (model.id) {
        if (
          !this.values["plan"] ||
          !this.values["plan"].subaccount ||
          this.values["plan"].subaccount.id != model.id
        )
          this.clearFrom("plan");

        this.planSearchHandler.addPrivateFilter(
          "AccountId",
          "equals",
          this.values["subaccountId"]
        );
        this.orderSearchHandler.addPrivateFilter(
          "AccountId",
          "equals",
          this.values["subaccountId"]
        );
        this.orderLineSearchHandler.addPrivateFilter(
          "AccountId",
          "equals",
          this.values["subaccountId"]
        );
        this.paymentInstructionSearchHandler.addPrivateFilter(
          "AccountId",
          "equals",
          this.values["subaccountId"]
        );
      }
    },
    selectPlan: function (model) {
      this.values["planId"] = typeof model.id == "undefined" ? null : model.id;
      this.values["plan"] = this.values["planId"] ? model : null;

      this.orderSearchHandler.clearFilters();
      this.orderLineSearchHandler.clearFilters();
      this.paymentInstructionSearchHandler.clearFilters();

      if (model.id) {
        if (
          !this.values["order"] ||
          !this.values["order"].plan ||
          this.values["order"].plan.id != model.id
        )
          this.clearFrom("order");

        this.orderSearchHandler.addPrivateFilter(
          "PlanId",
          "equals",
          this.values["planId"]
        );
        this.orderLineSearchHandler.addPrivateFilter(
          "PlanId",
          "equals",
          this.values["planId"]
        );
        this.paymentInstructionSearchHandler.addPrivateFilter(
          "PlanId",
          "equals",
          this.values["planId"]
        );
      }
    },
    selectOrder: function (model) {
      this.values["orderId"] = typeof model.id == "undefined" ? null : model.id;
      this.values["order"] = this.values["orderId"] ? model : null;

      this.orderLineSearchHandler.clearFilters();
      this.paymentInstructionSearchHandler.clearFilters();

      if (model.id) {
        if (
          !this.values["orderLine"] ||
          this.values["orderLine"].orderId != model.id
        )
          this.clearFrom("orderLine");

        this.orderLineSearchHandler.addPrivateFilter(
          "PlanId",
          "equals",
          this.values["orderId"]
        );
        this.paymentInstructionSearchHandler.addPrivateFilter(
          "PlanId",
          "equals",
          this.values["orderId"]
        );
      }
    },
    selectOrderLine: function (model) {
      this.values["orderLineId"] =
        typeof model.id == "undefined" ? null : model.id;
      this.values["orderLine"] = this.values["orderLineId"] ? model : null;

      this.paymentInstructionSearchHandler.clearFilters();

      if (model.id) {
        if (
          !this.values["paymentInstruction"] ||
          this.values["paymentInstruction"].orderLineId != model.id
        )
          this.clearFrom("paymentInstruction");

        this.paymentInstructionSearchHandler.addPrivateFilter(
          "orderLineId",
          "equals",
          this.values["orderLineId"]
        );
      }
    },
    selectPaymentInstruction: function (model) {
      this.values["paymentInstructionId"] =
        typeof model.id == "undefined" ? null : model.id;
      this.values["paymentInstruction"] = this.values["paymentInstructionId"]
        ? model
        : null;
    },

    clearFrom: function (modelType) {
      switch (modelType) {
        case "masterAccount":
          this.values["masterAccountId"] = null;
          this.values["masterAccount"] = null;
          break;
        case "subaccount":
          this.values["subaccountId"] = null;
          this.values["subaccount"] = null;
          break;
        case "plan":
          this.values["planId"] = null;
          this.values["plan"] = null;
          break;
        case "order":
          this.values["orderId"] = null;
          this.values["order"] = null;
          break;
        case "orderLine":
          this.values["orderLineId"] = null;
          this.values["orderLine"] = null;
          break;
        case "paymentInstruction":
          this.values["paymentInstructionId"] = null;
          this.values["paymentInstruction"] = null;
          break;
      }

      this.refresh();
    },
  },

  computed: {
    lastSelectedModel: function () {
      if (this.values["paymentInstructionId"]) return "paymentInstruction";
      if (this.values["orderLineId"]) return "orderLine";
      if (this.values["orderId"]) return "order";
      if (this.values["planId"]) return "plan";
      if (this.values["subaccountId"]) return "subaccount";
      if (this.values["masterAccountId"]) return "masterAccount";

      return null;
    },
  },

  mounted: function () {
    this.values["masterAccountId"] = this.value["masterAccountId"] ?? null;
    this.values["subaccountId"] = this.value["subaccountId"] ?? null;
    this.values["planId"] = this.value["planId"] ?? null;
    this.values["orderId"] = this.value["orderId"] ?? null;
    this.values["orderLineId"] = this.value["orderLineId"] ?? null;
    this.values["paymentInstructionId"] =
      this.value["paymentInstructionId"] ?? null;

    this.masterAccountSearchHandler.addPrivateFilter(
      "IsMasterAccount",
      "equals",
      "true"
    );
    this.subaccountSearchHandler.addPrivateFilter(
      "IsSubaccount",
      "equals",
      "true"
    );

    this.$emit("input", this.values);
  },
};
</script>

<style lang="scss"></style>
