import Vue from "vue";
import VueRouter from "vue-router";
import RouteObjects from "@/shared/router/route-objects";
import AccountHolderStore from "@/nextlevel-account-holder/store";

var router = {};

if (window.location.host.match(/nextlevel.*\.penchecks\.com/g) !== null) {
  Vue.use(VueRouter);

  const routes = [
    {
      path: "/",
      name: "Account Holder Home",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/PcNextLevelHome.vue"),
    },
    {
      path: "/landing/:userUuid([a-zA-Z0-9]{32})?",
      name: "Account Holder Landing",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/PcLandingPage.vue"),
    },
    {
      path: "/start",
      name: "Get Started",
      meta: { /* title: "Home", */ allowsAnonymousUser: true },
      component: () => import("../views/PcLandingPage.vue"),
    },
    {
      path: "/open-account",
      name: "Open Account",
      meta: { /* title: "Home", */ allowsAnonymousUser: true },
      component: () => import("../views/PcOpenNliAccount.vue"),
    },
    {
      path: "/apply-account",
      name: "Apply Account",
      meta: { /* title: "Home", */ allowsAnonymousUser: true },
      component: () => import("../views/PcApplyNliAccount.vue"),
    },
    {
      path: "/view-account",
      name: "View Account",
      meta: { /* title: "Home", */ allowsAnonymousUser: true },
      component: () => import("../views/PcViewNliAccount.vue"),
    },
    {
      path: "/transfer-assets",
      name: "Transfer Assets",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/PcTransferAssets.vue"),
    },
    {
      path: "/login",
      name: "Login",
      meta: { /* title: "Home", */ allowsAnonymousUser: true },
      component: () => import("../views/PcLogin.vue"),
    },
    {
      path: "/options",
      name: "Options",
      meta: { /* title: "Home", */ allowsAnonymousUser: true },
      component: () => import("../views/PcNliOptions.vue"),
    },
    {
      path: "/forgot-password",
      name: "Forgot Password",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/PcForgotPassword.vue"),
    },
    {
      path: "/forgot-email",
      name: "Forgot Email",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/PcForgotEmail.vue"),
    },
    {
      path: "/reset-password/:email_address/:reset_password_token",
      name: "Reset Password",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/PcResetPassword.vue"),
    },
    {
      path: "/confirm-email-address",
      name: "Confirm Email Address",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/PcConfirmEmailAddress.vue"),
    },
    {
      path: "/change-email-address/:emailAddress/:token",
      name: "Change Email Address",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/PcChangeEmailAddress.vue"),
    },
    {
      path: "/my-account",
      name: "My Account",
      component: () => import("../views/PcMyAccount.vue"),
    },
    {
      path: "/my-account/create-login",
      name: "My Account - Create Login",
      component: () => import("../views/PcMyAccount.vue"),
    },
    {
      path: "/my-account/edit-login",
      name: "My Account - Edit Login",
      component: () => import("../views/PcMyAccount.vue"),
    },
    {
      path: "/my-account/edit-login/change-email-address",
      name: "My Account - Edit login - Change email address",
      component: () => import("../views/PcMyAccount.vue"),
    },
    {
      path: "/my-account/edit-login/change-password",
      name: "My Account - Edit login - Change password",
      component: () => import("../views/PcMyAccount.vue"),
    },
    {
      path: "/my-account/edit-my-information",
      name: "My Account - Edit my information",
      component: () => import("../views/PcMyAccount.vue"),
    },
    {
      path: "/my-account/edit-contact",
      name: "My Account - Edit contact",
      component: () => import("../views/PcMyAccount.vue"),
    },
  ];

  router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
  });

  router.objects = new RouteObjects(router);

  router.beforeEach((to, from, next) => {
    function resolveNextRoute() {
      if (to.matched.some((record) => record.meta.allowsAnonymousUser)) {
        // if (AccountHolderStore.state.authenticatedUser) {
        //   if (
        //     to.path != "/" &&
        //     AccountHolderStore.state.authenticatedUser.lastPasswordChangeAt !==
        //       null &&
        //     to.path != "/confirm-email-address"
        //   ) {
        //     next("/");
        //   }
        // }

        next();
      } else {
        // if (!AccountHolderStore.state.authenticatedUser) {
        //   if (to.path != "/") {
        //     next("/");
        //   }
        // }

        next();
      }
    }

    // wait for authenticated user to finish loading before resolving route
    if (AccountHolderStore.state.isLoadingAuthenticatedUser) {
      AccountHolderStore.watch(
        (state) => state.isLoadingAuthenticatedUser,
        (loading) => {
          if (!loading) {
            resolveNextRoute();
          }
        }
      );
    } else {
      resolveNextRoute();
    }
  });

  router.afterEach((to) => {
    if (to.meta && to.meta.title) {
      document.title = to.meta.title + " | Nextlevel Portal";
    } else {
      document.title = "NextLevel Portal";
    }

    if (router.app && router.app.$gtag) {
      router.app.$gtag.pageview({
        page_title: document.title,
        page_path: to.path,
        page_location: window.location.href,
      });
    }
  });
}

export default router;
