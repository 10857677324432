import mixpanel from "mixpanel-browser";

const mixpanelClient = {
  install(Vue, token = "e1fad30de28166b1a2110c8a40e11e24") {
    mixpanel.init(token);
    Object.defineProperty(Vue.prototype, "$mixpanel", { value: mixpanel });
  },
};

export default mixpanelClient;
