"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";

class RecurringBatch extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "RecurringBatch";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/recurring/batches"
    );
  }

  getApiPathForCreate() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/recurring/batches"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/recurring/batches/" +
      this.id
    );
  }

  getAdminPath() {
    return "/recurring/batches/" + this.id;
  }

  get searchResultTitle() {
    return this.id;
  }

  // Returns a string, or an array of strings (for a multi-line subtitle)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }
}

export default RecurringBatch;
