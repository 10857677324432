<template>
  <v-alert
    class="ma-0 pa-0 overflow-hidden"
    :class="{ greenBanner: type === 'promotional' }"
    :color="color"
    outlined
    text
  >
    <div class="d-flex align-stretch">
      <pc-icon
        v-if="type !== 'announcement' && type !== 'promotional'"
        class="pl-4 pr-3 py-3"
        :name="iconName"
        :color="color"
        size="20"
      ></pc-icon>
      <div
        class="py-3"
        :class="[
          type === 'announcement' || type === 'promotional' ? 'pl-4' : '',
          dismissable ? 'pr-1' : 'pr-4',
        ]"
      >
        <slot></slot>
      </div>
      <button
        v-if="dismissable"
        class="ml-auto py-3 px-5"
        :class="closeButtonClass"
        @click.prevent="$emit('close')"
        tabindex="0"
      >
        <pc-icon name="pc-close-x" :color="color" size="22" light></pc-icon>
      </button>
    </div>
  </v-alert>
</template>

<script>
export default {
  name: "PcAlertReskinned",

  components: {},

  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return ["announcement", "promotional", "warning", "error"].includes(
          value
        );
      },
    },
    dismissable: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    iconName() {
      if (this.type === "warning") return "pc-warning";
      if (this.type === "error") return "pc-error";
      return "";
    },
    color() {
      switch (this.type) {
        case "announcement":
          return $colors.get("penChecksTeal");
        case "warning":
          return $colors.get("penChecksWarn");
        case "error":
          return $colors.get("penChecksRed");
        case "promotional":
          return $colors.get("penChecksPromotionalGreen");
        default:
          return "";
      }
    },
    closeButtonClass() {
      switch (this.type) {
        case "announcement":
          return "announcement-close-btn";
        case "warning":
          return "warning-close-btn";
        case "error":
          return "error-close-btn";
        case "promotional":
          return "promotion-close-btn";
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  font-size: 14px;
}

button {
  transition: all ease-in-out 0.1s;

  &:focus {
    outline-offset: -2px;
  }
}
.alert-banner-link {
  color: currentColor !important;
}

.announcement-close-btn {
  border-left: 1px solid #c2eeff;
  &:hover,
  &:focus {
    background-color: #daf3fb;
  }
  &:active {
    background-color: #bfe1ec;
  }
}

.warning-close-btn {
  border-left: 1px solid #ffdcc2;
  &:hover,
  &:focus {
    background-color: #fcefe5;
  }
  &:active {
    background-color: #ffdabf;
  }
}

.error-close-btn {
  border-left: 1px solid #ffc2c2;
  &:hover,
  &:focus {
    background-color: #fbdada;
  }
  &:active {
    background-color: #fbc2c2;
  }
}

.promotion-close-btn {
  border-left: 1px solid $penChecksPromotionalGreen;
}

.greenBanner,
.greenBanner::v-deep .promotion-close-btn .v-icon {
  background-color: #eaf1d8 !important;
}

.promotion-close-btn {
  &:hover,
  &:hover .v-icon,
  &:focus {
    background-color: #dee7c4 !important;
  }
  &:active {
    background-color: #dee7c4 !important;
  }
}

.greenBanner::v-deep .alert-banner-link {
  color: $penChecksPromotionalGreen !important;
}

.greenBanner.v-alert--text:before {
  background-color: unset;
}
</style>
