import PcSingletoneModals from "./singletons/PcSingletonModals";
import PcSingletonOverlay from "./singletons/PcSingletonOverlay";
import PcSingletonSnackbar from "./singletons/PcSingletonSnackbar";

const Singletons = {
  install(Vue) {
    this.Events = new Vue();

    Vue.component("pc-singleton-modals", PcSingletoneModals);
    Vue.component("pc-singleton-overlay", PcSingletonOverlay);
    Vue.component("pc-singleton-snackbar", PcSingletonSnackbar);

    Vue.prototype.$modals = {
      alert: function (options, callback, cancelCallback) {
        Singletons.Events.$emit("showAlert", options, callback, cancelCallback);
      },
      confirm: function (options, callback, cancelCallback) {
        Singletons.Events.$emit(
          "showConfirm",
          options,
          callback,
          cancelCallback
        );
      },
      prompt: function (options, callback, cancelCallback) {
        Singletons.Events.$emit(
          "showPrompt",
          options,
          callback,
          cancelCallback
        );
      },
      hide: function () {
        Singletons.Events.$emit("hideModal");
      },
    };

    Vue.prototype.$overlay = {
      show: function (options) {
        Singletons.Events.$emit("showOverlay", options);
      },
      hide: function () {
        Singletons.Events.$emit("hideOverlay");
      },
    };

    Vue.prototype.$snackbar = {
      show: function (options) {
        Singletons.Events.$emit("showSnackbar", options);
      },
      hide: function () {
        Singletons.Events.$emit("hideSnackbar");
      },
    };

    // Putting on the window, means we can call from anywhere javascript
    window.$modals = Vue.prototype.$modals;
    window.$overlay = Vue.prototype.$overlay;
    window.$snackbar = Vue.prototype.$snackbar;
  },
};

export default Singletons;
