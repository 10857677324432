"use strict";

import User from "./user";
import BaseModel from "@/shared/lib/client-sdk/base-model";

class EntityNote extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "EntityNote";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") + "/private/notes"
    );
  }

  getApiPathForCreate() {
    console.error("Cannot create note directly, must be created on an entity.");
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/notes/" +
      this.id
    );
  }

  getAdminPath() {
    console.error("Cannot go to an entity note.");
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultTitle() {
    return this.user.searchResultTitle();
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    if (this.note.length > 60) return this.note.subsr(0, 60) + "...";

    return this.note;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  // If the API returns a relationship as a sub-object, this tells the base model which class to use for them when it laods it in.
  get relationships() {
    return { user: User };
  }
}

export default EntityNote;
