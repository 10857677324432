"use strict";

import $ from "jquery";
import { v4 as uuidv4 } from "uuid";

class SearchFilter {
  constructor(searchableField, comparisonMethod, initialValue = null) {
    this.searchableField = searchableField; // Should be an instance of SearchField object
    this.comparisonMethod = comparisonMethod; // Should be a string

    this.textValue = "";
    this.numberValue = "";
    this.booleanValue = false;
    this.arrayValue = [];
    this.dateValue = null;
    this.timeValue = null;
    this.entitySearchIdValue = null;
    this.entitySearchArrayValue = [];

    this.numberRangeValue = [null, null]; // min, max
    this.dateRangeValue = [null, null];

    if (initialValue != null) {
      var properValue = this.setValueFromString(initialValue);
      this.setValue(properValue);
    }

    // In order for elements in a list to be identified as unique, we need to generate a unique id
    this.id = uuidv4();
  }

  getValuePropertyName() {
    if (this.isComparisonIsEmpty || this.isComparisonIsNotEmpty) return null;

    if (
      this.searchableField.isDataTypeText ||
      this.searchableField.isDataTypeSingleSelectList
    )
      return "textValue";

    if (
      this.searchableField.isDataTypeNumber &&
      !this.isComparisonIsBetween &&
      !this.isComparisonIsNotBetween
    )
      return "numberValue";

    if (this.searchableField.isDataTypeBoolean) return "booleanValue";

    if (
      this.searchableField.isDataTypeMultipleSelectList ||
      this.searchableField.isDataTypeMultipleSelectListPair
    )
      return "arrayValue";

    if (
      (this.searchableField.isDataTypeMonth ||
        this.searchableField.isDataTypeDate ||
        this.searchableField.isDataTypeDateTime) &&
      !this.isComparisonTimeIsAtOrBefore &&
      !this.isComparisonTimeIsAtOrAfter &&
      !this.isComparisonIsBetween &&
      !this.isComparisonIsNotBetween
    )
      return "dateValue";

    if (
      (this.searchableField.isDataTypeDate ||
        this.searchableField.isDataTypeDateTime) &&
      (this.isComparisonTimeIsAtOrBefore || this.isComparisonTimeIsAtOrAfter)
    )
      return "timeValue";

    if (this.searchableField.isDataTypeSingleSelectEntitySearch)
      return "entitySearchIdValue";

    if (this.searchableField.isDataTypeMultipleSelectEntitySearch)
      return "entitySearchArrayValue";

    if (
      this.searchableField.isDataTypeNumber &&
      (this.isComparisonIsBetween || this.isComparisonIsNotBetween)
    )
      return "numberRangeValue";

    if (
      (this.searchableField.isDataTypeMonth ||
        this.searchableField.isDataTypeDate ||
        this.searchableField.isDataTypeDateTime) &&
      (this.isComparisonIsBetween || this.isComparisonIsNotBetween)
    )
      return "dateRangeValue";
  }

  getValue() {
    const property = this.getValuePropertyName();

    if (property) return this[property];

    return null;
  }

  setValue(newValue) {
    const property = this.getValuePropertyName();

    if (property) this[property] = newValue;
  }

  _getQueryString() {
    var query =
      "search-" +
      this.searchableField.fieldName +
      "=" +
      this.comparisonMethod +
      "(";

    const property = this.getValuePropertyName();

    if (property) {
      var value = this.getValue();

      if (this.searchableField.isDataTypeBoolean) {
        query += value ? "true" : "false";
      } else if (
        property == "numberRangeValue" ||
        property == "dateRangeValue"
      ) {
        if ($.isArray(value) && value.length == 2) {
          value[0] = value[0] || "";
          value[1] = value[1] || "";

          query += value[0] + ".." + value[1];
        }
      } else if (this.isComparisonIsOneOf || this.isComparisonIsNotOneOf) {
        if (!$.isArray(value)) {
          value = value || "";
          value = value.replace(/[\[\]']+/g, "").split(",");
        }

        // Ensure if the data type is number, they are converted
        if (this.searchableField.isDataTypeNumber)
          value = value.filter((x) => $.isNumeric(x));

        // Ensure all the values are URL safe
        value = value.map((x) => encodeURIComponent(x));

        query += "[" + value.join(",") + "]";
      } else {
        query += encodeURIComponent(value);
      }
    }

    query += ")";

    return query;
  }

  setValueFromString(value) {
    const property = this.getValuePropertyName();

    if (this.searchableField.isDataTypeBoolean)
      return value === true || value === "1" || value === "true" ? true : false;

    if (property == "numberRangeValue" || property == "dateRangeValue") {
      if ($.isArray(value) && value.length == 2) return value;
      if (
        !value ||
        ($.isArray(value) && value.length != 2) ||
        !value.includes("..")
      )
        return [null, null];

      return value.split("..");
    }

    if (property == "arrayValue" || property == "entitySearchArrayValue") {
      if (value.startsWith("[")) value = value.substr(1);
      if (value.endsWith("]")) value = value.substr(0, value.length - 1);

      return value.split(",");
    }

    return value;
  }

  clone() {
    var newSearchFilter = new SearchFilter();

    newSearchFilter.searchableField = this.searchableField;
    newSearchFilter.comparisonMethod = this.comparisonMethod;
    newSearchFilter.textValue = this.textValue;
    newSearchFilter.numberValue = this.numberValue;
    newSearchFilter.booleanValue = this.booleanValue;
    newSearchFilter.arrayValue = this.arrayValue.slice();
    newSearchFilter.dateValue = this.dateValue;
    newSearchFilter.timeValue = this.timeValue;
    newSearchFilter.entitySearchIdValue = this.entitySearchIdValue;
    newSearchFilter.entitySearchArrayValue =
      this.entitySearchArrayValue.slice();
    newSearchFilter.numberRangeValue = this.numberRangeValue.slice();
    newSearchFilter.dateRangeValue = this.dateRangeValue.slice();

    return newSearchFilter;
  }

  static COMPARISON_METHODS() {
    return {
      EQUALS: "equals",
      DOES_NOT_EQUAL: "doesNotEqual",
      CONTAINS: "contains",
      DOES_NOT_CONTAIN: "doesNotContain",
      STARTS_WITH: "startsWith",
      DOES_NOT_START_WITH: "doesNotStartWith",
      ENDS_WITH: "endsWith",
      DOES_NOT_END_WITH: "doesNotEndWith",
      IS_LESS_THAN: "isLessThan",
      IS_LESS_THAN_OR_EQUAL_TO: "isLessThanOrEqualTo",
      IS_GREATER_THAN: "isGreaterThan",
      IS_GREATER_THAN_OR_EQUAL_TO: "isGreaterThanOrEqualTo",
      IS_ONE_OF: "isOneOf",
      IS_NOT_ONE_OF: "isNotOneOf",
      IS_BETWEEN: "isBetween",
      IS_NOT_BETWEEN: "isNotBetween",
      DATE_IS_ON_OR_BEFORE: "dateIsOnOrBefore",
      DATE_IS_ON_OR_AFTER: "dateIsOnOrAfter",
      RELATIVE_DATE_RANGE: "relativeDateRange",
      TIME_IS_AT_OR_BEFORE: "timeIsAtOrBefore",
      TIME_IS_AT_OR_AFTER: "timeIsAtOrAfter",
      IS_EMPTY: "isEmpty",
      IS_NOT_EMPTY: "isNotEmpty",
    };
  }

  get COMPARISON_METHODS() {
    if (!this["_comparisonMethods"])
      this._comparisonMethods = SearchFilter.COMPARISON_METHODS();

    return this._comparisonMethods;
  }

  // Simple accessor properties to adjust the UI easily based on comparison method
  get isComparisonEquals() {
    return this.comparisonMethod == this.COMPARISON_METHODS.EQUALS;
  }
  get isComparisonDoesNotEqual() {
    return this.comparisonMethod == this.COMPARISON_METHODS.DOES_NOT_EQUAL;
  }
  get isComparisonContains() {
    return this.comparisonMethod == this.COMPARISON_METHODS.CONTAINS;
  }
  get isComparisonDoesNotContain() {
    return this.comparisonMethod == this.COMPARISON_METHODS.DOES_NOT_CONTAIN;
  }
  get isComparisonStartsWith() {
    return this.comparisonMethod == this.COMPARISON_METHODS.STARTS_WITH;
  }
  get isComparisonDoesNotStartWith() {
    return this.comparisonMethod == this.COMPARISON_METHODS.DOES_NOT_START_WITH;
  }
  get isComparisonEndsWith() {
    return this.comparisonMethod == this.COMPARISON_METHODS.ENDS_WITH;
  }
  get isComparisonDoesNotEndWith() {
    return this.comparisonMethod == this.COMPARISON_METHODS.DOES_NOT_END_WITH;
  }
  get isComparisonIsLessThan() {
    return this.comparisonMethod == this.COMPARISON_METHODS.IS_LESS_THAN;
  }
  get isComparisonIsLessThanOrEqualTo() {
    return (
      this.comparisonMethod == this.COMPARISON_METHODS.IS_LESS_THAN_OR_EQUAL_TO
    );
  }
  get isComparisonIsGreaterThan() {
    return this.comparisonMethod == this.COMPARISON_METHODS.IS_GREATER_THAN;
  }
  get isComparisonIsGreaterThanOrEqualTo() {
    return (
      this.comparisonMethod ==
      this.COMPARISON_METHODS.IS_GREATER_THAN_OR_EQUAL_TO
    );
  }
  get isComparisonIsOneOf() {
    return this.comparisonMethod == this.COMPARISON_METHODS.IS_ONE_OF;
  }
  get isComparisonIsNotOneOf() {
    return this.comparisonMethod == this.COMPARISON_METHODS.IS_NOT_ONE_OF;
  }
  get isComparisonIsBetween() {
    return this.comparisonMethod == this.COMPARISON_METHODS.IS_BETWEEN;
  }
  get isComparisonIsNotBetween() {
    return this.comparisonMethod == this.COMPARISON_METHODS.IS_NOT_BETWEEN;
  }
  get isComparisonDateIsOnOrBefore() {
    return (
      this.comparisonMethod == this.COMPARISON_METHODS.DATE_IS_ON_OR_BEFORE
    );
  }
  get isComparisonDateIsOnOrAfter() {
    return this.comparisonMethod == this.COMPARISON_METHODS.DATE_IS_ON_OR_AFTER;
  }
  get isComparisonRelativeDateRange() {
    return this.comparisonMethod == this.COMPARISON_METHODS.RELATIVE_DATE_RANGE;
  }
  get isComparisonTimeIsAtOrBefore() {
    return (
      this.comparisonMethod == this.COMPARISON_METHODS.TIME_IS_AT_OR_BEFORE
    );
  }
  get isComparisonTimeIsAtOrAfter() {
    return this.comparisonMethod == this.COMPARISON_METHODS.TIME_IS_AT_OR_AFTER;
  }
  get isComparisonIsEmpty() {
    return this.comparisonMethod == this.COMPARISON_METHODS.IS_EMPTY;
  }
  get isComparisonIsNotEmpty() {
    return this.comparisonMethod == this.COMPARISON_METHODS.IS_NOT_EMPTY;
  }

  get shouldValueBeText() {
    return (
      !this.shouldValueBeAnArray &&
      !this.shouldValueBeARange &&
      !this.shouldValueBeEmpty
    );
  }
  get shouldValueBeAnArray() {
    return this.isComparisonIsOneOf || this.isComparisonIsNotOneOf;
  }
  get shouldValueBeARange() {
    return this.isComparisonIsBetween || this.isComparisonIsNotBetween;
  }
  get shouldValueBeEmpty() {
    return this.isComparisonIsEmpty || this.isComparisonIsNotEmpty;
  }
  get shouldValueBeTime() {
    return (
      this.isComparisonTimeIsAtOrBefore || this.isComparisonTimeIsAtOrAfter
    );
  }
  get shouldValueBeDate() {
    return (
      (this.searchableField.isDataTypeMonth ||
        this.searchableField.isDataTypeDate ||
        this.searchableField.isDataTypeDateTime) &&
      !this.shouldValueBeTime
    );
  }
}

export default SearchFilter;
