<template>
  <span class="mr-1">
    <v-menu offset-y v-if="hasSlot('menu')">
      <template v-slot:activator="{ on }">
        <v-chip
          :disabled="disabled"
          :color="backgroundColor"
          small
          label
          :text-color="light ? $colors.black : $colors.white"
          style="cursor: pointer"
          v-on="on"
          ><slot></slot
        ></v-chip>
      </template>
      <v-list>
        <slot name="menu"></slot>
      </v-list>
    </v-menu>

    <v-chip
      v-else
      :disabled="disabled"
      :color="backgroundColor"
      small
      label
      :text-color="light ? $colors.black : $colors.white"
      @click="clicked"
      ><slot></slot
    ></v-chip>
  </span>
</template>

<script>
export default {
  components: {},
  props: {
    color: {
      type: String,
    },
    light: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    backgroundColor: function () {
      return this.color ?? this.$colors.penChecksNavyBlue;
    },
  },

  methods: {
    hasSlot(name = "default") {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
    clicked() {
      this.$emit("click");
    },
  },

  mounted: function () {},
};
</script>

<style lang="scss"></style>
