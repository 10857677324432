import { render, staticRenderFns } from "./PcWindowItem.vue?vue&type=template&id=cda6e6de"
import script from "./PcWindowItem.vue?vue&type=script&lang=js"
export * from "./PcWindowItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VWindowItem})
