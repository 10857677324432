<template>
  <div v-if="this.size && this.size == 16">
    <download-16 :class="iconColorClass" />
  </div>
  <div v-else-if="this.size && this.size == 20">
    <download-20 :class="iconColorClass" />
  </div>
  <div v-else-if="this.size && this.size == 24">
    <download-24 :class="iconColorClass" />
  </div>
  <div v-else-if="this.size && this.size == 32">
    <download-32 :class="iconColorClass" />
  </div>
  <div v-else><download-16 :class="iconColorClass" /></div>
</template>

<script>
import {
  Download16,
  Download20,
  Download24,
  Download32,
} from "@carbon/icons-vue";

export default {
  name: "PcDownloadIcon",

  components: {
    "download-16": Download16,
    "download-20": Download20,
    "download-24": Download24,
    "download-32": Download32,
  },

  props: {
    size: {
      type: [Number, String],
      validator: function (value) {
        return [16, "16", 20, "20", 24, "24", 32, "32"].includes(value);
      },
      required: false,
      default: 16,
    },
    iconColorClass: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
