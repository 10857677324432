"use strict";

import Vue from "vue";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import $ from "jquery";
import _ from "lodash";
import EntityChangeLogsList from "./model-lists/entity-change-logs-list";
import EntityNotesList from "./model-lists/entity-notes-list";
import EntityDocumentsList from "./model-lists/entity-documents-list";
import EntityFlagsList from "./model-lists/entity-flags-list";

class BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    // IMPORTANT: All properties with an underscore start _ are NOT attributes of the object, and will be ignored by the API.

    // Whenever we load data over API we populate "_original"
    // This allows to see what a property's original value was to see if it's dirty (changed)
    this._original = {
      id: null,
    };

    // List of fields that must be include as dirty fields always
    this._fieldIntoRequest = [];

    // Regiser callbacks when certain events occur on the object
    // onRefreshing, onRefreshed, onCreating, onCreated, onUpdating, onUpdated, onSaving, onSaved,
    // onDeleting, onDeleted, onRestoring, onRestored
    this._callbacks = {};
    this._waitingRefreshCallbacks = [];

    // A custom api wrapper that manages communication to the API.
    this._ajaxRequest = null;

    // refreshing, updating, deleting, etc...
    this._currentOperation = null;

    // Lazy loaded, these will be filled with an EntityModelList objects, which manages collections (searching, adding, removing, etc...)
    this._entityChangeLogs = null;
    this._entityNotes = null;
    this._entityDocuments = null;
    this._flags = null;

    // This is a flag to tell if we've ever gotten this object refreshed from the API ever.  It's a good check to see if something is valid or not
    this._hasBeenRefreshedFromApiAtLeastOnce = false;

    // If we've passed in an object of attributes, we load them all in
    if ($.isPlainObject(idOrAttributes)) {
      this._loadAttributes(idOrAttributes);

      // Since we loaded fresh properties in here, we mark the model as "clean"
      if (Object.prototype.hasOwnProperty.call(this, "id")) this._makeClean();
    } else if (
      $.type(idOrAttributes) == "string" &&
      idOrAttributes.length == 32
    ) {
      // If we have a 32 character string, we assume it's the uuid
      this.set("id", idOrAttributes);

      // Connect to the API and refresh the attributes from the response
      this.refresh(onRefreshCallback);
    }
  }

  // Overwrite all these methods in the child classes ***********************************

  static getModelClassName() {
    console.error(
      this.constructor.name + " needs to implement getModelClassName()"
    );
  }

  static getApiPathForSearch() {
    console.error("Model needs to implement getApiPathForSearch()");
  }

  getApiPathForCreate() {
    console.error(
      this.constructor.name + " needs to implement getApiPathForCreate()"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    console.error(
      this.constructor.name +
        " needs to implement getApiPathForRefreshUpdateAndDelete()"
    );
  }

  getAdminPath() {
    console.error(this.constructor.name + " needs to implement getAdminPath()");
  }

  get searchResultTitle() {
    return "ERROR: No searchResultTitle defined on object";
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  // If the API returns a relationship as a sub-object, this tells the base model which class to use for them when it laods it in.
  // The key of the object is the attribute, and the value is the class to use for the object.
  get relationships() {
    return {};
  }

  // ***********************************

  /***** ATTRIBUTE ACCESSORS ******/

  /**
   * Getter that will return the value, or a default value if it's not present on the model
   */
  get(attribute, defaultValue = null) {
    return _.get(this, attribute, defaultValue);
  }

  /**
   * Setter for attributes on the model.
   *
   * If we have a relationship object (or array of objects) it will create the relationship as an object
   */
  set(attribute, value) {
    if (Object.prototype.hasOwnProperty.call(this, attribute))
      delete this[attribute];

    if ($.isPlainObject(value) || $.isArray(value)) {
      const relationshipClass = this._getRelationshipClass(attribute);
      if (relationshipClass) {
        if ($.isPlainObject(value)) {
          value = new relationshipClass(value);
        } else if ($.isArray(value)) {
          var newValue = [];
          for (var i = 0; i < value.length; i++) {
            newValue.push(new relationshipClass(value[i]));
          }
          value = newValue;
        }
      }
    }

    Vue.set(this, attribute, value);
  }

  _getRelationshipClass(attributeName) {
    const relationships = this.relationships;
    if ($.isPlainObject(relationships) && relationships[attributeName])
      return relationships[attributeName];
  }

  /**
   * Bulk setter for attributes on the model.
   */
  _loadAttributes(attributes) {
    for (var key in attributes) {
      if (!key.startsWith("_")) {
        this.set(key, attributes[key]);
      }
    }
  }

  includeFieldIntoRequest(field) {
    this._fieldIntoRequest.push(field);
  }

  clearFieldIntoRequest() {
    this._fieldIntoRequest = [];
  }

  /**
   * Gets all attributes on the model (by ignoring ones that start with an underscore)
   */
  getAttributes() {
    const instanceAttributes = {};

    for (var attribute in this) {
      if (!attribute.startsWith("_"))
        instanceAttributes[attribute] = this[attribute];
    }

    return instanceAttributes;
  }

  /**
   * Will return a deep clone of this object
   */
  clone() {
    var attributes = $.extend(true, {}, this.getAttributes());

    // Ensure any child models/array of models are cloned properly as well
    for (var key in attributes) {
      var value = attributes[key];

      // If this attribute is a cloneable model, clone it
      if (value && typeof value == "object" && $.isFunction(value["clone"])) {
        attributes[key] = value.clone();
      }

      // If this attribute is an array with cloneable models, clone the cloneable elements
      if ($.isArray(value)) {
        attributes[key] = this.cloneArrayOfModels(value);
      }
    }

    var newObject = new this.constructor(attributes);
    return newObject;
  }

  cloneArrayOfModels(array) {
    var newArray = [];
    for (var arrayElementIndex in array) {
      var arrayElementValue = array[arrayElementIndex];

      if (
        arrayElementValue &&
        typeof arrayElementValue == "object" &&
        $.isFunction(arrayElementValue["clone"])
      ) {
        newArray.push(arrayElementValue.clone());
      } else if ($.isPlainObject(arrayElementValue)) {
        newArray.push(JSON.parse(JSON.stringify(arrayElementValue)));
      } else {
        newArray.push(arrayElementValue);
      }
    }

    return newArray;
  }

  copyAttributesTo(otherModel) {
    var clone = this.clone();
    var attributes = clone.getAttributes();

    // Ensure any child models/array of models are cloned properly as well
    for (var key in attributes) {
      otherModel.set(key, attributes[key]);
    }
  }

  /***** DIRTY/CLEAN CHECKS ******/

  /**
   * This method will set all the _original values to current attribute values thus making this model "clean"
   * "clean" means the attributes are unchanged from their last refresh on the API.
   */
  _makeClean() {
    this._original = {};

    const attributes = this.getAttributes();
    for (var key in attributes) {
      var value = this[key];

      if (value && typeof value == "object" && $.isFunction(value["clone"])) {
        this._original[key] = value.clone();
      } else if ($.isArray(value)) {
        this._original[key] = this.cloneArrayOfModels(value);
      } else if ($.isPlainObject(value)) {
        this._original[key] = JSON.parse(JSON.stringify(value));
      } else {
        this._original[key] = value;
      }
    }
  }

  revertToOriginal(attribute = null) {
    const attributes = this.getAttributes();
    for (var key in attributes) {
      if (attribute == null || attribute == key)
        this[key] = this._original[key];
    }
  }

  original(attribute) {
    return this._original[attribute];
  }

  isClean() {
    return !this.isDirty();
  }

  hasBeenRefreshedFromApiAtLeastOnce() {
    return this._hasBeenRefreshedFromApiAtLeastOnce;
  }

  /**
   * If you pass now parameters to this, it will return true if ANY attribute is dirty (different) from the default value
   */
  isDirty(attributesToCheck) {
    return Object.keys(this.getDirtyAttributes(attributesToCheck)).length > 0;
  }

  /**
   * This method will return an array of attribute names that are dirty (changed) on the model.
   */
  getDirtyAttributes(attributesToCheck = null) {
    const dirtyAttributes = {};

    // If we don't pass in attributes to check, we'll check all the attributes on the object
    if (attributesToCheck === null)
      attributesToCheck = Object.keys(this.getAttributes());

    // If we didn't pass in an array, convert the single item to an array
    if (!$.isArray(attributesToCheck)) attributesToCheck = [attributesToCheck];

    // Loop through all the attributes to check
    for (var index in attributesToCheck) {
      const attribute = attributesToCheck[index];

      var originalValue = null;
      if (Object.prototype.hasOwnProperty.call(this._original, attribute))
        originalValue =
          typeof this._original[attribute] == "object"
            ? JSON.stringify(this._original[attribute])
            : this._original[attribute];

      const newValue =
        typeof this[attribute] == "object"
          ? JSON.stringify(this[attribute])
          : this[attribute];

      if (
        !Object.prototype.hasOwnProperty.call(this._original, attribute) ||
        originalValue != newValue ||
        this._fieldIntoRequest.includes(attribute)
      )
        dirtyAttributes[attribute] = this[attribute];
    }

    return dirtyAttributes;
  }

  /***** API INTERACTION ******/

  refresh(callback, requestUrl = null) {
    if (this._currentOperation == "refreshing" && requestUrl == null) {
      // If we are already refreshing, just add this refresh to the stack of callbacks
      this._waitingRefreshCallbacks.push(callback);
      return;
    } else if (this._isBusyError(callback)) return;

    this._currentOperation = "refreshing";
    this._waitingRefreshCallbacks = [];

    this._performStartingCallbacks(["refreshing"]);

    requestUrl = requestUrl || this.getApiPathForRefreshUpdateAndDelete();

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful && response.result != null) {
          this._hasBeenRefreshedFromApiAtLeastOnce = true;
          this._loadAttributes(response.result);
          this._makeClean();
        }

        this._currentOperation = null;
        this._performFinishedCallbacks("refreshed", callback, response);
        this._waitingRefreshCallbacks = [];
      }.bind(this)
    );
  }

  static fetchAll(callback) {
    var requestUrl = this.getApiPathForSearch();

    ApiRequest.send("GET", requestUrl, null, this).onComplete(
      function (response) {
        if (callback) {
          if (response.successful) {
            callback(response.result);
          } else {
            callback(false);
          }
        }
      }.bind(this)
    );
  }

  create(callback, requestUrl = null) {
    if (this.id && this.id.length > 0) {
      console.error("Cannot create model when it already exists.");
      if ($.isFunction(callback)) callback();
      return;
    }

    return this._save(callback, requestUrl);
  }

  update(callback, requestUrl = null, reloadAttributesAfterUpdate = true) {
    if (!this.id || this.id.length == 0) {
      console.error("Cannot update model without an id.");
      if ($.isFunction(callback)) callback();
      return;
    }

    return this._save(callback, requestUrl, null, reloadAttributesAfterUpdate);
  }

  updateAttribute(attributeName, value, callback, requestUrl = null) {
    if (this._isBusyError(callback)) return;

    this[attributeName] = value;

    this._save(callback, requestUrl, attributeName);
  }

  _save(
    callback,
    requestUrl,
    onlyUpdateSingleAttribute,
    reloadAttributesAfterUpdate
  ) {
    if (this._isBusyError(callback)) return;

    // WORKAROUND: default parameter doesn't work on _save, it sets the requestUrl to undefined even
    // when one was passed originally
    if (!reloadAttributesAfterUpdate) {
      reloadAttributesAfterUpdate = true;
    }

    const isUpdating = this.id && this.id.length == 32;
    const isCreating = !isUpdating;

    var reqUrl =
      requestUrl ||
      (isCreating
        ? this.getApiPathForCreate()
        : this.getApiPathForRefreshUpdateAndDelete());
    var requestMethod = isCreating ? "POST" : "PATCH";
    var requestBody = isCreating
      ? this.getAttributes()
      : this.getDirtyAttributes();

    if (onlyUpdateSingleAttribute)
      requestBody = this.isDirty(onlyUpdateSingleAttribute)
        ? { [onlyUpdateSingleAttribute]: this[onlyUpdateSingleAttribute] }
        : {};

    if (isUpdating && Object.keys(requestBody).length == 0) {
      // Don't try to do an empty PATCH request, just return.
      if ($.isFunction(callback)) callback();
      return;
    }

    this._currentOperation = isCreating ? "creating" : "updating";

    this._performStartingCallbacks([this._currentOperation, "saving"]);

    // Remove "createdAt", "updatedAt" and "deletedAt" from the requestBody - we never want to send those
    if (requestBody["createdAt"]) delete requestBody["createdAt"];
    if (requestBody["updatedAt"]) delete requestBody["updatedAt"];
    if (requestBody["deletedAt"]) delete requestBody["deletedAt"];

    ApiRequest.send(requestMethod, reqUrl, requestBody).onComplete(
      function (response) {
        if (response.successful && response.result != null) {
          this._hasBeenRefreshedFromApiAtLeastOnce = true;

          if (reloadAttributesAfterUpdate)
            this._loadAttributes(response.result);

          this._makeClean();
        }

        this._currentOperation = null;

        var finalCallback = isCreating ? "created" : "updated";
        this._performFinishedCallbacks(
          [finalCallback, "saved"],
          callback,
          response
        );
      }.bind(this)
    );
  }

  delete(callback, requestUrl = null) {
    if (this._isBusyError(callback)) return;

    this._currentOperation = "deleting";

    this._performStartingCallbacks(["deleting"]);

    requestUrl = requestUrl || this.getApiPathForRefreshUpdateAndDelete();

    ApiRequest.send("DELETE", requestUrl).onComplete(
      function (response) {
        if (response.successful && response.result != null) {
          this._hasBeenRefreshedFromApiAtLeastOnce = true;
          this._loadAttributes(response.result);
          this._makeClean();
        }

        this._currentOperation = null;
        this._performFinishedCallbacks("deleted", callback, response);
      }.bind(this)
    );
  }

  restore(callback, requestUrl = null) {
    if (this._isBusyError(callback)) return;

    this._currentOperation = "restoring";

    this._performStartingCallbacks(["restoring"]);

    requestUrl = requestUrl || this.getApiPathForRefreshUpdateAndDelete();

    requestUrl += "?restore=1";

    ApiRequest.send("POST", requestUrl).onComplete(
      function (response) {
        if (response.successful && response.result != null) {
          this._hasBeenRefreshedFromApiAtLeastOnce = true;
          this._loadAttributes(response.result);
          this._makeClean();
        }

        this._currentOperation = null;
        this._performFinishedCallbacks("restored", callback, response);
      }.bind(this)
    );
  }

  get isBusy() {
    return this._currentOperation != null;
  }

  get isSaving() {
    return (
      this._currentOperation == "saving" ||
      this._currentOperation == "updating" ||
      this._currentOperation == "creating"
    );
  }

  get isRefreshing() {
    return this._currentOperation == "refreshing";
  }

  _isBusyError(callback) {
    if (this.isBusy) {
      console.error(this.constructor.name + " is busy.");

      const busyResponse = {
        successful: false,
        message: this.constructor.name + " is busy.",
        httpStatus: null,
        result: null,
        data: null,
      };
      this._performFinishedCallbacks(callback, busyResponse);
      return true;
    }

    return false;
  }

  /***** EVENT HANDLERS *******/

  _performStartingCallbacks(callbackNames) {
    callbackNames = $.isArray(callbackNames) ? callbackNames : [callbackNames];

    for (var index in callbackNames) {
      var callbackName = callbackNames[index];

      if (
        Object.prototype.hasOwnProperty.call(this._callbacks, callbackName) &&
        $.isFunction(this._callbacks[callbackName])
      ) {
        const callback = this._callbacks[callbackName];
        callback(this);
      }
    }
  }

  _performFinishedCallbacks(callbackNames, finalCallback, response) {
    callbackNames = $.isArray(callbackNames) ? callbackNames : [callbackNames];

    if ($.isPlainObject(response)) response["model"] = this;

    for (var index in callbackNames) {
      var callbackName = callbackNames[index];

      if (
        Object.prototype.hasOwnProperty.call(this._callbacks, callbackName) &&
        $.isFunction(this._callbacks[callbackName])
      ) {
        const callback = this._callbacks[callbackName];
        callback(response);
      }
    }

    if (
      callbackNames == "refreshed" &&
      this._waitingRefreshCallbacks.length > 0
    ) {
      // See if we had any "in flight" refresh callbacks
      for (var i = 0; i < this._waitingRefreshCallbacks.length; i++) {
        var waitingCallback = this._waitingRefreshCallbacks[i];
        if ($.isFunction(waitingCallback)) {
          waitingCallback(response);
        }
      }
    }
    this._waitingRefreshCallbacks = [];

    if ($.isFunction(finalCallback)) {
      finalCallback(response);
    }
  }

  /** COMMON COLLECTIONS\LISTS */

  changeLogManager() {
    if (!this._entityChangeLogs)
      this._entityChangeLogs = new EntityChangeLogsList(this);

    return this._entityChangeLogs;
  }

  notesManager() {
    if (!this._entityNotes) this._entityNotes = new EntityNotesList(this);

    return this._entityNotes;
  }

  documentsManager() {
    if (!this._entityDocuments)
      this._entityDocuments = new EntityDocumentsList(this);

    return this._entityDocuments;
  }

  flagManager() {
    if (!this._flags) this._flags = new EntityFlagsList(this);

    return this._flags;
  }

  /***** METHODS TO BE CALLED WHEN CERTAIN EVENTS ARE FIRED *******/

  onRefreshing(callback) {
    this._callbacks["refreshing"] = callback;
  }

  onRefreshed(callback) {
    this._callbacks["refreshed"] = callback;
  }

  onCreating(callback) {
    this._callbacks["creating"] = callback;
  }

  onCreated(callback) {
    this._callbacks["created"] = callback;
  }

  onUpdating(callback) {
    this._callbacks["updating"] = callback;
  }

  onUpdated(callback) {
    this._callbacks["updated"] = callback;
  }

  onSaving(callback) {
    this._callbacks["saving"] = callback;
  }

  onSaved(callback) {
    this._callbacks["saved"] = callback;
  }

  onDeleting(callback) {
    this._callbacks["deleting"] = callback;
  }

  onDeleted(callback) {
    this._callbacks["deleted"] = callback;
  }

  onRestoring(callback) {
    this._callbacks["restoring"] = callback;
  }

  onRestored(callback) {
    this._callbacks["restored"] = callback;
  }
}

export default BaseModel;
