"use strict";

import $ from "jquery";
import BaseModel from "@/shared/lib/client-sdk/base-model";
import ApiRequest from "@/shared/lib/client-sdk/api-request";

class PaymentMethod extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "PaymentMethod";
  }

  static getApiPathForSearch() {
    var path =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/payment-methods";

    return path;
  }

  getApiPathForCreate() {
    console.error("Cannot create payment methods.");
  }

  getApiPathForRefreshUpdateAndDelete() {
    console.error("Cannot update payment methods.");
  }

  getAdminPath() {
    console.error("Cannot go to a payment method.");
  }

  get searchResultTitle() {
    return this.displayName;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  get relationships() {
    return {};
  }

  getPaymentGroups(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/payment-methods-groups";

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }
}

export default PaymentMethod;
