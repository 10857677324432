<template>
  <v-window-item>
    <slot></slot>
  </v-window-item>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      parentFixedHeaderLayout: null,
    };
  },
  methods: {
    registerWithParentFixedHeaderLayout: function () {
      this.parentFixedHeaderLayout = this.$parentWithTag(
        "pc-fixed-header-layout"
      );
      this.parentFixedHeaderLayout.refreshChildren();
    },
  },
  mounted: function () {
    this.registerWithParentFixedHeaderLayout();
  },

  destroyed: function () {
    if (this.parentFixedHeaderLayout)
      this.parentFixedHeaderLayout.refreshChildren();
  },
};
</script>

<style lang="scss"></style>
