<template>
  <v-col class="static-field-value" :class="className">
    <template v-if="singleLine">
      <strong>{{ label }}</strong
      ><span v-if="subtext" class="static-field-sub-text"> {{ subtext }}</span
      >:
    </template>
    <template v-else>
      <strong>{{ label }}</strong>
      <span v-if="subtext" class="static-field-sub-text">{{ subtext }}</span>
      <br v-if="label || subtext" />
    </template>

    <template v-if="validIf">
      <span :id="testId">
        <slot></slot>
      </span>
    </template>
    <template v-else>
      <span :id="testId" :style="missingTextStyle">
        <template
          v-if="
            showInvalidValue &&
            $slots.default &&
            $slots.default.length > 0 &&
            $slots.default[0].text
          "
        >
          <slot></slot>
        </template>
        <template v-else>
          <span :id="testId">
            {{ this.missingTextValue }}
          </span>
        </template>
      </span>
    </template>
  </v-col>
</template>

<script>
export default {
  components: {},

  props: {
    label: {
      type: String,
      required: true,
    },
    subtext: {
      type: String,
    },
    validIf: {},
    required: {
      type: Boolean,
      default: false,
    },
    showInvalidValue: {
      type: Boolean,
    },
    missingText: {
      type: String,
    },
    missingTextColor: {
      type: String,
    },
    singleLine: {
      type: Boolean,
    },
    cols: {
      type: Number,
    },
    testId: {
      type: String,
    },
  },

  computed: {
    missingTextValue: function () {
      if (this.missingText) return this.missingText;

      return this.required ? "MISSING" : "None";
    },
    missingTextStyle: function () {
      var style = "";
      if (this.missingTextColor)
        return style + "color:" + this.missingTextColor;

      style =
        style +
        "color:" +
        (this.required
          ? this.$colors.penChecksRed
          : this.$colors.penChecksGray);
      return style;
    },
    className: function () {
      var className = "";

      if (this.cols) className = "col-" + this.cols;

      if (this.required && !this.validIf) className += " has-error";

      return className;
    },
  },
};
</script>

<style lang="scss">
.static-field-value {
  color: $penChecksDarkGray;
  font-size: 14px;
}

.static-field-sub-text {
  font-size: 14px;
  font-weight: 400;
  color: $penChecksMediumGray;
}
</style>
