"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";

import Account from "./account";
import Plan from "./plan";
import InvoiceLine from "./invoice-line";

class Invoice extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "Invoice";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/invoices"
    );
  }

  getApiPathForCreate() {
    if (this.planId)
      return (
        window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
        "/public/plans/" +
        this.planId +
        "/invoices"
      );

    if (this.accountId)
      return (
        window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
        "/public/accounts/" +
        this.accountId +
        "/invoices"
      );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/invoices/" +
      this.id
    );
  }

  getAdminPath() {
    return "/recurring/invoices/" + this.id;
  }

  get relationships() {
    return {
      account: Account,
      plan: Plan,
      invoiceLines: InvoiceLine,
    };
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultTitle() {
    return this.id;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }
}

export default Invoice;
