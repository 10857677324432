"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import DepositLink from "@/shared/lib/client-sdk/models/deposit-link";
import Deposit from "@/shared/lib/client-sdk/models/deposit";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import $ from "jquery";

class BankTransaction extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);

    this._hasFetchedLinksAtLeastOnce = false;
    this._isFetchingLinks = false;
    this._links = [];
  }

  static getModelClassName() {
    return "BankTransaction";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/trust/bank-transactions"
    );
  }

  getApiPathForCreate() {}

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/trust/bank-transactions/" +
      this.id
    );
  }

  getAdminPath() {
    return "/bank-transactions/" + this.id;
  }

  get searchResultTitle() {
    return this.transactionDate + " $" + this.amount;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return this.transactionTypeCode;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  get hasFetchedLinksAtLeastOnce() {
    return this._hasFetchedLinksAtLeastOnce;
  }

  get isFetchingLinks() {
    return this._isFetchingLinks;
  }

  get links() {
    return this._links;
  }

  fetchLinks(callback) {
    if (this._isFetchingLinks) {
      if ($.isFunction(callback)) callback();

      return;
    }

    this._isFetchingLinks = true;

    var requestUrl = this.getApiPathForRefreshUpdateAndDelete() + "/links";

    ApiRequest.send("GET", requestUrl, null, DepositLink).onComplete(
      function (response) {
        this._hasFetchedLinksAtLeastOnce = true;
        this._isFetchingLinks = false;

        if (response.successful) {
          this._links = response.result;

          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  assignToDeposit(deposit, callback) {
    this.updateAssignedDeposit("POST", deposit, callback);
  }

  unassignFromDeposit(deposit, callback) {
    this.updateAssignedDeposit("DELETE", deposit, callback);
  }

  updateAssignedDeposit(requestMethod, deposit, callback) {
    this._currentOperation = "updating";

    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/trust/deposits/" +
      deposit.id +
      "/bank-transactions/" +
      this.id;

    ApiRequest.send(requestMethod, requestUrl, Deposit).onComplete(
      function (response) {
        this._currentOperation = null;

        if (response.successful) {
          deposit._loadAttributes(response.result);
          deposit._makeClean();

          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  setAdministrativeHoldDays(days, callback) {
    this._currentOperation = "updating";

    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/trust/bank-transactions/" +
      this.id +
      "/set-administrative-hold-days/" +
      days;

    ApiRequest.send("POST", requestUrl, BankTransaction).onComplete(
      function (response) {
        this._currentOperation = null;

        if (response.successful) {
          this._loadAttributes(response.result);
          this._makeClean();

          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }
}

export default BankTransaction;
