"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import AgreementVersion from "@/shared/lib/client-sdk/models/agreement-version";

class Agreement extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "Agreement";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/agreements"
    );
  }

  getApiPathForCreate() {
    console.error("Cannot create agreements.");
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/agreements/" +
      this.code
    );
  }

  getAdminPath() {
    console.error("Cannot go to an agreement.");
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultTitle() {
    return this.displayName;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return this.description;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }
  get relationships() {
    return {
      latestVersion: AgreementVersion,
    };
  }
}

export default Agreement;
