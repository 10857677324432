import Vue from "vue";
import VueRouter from "vue-router";
import RouteObjects from "@/shared/router/route-objects";

var router = {};

if (window.location.host.match(/amplify.*\.penchecks\.com/g) !== null) {
  Vue.use(VueRouter);

  const routes = [
    {
      path: "/",
      meta: { title: "Home" },
      component: () => import("../views/PcClientHomeRedirect.vue"),
    },
    {
      path: "/account-creation",
      meta: { title: "Account Creation" },
      component: () => import("../views/account/PcAccountCreation.vue"),
    },

    {
      path: "/:masterAccountUuid([a-zA-Z0-9]{32})",
      meta: { title: "Dashboard" },
      component: () => import("../views/PcClientPageHome.vue"),
    },

    {
      path: "/:masterAccountUuid([a-zA-Z0-9]{32})/plans",
      meta: { title: "Plans" },
      component: () => import("../views/plans/PcClientPageListPlans.vue"),
    },
    {
      path: "/:masterAccountUuid([a-zA-Z0-9]{32})/plans/:planUuid([a-zA-Z0-9]{32})",
      meta: { title: "Edit Plan" },
      component: () => import("../views/plans/PcClientPageEditPlan.vue"),
    },

    {
      path: "/:masterAccountUuid([a-zA-Z0-9]{32})/orders",
      meta: { title: "List Orders" },
      component: () =>
        import("../views/orders/PcClientPageOrdersListPlans.vue"),
    },
    {
      path: "/:masterAccountUuid([a-zA-Z0-9]{32})/plan-orders/:planUuid([a-zA-Z0-9]{32})",
      meta: { title: "Plan Orders" },
      component: () => import("../views/orders/PcClientPagePlanOrders.vue"),
    },
    {
      path: "/:masterAccountUuid([a-zA-Z0-9]{32})/orders/:orderUuid([a-zA-Z0-9]{32})",
      meta: { title: "Edit Order" },
      component: () => import("../views/orders/PcClientPageEditOrder.vue"),
    },
    {
      path: "/:masterAccountUuid([a-zA-Z0-9]{32})/print-order/:orderUuid([a-zA-Z0-9]{32})",
      meta: { title: "Print Order" },
      component: () => import("../views/orders/PcClientPagePrintOrder.vue"),
    },
    {
      path: "/:masterAccountUuid([a-zA-Z0-9]{32})/print-instructions",
      meta: { title: "Print Instructions" },
      component: () =>
        import("../views/orders/PcClientPagePrintInstructions.vue"),
    },
    {
      path: "/:masterAccountUuid([a-zA-Z0-9]{32})/recurring",
      meta: { title: "Recurring Plans" },
      component: () =>
        import("../views/recurring/PcClientPageListRecurringPlans.vue"),
    },
    {
      path: "/:masterAccountUuid([a-zA-Z0-9]{32})/recurring/:planUuid([a-zA-Z0-9]{32})",
      meta: { title: "Recurring Participants" },
      component: () =>
        import("../views/recurring/PcClientPageEditRecurringOrder.vue"),
    },
    {
      path: "/:masterAccountUuid([a-zA-Z0-9]{32})/recurring/:planUuid([a-zA-Z0-9]{32})/summary",
      meta: { title: "Recurring Summary" },
      component: () =>
        import("../views/recurring/PcClientPageRecurringPlanSummary.vue"),
    },
    {
      path: "/:masterAccountUuid([a-zA-Z0-9]{32})/account",
      meta: { title: "Account Settings" },
      component: () => import("../views/account/PcClientPageEditAccount.vue"),
    },
    {
      path: "/:masterAccountUuid([a-zA-Z0-9]{32})/account/:subaccountUuid([a-zA-Z0-9]{32})",
      meta: { title: "Account Settings" },
      component: () =>
        import("../views/account/PcClientPageEditSubaccount.vue"),
    },
    {
      path: "/:masterAccountUuid([a-zA-Z0-9]{32})/reports",
      meta: { title: "Reports" },
      component: () => import("../views/reports/PcReports.vue"),
    },
    {
      path: "/help",
      meta: { title: "Resources" },
      component: () => import("../views/help/PcClientHelp.vue"),
    },
    {
      path: "/help/templates/:serviceCode/:planTypeCode?",
      meta: { title: "Template Help" },
      component: () => import("../views/help/PcClientTemplateHelp.vue"),
    },
    {
      path: "/files/:storedFileUuid([a-zA-Z0-9]{32})/download",
      meta: { title: "File Download" },
      component: () => import("../views/PcClientFileDownload.vue"),
    },

    // Keep this last for 404s
    // { path: "/:masterAccountUuid/*", name: 'NotFound', meta: { title: "404 Not Found" }, component: () => import('../views/Pc404NotFound.vue') },

    // { path: "*", name: 'NotFoundHome', meta: { title: "Home" }, component: () => import('../views/PcClientHomeRedirect.vue') }
  ];

  router = new VueRouter({
    mode: "history",
    routes,
  });

  router.objects = new RouteObjects(router);

  router.afterEach((to, from) => {
    if (to.meta && to.meta.title) {
      document.title = to.meta.title + " | PenChecks Client";
    } else {
      document.title = "PenChecks Client";
    }

    if (router.app && router.app.$gtag) {
      router.app.$gtag.pageview({
        page_title: document.title,
        page_path: to.path,
        page_location: window.location.href,
      });
    }

    if (
      window.amplifyRuntimeConfig.get("VUE_APP_ENVIRONMENT") == "production" &&
      router.app &&
      router.app.$mixpanel
    ) {
      router.app.$mixpanel.track("Page change: " + to.meta.title, {
        "Current Page": to.meta.title,
        "Previous Page": from.meta.title,
      });
    }
  });
}

export default router;
