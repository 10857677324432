"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import $ from "jquery";

import AgreementUserResponse from "@/shared/lib/client-sdk/models/agreement-user-response";

class AgreementAmendmentVersion extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "AgreementAmendmentVersion";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/agreement-amendments"
    );
  }

  getApiPathForCreate() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/agreement-amendments"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/agreement-amendments/" +
      this.id
    );
  }

  getAdminPath() {
    return "/accounts/" + this.accountId;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultTitle() {
    return null;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  get relationships() {
    return { agreementUserResponse: AgreementUserResponse };
  }

  get hasDeadlineExpiredAndNotAgreed() {
    return (
      this.hasDeadlineExpired &&
      (!this.agreementUserResponse || !this.agreementUserResponse.agreedAt)
    );
  }

  createAgreementAmendment(callback) {
    let formData = new FormData();
    formData.append("agreementCode", this.agreementCode);
    formData.append("file", this.file);
    formData.append("lockAccountUntilAgreed", this.lockAccountUntilAgreed);
    if (this.identifier) {
      formData.append("identifier", this.identifier);
    }
    if (this.summary) {
      formData.append("summary", this.summary);
    }

    ApiRequest.upload(
      this.getApiPathForCreate(),
      formData,
      AgreementAmendmentVersion
    ).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  // updateAgreementAmendment(callback) {
  //     let formData = new FormData();
  //     formData.append("file", this.file);
  //     formData.append("identifier", this.identifier);
  //     formData.append("revision", this.revision);
  //     formData.append("isClientConsentRequired", this.isClientConsentRequired);
  //     formData.append("isAccountOwnerOnlyConsentRequired", this.isAccountOwnerOnlyConsentRequired);
  //     formData.append("lockAccountWithoutConsent", this.lockAccountWithoutConsent);

  //     ApiRequest.upload(this.getApiPathForRefreshUpdateAndDelete(), formData, AgreementAmendmentVersion).onComplete(function (response) {
  //         if (response.successful) {
  //             if ($.isFunction(callback))
  //                 callback(response.result, null)
  //         } else {
  //             if ($.isFunction(callback))
  //                 callback(null, response)
  //         }
  //     }.bind(this));
  // }

  accept(accountId, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/accounts/" +
      accountId +
      "/agreement-amendments/" +
      this.id +
      "/accept";

    var requestBody = {
      acceptedAt: this.acceptedAt,
    };

    ApiRequest.send(
      "POST",
      requestUrl,
      requestBody,
      AgreementAmendmentVersion
    ).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }
}

export default AgreementAmendmentVersion;
