"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";

class AgreementUserResponse extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "AgreementUserResponse";
  }

  static getApiPathForSearch() {
    console.error("Cannot search for agreement user response.");
  }

  getApiPathForCreate() {
    console.error("Cannot create agreement user response.");
  }

  getApiPathForRefreshUpdateAndDelete() {
    console.error("Cannot modify agreement user response.");
  }

  getAdminPath() {
    console.error("Cannot go to an agreement user response.");
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultTitle() {
    return null;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }
}

export default AgreementUserResponse;
