<template>
  <v-radio-group
    v-model="localValue"
    :disabled="disabled"
    :label="label"
    :id="id"
    :rules="rules"
    :row="row"
    @change="$emit('change')"
    class="pc-radio-group"
  >
    <slot></slot>
  </v-radio-group>
</template>

<script>
export default {
  props: {
    value: {},
    disabled: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    row: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },

  data: function () {
    return {
      localValue: null,
    };
  },

  computed: {
    rules: function () {
      if (!this.required) return [];

      return [
        () => {
          return this.localValue !== null || "Please select an option.";
        },
      ];
    },
  },

  watch: {
    localValue: function () {
      this.$emit("input", this.localValue);
      this.$emit("change", this.localValue);
    },
    value: function () {
      this.localValue = this.value;
    },
  },

  methods: {},

  mounted: function () {
    this.localValue = this.value;
  },
};
</script>

<style lang="scss">
.pc-radio-group {
  legend.v-label {
    margin-bottom: 10px;
  }
}
</style>
