import Vue from "vue";
import VueRouter from "vue-router";
import RouteObjects from "@/shared/router/route-objects";
import AdvisorStore from "@/advisor/store";

var router = {};

if (window.location.host.match(/advisorportal.*\.penchecks\.com/g) !== null) {
  Vue.use(VueRouter);

  const routes = [
    // Sign Up
    {
      path: "/sign-up-nlira/:emailAddress",
      name: "Sign up investment advisor",
      meta: { title: "Sign up investment advisor", allowsAnonymousUser: true },
      component: () => import("../views/users/PcSignUpRia.vue"),
    },
    {
      path: "/account-creation-ria",
      meta: { title: "Account Creation Advisor" },
      component: () => import("../views/users/PcAccountCreationRia.vue"),
    },
    {
      path: "/user-invitation/:accountUserUuid([a-zA-Z0-9]{32})",
      name: "User Invite",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/users/PcAdvisorUserInvite.vue"),
    },
    {
      path: "/forgot-password",
      name: "Forgot Password",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/users/PcAdvisorForgotPassword.vue"),
    },
    {
      path: "/reset-password/:email_address/:reset_password_token",
      name: "Reset Password",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/users/PcAdvisorResetPassword.vue"),
    },
    // Login
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/login",
      name: "Home",
      meta: { title: "Home", allowsAnonymousUser: true },
      component: () => import("../views/users/PcLogin.vue"),
    },
    // Home Page
    {
      path: "/accounts/:accountUuid([a-zA-Z0-9]{32})",
      meta: { title: "Dashboard" },
      component: () => import("../views/PcAdvisorPageHome.vue"),
    },
    // Register Investment Advisor
    {
      path: "/accounts/:accountUuid([a-zA-Z0-9]{32})/ria-reports",
      meta: { title: "Reports" },
      component: () => import("../views/ria/PcRiaMainReports.vue"),
    },
    {
      path: "/accounts/:accountUuid([a-zA-Z0-9]{32})/ria-account",
      meta: { title: "Account" },
      component: () => import("../views/ria/PcRiaMainAccount.vue"),
    },
    {
      path: "/advisory-firms/:accountUuid([a-zA-Z0-9]{32})",
      meta: { title: "Ria Firms" },
      component: () =>
        import("../views/ria/advisory-firm/PcRiaAdvisoryFirm.vue"),
    },
    {
      path: "/advisors/:accountUuid([a-zA-Z0-9]{32})",
      meta: { title: "Advisor" },
      component: () =>
        import(
          "../views/ria/advisory-firm/advisor/PcRiaAdvisoryFirmAdvisor.vue"
        ),
    },
    // Advisory Firms
    {
      path: "/accounts/:accountUuid([a-zA-Z0-9]{32})/advisory-firm-reports",
      meta: { title: "Reports" },
      component: () =>
        import("../views/advisory-firm/PcAdvisoryFirmMainReports.vue"),
    },
    {
      path: "/accounts/:accountUuid([a-zA-Z0-9]{32})/advisory-firm-account",
      meta: { title: "Advisory Firm Account" },
      component: () =>
        import("../views/advisory-firm/PcAdvisoryFirmMainAccount.vue"),
    },
    {
      path: "/firm-advisors/:accountUuid([a-zA-Z0-9]{32})",
      meta: { title: "Advisor" },
      component: () =>
        import("../views/advisory-firm/advisor/PcAdvisoryFirmAdvisor.vue"),
    },
    // Advisors
    {
      path: "/accounts/:accountUuid([a-zA-Z0-9]{32})/advisor-reports",
      meta: { title: "Reports" },
      component: () => import("../views/advisor/PcAdvisorMainReports.vue"),
    },
    {
      path: "/accounts/:accountUuid([a-zA-Z0-9]{32})/advisor-account",
      meta: { title: "Advisor Account" },
      component: () => import("../views/advisor/PcAdvisorMainAccount.vue"),
    },
    {
      path: "/campaigns/:campaignUuid([a-zA-Z0-9]{32})",
      meta: { title: "Campaign Prospects" },
      component: () =>
        import("../views/advisor/campaign/PcAdvisorCampaign.vue"),
    },
    {
      path: "/campaign-prospects/:prospectUuid([a-zA-Z0-9]{32})",
      meta: { title: "Prospect" },
      component: () =>
        import(
          "../views/advisor/campaign/prospect/PcAdvisorCampaignProspect.vue"
        ),
    },
    {
      path: "/prospects/:prospectUuid([a-zA-Z0-9]{32})",
      meta: { title: "Prospect" },
      component: () =>
        import("../views/advisor/prospect/PcAdvisorProspect.vue"),
    },
  ];

  router = new VueRouter({
    mode: "history",
    routes,
  });

  router.objects = new RouteObjects(router);

  router.beforeEach((to, from, next) => {
    function resolveNextRoute() {
      console.log("to path", to.path);
      if (to.matched.some((record) => record.meta.allowsAnonymousUser)) {
        if (AdvisorStore.state.authenticatedUser) {
          var accountId =
            AdvisorStore.state.authenticatedUser.lastAccessedAccountId;
          if (
            to.path != `/${accountId}` &&
            AdvisorStore.state.authenticatedUser.lastPasswordChangeAt !==
              null &&
            to.path != "/confirm-email-address"
          ) {
            next(`/${accountId}`);
          }
        }

        next();
      } else {
        if (!AdvisorStore.state.authenticatedUser) {
          if (to.path != "/") {
            next("/");
          }
        }

        next();
      }
    }

    // wait for authenticated user to finish loading before resolving route
    if (AdvisorStore.state.isLoadingAuthenticatedUser) {
      AdvisorStore.watch(
        (state) => state.isLoadingAuthenticatedUser,
        (loading) => {
          if (!loading) {
            resolveNextRoute();
          }
        }
      );
    } else {
      resolveNextRoute();
    }
  });

  router.afterEach((to) => {
    if (to.meta && to.meta.title) {
      document.title = to.meta.title + " | PenChecks Advisor";
    } else {
      document.title = "PenChecks Advisor";
    }

    if (router.app && router.app.$gtag) {
      router.app.$gtag.pageview({
        page_title: document.title,
        page_path: to.path,
        page_location: window.location.href,
      });
    }
  });
}

export default router;
