import { render, staticRenderFns } from "./PcSelectCardReskinned.vue?vue&type=template&id=38db6f6f&scoped=true"
import script from "./PcSelectCardReskinned.vue?vue&type=script&lang=js"
export * from "./PcSelectCardReskinned.vue?vue&type=script&lang=js"
import style0 from "./PcSelectCardReskinned.vue?vue&type=style&index=0&id=38db6f6f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38db6f6f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VCard,VCardText,VCardTitle,VTooltip})
