"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";

class TaxReportingFile extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "TaxReportingFile";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/tax-reporting-files"
    );
  }

  getApiPathForCreate() {
    console.error("Cannot create tax reporting change logs.");
  }

  getApiPathForRefreshUpdateAndDelete() {
    console.error("Cannot update tax reporting change logs.");
  }

  getAdminPath() {
    console.error("Cannot go to a tax reporting change log.");
  }

  get searchResultTitle() {
    return this.id;
  }

  // Returns a string, or an array of strings (for a multi-line subtitle)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }
}

export default TaxReportingFile;
