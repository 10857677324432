<template>
  <v-menu
    v-model="showDatePicker"
    offset-y
    :close-on-content-click="false"
    max-width="290"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="textFieldValue"
        :disabled="disabled"
        :clearable="clearable"
        :label="label + (required ? '' : ' (optional)')"
        readonly
        v-on="on"
        @click:clear="clearPressed"
        :dense="dense"
        :rules="required ? dateRules : []"
        :required="required"
        prepend-icon="fa-calendar-alt"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="componentValue"
      :type="monthOnly ? 'month' : 'date'"
      :color="$colors.penChecksTeal"
      :headerColor="$colors.penChecksTeal"
      :range="range"
      :min="minDate"
      :max="maxDate"
    ></v-date-picker>
  </v-menu>
</template>
<script>
import $ from "jquery";

export default {
  props: {
    value: {}, // in ISO 8601 format: YYYY-MM-DD
    label: {
      type: String,
      default: "Date",
    },
    range: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
    format: {
      type: String,
      default: "ddd, MMM Do YYYY",
    },
    monthOnly: {
      type: Boolean,
    },
    minDate: {
      type: String, // in ISO 8601 format: YYYY-MM-DD
    },
    maxDate: {
      type: String, // in ISO 8601 format: YYYY-MM-DD
    },
    enforceSameCalendarYearRule: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      showDatePicker: false,
      componentValue: null,
      dateRules: [
        (v) => {
          if (!v) {
            return `Please enter ${this.label}`;
          }

          if (this.enforceSameCalendarYearRule && !this.isSameCalendarYear()) {
            return "Date range must be within a single calendar year.";
          }

          return true;
        },
      ],
    };
  },

  watch: {
    value: function () {
      this.componentValue = this.removeDayIfRequired(this.value);
    },
    componentValue: function () {
      if (!$.isArray(this.componentValue) || this.componentValue.length == 2) {
        this.$emit("input", this.componentValue);

        if (
          ($.isArray(this.componentValue) && this.componentValue.length == 2) ||
          this.componentValue
        ) {
          this.showDatePicker = false;
        }
      }
    },
  },

  computed: {
    textFieldValue: function () {
      if (!this.range)
        return this.$options.filters.formatDate(
          this.componentValue,
          this.format
        );

      if (
        this.componentValue &&
        this.componentValue[0] &&
        this.componentValue[1]
      ) {
        var formattedValue1 = this.$options.filters.formatDate(
          this.componentValue[0],
          this.format
        );
        var formattedValue2 = this.$options.filters.formatDate(
          this.componentValue[1],
          this.format
        );
        return formattedValue1 + " to " + formattedValue2;
      }

      return "";
    },
  },

  methods: {
    clearPressed: function () {
      if (this.range) {
        this.componentValue = [];
        this.$emit("input", []);
      } else {
        this.componentValue = null;
        this.$emit("input", null);
      }
    },
    removeDayIfRequired: function (date) {
      if (!this.monthOnly || !date || $.isArray(date)) return date;

      var pieces = date.split("-");
      if (pieces.length == 3) {
        return pieces[0] + "-" + pieces[1];
      }

      return date;
    },
    isSameCalendarYear: function () {
      if (
        new Date(this.componentValue[0]).getUTCFullYear() !=
        new Date(this.componentValue[1]).getUTCFullYear()
      ) {
        return false;
      }

      return true;
    },
  },

  mounted: function () {
    if (this.range) {
      if ($.isArray(this.value) && this.value.length == 2)
        this.componentValue = [
          this.removeDayIfRequired(this.value[0]),
          this.removeDayIfRequired(this.value[1]),
        ];
      else this.componentValue = [];
    } else {
      this.componentValue = this.removeDayIfRequired(this.value);
    }
  },
};
</script>

<style lang="scss"></style>
