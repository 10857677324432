<template>
  <div class="capped-card" :class="{ hovering: hovering }">
    <!-- TOP ORIENTED CARD -->
    <v-card
      v-if="!leftCap"
      :elevation="0"
      @mouseover="hovering = !disabled"
      @mouseout="hovering = false"
      :height="height"
      :style="
        'width:' +
        cardWidth +
        '; overflow: hidden; background-color: ' +
        cardBackgroundColor
      "
    >
      <v-toolbar
        :color="color"
        elevation="0"
        :height="topCapSize"
        dark
        style="overflow: hidden"
      >
        <v-toolbar-title
          v-if="title && title.length > 0"
          :class="{ 'disabled-text': disabled }"
          style="font-size: 16px; font-weight: bold"
          >{{ title }}</v-toolbar-title
        >
        <template v-if="icon">
          <v-icon
            :size="iconSize"
            :style="
              'position: absolute; left: ' +
              iconLeftOffset +
              'px; bottom: ' +
              iconBottomOffset +
              'px; color: ' +
              iconColor
            "
          >
            {{ icon }}
          </v-icon>
        </template>
      </v-toolbar>
      <v-card-text
        class="text--primary pa-6 pb-5 pt-5"
        :class="{ 'disabled-text': disabled }"
        :style="'text-overflow: ellipsis; overflow: hidden; ' + maxTextHeight"
      >
        <slot></slot>
      </v-card-text>
    </v-card>

    <!-- LEFT ORIENTED CARD -->
    <v-card
      v-else
      :elevation="0"
      @mouseover="hovering = !disabled"
      @mouseout="hovering = false"
    >
      <div style="display: flex; min-height: 100px">
        <div
          :style="
            'position: relative; display: flex; flex-direction: column; align-items: center; justify-content: center; flex-basis: ' +
            leftCapSize +
            'px; width: ' +
            leftCapSize +
            'px; overflow: hidden; vertical-align: text-bottom; background-color: ' +
            cardBackgroundColor
          "
          class="pa-5"
        >
          <div
            v-if="title && title.length > 0"
            :style="
              'font-size: 16px; font-weight: bold; color: ' + $colors.white
            "
          >
            {{ title }}
          </div>
          <template v-if="icon">
            <v-icon
              :size="iconSize"
              :style="
                'position: absolute; left: ' +
                iconLeftOffset +
                'px; bottom: ' +
                iconBottomOffset +
                'px; color: ' +
                iconColor
              "
              >{{ icon }}</v-icon
            >
          </template>
        </div>
        <v-card-text
          class="text--primary pa-6 pt-8"
          :class="{ 'disabled-text': disabled }"
          :style="
            'transition: background-color 0.3s ease; display: flex; text-overflow: ellipsis; overflow: hidden; flex-direction: column; flex-grow: 1; background-color: ' +
            cardBackgroundColor
          "
        >
          <slot></slot>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  components: {},

  props: {
    color: {
      type: String,
      default: "#025E76", // penChecksDarkTeal
    },
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "100%",
    },
    iconLeftOffset: {
      type: Number,
      default: 24,
    },
    iconBottomOffset: {
      type: Number,
      default: -10,
    },
    iconSize: {
      type: Number,
      default: 52,
    },
    iconColor: {
      type: String,
      default: "#FFFFFF99",
    },
    leftCap: {
      type: Boolean,
      default: false,
    },
    capSize: {
      type: Number,
    },
    width: {
      type: [Number, String],
    },
  },

  data: function () {
    return {
      hovering: false,
    };
  },

  computed: {
    iconTopMargin: function () {
      return this.iconTopOffset;
    },
    iconLeftMargin: function () {
      return !this.leftCap ? -16 + this.iconLeftOffset : this.iconLeftOffset;
    },
    leftCapSize: function () {
      return this.capSize ? this.capSize : 120;
    },
    topCapSize: function () {
      return this.capSize ? this.capSize : 50;
    },
    cardBackgroundColor: function () {
      if (this.disabled) return this.$colors.penChecksLightGray;

      return this.selected || this.hovering
        ? this.$colors.penChecksLightTeal
        : this.$colors.white;
    },
    maxTextHeight: function () {
      if (!this.height) return "";

      return "max-height: " + (this.height - this.topCapSize) + "px;";
    },
    cardWidth: function () {
      if (!this.width) {
        return "100%";
      }

      if (typeof this.width === "number") {
        return this.width.toString() + "px";
      }

      return this.width;
    },
  },

  methods: {
    clicked: function () {
      if (!this.disabled) {
        this.$emit("click");
      }
    },
  },
  mounted: function () {
    $(this.$el).click(this.clicked.bind(this));
  },
};
</script>

<style lang="scss" scoped>
.capped-card {
  height: 100%;
  margin-bottom: 8px;

  &.hovering {
    cursor: pointer;

    div.v-card {
      border: solid $penChecksTeal 1px !important;
      background-color: #ecf3f5 !important;
    }
  }

  .v-card {
    border: solid $penChecksLightGray 1px;
  }

  .disabled-text > p {
    color: $penChecksMediumGray !important;
  }
}
</style>
