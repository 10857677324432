import Vue from "vue";
import VueRouter from "vue-router";
import RouteObjects from "@/shared/router/route-objects";

var router = {};

if (window.location.host.match(/admin.*\.penchecks\.com/g) !== null) {
  Vue.use(VueRouter);

  const routes = [
    {
      path: "/",
      name: "Home",
      component: () => import("../views/PcAdminHome.vue"),
    },

    {
      comment: "***** ACCOUNTS *****",
      path: "/accounts",
      name: "Accounts",
      meta: { title: "Accounts" },
      component: () => import("../views/accounts/PcSearchAccounts.vue"),
    },
    {
      path: "/accounts/:accountUuid",
      name: "AccountDetails",
      meta: { title: "Account Details" },
      component: () => import("../views/accounts/PcAccountDetails.vue"),
    },
    {
      path: "/accounts/:accountUuid/bulk-orders/:bulkOrderUuid",
      name: "BulkOrderSummary",
      meta: { title: "Bulk Order Summary" },
      component: () => import("../views/accounts/PcAccountDetails.vue"),
    },

    {
      path: "/users",
      name: "Users",
      meta: { title: "Search Users" },
      component: () => import("../views/users/PcSearchUsers.vue"),
    },
    {
      path: "/users/:userUuid",
      name: "UserDetails",
      meta: { title: "User Details" },
      component: () => import("../views/users/PcUserDetails.vue"),
    },

    {
      path: "/participants",
      name: "Participants",
      meta: { title: "Search Participants" },
      component: () => import("../views/participants/PcSearchParticipants.vue"),
    },
    {
      path: "/participants/:participantUuid",
      name: "ParticipantDetails",
      meta: { title: "Participant Details" },
      component: () => import("../views/participants/PcParticipantDetails.vue"),
    },

    {
      path: "/plans",
      name: "Plans",
      meta: { title: "Plans" },
      component: () => import("../views/plans/PcSearchPlans.vue"),
    },
    {
      path: "/plans/:planUuid",
      name: "PlanDetails",
      meta: { title: "Plan Details" },
      component: () => import("../views/plans/PcPlanDetails.vue"),
    },

    {
      path: "/national-registry-accounts",
      name: "NationalRegistryAccounts",
      meta: { title: "National Registry Accounts" },
      component: () =>
        import(
          "../views/national-registry/PcSearchNationalRegistryAccounts.vue"
        ),
    },
    {
      path: "/national-registry/accounts/:nationalRegistryAccountUuid",
      name: "NationalRegistryAccountDetails",
      meta: { title: "National Registry Account Details" },
      component: () =>
        import(
          "../views/national-registry/PcNationalRegistryAccountDetails.vue"
        ),
    },

    {
      path: "/invoices/:invoiceUuid",
      name: "InvoiceDetails",
      meta: { title: "Invoice Details" },
      component: () => import("../views/plans/PcInvoiceDetails.vue"),
    },

    {
      comment: "***** ORDERS *****",
      path: "/orders",
      name: "Orders",
      meta: { title: "Orders" },
      component: () => import("../views/orders/PcSearchOrders.vue"),
    },
    {
      path: "/orders/:orderUuid",
      name: "OrderDetails",
      meta: { title: "Order Details" },
      component: () => import("../views/orders/PcOrderDetails.vue"),
    },
    {
      path: "/:accountUuid/orders/:orderUuid",
      meta: { title: "Edit Order" },
      component: () => import("../views/orders/PcOrderDetails.vue"),
    },

    {
      path: "/order-lines",
      name: "Order Lines",
      meta: { title: "Order Lines" },
      component: () => import("../views/orders/PcSearchOrderLines.vue"),
    },
    {
      path: "/order-lines/:orderLineUuid",
      name: "Order Line Details",
      meta: { title: "Order Line Details" },
      component: () => import("../views/orders/PcOrderLineDetails.vue"),
    },
    {
      path: "/recurring-order-lines",
      name: "Recurring Order Lines",
      meta: { title: "Recurring Order Lines" },
      component: () =>
        import("../views/orders/PcSearchRecurringOrderLines.vue"),
    },

    {
      path: "/payment-instructions",
      name: "PaymentInstructions",
      meta: { title: "Payment Instructions" },
      component: () =>
        import("../views/payments/PcSearchPaymentInstructions.vue"),
    },

    {
      path: "/payments",
      name: "Payments",
      meta: { title: "Payments" },
      component: () => import("../views/payments/PcSearchPayments.vue"),
    },
    {
      path: "/payments/:paymentUuid",
      name: "PaymentDetails",
      meta: { title: "Payment Details" },
      component: () => import("../views/payments/PcPaymentDetails.vue"),
    },

    {
      comment: "***** OPERATIONS/TRUST DEPT *****",
      path: "/trust/home",
      name: "TrustDepartment",
      meta: { title: "Trust Department" },
      component: () =>
        import("../views/trust-department/PcTrustDepartment.vue"),
    },
    {
      path: "/trust/orders-to-associate",
      name: "TrustOrdersToAssociate",
      meta: { title: "Orders to Associate" },
      component: () =>
        import("../views/trust-department/PcTrustDepartment.vue"),
    },
    {
      path: "/trust/deposits",
      name: "TrustDeposits",
      meta: { title: "Deposits" },
      component: () =>
        import("../views/trust-department/PcTrustDepartment.vue"),
    },
    {
      path: "/trust/deposits/:depositUuid",
      name: "TrustDeposit",
      meta: { title: "Deposit Details" },
      component: () =>
        import("../views/trust-department/PcTrustDepartment.vue"),
    },
    {
      path: "/deposit-links",
      name: "Deposit Links",
      meta: { title: "Deposit Links to Plan" },
      component: () => import("../views/PcSearchDepositLinks.vue"),
    },
    {
      path: "/deposit-links-to-order",
      name: "Deposit Links to Order",
      meta: { title: "Deposit Links to Order" },
      component: () => import("../views/PcSearchDepositLinks.vue"),
    },
    {
      path: "/deposit-links-to-order-line",
      name: "Deposit Links to Order Line",
      meta: { title: "Deposit Links to Order Line" },
      component: () => import("../views/PcSearchDepositLinks.vue"),
    },
    {
      path: "/trust/payment-instructions-to-review",
      name: "TrustPaymentInstructionsToReview",
      meta: { title: "Trust Payment Instructions" },
      component: () =>
        import("../views/trust-department/PcTrustDepartment.vue"),
    },
    {
      path: "/trust/bank-transactions",
      name: "BankTransactions",
      meta: { title: "Bank Transactions" },
      component: () =>
        import("../views/trust-department/PcTrustDepartment.vue"),
    },
    {
      path: "/trust/bank-transaction-files",
      name: "BankTransactionFiles",
      meta: { title: "Bank Transaction Files" },
      component: () =>
        import("../views/trust-department/PcTrustDepartment.vue"),
    },
    {
      path: "/trust/upload-deposits",
      name: "UploadDeposits",
      meta: { title: "Upload Deposits" },
      component: () =>
        import("../views/trust-department/PcTrustDepartment.vue"),
    },
    {
      path: "/trust/john-hancock",
      name: "JohnHancock",
      meta: { title: "John Hancock" },
      component: () =>
        import("../views/trust-department/PcTrustDepartment.vue"),
    },

    {
      path: "/trust/associations/home",
      name: "Associations",
      meta: { title: "Associations" },
      component: () =>
        import("../views/trust-department/PcTrustAssociations.vue"),
    },
    {
      path: "/trust/associations/rbp",
      name: "RbpAssociations",
      meta: { title: "RBP Associations" },
      component: () =>
        import("../views/trust-department/PcTrustAssociations.vue"),
    },
    {
      path: "/trust/associations/:linkOrCredit(links|credit)/:type(plans|accounts|orders|order-lines)",
      name: "Associations Search",
      meta: { title: "Associations Search" },
      component: () =>
        import("../views/trust-department/PcTrustAssociations.vue"),
    },

    {
      path: "/auto-service",
      name: "Custodian Auto Service",
      meta: { title: "Custodian Auto Service" },
      component: () =>
        import("../views/auto-service/PcCustodianAutoService.vue"),
    },

    {
      comment: "***** OPERATIONS/DISTRIBUTIONS DEPT *****",
      path: "/distributions",
      name: "DistributionsDepartment",
      meta: { title: "Distributions Department" },
      component: () =>
        import(
          "../views/distributions-department/PcDistributionsDepartment.vue"
        ),
    },
    {
      path: "/distributions/orders-to-initiate",
      name: "OrdersToInitiate",
      meta: { title: "Orders to Initiate" },
      component: () =>
        import(
          "../views/distributions-department/PcDistributionsDepartment.vue"
        ),
    },
    {
      path: "/distributions/payment-instructions-ready-to-pay",
      name: "PaymentInstructionsReadyToPay",
      meta: { title: "Payments Ready to Pay" },
      component: () =>
        import(
          "../views/distributions-department/PcDistributionsDepartment.vue"
        ),
    },

    {
      path: "/payment-batches",
      name: "PaymentBatches",
      meta: { title: "Payment Batches" },
      component: () =>
        import(
          "../views/distributions-department/PcDistributionsDepartment.vue"
        ),
    },
    {
      path: "/payment-batches/:paymentBatchUuid",
      name: "PaymentBatchDetails",
      meta: { title: "Payment Batch Details" },
      component: () =>
        import(
          "../views/distributions-department/PcDistributionsDepartment.vue"
        ),
    },

    {
      path: "/distributions/missing-notices",
      name: "MissingNotices",
      meta: { title: "Missing Notices" },
      component: () =>
        import(
          "../views/distributions-department/PcDistributionsDepartment.vue"
        ),
    },
    {
      path: "/distributions/documents-ready-to-send",
      name: "OrderLineDocuments",
      meta: { title: "Documents Ready to Send" },
      component: () =>
        import(
          "../views/distributions-department/PcDistributionsDepartment.vue"
        ),
    },
    {
      path: "/document-batches",
      name: "DocumentBatches",
      meta: { title: "Document Batches" },
      component: () =>
        import(
          "../views/distributions-department/PcDistributionsDepartment.vue"
        ),
    },
    {
      path: "/document-batches/:documentBatchUuid",
      name: "DocumentBatchDetails",
      meta: { title: "Document Batch Details" },
      component: () =>
        import(
          "../views/distributions-department/PcDistributionsDepartment.vue"
        ),
    },

    {
      path: "/distributions/expired-order-lines",
      name: "ExpiredOrderLines",
      meta: { title: "Expired Order Lines" },
      component: () =>
        import(
          "../views/distributions-department/PcDistributionsDepartment.vue"
        ),
    },
    {
      path: "/distributions/expired-order-lines-to-follow-up",
      name: "ExpiredOrderLinesToFollowUp",
      meta: { title: "Follow Up on Expired Order Lines" },
      component: () =>
        import(
          "../views/distributions-department/PcDistributionsDepartment.vue"
        ),
    },

    {
      path: "/distributions/bulk-operations",
      name: "BulkOperations",
      meta: { title: "Bulk Operations" },
      component: () =>
        import(
          "../views/distributions-department/PcDistributionsDepartment.vue"
        ),
    },

    {
      path: "/recurring/home",
      name: "Recurring Benefits",
      meta: { title: "Recurring Benefits" },
      component: () => import("../views/recurring/PcRecurringDepartment"),
    },
    {
      path: "/recurring/plans",
      name: "Recurring Plans",
      meta: { title: "Recurring Plans" },
      component: () => import("../views/recurring/PcRecurringDepartment"),
    },
    {
      path: "/recurring/participants",
      name: "Recurring Participants",
      meta: { title: "Recurring Participants" },
      component: () => import("../views/recurring/PcRecurringDepartment"),
    },
    {
      path: "/recurring/invoices",
      name: "Invoices",
      meta: { title: "Invoices" },
      component: () => import("../views/recurring/PcRecurringDepartment"),
    },
    {
      path: "/recurring/invoices/:invoiceUuid",
      name: "InvoiceDetail",
      meta: { title: "Invoice Detail" },
      component: () => import("../views/recurring/PcRecurringDepartment"),
    },

    {
      path: "/recurring/create-batch",
      name: "CreateRecurringBatch",
      meta: { title: "Create Recurring Batch" },
      component: () => import("../views/recurring/PcRecurringDepartment"),
    },
    {
      path: "/recurring/batches",
      name: "RecurringBatches",
      meta: { title: "Recurring Batches" },
      component: () => import("../views/recurring/PcRecurringDepartment"),
    },
    {
      path: "/recurring/batch-orders",
      name: "RecurringBatchOrders",
      meta: { title: "Recurring Batch Orders" },
      component: () => import("../views/recurring/PcRecurringDepartment"),
    },
    {
      path: "/recurring/batches/:recurringBatchUuid",
      name: "RecurringBatchDetail",
      meta: { title: "Recurring Batch Detail" },
      component: () => import("../views/recurring/PcRecurringDepartment"),
    },

    {
      path: "/accounts-division/order-lines",
      name: "AccountsDivisionOrderLines",
      meta: { title: "Order Lines | Accounts Division" },
      component: () =>
        import("../views/accounts-division/PcAccountsDivision.vue"),
    },
    {
      path: "/accounts-division/duplicate-order-lines",
      name: "AccountsDivisionDuplicateOrderLines",
      meta: { title: "Duplicate Order Lines | Accounts Division" },
      component: () =>
        import("../views/accounts-division/PcAccountsDivision.vue"),
    },
    {
      path: "/deposit-batches",
      name: "DepositBatches",
      meta: { title: "Deposit Batches" },
      component: () =>
        import("../views/accounts-division/PcAccountsDivision.vue"),
    },
    {
      path: "/deposit-batches/:depositBatchUuid",
      name: "DepositBatchDetails",
      meta: { title: "Deposit Batch Details" },
      component: () =>
        import("../views/accounts-division/PcAccountsDivision.vue"),
    },
    {
      path: "/accounts-division/next-level-queue",
      name: "AccountsDivisionNextLevelQueue",
      meta: { title: "Next Level Queue | Accounts Division" },
      component: () =>
        import("../views/accounts-division/PcAccountsDivision.vue"),
    },
    {
      path: "/accounts-division/next-level-failed-queue",
      name: "AccountsDivisionNextLevelFailedQueue",
      meta: { title: "Next Level Failed Queue | Accounts Division" },
      component: () =>
        import("../views/accounts-division/PcAccountsDivision.vue"),
    },
    {
      path: "/accounts-division/order-line-documents",
      name: "AccountsDivisionDocuments",
      meta: { title: "Documents | Accounts Division" },
      component: () =>
        import("../views/accounts-division/PcAccountsDivision.vue"),
    },
    {
      path: "/accounts-division/document-batches",
      name: "AccountsDivisionDocumentBatch",
      meta: { title: "Document Batches | Accounts Division" },
      component: () =>
        import("../views/accounts-division/PcAccountsDivision.vue"),
    },
    {
      path: "/accounts-division/document-batches/:documentBatchUuid",
      name: "AccountsDivisionDocumentBatchDetail",
      meta: { title: "Document Batch Detail | Accounts Division" },
      component: () =>
        import("../views/accounts-division/PcAccountsDivision.vue"),
    },

    {
      path: "/taxes-payable",
      name: "TaxesPayable",
      meta: { title: "Federal & State Tax Remittance" },
      component: () => import("../views/taxes-and-fees/PcTaxes.vue"),
    },
    {
      path: "/order-line-taxes",
      name: "OrderLineTaxes",
      meta: { title: "Order Line Taxes" },
      component: () => import("../views/taxes-and-fees/PcTaxes.vue"),
    },
    {
      path: "/tax-payments",
      name: "TaxPayments",
      meta: { title: "Tax Payments" },
      component: () => import("../views/taxes-and-fees/PcTaxes.vue"),
    },
    {
      path: "/tax-refunds",
      name: "TaxRefunds",
      meta: { title: "Tax Refunds" },
      component: () => import("../views/taxes-and-fees/PcTaxes.vue"),
    },
    {
      path: "/tax-refunds/:taxRefundUuid",
      name: "TaxRefundsDetails",
      meta: { title: "TaxRefunds Details" },
      component: () => import("../views/taxes-and-fees/PcTaxRefundDetails.vue"),
    },
    {
      path: "/state-tax-rates",
      name: "StateTaxRates",
      meta: { title: "State Tax Rates" },
      component: () => import("../views/taxes-and-fees/PcTaxes.vue"),
    },
    {
      path: "/tax-reporting-change-request-queue",
      name: "TaxReportingChangeRequestQueue",
      meta: { title: "1099 Change Request Queue" },
      component: () => import("../views/taxes-and-fees/PcTaxes.vue"),
    },
    {
      path: "/taxing-authority/:authorityId",
      name: "TaxingAuthorityHome",
      meta: { title: "Taxing Authority Home" },
      component: () =>
        import("../views/taxes-and-fees/PcTaxingAuthorityHome.vue"),
    },
    {
      path: "/irs-form-reporting",
      name: "IRS Form Reporting",
      meta: { title: "IRS Form Reporting" },
      component: () => import("../views/taxes-and-fees/PcTaxes.vue"),
    },
    {
      path: "/irs-form-archive",
      name: "IRS Form Archive",
      meta: { title: "IRS Form Archive" },
      component: () => import("../views/taxes-and-fees/PcTaxes.vue"),
    },
    {
      path: "/tax_adjustments_ledger",
      name: "Tax Adjustments Ledger",
      meta: { title: "Tax Adjustments Ledger" },
      component: () => import("../views/taxes-and-fees/PcTaxes.vue"),
    },

    {
      path: "/fees-payable",
      name: "FeesPayable",
      meta: { title: "Fees Payable" },
      component: () => import("../views/taxes-and-fees/PcFees.vue"),
    },
    {
      path: "/order-line-fees",
      name: "OrderLineFees",
      meta: { title: "Order Line Fees" },
      component: () => import("../views/taxes-and-fees/PcFees.vue"),
    },

    {
      comment: "***** LEGAL *****",
      path: "/legal/fincen",
      name: "FinCEN",
      meta: { title: "FinCEN" },
      component: () => import("../views/legal/PcUploadFinCenFile.vue"),
    },

    {
      comment: "***** SYSTEM *****",
      path: "/authorization/staff-members",
      name: "StaffMembers",
      meta: { title: "Staff Members" },
      component: () => import("../views/permissions/PcAuthorization.vue"),
    },
    {
      path: "/authorization/roles",
      name: "Roles",
      meta: { title: "Roles" },
      component: () => import("../views/permissions/PcAuthorization.vue"),
    },
    {
      path: "/authorization/permissions",
      name: "Permissions",
      meta: { title: "Permissions" },
      component: () => import("../views/permissions/PcAuthorization.vue"),
    },

    {
      path: "/agreements",
      name: "Agreements",
      meta: { title: "Agreements" },
      component: () => import("../views/agreements/PcAgreementDetails.vue"),
    },

    {
      path: "/messages",
      name: "Messages",
      meta: { title: "Messages" },
      component: () => import("../views/messages/PcSearchMessages.vue"),
    },
    {
      path: "/messages/:messageUuid",
      name: "MessageDetails",
      meta: { title: "Message Details" },
      component: () => import("../views/messages/PcMessageDetails.vue"),
    },

    {
      path: "/rules-engine",
      name: "Rules Engine",
      meta: { title: "Rules Engine" },
      component: () => import("../views/rules-engine/PcRulesEngine.vue"),
    },
    {
      path: "/notice-management/:category",
      name: "Add Notice",
      meta: { title: "Add Notice" },
      component: () =>
        import("../views/content-management/notices/PcNoticeDetails.vue"),
    },
    {
      path: "/content-management",
      name: "Content Management",
      meta: { title: "Content Management" },
      component: () =>
        import("../views/content-management/PcContentManagement.vue"),
      children: [
        {
          path: "notices",
          name: "Notices",
          meta: { title: "Content Management - Notices" },
          component: () =>
            import(
              "../views/content-management/notices/PcNoticeManagement.vue"
            ),
        },
        {
          path: "user-guides",
          name: "User Guides",
          meta: { title: "Content Management - User Guides" },
          component: () =>
            import(
              "../views/content-management/user-guides/PcUserGuideManagement.vue"
            ),
        },
      ],
    },
    {
      path: "/ui-components",
      name: "UI Components",
      meta: { title: "UI Components" },
      component: () =>
        import("../views/ui-components/PcUiComponentDetails.vue"),
    },
    {
      path: "/technology-team",
      name: "Technology Team",
      meta: { title: "Technology Team" },
      component: () =>
        import("../views/technology-department/PcTechnologyDepartment.vue"),
    },

    {
      comment: "***** GENERAL *****",
      path: "/files/:storedFileUuid([a-zA-Z0-9]{32})/download",
      meta: { title: "File Download" },
      component: () => import("../views/PcAdminFileDownload.vue"),
    },

    // Keep this last for 404s
    {
      path: "/*",
      name: "NotFound",
      meta: { title: "404 Not Found" },
      component: () => import("../views/Pc404NotFound.vue"),
    },
  ];

  router = new VueRouter({
    mode: "history",
    routes,
  });

  router.objects = new RouteObjects(router);

  router.afterEach((to) => {
    if (to.meta && to.meta.title) {
      document.title = to.meta.title + " | PenChecks Admin";
    } else {
      document.title = "PenChecks Admin";
    }

    if (router.app && router.app.$gtag) {
      router.app.$gtag.pageview({
        page_title: document.title,
        page_path: to.path,
        page_location: window.location.href,
      });
    }
  });
}

export default router;
