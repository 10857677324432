<template>
  <v-app id="client-app">
    <pc-singleton-modals id="singleton-client-modals"></pc-singleton-modals>
    <pc-singleton-overlay id="singleton-client-overlay"></pc-singleton-overlay>
    <pc-singleton-snackbar
      id="singleton-client-snackbar"
    ></pc-singleton-snackbar>

    <div
      v-if="
        initialAuthenticationRequestComplete &&
        $store.state.authenticatedUser &&
        $store.state.authenticatedUser.isClient()
      "
      class="fill-height pc-client-app-container"
    >
      <router-view></router-view>
    </div>
  </v-app>
</template>

<script>
import notifyPendo from "@/shared/lib/notifyPendo";

export default {
  components: {},

  data: () => ({
    initialAuthenticationRequestComplete: false,
  }),

  watch: {
    $route() {
      // Called whenever the route changes.
    },
  },

  methods: {
    // Called whenever the route changes
    routeChanged() {},
  },

  mounted: function () {
    window.pendo.initialize();
    let pathname = window.location.pathname;
    console.log("Pathname:", pathname);

    // float disclosure file is public and available to non-authenticated users
    if (pathname === "/float-disclosure") {
      console.log("Redirect to pdf");
      window.location =
        window.amplifyRuntimeConfig.get("VUE_APP_CLIENT_BASE_URL") +
        "/PenChecksFloatDisclosure.pdf";
      return;
    }

    // This Client App component is only mounted once per page load, so this is the area to CLIENT site bootstrapping

    // Initalize with the progress overlay
    this.$overlay.show("Loading...");

    // Refresh our authenticated user.
    this.$store.dispatch(
      "refreshAuthenticatedUser",
      function (user) {
        if (user && user.isClient()) {
          this.$overlay.hide();
          this.initialAuthenticationRequestComplete = true;
          notifyPendo(user);
          this.$mixpanel.identify(user.emailAddress);
          this.$mixpanel.people.set({
            "User Type": user.type,
            $name: user.firstName + " " + user.lastName,
            $email: user.emailAddress,
          });
        } else {
          window.location =
            window.amplifyRuntimeConfig.get("VUE_APP_LOGIN_URL") +
            "?returnUrl=" +
            encodeURI(window.location.href);
        }
      }.bind(this)
    );

    this.$cache.fetchAndCacheLookupData();
  },
};
</script>

<style lang="scss">
#client-app {
  font-family: "Roboto", sans-serif;
  letter-spacing: 0px;

  .v-card__text {
    line-height: 18px !important;
    font-size: 14px !important;
  }
  @media (max-width: 960px) {
    body {
      width: max-content !important;
    }
    .pc-client-app-container {
      width: max-content !important;
    }
  }

  @media print {
    .pc-client-app-container {
      width: 100% !important;
    }

    .v-navigation-drawer {
      display: none;
    }

    .v-main {
      padding-left: 0 !important;
    }
  }

  // Vuetify v2.4 changed styling for nested grid items, causing spacing across the
  // app to be affected. A solution was found here that restores our previous spacing:
  // https://github.com/vuetifyjs/vuetify/issues/12848
  .row {
    margin-top: 0;
    margin-bottom: 0;
  }

  .row + .row {
    margin-top: 0;
  }
}

html {
  overflow: auto;
}

.requiredField:before {
  content: " *";
  color: #d32f2f;
  margin-right: 5px;
  margin-top: 5px;
  font-size: 15px;
}

.requiredFieldText .requiredField:before {
  vertical-align: top;
}

.requiredFieldText {
  vertical-align: text-top;
  font-size: 13px;
  color: #989898;
}
</style>
