<template>
  <div class="static-file-value" :style="label ? 'padding: 12px;' : ''">
    <template v-if="label">
      <template v-if="singleLine">
        <strong>{{ label }}:</strong>
      </template>
      <template v-else>
        <strong>{{ label }}</strong>
        <br />
      </template>
    </template>

    <template>
      <pc-button
        v-if="isDownloadable"
        :disabled="!fileId"
        text
        small
        @click.stop="downloadFile()"
      >
        <pc-icon
          v-if="showIcons"
          name="pc-download"
          size="14"
          class="pr-2"
        ></pc-icon
        >{{ buttonText ? buttonText : "Download" }}
      </pc-button>
      <pc-button
        v-else-if="isPrintable"
        :disabled="!fileId"
        text
        small
        @click.stop="printFile()"
      >
        <pc-icon
          v-if="showIcons"
          name="pc-workflow-printing"
          size="14"
          class="pr-2"
        ></pc-icon
        >{{ buttonText ? buttonText : "Print" }}
      </pc-button>
      <pc-button
        v-else
        :disabled="!fileId"
        text
        small
        @click.stop="viewFile()"
        >{{ buttonText ? buttonText : "View" }}</pc-button
      >
      <pc-button
        :disabled="!fileId"
        v-if="allowsDelete"
        text
        small
        @click="$emit('delete')"
      >
        <pc-icon name="pc-delete" :size="12"></pc-icon>
      </pc-button>
      <span v-if="suffix">{{ suffix }}</span>
    </template>
  </div>
</template>
<script>
export default {
  components: {},

  props: {
    label: {
      type: String,
    },
    buttonText: {
      type: String,
    },
    suffix: {
      type: String,
    },
    fileId: {
      type: String,
    },
    showIcons: {
      type: Boolean,
    },
    isDownloadable: {
      type: Boolean,
    },
    isPrintable: {
      type: Boolean,
    },
    allowsDelete: {
      type: Boolean,
    },
    singleLine: {
      type: Boolean,
    },
  },

  methods: {
    downloadFile() {
      window.location.href =
        window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
        "/public/files/" +
        this.fileId +
        "/download";
    },
    printFile() {
      var w = window.open(
        window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
          "/public/files/" +
          this.fileId +
          "/view"
      );
      w.print();
    },
    viewFile() {
      window.open(
        window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
          "/public/files/" +
          this.fileId +
          "/view"
      );
    },
  },
};
</script>

<style lang="scss">
.static-file-value {
  font-size: 14px;
}
</style>
