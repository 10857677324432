<template>
  <pc-dropdown-multiple
    v-if="items.length > 0"
    v-model="selectedValue"
    :items="items"
    :item-disabled="disableItem"
    :loading="searchRequestHandler.isBusy"
    :hide-selected="hideSelected"
    item-text="searchResultTitle"
    item-value="code"
    :rules="required ? selectRules : []"
    :label="label"
    :prepend-icon="prependIcon"
    :clearable="clearable"
    :item-color="$colors.penChecksTeal"
    :menu-props="{ offsetY: true }"
    :dense="dense"
    :enable-select-all="enableSelectAll"
    :select-all-text="selectAllText"
    return-object
    @change="$emit('change', selectedValue)"
  >
  </pc-dropdown-multiple>
</template>

<script>
import SearchRequestHandler from "@/shared/lib/client-sdk/search/search-request-handler";
import PcDropdownMultiple from "./PcDropdownMultiple.vue";

export default {
  components: { PcDropdownMultiple },

  props: {
    value: {
      type: Array,
    },
    label: {
      type: String,
      default: "Select",
    },
    entityType: {
      type: String,
      required: true,
    },
    dense: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
    },
    hideSelected: {
      type: Boolean,
    },
    prependIcon: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    planType: {
      type: String,
    },
    enableSelectAll: {
      type: Boolean,
      default: false,
    },
    selectAllText: {
      type: String,
    },
  },

  data: function () {
    return {
      searchRequestHandler: new SearchRequestHandler(this.entityType),
      items: this.$cache.get("LookupData_" + this.entityType, []),
      selectedValue: this.value,
      selectRules: [
        (v) => {
          if (!this.required) return true;

          return v.length > 0 || "Please " + this.label.toLowerCase();
        },
        (v) => {
          if (
            [
              "definedContributionCashBalance",
              "definedContributionMoneyPurchase",
              "definedBenefitPension",
            ].includes(this.planType)
          ) {
            const joinSurvivors = [
              "jointSurvivor50",
              "jointSurvivor66",
              "jointSurvivor75",
              "jointSurvivor100",
            ];
            const jointSurvivor = v.some((annuity) =>
              joinSurvivors.includes(annuity.code)
            );
            if (!jointSurvivor) {
              return "At least one of the joint survivor annuity types is required";
            }
          }
          return true;
        },
      ],
    };
  },

  watch: {
    value(newValue) {
      this.selectedValue = newValue;
    },
    selectedValue(newValue) {
      this.$emit("input", newValue);
      this.$emit("select", newValue);
    },
  },

  methods: {
    // This method disables list items in the dropdown. To keep logic scoped to specific entityTypes, add a new case to the switch statement.
    disableItem(item) {
      switch (this.entityType) {
        case "AnnuityType":
          if (
            [
              "definedContributionCashBalance",
              "definedContributionMoneyPurchase",
              "definedBenefitPension",
            ].includes(this.planType) &&
            item.code === "lifeAnnuity"
          ) {
            return true;
          }
      }
    },
  },

  mounted: function () {
    if (this.items.length == 0) {
      this.searchRequestHandler.search(
        function (response) {
          if (response && response.results) {
            this.items = response.results;
            this.$cache.set("LookupData_" + this.entityType, this.items);
            this.selectedValue = this.value;
          }
        }.bind(this)
      );
    } else {
      this.selectedValue = this.value;
    }
  },
};
</script>

<style lang="scss"></style>
