<template>
  <div v-if="this.type == undefined">
    <div v-if="this.size && this.size == 16">
      <view-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <view-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <view-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <view-32 :class="iconColorClass" />
    </div>
    <div v-else><view-16 :class="iconColorClass" /></div>
  </div>
  <div v-else-if="this.type == 'off'">
    <div v-if="this.size && this.size == 16">
      <view-off-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <view-off-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <view-off-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <view-off-32 :class="iconColorClass" />
    </div>
    <div v-else><view-off-16 :class="iconColorClass" /></div>
  </div>
  <div v-else-if="this.type == 'filled'">
    <div v-if="this.size && this.size == 16">
      <view-filled-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <view-filled-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <view-filled-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <view-filled-32 :class="iconColorClass" />
    </div>
    <div v-else><view-filled-16 :class="iconColorClass" /></div>
  </div>
  <div v-else-if="this.type == 'off-filled'">
    <div v-if="this.size && this.size == 16">
      <view-off-filled-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <view-off-filled-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <view-off-filled-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <view-off-filled-32 :class="iconColorClass" />
    </div>
    <div v-else><view-off-filled-16 :class="iconColorClass" /></div>
  </div>
</template>

<script>
import {
  View16,
  View20,
  View24,
  View32,
  ViewOff16,
  ViewOff20,
  ViewOff24,
  ViewOff32,
  ViewFilled16,
  ViewFilled20,
  ViewFilled24,
  ViewFilled32,
  VerticalView32,
  ViewOffFilled16,
  ViewOffFilled20,
  ViewOffFilled24,
  ViewOffFilled32,
} from "@carbon/icons-vue";

export default {
  name: "PcViewIcon",

  components: {
    "view-16": View16,
    "view-20": View20,
    "view-24": View24,
    "view-32": View32,
    "view-off-16": ViewOff16,
    "view-off-20": ViewOff20,
    "view-off-24": ViewOff24,
    "view-off-32": ViewOff32,
    "view-filled-16": ViewFilled16,
    "view-filled-20": ViewFilled20,
    "view-filled-24": ViewFilled24,
    "view-filled-32": ViewFilled32,
    "view-off-filled-16": ViewOffFilled16,
    "view-off-filled-20": ViewOffFilled20,
    "view-off-filled-24": ViewOffFilled24,
    "view-off-filled-32": ViewOffFilled32,
  },

  props: {
    size: {
      type: [Number, String],
      validator: function (value) {
        return [16, "16", 20, "20", 24, "24", 32, "32"].includes(value);
      },
      required: false,
      default: 16,
    },
    type: {
      type: String,
      required: false,
      default: undefined,
      validator: function (value) {
        return [undefined, "filled", "off", "off-filled", "vertical"].includes(
          value
        );
      },
    },
    iconColorClass: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
