import SystemProperty from "@/shared/lib/client-sdk/models/system-property";

export default {
  state: {
    properties: {},
  },
  mutations: {
    setSystemProperties(state, properties) {
      state.properties = properties;
    },
  },
  getters: {
    getSystemProperty: (state) => (propertyName) => {
      if (!(state.properties instanceof Array)) return null;
      return state.properties.find((x) => x.propertyName === propertyName)
        ?.value;
    },
    getSystemPropertyAsNumber: (state) => (propertyName) => {
      if (!(state.properties instanceof Array)) return null;
      var value = state.properties.find(
        (x) => x.propertyName == propertyName
      )?.value;
      if (value && !isNaN(parseInt(value))) return parseInt(value);
      return null;
    },
    getSystemPropertyAsDate: (state) => (propertyName) => {
      if (!(state.properties instanceof Array)) return null;
      var value = state.properties.find(
        (x) => x.propertyName == propertyName
      )?.value;
      if (value && !isNaN(Date.parse(value))) return new Date(value);
      return null;
    },
  },
  actions: {
    fetchSystemProperties(context) {
      SystemProperty.fetchAll(
        function (items) {
          if (items) {
            context.commit("setSystemProperties", items);
          } else {
            console.warn("Could not get lookup data");
          }
        }.bind(this)
      );
    },
  },
};
