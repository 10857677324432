<template>
  <v-window v-bind="$attrs" v-on="$listeners" v-model="windowItemIndex">
    <slot></slot>
  </v-window>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
    },
    useUrlHash: {
      type: Boolean,
    },
    useRoutePath: {
      type: Boolean,
    },
  },

  data: () => {
    return {
      children: [],
      windowItemIndex: 0,
      currentRoutePath: "",
      currentUrlHash: "",
    };
  },

  watch: {
    $route: function () {
      this.refreshRouteValues();
    },
    "$urlHash.value": function () {
      this.refreshRouteValues();
    },
    value: function () {
      if (!this.useUrlHash && !this.useRoutePath) {
        this.windowItemIndex = this.value;
      }
    },
    windowItemIndex: function () {
      if (!this.useUrlHash && !this.useRoutePath) {
        this.$emit("input", this.windowItemIndex);
      }
    },
  },

  computed: {
    uniqueKey: function () {
      return this.$vnode.tag + this.$urlHash.value + this.$urlHash.suffix;
    },
    currentChildIndex: function () {
      if (!this.useUrlHash && !this.useRoutePath) {
        if (
          this.windowItemIndex >= 0 &&
          this.windowItemIndex < this.children.length
        ) {
          return this.windowItemIndex;
        }
      } else {
        for (var i = 0; i < this.children.length; i++) {
          var child = this.children[i];

          if (
            (this.useUrlHash && child.value == this.currentUrlHash) ||
            (this.useRoutePath && child.value == this.currentRoutePath)
          ) {
            return i;
          }
        }
      }

      return this.children.length > 0 ? 0 : null;
    },
    currentChild: function () {
      var index = this.currentChildIndex;
      if (index >= 0) {
        return this.children[index];
      }

      return null;
    },
  },

  methods: {
    refreshChildren: function () {
      this.children = this.$childrenWithTag("pc-window-item");
    },
    refreshRouteValues: function () {
      this.currentRoutePath = this.$route.path; // 'path' does not include hashes, or query strings

      // The matched path will be as it was defined in the route file.  So VARIABLES will be with the : colon front
      // eg: Will be '/trust/deposits/:depositUuid' VS currentRoutePath will be: '/trust/deposits/asdasfafsfssdsdfsdsfdfds23423423'
      this.currentRouteMatchedPath =
        this.$route.matched && this.$route.matched.length > 0
          ? this.$route.matched[0].path
          : null;
      this.currentUrlHash = this.$urlHash.value; // The hash value to the left of the colon ':'

      if (this.useUrlHash || this.useRoutePath) {
        for (var i = 0; i < this.children.length; i++) {
          var child = this.children[i];

          if (
            (this.useUrlHash && child.value == this.currentUrlHash) ||
            (this.useRoutePath &&
              (child.value == this.currentRoutePath ||
                child.value == this.currentRouteMatchedPath))
          ) {
            this.windowItemIndex = i;
            break;
          }
        }
      }
    },
  },

  mounted: function () {
    this.refreshRouteValues();
  },
};
</script>

<style lang="scss"></style>
