"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import $ from "jquery";

import AgreementUserResponse from "@/shared/lib/client-sdk/models/agreement-user-response";
import AgreementAmendmentVersion from "@/shared/lib/client-sdk/models/agreement-amendment-version";

class AgreementVersion extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "AgreementVersion";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/agreement-versions"
    );
  }

  getApiPathForCreate() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/agreement-versions"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/agreement-versions/" +
      this.id
    );
  }

  getAdminPath() {
    return "/accounts/" + this.accountId;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultTitle() {
    return null;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  get relationships() {
    return {
      agreementUserResponse: AgreementUserResponse,
      agreementAmendments: AgreementAmendmentVersion,
    };
  }

  get hasDeadlineExpiredAndNotAgreed() {
    return (
      this.hasDeadlineExpired &&
      (!this.agreementUserResponse || !this.agreementUserResponse.agreedAt)
    );
  }

  createAgreementVersion(callback) {
    let formData = new FormData();
    formData.append("agreementCode", this.agreementCode);
    formData.append("file", this.file);
    formData.append("version", this.version);
    formData.append("isClientConsentRequired", this.isClientConsentRequired);
    formData.append("applyToNewAccountsOnly", this.applyToNewAccountsOnly);
    if (this.identifier) {
      formData.append("identifier", this.identifier);
    }
    if (this.replaceOn) {
      formData.append("replaceOn", this.replaceOn);
    }
    if (this.deadlineOn) {
      formData.append("deadlineOn", this.deadlineOn);
    }
    if (this.accountId) {
      formData.append("accountId", this.accountId);
    }

    ApiRequest.upload(
      this.getApiPathForCreate(),
      formData,
      AgreementVersion
    ).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  updateAgreementVersion(callback) {
    let formData = new FormData();
    formData.append("file", this.file);
    formData.append("isClientConsentRequired", this.isClientConsentRequired);
    formData.append("applyToNewAccountsOnly", this.applyToNewAccountsOnly);
    if (this.identifier) {
      formData.append("identifier", this.identifier);
    }
    if (this.replaceOn) {
      formData.append("replaceOn", this.replaceOn);
    }
    if (this.deadlineOn) {
      formData.append("deadlineOn", this.deadlineOn);
    }

    ApiRequest.upload(
      this.getApiPathForRefreshUpdateAndDelete(),
      formData,
      AgreementVersion
    ).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  accept(accountId, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/accounts/" +
      accountId +
      "/agreement-versions/" +
      this.id +
      "/accept";

    var requestBody = {
      acceptedAt: this.acceptedAt,
    };

    ApiRequest.send(
      "POST",
      requestUrl,
      requestBody,
      AgreementVersion
    ).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  restore(callback) {
    var requestUrl = this.getApiPathForRefreshUpdateAndDelete() + "/restore";

    ApiRequest.send("POST", requestUrl, null, AgreementVersion).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  static loadAgreementVersion(accountId, agreementCode, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/accounts/" +
      accountId +
      "/agreement-versions?agreementCode=" +
      agreementCode;

    ApiRequest.send("GET", requestUrl, null, AgreementVersion).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  static sortAgreementVersionsAndIdentifyLatestVersionForNewAccountsOnly(
    agreementVersions
  ) {
    if (!agreementVersions) {
      return;
    }

    // sort by:
    // agreements that apply to new accounts only desc
    // agreements replace date asc
    // agreements created date asc (used if replace dates are the same)
    agreementVersions.sort((a, b) =>
      a.applyToNewAccountsOnly && !b.applyToNewAccountsOnly
        ? 1
        : !a.applyToNewAccountsOnly && b.applyToNewAccountsOnly
        ? -1
        : 0 ||
          new Date(b.replaceOn).getTime() - new Date(a.replaceOn).getTime() ||
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    let latestVersionForNewAccountsOnly = null;
    for (var agreementVersion of agreementVersions) {
      if (agreementVersion.applyToNewAccountsOnly) {
        agreementVersion.isLatestVersionForNewAccountsOnly = false;
        if (
          agreementVersion.status == "active" &&
          !latestVersionForNewAccountsOnly
        ) {
          agreementVersion.isLatestVersionForNewAccountsOnly = true;
          latestVersionForNewAccountsOnly = agreementVersion;
        }
      }
    }

    return latestVersionForNewAccountsOnly;
  }
}

export default AgreementVersion;
