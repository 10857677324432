<template>
  <div
    :style="widthStyle"
    class="pc-card mx-auto"
    :class="{ 'no-border': noBorder }"
  >
    <v-row v-if="outerTitle || hasSlot('outer-top-right-content')">
      <v-col>
        <span :id="setId('OuterTitle')" class="outer-title">{{
          outerTitle
        }}</span>
      </v-col>
      <v-col
        class="col-5"
        style="position: relative"
        v-if="hasSlot('outer-top-right-content')"
      >
        <div style="position: absolute; right: 0; bottom: 0" class="mr-3 mb-2">
          <slot name="outer-top-right-content"></slot>
        </div>
      </v-col>
    </v-row>

    <v-card class="elevation-0" :class="cardClass">
      <v-card-text>
        <v-row v-if="innerTitle" justify="center">
          <v-col class="col-10 mt-4" :class="{ 'text-center': !textLeft }">
            <span :id="setId('Title')" class="card-title">{{
              innerTitle
            }}</span>
          </v-col>
        </v-row>

        <v-row v-if="innerSubtitle" justify="center">
          <v-col class="col-10" :class="{ 'text-center': !textLeft }">
            <span :id="setId('Subtitle')" class="card-subtitle">{{
              innerSubtitle
            }}</span>
          </v-col>
        </v-row>

        <v-row :class="{ 'text-center': !textLeft }">
          <v-col :class="contentClass">
            <slot></slot>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row v-if="hasSlot('outer-bottom-content')">
      <v-col>
        <slot name="outer-bottom-content"></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    outerTitle: {
      type: String,
    },
    innerTitle: {
      type: String,
    },
    innerSubtitle: {
      type: String,
    },
    width: {
      type: Number,
      default: 600,
    },
    fillWidth: {
      type: Boolean,
    },
    textLeft: {
      type: Boolean,
    },
    innerClass: {
      type: String,
    },
    contentClass: {
      type: String,
      default: "px-10",
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    idPrefix: {
      type: String,
      default: "",
    },
  },
  computed: {
    widthStyle: function () {
      if (this.fillWidth) return;

      if (this.width && this.width > 0) {
        return "width: " + this.width + "px";
      }

      return null;
    },
    cardClass: function () {
      var classText = this.innerClass;
      if (!this.textLeft) classText += " text-center ";

      return classText;
    },
  },
  methods: {
    hasSlot(name) {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },

    setId: function (idSufix) {
      return idSufix && this.idPrefix ? this.idPrefix + idSufix : undefined;
    },
  },
};
</script>

<style lang="scss">
.pc-card {
  .v-card {
    border: $penChecksLightGray solid 1px !important;
    border-radius: 6px;
  }

  &.no-border .v-card {
    border: $penChecksLightGray solid 0px !important;
    border-style: none !important;
    border-width: 0px !important;
  }

  .outer-title {
    color: $penChecksMediumGray;
    font-size: 16px;
  }

  .card-title {
    font-size: 24px;
    color: $penChecksDarkGray;
  }

  .card-subtitle {
    font-size: 16px;
    color: $penChecksMediumGray;
  }
}
</style>
