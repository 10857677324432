"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import Account from "@/shared/lib/client-sdk/models/account";
import $ from "jquery";
import Vue from "vue";

class User extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "User";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") + "/public/users"
    );
  }

  getApiPathForCreate() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") + "/public/users"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/" +
      this.id
    );
  }

  getAdminPath() {
    return "/users/" + this.id;
  }

  get searchResultTitle() {
    return this.fullName;
  }

  get relationships() {
    return {
      masterAccounts: Account,
    };
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return this.emailAddress;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  /**** HELPER ADDITIONS ********/

  get fullName() {
    return this.firstName + " " + this.lastName;
  }

  isStaffMember() {
    return this["type"] == "staffMember";
  }

  isClient() {
    return this["type"] == "client";
  }

  isParticipant() {
    return this["type"] == "participant";
  }

  isNationalRegistryClient() {
    return this["type"] == "nationalRegistryClient";
  }

  isAdvisor() {
    return this["type"] == "advisor";
  }

  isAccountHolder() {
    return this["type"] == "accountHolder";
  }

  isMissingAccountCreationData() {
    return (
      this["firstName"] == null ||
      this["lastName"] == null ||
      this["emailAddress"] == null ||
      this["phoneNumber"] == null ||
      this["password"] == null
    );
  }

  hasVerificationCodeData() {
    return this["verificationCode"] != null;
  }

  static fetchAuthenticatedUser(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/authenticated-user";

    const user = new User();
    user.refresh(
      function (response) {
        var appVersion = "unknown";

        if (response && response.data && response.data.status)
          appVersion = response.data.status.appVersion;

        if (response.successful) {
          callback(user, appVersion);

          window.authenticatedUser = user;
          window.amplifyAppVersion = appVersion;
        } else {
          callback(false);
        }
      }.bind(this),
      requestUrl
    );
  }

  static fetchNationalRegistryAuthenticatedUser(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/national-registry/users/authenticated-user";

    const user = new User();
    user.refresh(
      function (response) {
        var appVersion = "unknown";

        if (response && response.data && response.data.status)
          appVersion = response.data.status.appVersion;

        if (response.successful) {
          callback(user, appVersion);

          window.authenticatedUser = user;
          window.natioanlRegistryAppVersion = appVersion;
        } else {
          callback(false);
        }
      }.bind(this),
      requestUrl
    );
  }

  register(captchaToken, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/register";
    //super.create(callback, requestUrl)
    var requestBody = this.getAttributes();

    requestBody["captchaClientResponse"] = captchaToken;

    ApiRequest.send("POST", requestUrl, requestBody).onComplete(
      function (response) {
        if (response.successful) {
          const user = new User(response.result);

          if ($.isFunction(callback)) callback(user, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  static confirmEnabledPhoneNumberSmsCode(
    smsEnabledPhoneNumber,
    smsEnabledPhoneNumberConfirmationCode,
    callback
  ) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/authenticated-user/confirm-sms-verification-code";

    var requestBody = {
      smsEnabledPhoneNumber: smsEnabledPhoneNumber,
      smsEnabledPhoneNumberConfirmationCode:
        smsEnabledPhoneNumberConfirmationCode,
    };

    ApiRequest.send("POST", requestUrl, requestBody).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response, null);
        } else {
          if ($.isFunction(callback))
            callback(null, response.message, response);
        }
      }.bind(this)
    );
  }

  static updateSmsPhoneNumber(requestBody, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/authenticated-user/update-sms-number";

    ApiRequest.send("POST", requestUrl, requestBody).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  static registerSmsPhoneNumber(smsEnabledPhoneNumber, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/authenticated-user/register-sms-number";
    var requestBody = {
      smsEnabledPhoneNumber: smsEnabledPhoneNumber,
    };

    ApiRequest.send("POST", requestUrl, requestBody).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  static resendSmsVerificationCode(smsEnabledPhoneNumber, method, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/authenticated-user/resend-sms-verification-code";
    var requestBody = {
      smsEnabledPhoneNumber: smsEnabledPhoneNumber,
      method: method,
    };

    ApiRequest.send("POST", requestUrl, requestBody).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }
  static resendEmailTwoFactorCode(userName, method, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/resend-email-two-factor-code";
    var requestBody = {
      userName: userName,
      method: method,
    };

    ApiRequest.send("POST", requestUrl, requestBody).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  static resendSmsTwoFactorCode(userName, method, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/resend-sms-two-factor-code";
    var requestBody = {
      userName: userName,
      method: method,
    };

    ApiRequest.send("POST", requestUrl, requestBody).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  static deferSmsConfiguration(userUuid, deferralCount, optOut, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/" +
      userUuid;
    var requestBody = {
      twoFactorDeferralCount: deferralCount,
      smsTwoFactorDeclined: optOut,
    };

    ApiRequest.send("PATCH", requestUrl, requestBody).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  registerNationalRegistryUser(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/national-registry/users/register";
    var requestBody = this.getAttributes();

    ApiRequest.send("POST", requestUrl, requestBody).onComplete(
      function (response) {
        if (response.successful) {
          const user = new User(response.result);

          if ($.isFunction(callback)) callback(user, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  registerInvitedUser(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/register-invited";
    //super.create(callback, requestUrl)
    var requestBody = this.getAttributes();

    ApiRequest.send("POST", requestUrl, requestBody).onComplete(
      function (response) {
        if (response.successful) {
          const authToken = response.result.authentication.token;

          Vue.$cookies.set(
            "authToken",
            authToken,
            Infinity,
            "/",
            window.amplifyRuntimeConfig.get("VUE_APP_AMPLIFY_COOKIE_DOMAIN")
          );

          const user = new User(response.result.user);

          window.authenticatedUser = user;

          if ($.isFunction(callback)) callback(user, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  deleteAccountInvitation(userUuid, accountUuid, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/" +
      userUuid +
      "/delete-account-invitation/" +
      accountUuid;

    ApiRequest.send("POST", requestUrl, null).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  static getDefaultTwoFactorInfo(username, password, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/default-two-factor-info";

    var requestBody = {
      username: username,
      password: password,
      step: "verifyPassword",
    };

    ApiRequest.send("POST", requestUrl, requestBody).onComplete(
      function (response) {
        if (response.successful) {
          if (response.result && response.result.user) {
            if ($.isFunction(callback)) {
              const user = new User(response.result.user);
              callback(user, null);
            }
          }
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  static setDefaultTwoFactor(twoFactorMethod, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/authenticated-user/update-two-factor-default";

    var requestBody = {
      twoFactorDefault: twoFactorMethod,
    };

    ApiRequest.send("PATCH", requestUrl, requestBody).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  static attemptLogin(username, password, callback, token) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/login";

    var requestData = {
      username: username,
      password: password,
      step: "verifyTwoFactorCode",
    };

    if (token) {
      requestData["token"] = token;
    }

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if (
            response.result &&
            response.result.multiFactorAuthenticationCodeSent
          ) {
            if ($.isFunction(callback)) callback(null, null, true);
          } else {
            if (response.result.authentication) {
              const authToken = response.result.authentication.token;

              Vue.$cookies.set(
                "authToken",
                authToken,
                Infinity,
                "/",
                window.amplifyRuntimeConfig.get("VUE_APP_AMPLIFY_COOKIE_DOMAIN")
              );
            }

            const user = new User(response.result.user);
            window.authenticatedUser = user;

            if ($.isFunction(callback))
              callback(
                user,
                null,
                false,
                response.result.authentication != null
              );
          }
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  static attemptNationalRegistryLogin(username, password, callback, token) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/national-registry/users/login";

    var requestData = {
      username: username,
      password: password,
      step: "verifyTwoFactorCode",
    };

    if (token) {
      requestData["token"] = token;
    }

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if (
            response.result &&
            response.result.multiFactorAuthenticationCodeSent
          ) {
            // document.location = window.amplifyRuntimeConfig.get('VUE_APP_CLIENT_BASE_URL')
            if ($.isFunction(callback)) {
              callback(null, null, true);
            }
          } else if (
            response.result &&
            !response.result.user.emailAddressConfirmed &&
            response.result.authentication == null
          ) {
            // first time from old site, reset password and confirm email
            document.location =
              window.amplifyRuntimeConfig.get(
                "VUE_APP_NATIONAL_REGISTRY_BASE_URL"
              ) + "/new-password";
          } else {
            if (response.result.authentication) {
              const authToken = response.result.authentication.token;
              const user = new User(response.result.user);
              window.authenticatedUser = user;

              if (response.result.user.type == "nationalRegistryClient") {
                Vue.$cookies.set(
                  "authToken",
                  authToken,
                  Infinity,
                  "/",
                  window.amplifyRuntimeConfig.get("VUE_APP_NRURB_COOKIE_DOMAIN")
                );
              } else {
                Vue.$cookies.set(
                  "authToken",
                  authToken,
                  Infinity,
                  "/",
                  window.amplifyRuntimeConfig.get(
                    "VUE_APP_AMPLIFY_COOKIE_DOMAIN"
                  )
                );
              }

              //first time from old site, reset password and confirm email
              if (
                response.result &&
                !response.result.user.emailAddressConfirmed &&
                response.result.authentication == null
              ) {
                document.location =
                  window.amplifyRuntimeConfig.get(
                    "VUE_APP_NATIONAL_REGISTRY_BASE_URL"
                  ) + "/new-password";
              }

              if ($.isFunction(callback))
                callback(
                  user,
                  null,
                  false,
                  response.result.authentication != null
                );
            }
          }
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  static sendPasswordResetLink(username, captchaToken, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/send-reset-password-token";

    var requestData = {
      userName: username,
      captchaClientResponse: captchaToken,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  static resetPassword(username, token, password, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/reset-password";

    var requestData = {
      userName: username,
      resetPasswordToken: token,
      newPassword: password,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if ($.isFunction(callback)) callback(response);
      }.bind(this)
    );
  }

  static confirmEmailAddressByCode(
    emailAddress,
    emailConfirmationCode,
    callback
  ) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/confirm-email-address-by-code";

    var requestData = {
      emailAddress: emailAddress,
      emailConfirmationCode: emailConfirmationCode,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if (!response.result) {
            if ($.isFunction(callback)) callback(null, "Invalid data", null);
            return;
          }

          if (response.result.authentication) {
            const authToken = response.result.authentication.token;

            Vue.$cookies.set(
              "authToken",
              authToken,
              Infinity,
              "/",
              window.amplifyRuntimeConfig.get("VUE_APP_AMPLIFY_COOKIE_DOMAIN")
            );
          }

          const user = new User(response.result.user);
          window.authenticatedUser = user;

          if ($.isFunction(callback)) callback(user, null);
        } else {
          if ($.isFunction(callback))
            callback(null, response.message, response);
        }
      }.bind(this)
    );
  }

  static confirmNationalRegistryEmailAddressByCode(
    emailAddress,
    emailConfirmationCode,
    callback
  ) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/national-registry/users/confirm-email-address-by-code";

    var requestData = {
      emailAddress: emailAddress,
      emailConfirmationCode: emailConfirmationCode,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if (response.result.authentication) {
            const authToken = response.result.authentication.token;

            Vue.$cookies.set(
              "authToken",
              authToken,
              Infinity,
              "/",
              window.amplifyRuntimeConfig.get("VUE_APP_NRURB_COOKIE_DOMAIN")
            );
          }

          const user = new User(response.result.user);
          window.authenticatedUser = user;

          if ($.isFunction(callback)) callback(user, null);
        } else {
          if ($.isFunction(callback))
            callback(null, response.message, response);
        }
      }.bind(this)
    );
  }

  static confirmEmailAddressByToken(userId, emailConfirmationToken, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/" +
      userId +
      "/confirm-email-address-by-token";

    var requestData = {
      emailConfirmationToken: emailConfirmationToken,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if (response.result.authentication) {
            const authToken = response.result.authentication.token;

            Vue.$cookies.set(
              "authToken",
              authToken,
              Infinity,
              "/",
              window.amplifyRuntimeConfig.get("VUE_APP_AMPLIFY_COOKIE_DOMAIN")
            );
          }

          const user = new User(response.result.user);
          window.authenticatedUser = user;

          if ($.isFunction(callback)) callback(user, null);
        } else {
          if ($.isFunction(callback))
            callback(null, response.message, response);
        }
      }.bind(this)
    );
  }

  static sendChangeEmailAddressToken(newEmailAddress, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/authenticated-user/send-change-email-address-token";

    var requestData = {
      newEmailAddress,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if ($.isFunction(callback)) callback(response);
      }.bind(this)
    );
  }

  static changePassword(currentPassword, newPassword, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/authenticated-user/change-password";

    var requestData = {
      currentPassword,
      newPassword,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null, null);
        } else {
          if ($.isFunction(callback))
            callback(null, response.message, response);
        }
      }.bind(this)
    );
  }

  static checkPassword(password, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/authenticated-user/check-password";

    var requestData = {
      password,
    };

    ApiRequest.send("PATCH", requestUrl, requestData).onComplete(
      function (response) {
        if ($.isFunction(callback)) callback(response);
      }.bind(this)
    );
  }

  static resendConfirmationEmail(emailAddress, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/resend-confirmation-email";

    var requestData = {
      emailAddress: emailAddress,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  static resendVerificationCodeEmail(emailAddress, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/resend-verification-code-email";

    var requestData = {
      emailAddress: emailAddress,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback))
            callback(null, response.message, response);
        }
      }.bind(this)
    );
  }

  static changeEmailAddress(
    newEmailAddress,
    changeEmailAddressToken,
    callback
  ) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/authenticated-user/change-email-address";

    var requestData = {
      newEmailAddress,
      changeEmailAddressToken,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback))
            callback(response.result, response.message, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  static checkForgotEmail(lastName, taxId, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/check-forgot-email";

    var requestData = {
      lastName: lastName,
      taxId: taxId,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  static checkEmailAvailable(emailAddress) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/check-email-available";

    var requestData = {
      emailAddress: emailAddress,
    };

    return new Promise((resolve, reject) => {
      ApiRequest.send("POST", requestUrl, requestData).onComplete(
        function (response) {
          if (response.successful) {
            resolve(response);
          } else {
            reject(response);
          }
        }.bind(this)
      );
    });
  }

  static getCurrentAuthToken() {
    return Vue.$cookies.get("authToken");
  }

  static isCurrentlyAuthenticated() {
    const authToken = User.getCurrentAuthToken();
    return authToken && authToken.length > 5;
  }

  static logoutAuthenticatedUser(callback) {
    Vue.$cookies.remove(
      "authToken",
      "/",
      window.amplifyRuntimeConfig.get("VUE_APP_AMPLIFY_COOKIE_DOMAIN")
    );

    if ($.isFunction(callback)) callback();
  }

  static logoutNationalRegistryAuthenticatedUser(callback) {
    Vue.$cookies.remove(
      "authToken",
      "/",
      window.amplifyRuntimeConfig.get("VUE_APP_NRURB_COOKIE_DOMAIN")
    );

    if ($.isFunction(callback)) callback();
  }

  unlockUser(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      `/public/users/${this.id}/unlock`;

    ApiRequest.send("POST", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  can(permissionCode) {
    if (this.isStaffMember())
      return (
        $.isArray(this.staffMemberPermissions) &&
        this.staffMemberPermissions.includes(permissionCode)
      );

    if (this.isClient() || this.isAdvisor())
      return (
        $.isArray(this.clientPermissions) &&
        this.clientPermissions.includes(permissionCode)
      );

    if (this.isNationalRegistryClient())
      return (
        $.isArray(this.nationalRegistryClientPermissions) &&
        this.nationalRegistryClientPermissions.includes(permissionCode)
      );

    return false;
  }

  cant(permissionCode) {
    return !this.can(permissionCode);
  }

  getNextLevelAccountHolderUser(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/account-holder/" +
      this.id;

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }
}

export default User;
