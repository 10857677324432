<template>
  <!-- App.vue -->

  <div>
    <v-app-bar app class="app-bar">
      <div class="teal"></div>
    </v-app-bar>

    <v-main class="ma-4">
      <v-container fluid>
        <h1>Special Tax Notice</h1>

        <div>
          <p>
            <span
              >If you are here, you have been notified that a plan you are
              associated with is making a distribution on your behalf. This
              distribution may have tax consequences and thus, you are being
              provided the following required Special Tax Notice.</span
            >
          </p>
          <p>
            <span
              >Under section 402(f) of the Internal Revenue Code, plan
              administrators (or their service providers) are required to
              provide participants, who have an eligible rollover distribution,
              with a written explanation of certain options and rules related to
              their distribution. Commonly referred to as the &quot;402(f)
              Notice&quot; or &quot;Special Tax Notice&quot;, this information
              must be provided to participants before making an eligible
              rollover distribution.</span
            >
          </p>
        </div>

        <div class="text-green text-h5 py-3">
          <strong
            >A paper copy of this notice can be mailed to you, free of charge,
            by request.</strong
          >
        </div>

        <div>
          <p>
            To request a free paper copy please contact our customer service
            representatives at <a href="tel:8005413938">800-541-3938</a>.
            <span
              >Or, you may download a printer-friendly version of the Special
              Tax Notice by clicking the link below.</span
            >
          </p>
        </div>

        <div class="text-center">
          <v-btn
            elevation="2"
            href="/special_tax_notice_no_logo.pdf"
            target="_blank"
            color="primary"
            outlined
          >
            <v-icon>download</v-icon>DOWNLOAD THE NOTICE</v-btn
          >
        </div>

        <div class="py-4">
          <v-divider></v-divider>
        </div>

        <div class="text-green text-h5 py-3">
          <strong
            >For your convenience, the full text of the special tax notice is
            provided below.</strong
          >
        </div>

        <div>
          <p><strong>DISTRIBUTION NOTICE</strong></p>
          <p>
            <strong>30 Day Waiver</strong><br />You are entitled to at least 30
            days to consider the decision to elect a distribution of your
            retirement benefit. You also have the right to waive the 30-day
            waiting period and receive your distribution without delay.
          </p>
          <p>
            <strong
              >SPECIAL TAX NOTICE REQUIRED BY &#xA7;402(f) OF THE INTERNAL
              REVENUE CODE</strong
            >
          </p>
          <p>
            <strong>Introduction<br /></strong>This notice is provided to you by
            PenChecks Trust because all or part of the payment that you will
            soon receive from either your former employer&apos;s retirement plan
            (&quot;the Plan&quot;), or your Automatic Rollover or Missing
            Participant IRA, may be eligible for rollover by you to a
            traditional IRA and/or a Roth IRA, or to another eligible retirement
            plan (if that plan accepts rollovers). This notice explains how you
            may continue to defer federal income tax on your retirement savings
            and contains important information you will need to consider before
            you decide how to receive your retirement plan benefits. These rules
            are often complex and may contain conditions and exceptions.
            Accordingly, this notice cannot address all possible scenarios and
            you may need to consult with a professional tax advisor before you
            receive your distribution from the Plan.<strong>&nbsp;</strong>
          </p>
          <p>
            <strong>What is a &quot;rollover&quot;?<br /></strong>A rollover is
            when you transfer your retirement assets from one retirement plan or
            IRA to another retirement plan or IRA. The IRS rules discussed in
            Part A and Part B below determine whether or not you will incur
            taxes on the transfer.
          </p>
          <p>
            <strong>PART A<br /></strong
            ><strong>For Payments Not from a Designated Roth Account</strong>
          </p>
          <p>
            <strong>YOUR ROLLOVER OPTIONS<br /></strong>You are receiving this
            notice because all or a portion of a payment you are receiving from
            either your former employer&apos;s retirement plan (&quot;the
            Plan&quot;), or your Automatic Rollover or Missing Participant IRA
            is eligible to be rolled over to a traditional IRA or an employer
            plan if that plan accepts rollovers. This notice is intended to help
            you decide whether to do such a rollover.
          </p>
          <p>
            This notice describes the rollover rules that apply to payments from
            the Plan that are not from a designated Roth account (a type of
            account with special tax rules in some employer plans). If you also
            receive a payment from a designated Roth account in the Plan, you
            will be provided a different notice for that payment, and the Plan
            administrator or the payor will tell you the amount that is being
            paid from each account.
          </p>
          <p>
            Rules that apply to most payments from a plan are described in the
            &quot;General Information About Rollovers&quot; section. Special
            rules that only apply in certain circumstances are described in the
            &quot;Special Rules and Options&quot; section.
          </p>
          <p><strong>GENERAL INFORMATION ABOUT ROLLOVERS</strong></p>
          <p>
            <strong>How can a rollover affect my taxes?<br /></strong>You will
            be taxed on a payment from the Plan if you do not roll it over. If
            you are under age 59 1/2 and do not do a rollover, you will also
            have to pay a 10% additional income tax on early distributions
            (generally, distributions made before age 59 1/2), unless an
            exception applies. However, if you do a rollover, you will not have
            to pay tax until you receive payments later and the 10% additional
            income tax will not apply if those payments are made after you are
            age 59 1/2 (or if an exception applies).
          </p>
          <p>
            <strong
              >What types of retirement accounts and plans may accept my
              rollover?<br /></strong
            >You may roll over the payment to either an IRA (an individual
            retirement account or individual retirement annuity) or an employer
            plan (a tax-qualified plan, section 403(b) plan, or governmental
            section 457(b) plan) that will accept the rollover. The rules of the
            IRA or employer plan that holds the rollover will determine your
            investment options, fees, and rights to payment from the IRA or
            employer plan (for example, no spousal consent rules apply to IRAs
            and IRAs may not provide loans). Further, the amount rolled over
            will become subject to the tax rules that apply to the IRA or
            employer plan.
          </p>
          <p>
            <strong>How do I do a rollover?<br /></strong>There are two ways to
            do a rollover. You can do either a direct rollover or a 60-day
            rollover.
          </p>
          <p>
            <u>If you do a direct rollover</u>, the Plan will make the payment
            directly to your IRA or an employer plan. You should contact the IRA
            sponsor or the administrator of the employer plan for information on
            how to do a direct rollover.
          </p>
          <p>
            <u>If you do not do a direct rollover</u>, you may still do a
            rollover by making a deposit into an IRA or eligible employer plan
            that will accept it. Generally, you will have 60 days after you
            receive the payment to make the deposit. If you do not do a direct
            rollover, the Plan is required to withhold 20% of the payment for
            federal income taxes (up to the amount of cash and property received
            other than employer stock). This means that, in order to roll over
            the entire payment in a 60- day rollover, you must use other funds
            to make up for the 20% withheld. If you do not roll over the entire
            amount of the payment, the portion not rolled over will be taxed and
            will be subject to the 10% additional income tax on early
            distributions if you are under age 59 1/2 (unless an exception
            applies).
          </p>
          <p>
            <strong>How much may I roll over?<br /></strong>If you wish to do a
            rollover, you may roll over all or part of the amount eligible for
            rollover. Any payment from the Plan is eligible for rollover,
            except:
          </p>
          <ul>
            <li>
              Certain payments spread over a period of at least 10 years or over
              your life or life expectancy (or the lives or joint life
              expectancy of you and your beneficiary);
            </li>
            <li>
              Required minimum distributions after age 70 1/2 (if you were born
              before July 1, 1949) or age 72 (if you were born after June 30,
              1949) or after death;
            </li>
            <li>Hardship distributions;</li>
            <li>ESOP dividends;</li>
            <li>
              Corrective distributions of contributions that exceed tax law
              limitations;
            </li>
            <li>
              Loans treated as deemed distributions (for example, loans in
              default due to missed payments before your employment ends);
            </li>
            <li>Cost of life insurance paid by the Plan;</li>
            <li>
              Payments of certain automatic enrollment contributions requested
              to be withdrawn within 90 days of the first contribution; and
            </li>
            <li>
              Amounts treated as distributed because of a prohibited allocation
              of S corporation stock under an ESOP (also, there will generally
              be adverse tax consequences if you roll over a distribution of S
              corporation stock to an IRA).
            </li>
          </ul>
          <p>
            The Plan administrator or the payor can tell you what portion of a
            payment is eligible for rollover.
          </p>
          <p>
            <strong
              >If I don&apos;t do a rollover, will I have to pay the 10%
              additional income tax on early distributions?<br /></strong
            >If you are under age 59 1/2, you will have to pay the 10%
            additional income tax on early distributions for any payment from
            the Plan (including amounts withheld for income tax) that you do not
            roll over, unless one of the exceptions listed below applies. This
            tax applies to the part of the distribution that you must include in
            income<br />and is in addition to the regular income tax on the
            payment not rolled over.
          </p>
          <p>
            The 10% additional income tax does not apply to the following
            payments from the Plan:
          </p>
          <ul>
            <li>
              Payments made after you separate from service if you will be at
              least age 55 in the year of the separation;
            </li>
            <li>
              Payments that start after you separate from service if paid at
              least annually in equal or close to equal amounts over your life
              or life expectancy (or the lives or joint life expectancy of you
              and your beneficiary);
            </li>
            <li>
              Payments from a governmental plan made after you separate from
              service if you are a qualified public safety employee and you will
              be at least age 50 in the year of the separation;
            </li>
            <li>Payments made due to disability;</li>
            <li>Payments after your death;</li>
            <li>Payments of ESOP dividends;</li>
            <li>
              Corrective distributions of contributions that exceed tax law
              limitations;
            </li>
            <li>Cost of life insurance paid by the Plan;</li>
            <li>
              Payments made directly to the government to satisfy a federal tax
              levy;
            </li>
            <li>
              Payments made under a qualified domestic relations order (QDRO);
            </li>
            <li>
              Payments up to the amount of your deductible medical expenses
              (without regard to whether you itemize deductions for the taxable
              year);
            </li>
            <li>
              Certain payments made while you are on active duty if you were a
              member of a reserve component called to duty after September 11,
              2001 for more than 179 days;
            </li>
            <li>
              Payments of certain automatic enrollment contributions requested
              to be with drawn within 90 days of the first contribution; and
            </li>
            <li>
              Payments for certain distributions relating to certain federally
              declared disasters.
            </li>
          </ul>
          <p>
            <strong
              >If I do a rollover to a Roth IRA, will the 10% additional income
              tax apply to early distributions from the IRA?<br /></strong
            >If you receive a payment from a Roth IRA when you are under age 59
            1/2, you will have to pay the 10% additional income tax on early
            distributions on the earnings paid from the Roth IRA, unless an
            exception applies or the payment is a qualified distribution. In
            general, the exceptions to the 10% additional income tax for early
            distributions from a Roth IRA listed above are the same as the
            exceptions for early distributions from a plan. However, there are a
            few differences for payments from a Roth IRA, including:
          </p>
          <ul>
            <li>
              The exception for payments made after you separate from service if
              you will be at least age 55 in the year of the separation (or age
              50 for qualified public safety employees) does not apply.
            </li>
            <li>
              The exception for qualified domestic relations orders (QDROs) does
              not apply (although a special rule applies under which, as part of
              a divorce or separation agreement, a tax-free transfer may be made
              directly to a Roth IRA of a spouse or former spouse).
            </li>
            <li>
              The exception for payments made at least annually in equal or
              close to equal amounts over a specified period applies without
              regard to whether you have had a separation from service.
            </li>
            <li>
              There are additional exceptions for (1) payments for qualified
              higher education expenses, (2) payments up to $10,000 used in a
              qualified first-time home purchase, and (3) payments for health
              insurance premiums after you have received unemployment
              compensation for 12 consecutive weeks (or would have been eligible
              to receive unemployment compensation but for self-employed
              status).
            </li>
          </ul>
          <p>
            <strong>Will I owe State income taxes?<br /></strong>This notice
            does not describe any State or local income tax rules (including
            withholding rules).
          </p>
          <p><strong>SPECIAL RULES AND OPTIONS</strong></p>
          <p>
            <strong>&nbsp;</strong
            ><strong
              >If your payment includes after-tax contributions<br /></strong
            >After-tax contributions included in a payment are not taxed. If a
            payment is only part of your benefit, an allocable portion of your
            after-tax contributions is included in the payment, so you cannot
            take a payment of only after-tax contributions. However, if you have
            pre-1987 after-tax contributions maintained in a separate account, a
            special rule may apply to determine whether the after-tax
            contributions are included in a payment. In addition, special rules
            apply when you do a rollover, as described below.
          </p>
          <p>
            You may roll over to an IRA a payment that includes after-tax
            contributions through either a direct rollover or a 60-day rollover.
            You must keep track of the aggregate amount of the after-tax
            contributions in all of your IRAs (in order to determine your
            taxable income for later payments from the IRAs). If you do a direct
            rollover of only a portion of the amount paid from the Plan and at
            the same time the rest is paid to you, the portion directly rolled
            over consists first of the amount that would be taxable if not
            rolled over. For example, assume you are receiving a distribution of
            $12,000, of which $2,000 is after-tax contributions. In this case,
            if you directly roll over $10,000 to an IRA that is not a Roth IRA,
            no amount is taxable because the $2,000 amount not directly rolled
            over is treated as being after-tax contributions. If you do a direct
            rollover of the entire amount paid from the Plan to two or more
            destinations at the same time, you can choose which destination
            receives the after-tax contributions.
          </p>
          <p>
            If you do a 60-day rollover to an IRA of only a portion of a payment
            made to you, the after-tax contributions are treated as rolled over
            last. For example, assume you are receiving a distribution of
            $12,000, of which $2,000 is after-tax contributions, and no part of
            the distribution is directly rolled over. In this case, if you roll
            over $10,000 to an IRA that is not a Roth IRA in a 60-day rollover,
            no amount is taxable because the $2,000 amount not rolled over is
            treated as being after-tax contributions.
          </p>
          <p>
            You may roll over to an employer plan all of a payment that includes
            after-tax contributions, but only through a direct rollover (and
            only if the receiving plan separately accounts for after-tax
            contributions and is not a governmental section 457(b) plan). You
            can do a 60-day rollover to an employer plan of part of a payment
            that includes after-tax contributions, but only up to the amount of
            the payment that would be taxable if not rolled over.
          </p>
          <p>
            <strong>If you miss the 60-day rollover deadline<br /></strong
            >Generally, the 60-day rollover deadline cannot be extended.
            However, the IRS has the limited authority to waive the deadline
            under certain extraordinary circumstances, such as when external
            events prevented you from completing the rollover by the 60-day
            rollover deadline. Under certain circumstances, you may claim
            eligibility for a waiver of the 60-day rollover deadline by making a
            written self-certification. Otherwise, to apply for a waiver from
            the IRS, you must file a private letter ruling request with the IRS.
            Private letter ruling requests require the payment of a
            nonrefundable user fee. For more information, see IRS Publication
            590-A,
            <em>Contributions to Individual Retirement Arrangements (IRAs)</em>.
          </p>
          <p>
            <strong
              >If your payment includes employer stock that you do not roll
              over<br /></strong
            >If you do not do a rollover, you can apply a special rule to
            payments of employer stock (or other employer securities) that are
            either attributable to after-tax contributions or paid in a lump sum
            after separation from service (or after age 59 1/2, disability, or
            the participant&apos;s death). Under the special rule, the net
            unrealized appreciation on the stock will not be taxed when
            distributed from the
          </p>
          <p>
            Plan and will be taxed at capital gain rates when you sell the
            stock. Net unrealized appreciation is generally the increase in the
            value of employer stock after it was acquired by the Plan. If you do
            a rollover for a payment that includes employer stock (for example,
            by selling the stock and rolling over the proceeds within 60 days of
            the payment), the special rule relating to the distributed employer
            stock will not apply to any subsequent payments from the IRA or
            employer plan. The Plan administrator can tell you the amount of any
            net unrealized appreciation.
          </p>
          <p>
            <strong
              >If you have an outstanding loan that is being offset<br /></strong
            >If you have an outstanding loan from the Plan, your Plan benefit
            may be offset by the outstanding amount of the loan, typically when
            your employment ends. The offset amount is treated as a distribution
            to you at the time of the offset. Generally, you may roll over all
            or any portion of the offset amount. Any offset amount that is not
            rolled over will be taxed (including the 10% additional income tax
            on early distributions, unless an exception applies). You may roll
            over offset amounts to an IRA or an employer plan (if the terms of
            the employer plan permit the plan to receive plan loan offset
            rollovers).
          </p>
          <p>
            How long you have to complete the rollover depends on what kind of
            plan loan offset you have. If you have a qualified plan loan offset,
            you will have until your tax return due date (including extensions)
            for the tax year during which the offset occurs to complete your
            rollover. A qualified plan loan offset occurs when a plan loan in
            good standing is offset because your employer plan terminates, or
            because you sever from employment. If your plan loan offset occurs
            for any other reason, then you have 60 days from the date the offset
            occurs to complete your rollover.
          </p>
          <p>
            <strong>If you were born on or before January 1, 1936<br /></strong
            >If you were born on or before January 1, 1936 and receive a lump
            sum distribution that you do not roll over, special rules for
            calculating the amount of the tax on the payment might apply to you.
            For more information, see IRS Publication 575,
            <em>Pension and Annuity Income</em>.
          </p>
          <p>
            <strong
              >If your payment is from a governmental section 457(b) plan<br /></strong
            >If the Plan is a governmental section 457(b) plan, the same rules
            described elsewhere in this notice generally apply, allowing you to
            roll over the payment to an IRA or an employer plan that accepts
            rollovers. One difference is that, if you do not do a rollover, you
            will not have to pay the 10% additional income tax on early
            distributions from the Plan even if you are under age 59 1/2 (unless
            the payment is from a separate account holding rollover
            contributions that were made to the Plan from a tax-qualified plan,
            a section 403(b) plan, or an IRA). However, if you do a rollover to
            an IRA or to an employer plan that is not a governmental section
            457(b)plan, a later distribution made before age 59 1/2 will be
            subject to the 10% additional income tax on early distributions
            (unless an exception applies). Other differences include that you
            cannot do a rollover if the payment is due to an &quot;unforeseeable
            emergency&quot; and the special rules under &quot;If your payment
            includes employer stock that you do not roll over&quot; and &quot;If
            you were born on or before January 1, 1936&quot; do not apply.
          </p>
          <p>
            <strong
              >If you are an eligible retired public safety officer and your
              payment is used to pay for health coverage or qualified long-term
              care insurance<br /></strong
            >If the Plan is a governmental plan, you retired as a public safety
            officer, and your retirement was by reason of disability or was
            after normal retirement age, you can exclude from your taxable
            income Plan payments paid directly as premiums to an accident or
            health plan (or a qualified long-term care insurance contract) that
            your employer maintains for you, your spouse, or your dependents, up
            to a maximum of $3,000 annually. For this purpose, a public safety
            officer is a law enforcement officer, firefighter, chaplain, or
            member of a rescue squad or ambulance crew.
          </p>
          <p>
            <strong>If you roll over your payment to a Roth IRA<br /></strong>If
            you roll over a payment from the Plan to a Roth IRA, a special rule
            applies under which the amount of the payment rolled over (reduced
            by any after-tax amounts) will be taxed. However, the 10% additional
            income tax on early distributions will not apply (unless you take
            the amount rolled over out of the Roth IRA within 5 years, counting
            from January 1 of the year of the rollover).
          </p>
          <p>
            If you roll over the payment to a Roth IRA, later payments from the
            Roth IRA that are qualified distributions will not be taxed
            (including earnings after the rollover). A qualified distribution
            from a Roth IRA is a payment made after you are age 59 1/2 (or after
            your death or disability, or as a qualified first-time homebuyer
            distribution of up to $10,000) and after you have had a Roth IRA for
            at least 5 years. In applying this 5-year rule, you count from
            January 1 of the year for which your first contribution was made to
            a Roth IRA. Payments from the Roth IRA that are not qualified
            distributions will be taxed to the extent of earnings after the
            rollover, including the 10% additional income tax on early
            distributions (unless an exception applies). You do not have to take
            required minimum distributions from a Roth IRA during your lifetime.
            For more information, see IRS Publication 590-A,
            <em
              >Contributions to Individual Retirement Arrangements (IRAs), and
              IRS Publication 590-B, Distributions from Individual Retirement
              Arrangements (IRAs).</em
            >
          </p>
          <p>
            <strong
              >If you do a rollover to a designated Roth account in the Plan<br /></strong
            >You cannot roll over a distribution to a designated Roth account in
            another employer&apos;s plan. However, you can roll the distribution
            over into a designated Roth account in the distributing Plan. If you
            roll over a payment from the Plan to a designated Roth account in
            the Plan, the amount of the payment rolled over (reduced by any
            after-tax amounts directly rolled over) will be taxed. However, the
            10% additional tax on early distributions will not apply (unless you
            take the amount rolled over out of the designated Roth account
            within the 5-year period that begins on January 1 of the year of the
            rollover).
          </p>
          <p>
            If you roll over the payment to a designated Roth account in the
            Plan, later payments from the designated Roth account that are
            qualified distributions will not be taxed (including earnings after
            the rollover). A qualified distribution from a designated Roth
            account is a payment made both after you are age 59 1/2 (or after
            your death or disability) and after you have had a designated Roth
            account in the Plan for at least 5 years. In applying this 5-year
            rule, you count from January 1 of the year your first contribution
            was made to the designated Roth account. However, if you made a
            direct rollover to a designated Roth account in the Plan from a
            designated Roth account in a plan of another employer, the 5-year
            period begins on January 1 of the year you made the first
            contribution to the designated Roth account in the Plan or, if
            earlier, to the designated Roth account in the plan of the other
            employer. Payments from the designated Roth account that are not
            qualified distributions will be taxed to the extent of earnings
            after the rollover, including the 10% additional income tax on early
            distributions (unless an exception applies).
          </p>
          <p>
            <strong>If you are not a Plan participant<br /></strong
            ><u>Payments after death of the participant</u>. If you receive a
            distribution after the participant&apos;s death that you do not roll
            over, the distribution will generally be taxed in the same manner
            described elsewhere in this notice. However, the 10% additional
            income tax on early distributions and the special rules for public
            safety officers do not apply, and the special rule described under
            the section &quot;If you were born on or before January 1,
            1936&quot; applies only if the participant was born on or before
            January 1, 1936.
          </p>
          <p style="padding-left: 40px">
            <strong>If you are a surviving spouse.</strong> If you receive a
            payment from the Plan as the surviving spouse of a deceased
            participant, you have the same rollover options that the participant
            would have had, as described elsewhere in this notice. In addition,
            if you choose to do a rollover to an IRA, you may treat the IRA as
            your own or as an inherited IRA.
          </p>
          <p style="padding-left: 40px">
            An IRA you treat as your own is treated like any other IRA of yours,
            so that payments made to you before you are age 59 1/2 will be
            subject to the 10% additional income tax on early distributions
            (unless an exception applies) and required minimum distributions
            from your IRA do not have to start until after you are age 70 1/2
            (if you were born before July 1, 1949) or age 72 (if you were born
            after June 30, 1949).
          </p>
          <p style="padding-left: 40px">
            If you treat the IRA as an inherited IRA, payments from the IRA will
            not be subject to the 10% additional income tax on early
            distributions. However, if the participant had started taking
            required minimum distributions, you will have to receive required
            minimum distributions from the inherited IRA. If the participant had
            not started taking required minimum distributions from the Plan, you
            will not have to start receiving required minimum distributions from
            the inherited IRA until the year the participant would have been age
            70 1/2 (if you were born before July 1, 1949) or age 72 (if you were
            born after June 30, 1949).
          </p>
          <p style="padding-left: 40px">
            <strong
              >If you are a surviving beneficiary other than a spouse.</strong
            >
            If you receive a payment from the Plan because of the
            participant&apos;s death and you are a designated beneficiary other
            than a surviving spouse, the only rollover option you have is to do
            a direct rollover to an inherited IRA. Payments from the inherited
            IRA will not be subject to the 10% additional income tax on early
            distributions. You will have to receive required minimum
            distributions from the inherited IRA.
          </p>
          <p>
            <u>Payments under a qualified domestic relations order</u>. If you
            are the spouse or former spouse of the participant who receives a
            payment from the Plan under a qualified domestic relations order
            (QDRO), you generally have the same options and the same tax
            treatment that the participant would have (for example, you may roll
            over the payment to your own IRA or an eligible employer plan that
            will accept it). However, payments under the QDRO will not be
            subject to the 10% additional income tax on early distributions.
          </p>
          <p>
            <strong>If you are a nonresident alien<br /></strong>If you are a
            nonresident alien and you do not do a direct rollover to a U.S. IRA
            or U.S. employer plan, instead of withholding 20%, the Plan is
            generally required to withhold 30% of the payment for federal income
            taxes. If the amount withheld exceeds the amount of tax you owe (as
            may happen if you do a 60-day rollover), you may request an income
            tax refund by filing Form 1040NR and attaching your Form 1042-S. See
            Form W-8BEN for claiming that you are entitled to a reduced rate of
            withholding under an income tax treaty. For more information, see
            also IRS Publication 519, U.S. Tax Guide for Aliens, and IRS
            Publication 515, Withholding of Tax on Nonresident Aliens and
            Foreign Entities.
          </p>
          <p>
            <strong>Other special rules<br /></strong>If a payment is one in a
            series of payments for less than 10 years, your choice whether to
            make a direct rollover will apply to all later payments in the
            series (unless you make a different choice for later payments).
          </p>
          <p>
            If your payments for the year are less than $200 (not including
            payments from a designated Roth account in the Plan), the Plan is
            not required to allow you to do a direct rollover and is not
            required to withhold federal income taxes. However, you may do a
            60-day rollover.
          </p>
          <p>
            Unless you elect otherwise, a mandatory cashout of more than $1,000
            (not including payments from a designated Roth account in the Plan)
            will be directly rolled over to an IRA chosen by the Plan
            administrator or the payor. A mandatory cashout is a payment from a
            plan to a participant made before age 62 (or normal retirement age,
            if later) and without consent, where the participant&apos;s benefit
            does not exceed $7,000 (not including any amounts held under the
            plan as a result of a prior rollover made to the plan).
          </p>
          <p>
            You may have special rollover rights if you recently served in the
            U.S. Armed Forces. For more information on special rollover rights
            related to the U.S. Armed Forces, see IRS Publication 3, Armed
            Forces&apos; Tax Guide. You also may have special rollover rights if
            you were affected by a federally declared disaster (or similar
            event), or if you received a distribution on account of a disaster.
            For more information on special rollover rights related to disaster
            relief, see the IRS website at
            <a href="https://www.irs.gov/" target="_blank" rel="noopener"
              ><strong>www.irs.gov</strong></a
            >.
          </p>
          <p>
            <strong>FOR MORE INFORMATION<br /></strong>You may wish to consult
            with the Plan administrator or payor, or a professional tax advisor,
            before taking a payment from the Plan. Also, you can find more
            detailed information on the federal tax treatment of payments from
            employer plans in: IRS Publication 575, Pension and Annuity Income;
            IRS Publication 590-A, Contributions to Individual Retirement
            Arrangements (IRAs); IRS Publication 590-B, Distributions from
            Individual Retirement Arrangements (IRAs); and IRS Publication 571,
            Tax- Sheltered Annuity Plans (403(b) Plans). These publications are
            available from a local IRS office, on the web at
            <a href="https://www.irs.gov/" target="_blank" rel="noopener"
              ><strong>www.irs.gov</strong></a
            >, or by calling 1-800-TAX-FORM.
          </p>
          <p>
            <strong>PART B<br /></strong
            ><strong>For Payments from a Designated Roth Account</strong>
          </p>
          <p><strong>YOUR ROLLOVER OPTIONS</strong></p>
          <p>
            You are receiving this notice because all or a portion of a payment
            you are receiving from either your former employer&apos;s retirement
            plan (&quot;the Plan&quot;), or your Automatic Rollover or Missing
            Participant IRA is eligible to be rolled over to a Roth IRA or
            designated Roth account in an employer plan. This notice is intended
            to help you decide whether to do a rollover.
          </p>
          <p>
            <u
              >This notice describes the rollover rules that apply to payments
              from the Plan that are from a designated Roth account</u
            >. If you also receive a payment from the Plan that is not from a
            designated Roth account, you will be provided a different notice for
            that payment, and the Plan administrator or the payor will tell you
            the amount that is being paid from each account.
          </p>
          <p>
            Rules that apply to most payments from a designated Roth account are
            described in the &quot;General Information About Rollovers&quot;
            section. Special rules that only apply in certain circumstances are
            described in the &quot;Special Rules and Options&quot; section.
          </p>
          <p><strong>GENERAL INFORMATION ABOUT ROLLOVERS</strong></p>
          <p>
            <strong>How can a rollover affect my taxes?<br /></strong>After-tax
            contributions included in a payment from a designated Roth account
            are not taxed, but earnings might be taxed. The tax treatment of
            earnings included in the payment depends on whether the payment is a
            qualified distribution. If a payment is only part of your designated
            Roth account, the payment will include an allocable portion of the
            earnings in your designated Roth account.
          </p>
          <p>
            If the payment from the Plan is not a qualified distribution and you
            do not do a rollover to a Roth IRA or a designated Roth account in
            an employer plan, you will be taxed on the earnings in the payment.
            If you are under age 59 1/2, a 10% additional income tax on early
            distributions (generally, distributions made before age 59 1/2) will
            also apply to the earnings (unless an exception applies). However,
            if you do a rollover, you will not have to pay taxes currently on
            the earnings and you will not have to pay taxes later on payments
            that are qualified distributions.
          </p>
          <p>
            If the payment from the Plan is a qualified distribution, you will
            not be taxed on any part of the payment even if you do not do a
            rollover. If you do a rollover, you will not be taxed on the amount
            you roll over and any earnings on the amount you roll over will not
            be taxed if paid later in a qualified distribution.
          </p>
          <p>
            A qualified distribution from a designated Roth account in the Plan
            is a payment made after you are age 59 1/2 (or after your death or
            disability) and after you have had a designated Roth account in the
            Plan for at least 5 years. In applying the 5-year rule, you count
            from January 1 of the year your first contribution was made to the
            designated Roth account. However, if you did a direct rollover to a
            designated Roth account in the Plan from a designated Roth account
            in another employer plan, your participation will count from January
            1 of the year your first contribution was made to the designated
            Roth account in the Plan or, if earlier, to the designated Roth
            account in the other employer plan.
          </p>
          <p>
            <strong
              >What types of retirement accounts and plans may accept my
              rollover?<br /></strong
            >You may roll over the payment to either a Roth IRA (a Roth
            individual retirement account or Roth individual retirement annuity)
            or a designated Roth account in an employer plan (a tax-qualified
            plan, section 403(b) plan, or governmental section 457 plan) that
            will accept the rollover. The rules of the Roth IRA or employer plan
            that holds the rollover will determine your investment options,
            fees, and rights to payment from the Roth IRA or employer plan (for
            example, no spousal consent rules apply to Roth IRAs and Roth IRAs
            may not provide loans). Further, the amount rolled over will become
            subject to the tax rules that apply to the Roth IRA or the
            designated Roth account in the employer plan. In general, these tax
            rules are similar to those described elsewhere in this notice, but
            differences include:
          </p>
          <ul>
            <li>
              If you do a rollover to a Roth IRA, all of your Roth IRAs will be
              considered for purposes of determining whether you have satisfied
              the 5-year rule (counting from January 1 of the year for which
              your first contribution was made to any of your Roth IRAs).
            </li>
            <li>
              If you do a rollover to a Roth IRA, you will not be required to
              take a distribution
            </li>
            <li>
              from the Roth IRA during your lifetime and you must keep track of
              the aggregate amount of the after-tax contributions in all of your
              Roth IRAs (in order to determine your taxable income for later
              Roth IRA payments that are not qualified distributions).
            </li>
            <li>
              Eligible rollover distributions from a Roth IRA can only be rolled
              over to another Roth IRA.
            </li>
          </ul>
          <p>
            <strong>How do I do a rollover?<br /></strong>There are two ways to
            do a rollover. You can either do a direct rollover or a 60- day
            rollover.
          </p>
          <p>
            <u>If you do a direct rollover</u>, the Plan will make the payment
            directly to your Roth IRA or designated Roth account in an employer
            plan. You should contact the Roth IRA sponsor or the administrator
            of the employer plan for information on how to do a direct rollover.
          </p>
          <p>
            <u>If you do not do a direct rollover</u>, you may still do a
            rollover by making a deposit (generally within 60 days) into a Roth
            IRA, whether the payment is a qualified or nonqualified
            distribution. In addition, you can do a rollover by making a deposit
            within 60 days into a designated Roth account in an employer plan if
            the payment is a nonqualified distribution and the rollover does not
            exceed the amount of the earnings in the payment. You cannot do a
            60-day rollover to an employer plan of any part of a qualified
            distribution. If you receive a distribution that is a nonqualified
            distribution and you do not roll over an amount at least equal to
            the earnings allocable to the distribution, you will be taxed on the
            amount of those earnings not rolled over, including the 10%
            additional income tax on early distributions if you are under age 59
            1/2 (unless an exception applies). If you do a direct rollover of
            only a portion of the amount paid from the Plan and a portion is
            paid to you at the same time, the portion directly rolled over
            consists first of earnings.
          </p>
          <p>
            If you do not do a direct rollover and the payment is not a
            qualified distribution, the Plan is required to withhold 20% of the
            earnings for federal income taxes (up to the amount of cash and
            property received other than employer stock). This means that, in
            order to roll over the entire payment in a 60-day rollover to a Roth
            IRA, you must use other funds to make up for the 20% withheld.
          </p>
          <p>
            <strong>How much may I roll over?<br /></strong>If you wish to do a
            rollover, you may roll over all or part of the amount eligible for
            rollover. Any payment from the Plan is eligible for rollover,
            except:
          </p>
          <ul>
            <li>
              Certain payments spread over a period of at least 10 years or over
              your life or life expectancy (or the lives or joint life
              expectancy of you and your beneficiary);
            </li>
            <li>
              Required minimum distributions after age 70 1/2 (if you were born
              before July 1, 1949) or age 72 (if you were born after June 30,
              1949) or after death
            </li>
            <li>Hardship distributions;</li>
            <li>ESOP dividends;</li>
            <li>
              Corrective distributions of contributions that exceed tax law
              limitations;
            </li>
            <li>
              Loans treated as deemed distributions (for example, loans in
              default due to missed payments before your employment ends);
            </li>
            <li>Cost of life insurance paid by the Plan;</li>
            <li>
              Payments of certain automatic enrollment contributions requested
              to be withdrawn within 90 days of the first contribution; and
            </li>
            <li>
              Amounts treated as distributed because of a prohibited allocation
              of S corporation stock under an ESOP (also, there will generally
              be adverse tax consequences if S corporation stock is held by an
              IRA).
            </li>
          </ul>
          <p>
            The Plan administrator or the payor can tell you what portion of a
            payment is eligible for rollover.
          </p>
          <p>
            <strong
              >If I don&apos;t do a rollover, will I have to pay the 10%
              additional income tax on early distributions?<br /></strong
            >If a payment is not a qualified distribution and you are under age
            59 1/2, you will have to pay the 10% additional income tax on early
            distributions with respect to the earnings allocated to the payment
            that you do not roll over (including amounts withheld for income
            tax), unless one of the exceptions listed below applies. This tax is
            in addition to the regular income tax on the earnings not rolled
            over.
          </p>
          <p>
            The 10% additional income tax does not apply to the following
            payments from the Plan:
          </p>
          <ul>
            <li>
              Payments made after you separate from service if you will be at
              least age 55 in the year of the separation;
            </li>
            <li>
              Payments that start after you separate from service if paid at
              least annually in equal or close to equal amounts over your life
              or life expectancy (or the lives or joint life expectancy of you
              and your beneficiary);
            </li>
            <li>
              Payments from a governmental plan made after you separate from
              service if you are a qualified public safety employee and you will
              be at least age 50 in the year of the separation;
            </li>
            <li>Payments made due to disability;</li>
            <li>Payments after your death;</li>
            <li>Payments of ESOP dividends;</li>
            <li>
              Corrective distributions of contributions that exceed tax law
              limitations;
            </li>
            <li>Cost of life insurance paid by the Plan;</li>
            <li>
              Payments made directly to the government to satisfy a federal tax
              levy;
            </li>
            <li>
              Payments made under a qualified domestic relations order (QDRO);
            </li>
            <li>
              Payments up to the amount of your deductible medical expenses
              (without regard to whether you itemize deductions for the taxable
              year);
            </li>
            <li>
              Certain payments made while you are on active duty if you were a
              member of a reserve component called to duty after September 11,
              2001 for more than 179 days;
            </li>
            <li>
              Payments of certain automatic enrollment contributions requested
              to be withdrawn within 90 days of the first contribution; and
            </li>
            <li>
              Payments for certain distributions relating to certain federally
              declared disasters.
            </li>
          </ul>
          <p>
            <strong
              >If I do a rollover to a Roth IRA, will the 10% additional income
              tax apply to early distributions from the IRA?<br /></strong
            >If you receive a payment from a Roth IRA when you are under age 59
            1/2, you will have to pay the 10% additional income tax on early
            distributions on the earnings paid from the Roth IRA, unless an
            exception applies or the payment is a qualified distribution. In
            general, the exceptions to the 10% additional income tax for early
            distributions from a Roth IRA listed above are the same as the
            exceptions for early distributions from a plan. However, there are a
            few differences for payments from a Roth IRA, including:
          </p>
          <ul>
            <li>
              The exception for payments made after you separate from service if
              you will be at least age 55 in the year of the separation (or age
              50 for qualified public safety employees) does not apply.
            </li>
            <li>
              The exception for qualified domestic relations orders (QDROs) does
              not apply (although a special rule applies under which, as part of
              a divorce or separation agreement, a tax-free transfer may be made
              directly to a Roth IRA of a spouse or former spouse).
            </li>
            <li>
              The exception for payments made at least annually in equal or
              close to equal amounts over a specified period applies without
              regard to whether you have had a separation from service.
            </li>
            <li>
              There are additional exceptions for (1) payments for qualified
              higher education expenses, (2) payments up to $10,000 used in a
              qualified first-time home purchase, and (3) payments for health
              insurance premiums after you have received unemployment
              compensation for 12 consecutive weeks (or would
            </li>
            <li>
              have been eligible to receive unemployment compensation but for
              self-employed status).
            </li>
          </ul>
          <p>
            <strong>Will I owe State income taxes?<br /></strong>This notice
            does not describe any State or local income tax rules (including
            withholding rules).
          </p>
          <p>
            <strong>SPECIAL RULES AND OPTIONS</strong><strong>&nbsp;</strong>
          </p>
          <p>
            <strong>If you miss the 60-day rollover deadline<br /></strong
            >Generally, the 60-day rollover deadline cannot be extended.
            However, the IRS has the limited authority to waive the deadline
            under certain extraordinary circumstances, such as when external
            events prevented you from completing the rollover by the 60- day
            rollover deadline. Under certain circumstances, you may claim
            eligibility for a waiver of the 60-day rollover deadline by making a
            written self-certification. Otherwise, to apply for a waiver from
            the IRS, you must file a private letter ruling request with the IRS.
            Private letter ruling requests require the payment of a
            nonrefundable user fee. For more information, see IRS Publication
            590-A, Contributions to Individual Retirement Arrangements (IRAs).
          </p>
          <p>
            <strong
              >If your payment includes employer stock that you do not roll
              over</strong
            >
            If you receive a payment that is not a qualified distribution and
            you do not roll it over, you can apply a special rule to payments of
            employer stock (or other employer securities) that are paid in a
            lump sum after separation from service (or after age 59 1/2,
            disability, or the participant&apos;s death). Under the special
            rule, the will not be taxed when distributed to you from the Plan
            and will be taxed at capital gain rates when you sell the stock. If
            you do a rollover to a Roth IRA for a nonqualified distribution that
            includes employer stock (for example, by selling the stock and
            rolling over the proceeds within 60 days of the distribution), you
            will not have any taxable income and the special rule relating to
            the distributed employer stock will not apply to any subsequent
            payments from the Roth IRA or employer plan. Net unrealized
            appreciation is generally the increase in the value of the employer
            stock after it was acquired by the Plan. The Plan administrator can
            tell you the amount of any net unrealized appreciation.
          </p>
          <p>
            If you receive a payment that is a qualified distribution that
            includes employer stock and you do not roll it over, your basis in
            the stock (used to determine gain or loss when you later sell the
            stock) will equal the fair market value of the stock at the time of
            the payment from the Plan.
          </p>
          <p>
            <strong
              >If you have an outstanding loan that is being offset<br /></strong
            >If you have an outstanding loan from the Plan, your Plan benefit
            may be offset by the outstanding amount of the loan, typically when
            your employment ends. The offset amount is treated as a distribution
            to you at the time of the offset. Generally, you may roll over all
            or any portion of the offset amount. If the distribution
            attributable to the offset is not a qualified distribution and you
            do not roll over the offset amount, you will be taxed on any
            earnings included in the distribution (including the 10% additional
            income tax on early distributions, unless an exception applies). You
            may roll over the earnings included in the loan offset to a Roth IRA
            or designated Roth account in an employer plan (if the terms of the
            employer plan permit the plan to receive plan loan offset
            rollovers). You may also roll over the full amount of the offset to
            a Roth IRA. How long you have to complete the rollover depends on
            what kind of plan loan offset you have. If you have a qualified plan
            loan offset, you will have until your tax return due date (including
            extensions) for the tax year during which the offset occurs to
            complete your rollover. A qualified plan loan offset occurs when a
            plan loan in good standing is offset because your employer plan
            terminates, or because you sever from employment. If your plan loan
            offset occurs for any other reason, then you have 60 days from the
            date the offset occurs to complete your rollover.<strong
              >&nbsp;</strong
            >
          </p>
          <p>
            <strong
              >If you receive a nonqualified distribution and you were born on
              or before January 1, 1936<br /></strong
            >If you were born on or before January 1, 1936 and receive a lump
            sum distribution that is not a qualified distribution and that you
            do not roll over, special rules for calculating the amount of the
            tax on the earnings in the payment might apply to you. For more
            information, see IRS Publication 575, Pension and Annuity Income.
          </p>
          <p>
            <strong
              >If your payment is from a governmental section 457(b) plan<br /></strong
            >If the Plan is a governmental section 457(b) plan, the same rules
            described elsewhere in this notice generally apply, allowing you to
            roll over the payment to an IRA or an employer plan that accepts
            rollovers. One difference is that, if you receive a payment that is
            not a qualified distribution and you do not roll it over, you will
            not have to pay the 10% additional income tax on early distributions
            with respect to the earnings allocated to the payment that you do
            not roll over, even if you are under age 59 1/2 (unless the payment
            is from a separate account holding rollover contributions that were
            made to the Plan from a tax-qualified plan, a section 403(b) plan,
            or an IRA). However, if you do a rollover to an IRA or to an
            employer plan that is not a governmental section 457(b) plan, a
            later distribution that is not a qualified distribution made before
            age 59 1/2 will be subject to the 10% additional income tax on
            earnings allocated to the payment (unless an exception applies).
            Other differences include that you cannot do a rollover if the
            payment is due to an &quot;unforeseeable emergency&quot; and the
            special rules under &quot;If your payment includes employer stock
            that you do not roll over&quot; and &quot;If you were born on or
            before January 1, 1936&quot; do not apply.
          </p>
          <p>
            <strong
              >If you receive a nonqualified distribution, are an eligible
              retired public safety officer, and your payment is used to pay for
              health coverage or qualified long-term care insurance<br /></strong
            >If the Plan is a governmental plan, you retired as a public safety
            officer, and your retirement was by reason of disability or was
            after normal retirement age, you can exclude from your taxable
            income nonqualified distributions paid directly as premiums to an
            accident or health plan (or a qualified long-term care insurance
            contract) that your employer maintains for you, your spouse, or your
            dependents, up to a maximum of $3,000 annually. For this purpose, a
            public safety officer is a law enforcement officer, firefighter,
            chaplain, or member of a rescue squad or ambulance crew.
          </p>
          <p>
            <strong>If you are not a Plan participant<br /></strong
            ><u>Payments after death of the participant</u>. If you receive a
            distribution after the participant&apos;s death that you do not roll
            over, the distribution will generally be taxed in the same manner
            described elsewhere in this notice. However, whether the payment is
            a qualified distribution generally depends on when the participant
            first made a contribution to the designated Roth account in the
            Plan. Also, the 10% additional income tax on early distributions and
            the special rules for public safety officers do not apply, and the
            special rule described under the section &quot;If you receive a
            nonqualified distribution and you were born on or before January 1,
            1936&quot; applies only if the participant was born on or before
            January 1, 1936.
          </p>
          <p style="padding-left: 40px">
            <strong>If you are a surviving spouse. </strong>If you receive a
            payment from the Plan as the surviving spouse of a deceased
            participant, you have the same rollover options that the participant
            would have had, a described elsewhere in this notice. In addition,
            if you choose to do a rollover to a Roth IRA, you may treat the Roth
            IRA as your own or as an inherited Roth IRA.
          </p>
          <p style="padding-left: 40px">
            A Roth IRA you treat as your own is treated like any other Roth IRA
            of yours, so that you will not have to receive any required minimum
            distributions during your lifetime and earnings paid to you in a
            nonqualified distribution before you are age 59 1/2 will be subject
            to the 10% additional income tax on early distributions (unless an
            exception applies).
          </p>
          <p style="padding-left: 40px">
            If you treat the Roth IRA as an inherited Roth IRA, payments from
            the Roth IRA will not be subject to the 10% additional income tax on
            early distributions. An inherited Roth IRA is subject to required
            minimum distributions. If the participant had started taking
            required minimum distributions from the Plan, you will have to
            receive required minimum distributions from the inherited Roth IRA.
            If the participant had not started taking required minimum
            distributions, you will not have to start receiving required minimum
            distributions from the inherited Roth IRA until the year the
            participant would have been age 70 1/2 (if born before July 1, 1949)
            or age 72 (if born after June 30, 1949).
          </p>
          <p style="padding-left: 40px">
            <strong
              >If you are a surviving beneficiary other than a spouse.</strong
            >
            If you receive a payment from the Plan because of the
            participant&apos;s death and you are a designated beneficiary other
            than a surviving spouse, the only rollover option you have is to do
            a direct rollover to an inherited Roth IRA. Payments from the
            inherited Roth IRA, even if made in a nonqualified distribution,
            will not be subject to the 10% additional income tax on early
            distributions. You will have to receive required minimum
            distributions from the inherited Roth IRA.
          </p>
          <p>
            <u>Payments under a qualified domestic relations order</u>. If you
            are the spouse or a former spouse of the participant who receives a
            payment from the Plan under a qualified domestic relations order
            (QDRO), you generally have the same options and the same tax
            treatment that the participant would have (for example, you may roll
            over the payment as described in this notice).
          </p>
          <p>
            <strong>If you are a nonresident alien<br /></strong>If you are a
            nonresident alien and you do not do a direct rollover to a U.S. IRA
            or U.S. employer plan, instead of withholding 20%, the Plan is
            generally required to withhold 30% of the payment for federal income
            taxes. If the amount withheld exceeds the amount of tax you owe (as
            may happen if you do a 60-day rollover), you may request an income
            tax refund by filing Form 1040NR and attaching your Form 1042-S. See
            Form W-8BEN for claiming that you are entitled to a reduced rate of
            withholding under an income tax treaty. For more information, see
            also IRS Publication 519, U.S. Tax Guide for Aliens, and IRS
            Publication 515,
            <em
              >Withholding of Tax on Nonresident Aliens and Foreign Entities</em
            >.
          </p>
          <p>
            <strong>Other special rules<br /></strong>If a payment is one in a
            series of payments for less than 10 years, your choice whether to
            make a direct rollover will apply to all later payments in the
            series (unless you make a different choice for later payments).
          </p>
          <p>
            If your payments for the year (only including payments from the
            designated Roth account in the Plan) are less than $200, the Plan is
            not required to allow you to do a direct rollover and is not
            required to withhold federal income taxes. However, you can do a
            60-day rollover.
          </p>
          <p>
            Unless you elect otherwise, a mandatory cashout from the designated
            Roth account in the Plan of more than $1,000 will be directly rolled
            over to a Roth IRA chosen by the Plan administrator or the payor. A
            mandatory cashout is a payment from a plan to a participant made
            before age 62 (or normal retirement age, if later) and without
            consent, where the participant&apos;s benefit does not exceed $7,000
            (not including any amounts held under the plan as a result of a
            prior rollover made to the plan).
          </p>
          <p>
            You may have special rollover rights if you recently served in the
            U.S. Armed Forces. For more information on special rollover rights
            related to the U.S. Armed Forces, see IRS Publication 3, Armed
            Forces&apos; Tax Guide. You also may have special rollover rights if
            you were affected by a federally declared disaster (or similar
            event), or if you received a distribution on account of a disaster.
            For more information on special rollover rights related to disaster
            relief, see the IRS website at
            <a href="https://www.irs.gov/" target="_blank" rel="noopener"
              ><strong>www.irs.gov</strong></a
            ><strong>.</strong>
          </p>
          <p>
            <strong>FOR MORE INFORMATION<br /></strong>You may wish to consult
            with the Plan administrator or payor, or a professional tax advisor,
            before taking a payment from the Plan. Also, you can find more
            detailed information on the federal tax treatment of payments from
            employer plans in: IRS Publication 575,
            <em
              >Pension and Annuity Income; IRS Publication 590-A, Contributions
              to Individual Retirement Arrangements (IRAs); IRS Publication
              590-B, Distributions from Individual Retirement Arrangements
              (IRAs)</em
            >; and IRS Publication 571, Tax- Sheltered Annuity Plans (403(b)
            Plans). These publications are available from a local IRS office, on
            the web at
            <a href="https://www.irs.gov/" target="_blank" rel="noopener"
              ><strong>www.irs.gov</strong></a
            >, or by calling 1-800-TAX-FORM.
          </p>
        </div>
      </v-container>
    </v-main>

    <v-footer app class="app-footer"> </v-footer>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
h1 {
  color: #005e76;
}

.app-bar {
  background-color: #0b223b !important;
  border-top: 1em solid #005e76 !important;
}

.app-footer {
  background-color: #0b223b !important;
  border-bottom: 1em solid #005e76 !important;
}

.text-green {
  color: #98b71b;
}

li {
  padding-bottom: 0.5rem;
}
</style>
