"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import Account from "@/shared/lib/client-sdk/models/account";
import OrderLine from "@/shared/lib/client-sdk/models/order-line";

class OrderLineAssociation extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "OrderLineAssociation";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/trust/order-line-associations"
    );
  }

  getApiPathForCreate() {
    if (!this.orderLineId) {
      console.error("Cannot create association without order line id");
      return;
    }

    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/trust/order-lines/" +
      this.orderLineId +
      "/associations"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/trust/order-line-associations/" +
      this.id
    );
  }

  getAdminPath() {
    return "/order-line-associations/" + this.id;
  }

  get searchResultTitle() {
    return (
      (this.orderLineId != null ? "Order" : "Account Credit") +
      " $" +
      this.amount
    );
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  get relationships() {
    return { orderLine: OrderLine, creditForAccount: Account };
  }
}

export default OrderLineAssociation;
