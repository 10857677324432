<template>
  <div class="big-icon-button" :class="{ hovering: hovering }">
    <v-card
      :disabled="disabled"
      :style="styles"
      :elevation="hovering ? 3 : 0"
      @mouseover="hovering = !disabled"
      @mouseout="hovering = false"
    >
      <p>
        <pc-icon
          :color="$colors.penChecksTeal"
          size="75"
          :name="icon"
          :disabled="disabled"
        ></pc-icon>
      </p>
      <p>
        <strong><slot></slot></strong>
      </p>
    </v-card>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      default: 150,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
    },
  },

  data: function () {
    return {
      hovering: false,
    };
  },

  computed: {
    styles: function () {
      let height = "height: " + this.height + "px;";
      let width = this.width ? "width: " + this.width + "px;" : null;

      if (width) {
        return height + width;
      }

      return height;
    },
  },

  methods: {
    clicked: function () {
      if (!this.disabled) {
        this.$emit("click");
      }
    },
  },
  mounted: function () {
    $(this.$el).click(this.clicked.bind(this));
  },
};
</script>

<style lang="scss">
.big-icon-button {
  &.hovering {
    cursor: pointer;
  }

  .v-card {
    border: solid $penChecksMediumGray 1px;
    text-align: center;
    padding: 20px;
  }
}
</style>
