var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"capped-card",class:{ hovering: _vm.hovering }},[(!_vm.leftCap)?_c('v-card',{style:('width:' +
        _vm.cardWidth +
        '; transition: background-color 0.3s ease; overflow: hidden; background-color: ' +
        _vm.cardBackgroundColor),attrs:{"elevation":_vm.hovering || _vm.selected ? 3 : 0,"height":_vm.height},on:{"mouseover":function($event){_vm.hovering = !_vm.disabled},"mouseout":function($event){_vm.hovering = false}}},[_c('v-toolbar',{staticStyle:{"overflow":"hidden"},attrs:{"color":_vm.color,"elevation":"0","height":_vm.topCapSize,"dark":""}},[(_vm.title && _vm.title.length > 0)?_c('v-toolbar-title',{class:{ 'disabled-text': _vm.disabled },staticStyle:{"font-size":"16px","font-weight":"bold"}},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.icon)?[_c('v-icon',{style:('position: absolute; left: ' +
              _vm.iconLeftOffset +
              'px; bottom: ' +
              _vm.iconBottomOffset +
              'px; color: ' +
              _vm.iconColor),attrs:{"size":_vm.iconSize}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])]:_vm._e()],2),_c('v-card-text',{staticClass:"text--primary pa-6 pb-10 pt-10",class:{ 'disabled-text': _vm.disabled },style:('text-overflow: ellipsis; overflow: hidden; ' + _vm.maxTextHeight)},[_vm._t("default")],2)],1):_c('v-card',{attrs:{"elevation":_vm.hovering || _vm.selected ? 3 : 0},on:{"mouseover":function($event){_vm.hovering = !_vm.disabled},"mouseout":function($event){_vm.hovering = false}}},[_c('div',{staticStyle:{"display":"flex","min-height":"100px"}},[_c('div',{staticClass:"pa-5",style:('position: relative; display: flex; flex-direction: column; align-items: center; justify-content: center; flex-basis: ' +
            _vm.leftCapSize +
            'px; width: ' +
            _vm.leftCapSize +
            'px; overflow: hidden; vertical-align: text-bottom; background-color: ' +
            _vm.cardBackgroundColor)},[(_vm.title && _vm.title.length > 0)?_c('div',{style:('font-size: 16px; font-weight: bold; color: ' + _vm.$colors.white)},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.icon)?[_c('v-icon',{style:('position: absolute; left: ' +
                _vm.iconLeftOffset +
                'px; bottom: ' +
                _vm.iconBottomOffset +
                'px; color: ' +
                _vm.iconColor),attrs:{"size":_vm.iconSize}},[_vm._v(_vm._s(_vm.icon))])]:_vm._e()],2),_c('v-card-text',{staticClass:"text--primary pa-6 pt-8",class:{ 'disabled-text': _vm.disabled },style:('transition: background-color 0.3s ease; display: flex; text-overflow: ellipsis; overflow: hidden; flex-direction: column; flex-grow: 1; background-color: ' +
            _vm.cardBackgroundColor)},[_vm._t("default")],2)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }