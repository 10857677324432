<template>
  <div v-if="this.type == undefined && !this.alternate">
    <div v-if="this.size && this.size == 16">
      <warning-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <warning-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <warning-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <warning-32 :class="iconColorClass" />
    </div>
    <div v-else><checkmark-16 :class="iconColorClass" /></div>
  </div>
  <div v-else-if="this.type == undefined && this.alternate">
    <div v-if="this.size && this.size == 16">
      <warning-alt-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <warning-alt-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <warning-alt-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <warning-alt-32 :class="iconColorClass" />
    </div>
    <div v-else><add-alt-16 :class="iconColorClass" /></div>
  </div>
  <div v-else-if="this.type == 'filled' && this.alternate">
    <div v-if="this.size && this.size == 16">
      <warning-alt-filled-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <warning-alt-filled-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <warning-alt-filled-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <warning-alt-filled-32 :class="iconColorClass" />
    </div>
    <div v-else><add-16 :class="iconColorClass" /></div>
  </div>
</template>
<script>
import {
  Warning16,
  Warning20,
  Warning24,
  Warning32,
  WarningAlt16,
  WarningAlt20,
  WarningAlt24,
  WarningAlt32,
  WarningAltFilled16,
  WarningAltFilled20,
  WarningAltFilled24,
  WarningAltFilled32,
} from "@carbon/icons-vue";

export default {
  name: "PcWarningAltIcon",

  components: {
    "warning-16": Warning16,
    "warning-20": Warning20,
    "warning-24": Warning24,
    "warning-32": Warning32,
    "warning-alt-16": WarningAlt16,
    "warning-alt-20": WarningAlt20,
    "warning-alt-24": WarningAlt24,
    "warning-alt-32": WarningAlt32,
    "warning-alt-filled-16": WarningAltFilled16,
    "warning-alt-filled-20": WarningAltFilled20,
    "warning-alt-filled-24": WarningAltFilled24,
    "warning-alt-filled-32": WarningAltFilled32,
  },

  props: {
    size: {
      type: [Number, String],
      validator: function (value) {
        return [16, "16", 20, "20", 24, "24", 32, "32"].includes(value);
      },
      required: false,
      default: 16,
    },
    alternate: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: undefined,
      validator: function (value) {
        return [undefined, "filled"].includes(value);
      },
    },
    iconColorClass: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
