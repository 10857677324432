<template>
  <div :class="{ 'singleton-modals-style': isParticipantPortalField }">
    <v-dialog
      v-if="show"
      v-model="show"
      :max-width="maxWidth"
      :persistent="persistent"
      content-class="singleton-modal singleton-modals-dialog-style"
    >
      <v-card class="singleton-modals-card-style">
        <v-icon
          id="closeModalIconId"
          v-if="!hideXIcon"
          style="position: absolute; right: 10px; top: 5px"
          @click="alertDismissed(false)"
          class="singleton-modals-close-icon-style"
          >fal fa-times</v-icon
        >

        <v-card-title
          id="modalTitleId"
          :class="{
            headline: !isParticipantPortalField,
            'singleton-modals-title-style': isParticipantPortalField,
          }"
          :style="'color: ' + $colors.penChecksDarkGray"
        >
          <div v-if="!isParticipantPortalField" style="word-break: keep-all">
            {{ title }}
          </div>
          <v-row
            v-if="isParticipantPortalField"
            class="singleton-modals-title-row-style"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M2.73804 17.9979C1.96611 19.3313 2.92823 21 4.46889 21H19.5311C21.0718 21 22.0339 19.3313 21.262 17.9979L13.7309 4.98965C12.9605 3.65908 11.0395 3.65908 10.2691 4.98965L2.73804 17.9979Z"
                fill="#101F48"
              />
              <path
                d="M2.73804 17.9979C1.96611 19.3313 2.92823 21 4.46889 21H19.5311C21.0718 21 22.0339 19.3313 21.262 17.9979L13.7309 4.98965C12.9605 3.65908 11.0395 3.65908 10.2691 4.98965L2.73804 17.9979ZM13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17ZM13 13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V10C11 9.44772 11.4477 9 12 9C12.5523 9 13 9.44772 13 10V13Z"
                fill="#F3AF3D"
              />
            </svg>
            <span>{{ title }}</span>
          </v-row>
        </v-card-title>

        <v-card-text
          :class="{
            'py-0': !isParticipantPortalField,
            'singleton-modals-text-style': isParticipantPortalField,
          }"
        >
          <span v-html="text"></span>
        </v-card-text>

        <v-card-text v-if="showInputText" class="singleton-modals-input-style">
          <v-text-field
            :color="$colors.penChecksTeal"
            id="prompt-modal-input-value"
            autofocus
            :label="inputLabel"
            :error-messages="error"
            v-model="inputValue"
            v-on:keyup.enter="alertDismissed(true)"
            v-on:keyup.esc="alertDismissed(false)"
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="singleton-modals-actions-style">
          <v-spacer></v-spacer>
          <v-btn
            v-if="cancelButtonText && !isParticipantPortalField"
            :color="cancelButtonColor"
            id="cancelButtonId"
            text
            @click="alertDismissed(false)"
          >
            {{ cancelButtonText }}
          </v-btn>
          <div
            v-if="cancelButtonText && isParticipantPortalField"
            class="singleton-modals-cancel-button-style"
          >
            <div>
              <a @click="alertDismissed(false)">
                <span class="singleton-modals-cancel-button-text-style">{{
                  cancelButtonText
                }}</span>
              </a>
            </div>
          </div>

          <v-btn
            v-if="!isParticipantPortalField"
            :color="okayButtonColor"
            id="okButtonId"
            text
            @click="alertDismissed(true)"
          >
            {{ okayButtonText }}
          </v-btn>
          <pc-button
            v-if="isParticipantPortalField"
            class="singleton-modals-ok-button-style"
            :color="okayButtonColor"
            :participant-portal="isParticipantPortalField"
            @click="alertDismissed(true)"
          >
            <div>
              <span class="singleton-modals-ok-button-text-style">{{
                okayButtonText
              }}</span>
            </div>
          </pc-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Singletons from "@/shared/plugins/singletons";
import $ from "jquery";

export default {
  components: {},

  data: () => ({
    show: false,
    title: null,
    text: null,
    showInputText: false,
    inputLabel: null,
    inputValue: "",
    okayButtonText: null,
    okayButtonColor: null,
    cancelButtonText: null,
    cancelButonColor: null,
    persistent: false,
    hideXIcon: false,
    callback: null,
    cancelCallback: null,
    error: null,
    maxWidth: null,
    isParticipantPortalField: false,
  }),

  methods: {
    /**
     * Show an alert, with a simple "ok" button.
     */
    showAlert: function (options, callback, cancelCallback) {
      if ($.type(options) === "string") options = { text: options };

      if (!$.isPlainObject(options)) options = {};

      this.show = true;
      this.error = null;
      this.title = options["title"] || null;
      this.text = options["text"] || null;

      this.persistent = options["persistent"] || false;
      this.hideXIcon = options["hideXIcon"] || false;

      this.showInputText = options["showInputText"] || false;
      this.inputValue = options["inputValue"] || null;
      this.inputLabel = options["inputLabel"] || null;

      this.okayButtonText = options["okayButtonText"] || "OK";
      this.okayButtonColor =
        options["okayButtonColor"] || this.$colors.penChecksTeal;

      this.cancelButtonText = options["cancelButtonText"] || null;
      this.cancelButtonColor =
        options["cancelButtonColor"] || this.$colors.penChecksTeal;
      this.callback = callback || null;
      this.cancelCallback = cancelCallback || null;

      this.maxWidth = options["maxWidth"] || 432;
      this.isParticipantPortalField =
        options["isParticipantPortalField"] || false;
    },
    alertDismissed: function (okayWasClicked) {
      if (
        okayWasClicked &&
        this.showInputText &&
        (!this.inputValue || this.inputValue.trim().length == 0)
      ) {
        this.error = "Please enter a value";
        return;
      }

      this.show = false;

      const callbackValue = this.showInputText ? this.inputValue : null;

      if (okayWasClicked && $.isFunction(this.callback))
        this.callback(callbackValue);

      if (!okayWasClicked && $.isFunction(this.cancelCallback))
        this.cancelCallback();
    },
    /**
     * Ask a yes\no question.  Will default to "Okay" and "Cancel" buttons.
     */
    showConfirm: function (options, callback, cancelCallback) {
      if ($.type(options) === "string") options = { text: options };

      this.showAlert(options, callback, cancelCallback);

      this.okayButtonText = options["okayButtonText"] || "Okay";
      this.cancelButtonText = options["cancelButtonText"] || "Cancel";
      this.showInputText = options["showInputText"] || false;
      this.persistent = options["persistent"] || true;
    },
    /**
     * Prompts with a question, and a free form text box.  Has "Okay" and "Cancel" buttons.
     */
    showPrompt: function (options, callback, cancelCallback) {
      if ($.type(options) === "string") options = { text: options };

      this.showAlert(options, callback, cancelCallback);

      this.okayButtonText = options["okayButtonText"] || "Okay";
      this.cancelButtonText = options["cancelButtonText"] || "Cancel";
      this.showInputText = options["showInputText"] || true;
      this.persistent = options["persistent"] || true;
    },
    hideModal() {
      this.show = false;
    },
  },

  mounted: function () {
    Singletons.Events.$on("showAlert", this.showAlert.bind(this));
    Singletons.Events.$on("showConfirm", this.showConfirm.bind(this));
    Singletons.Events.$on("showPrompt", this.showPrompt.bind(this));
    Singletons.Events.$on("hideModal", this.hideModal.bind(this));
  },
};
</script>

<style lang="scss">
.singleton-modal {
  .v-card__title.headline {
    font-size: 18px !important;
    font-weight: normal !important;
  }
}

.singleton-modals-dialog-style {
  font-family: "Open Sans", sans-serif;

  .singleton-modals-card-style {
    .singleton-modals-title-style {
      margin: 0px;
      padding: 0px !important;

      .singleton-modals-title-row-style {
        margin: 0px;
        padding: 24px 32px 24px 32px !important;

        div {
          width: 3px;
          height: 24px;
          border-radius: 1px;
          background-color: $primary;
        }

        span {
          margin-top: -4px;
          margin-left: 12px !important;
          color: $neutral90;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .singleton-modals-text-style {
      color: $neutral70 !important;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.25px;
      line-height: 20px;
      text-align: left;

      padding: 0px 32px 24px 32px !important;

      span {
        p {
          color: $neutral70 !important;
        }
      }
    }

    .singleton-modals-input-style {
    }

    .singleton-modals-actions-style {
      padding-bottom: 24px !important;

      .singleton-modals-cancel-button-style {
        margin-right: 24px;
        padding-left: 24px;
        padding-right: 24px;
        .singleton-modals-cancel-button-text-style {
          font-size: 14px;
          font-weight: 600;
          color: $neutral60;
        }
      }

      .singleton-modals-ok-button-style {
        margin-right: 16px;

        button {
          height: 40px !important;
          padding-left: 24px;
          padding-right: 24px;
          background-color: $primary;
          border-color: $primary !important;
          border-radius: 6px;

          span {
            padding-top: 10px;
            padding-bottom: 10px;

            div {
              .singleton-modals-ok-button-text-style {
                font-weight: 600;
                text-transform: none;
                width: fit-content;
              }
            }
          }
        }
      }
    }
  }
}
</style>
