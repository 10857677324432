"use strict";

import User from "@/shared/lib/client-sdk/models/user";
import ApiResponse from "./api-response";
import $ from "jquery";

class ApiRequest {
  constructor(requestMethod, requestUrl, requestData, resultClass) {
    this.requestMethod = requestMethod;
    this.requestUrl = requestUrl;
    this.requestData = requestData;
    this.resultClass = resultClass;

    this._ajax = null;
    this._isBusy = false;

    this._onSuccessCallback = null;
    this._onErrorCallback = null;
    this._onCompleteCallback = null;
  }

  /******** REGISTER EXTERNAL EVENT HANDLER CALLBACKS *********/

  onSuccess(callback) {
    this._onSuccessCallback = callback;
    return this;
  }

  onError(callback) {
    this._onErrorCallback = callback;
    return this;
  }

  onComplete(callback) {
    this._onCompleteCallback = callback;
    return this;
  }

  /******** ACTUAL AJAX CALL *********/

  static send(requestMethod, requestUrl, requestData, resultClass) {
    const apiRequest = new ApiRequest(
      requestMethod,
      requestUrl,
      requestData,
      resultClass
    );
    return apiRequest.send();
  }

  static upload(requestUrl, formData, resultClass) {
    const apiRequest = new ApiRequest(
      "POST",
      requestUrl,
      formData,
      resultClass
    );
    return apiRequest.send();
  }

  send() {
    if (this._ajax) {
      console.error(
        "Previous AJAX request was not yet complete. Cannot send request."
      );
      return;
    }

    // Cancel any other inflight processes
    this.cancel();

    const options = this._getAjaxOptions();

    this._isBusy = true;
    this._ajax = $.ajax(options);

    return this;
  }

  cancel() {
    if (this._ajax) this._ajax.abort();

    this._ajax = null;
    this._isBusy = false;
    this._onSuccessCallback = null;
    this._onErrorCallback = null;
    this._onCompleteCallback = null;
  }

  get isBusy() {
    return this._isBusy;
  }

  _getAjaxOptions() {
    const ajaxOptions = {
      type: this.requestMethod,
      url: this.requestUrl,
    };

    if (window.location.hostname.includes("unclaimedretirementbenefits.com")) {
      ajaxOptions["url"] = ajaxOptions["url"].replace(
        "penchecks.com",
        "unclaimedretirementbenefits.com"
      );
    }

    var isFileUploadRequest = this.requestData instanceof FormData;
    if (isFileUploadRequest) {
      ajaxOptions.cache = false;
      ajaxOptions.contentType = false;
      ajaxOptions.processData = false;
      ajaxOptions.headers = {};
    } else {
      ajaxOptions.dataType = "json";
      ajaxOptions.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    }

    if (User.isCurrentlyAuthenticated()) {
      const authToken = User.getCurrentAuthToken();
      ajaxOptions.headers["Authorization"] = "Bearer " + authToken;
    }

    // TODO: Adjust it to load the Advisor Account
    if (window.location.hostname.includes("amplify")) {
      const masterAccountId = window.location.pathname.split("/")[1];
      if (masterAccountId.length == 32) {
        ajaxOptions.headers["X-Master-Account-Id"] = masterAccountId;
      }
    }

    ajaxOptions.headers["X-Client-Url"] = window.location;
    ajaxOptions.headers["X-Utc-Offset"] = new Date().getTimezoneOffset();

    if (this.requestData !== null) {
      ajaxOptions["data"] =
        typeof this.requestData != "string" && !isFileUploadRequest
          ? JSON.stringify(this.requestData)
          : this.requestData;
    }

    ajaxOptions["success"] = this._onAjaxSuccess.bind(this);
    ajaxOptions["error"] = this._onAjaxError.bind(this);

    ajaxOptions["statusCode"] = {
      401: function () {
        const host = window.location.host;
        if (host) {
          if (
            host.includes("admin.penchecks.com") ||
            host.includes("amplify.penchecks.com")
          )
            document.location =
              window.amplifyRuntimeConfig.get("VUE_APP_LOGIN_URL") +
              "?returnUrl=" +
              encodeURI(window.location.href);
        }
      },
    };

    return ajaxOptions;
  }

  _onAjaxSuccess(responseData, httpStatus) {
    // Even though the ajax call was successful, we might have an improperly formatted result, or an error in the response, so we check that here.
    if (
      (responseData &&
        !Object.prototype.hasOwnProperty.call(responseData, "status")) ||
      !Object.prototype.hasOwnProperty.call(
        responseData.status,
        "successful"
      ) ||
      !responseData.status.successful ||
      !Object.prototype.hasOwnProperty.call(responseData, "result")
    )
      return this._onAjaxError(responseData, httpStatus);

    const apiResponse = new ApiResponse(
      true,
      responseData,
      httpStatus,
      this.resultClass
    );

    if ($.isFunction(this._onSuccessCallback))
      this._onSuccessCallback(apiResponse);

    if ($.isFunction(this._onCompleteCallback))
      this._onCompleteCallback(apiResponse);

    this._isBusy = false;
  }

  _onAjaxError(responseData, httpStatus, error) {
    responseData = responseData.responseJSON;

    const apiResponse = new ApiResponse(
      false,
      responseData,
      httpStatus,
      this.resultClass,
      error
    );

    if ($.isFunction(this._onErrorCallback)) this._onErrorCallback(apiResponse);

    if ($.isFunction(this._onCompleteCallback))
      this._onCompleteCallback(apiResponse);

    this._isBusy = false;
  }
}

export default ApiRequest;
