"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";

class DocumentTemplate extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "DocumentTemplate";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/document-templates"
    );
  }

  getApiPathForCreate() {
    console.error("Cannot currently create document templates.");
  }

  getApiPathForRefreshUpdateAndDelete() {
    console.error("Cannot currently update a document template.");
  }

  getAdminPath() {
    console.error("Cannot currently view a document template.");
  }

  get searchResultTitle() {
    return this.displayName;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return this.createdAt;
  }

  get searchResultStatus() {
    return this.status;
  }

  // If the API returns a relationship as a sub-object, this tells the base model which class to use for them when it loads it in.
  get relationships() {
    return {};
  }
}

export default DocumentTemplate;
