"use strict";

import BaseModelList from "./base-model-list";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import $ from "jquery";

class EntityChangeLogsList extends BaseModelList {
  constructor(parentModel) {
    super(parentModel);
  }

  getModelClassName() {
    return "EntityChangeLog";
  }

  getApiPathForSearch() {
    return (
      this.parentModel
        .getApiPathForRefreshUpdateAndDelete()
        .replace("/public/", "/private/") + "/change-logs"
    );
  }

  getApiPathForAdd() {
    console.error("Cannot add an entity change log.");
  }

  getApiPathForRefreshUpdateAndDelete() {
    console.error("Cannot delete an entity change log.");
  }

  getChangeLogEntriesCount(callback) {
    var requestUrl = this.getApiPathForSearch() + "-count";
    ApiRequest.send("GET", requestUrl, null, EntityChangeLogsList).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  getChangeLogEntries(options, callback) {
    var requestUrl = this.getApiPathForSearch();
    ApiRequest.send(
      "GET",
      requestUrl,
      $.param(options),
      EntityChangeLogsList
    ).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }
}

export default EntityChangeLogsList;
