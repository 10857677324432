const Helpers = {
  install(Vue) {
    Vue.prototype.$parentWithTag = function (tagName) {
      var parent = this.$parent;

      do {
        if (!parent.$vnode || !parent.$vnode.componentOptions) {
          return null;
        }

        if (
          parent.$vnode &&
          parent.$vnode.componentOptions &&
          parent.$vnode.componentOptions.tag == tagName
        ) {
          break;
        }
        parent = parent.$parent;
      } while (parent);

      return parent;
    };

    Vue.prototype.$childrenWithTag = function (tagName) {
      var children = [];

      var findChildren = (parent) => {
        for (var i = 0; i < parent.$children.length; i++) {
          var child = parent.$children[i];

          if (!child || !child.$vnode || !child.$vnode.componentOptions) {
            continue;
          }

          if (child.$vnode.componentOptions.tag == tagName) {
            children.push(child);
          } else if (child.$children && child.$children.length > 0) {
            findChildren(child);
          }
        }
      };

      findChildren(this);

      return children;
    };
  },
};

export default Helpers;
