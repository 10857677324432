<template>
  <div
    :class="
      isParticipantPortalField
        ? 'routing-number is-participant-portal-field'
        : 'routing-number is-general-field'
    "
  >
    <div
      v-if="isParticipantPortalField && showHeader"
      class="routing-number-header-style"
    >
      <span v-if="required" class="error-text">*</span>Routing number
    </div>
    <v-text-field
      :id="id"
      v-model="text"
      :type="type"
      :rules="inputRules"
      :label="isParticipantPortalField ? '' : inputLabel"
      :placeholder="placeholder"
      :validate-on-blur="true"
      :disabled="disabled"
      :clearable="clearable"
      :dense="dense"
      :errorMessages="errors"
      :color="$colors.penChecksTeal"
      :hint="hint"
      :persistent-hint="persistentHint"
      :append-icon="appendIcon"
      v-on="$listeners"
      @keyup.enter="$emit('enter', $event)"
      @change="$emit('change', text)"
      @click:clear="$emit('clear')"
      @blur="$emit('blur')"
      @focus="errors = null"
      ref="textfield"
      class="pc-routing-number"
      :outlined="isParticipantPortalField"
    ></v-text-field>
  </div>
</template>
<script>
export default {
  name: "PcRoutingNumberReskinned",

  props: {
    value: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
    },
    appendIcon: {
      type: String,
    },
    id: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    errorMessages: {
      type: Array,
    },
    dense: {
      type: Boolean,
    },
    hint: {
      type: String,
    },
    persistentHint: {
      type: Boolean,
    },
    hideOptionalText: {
      type: Boolean,
    },
    maxLength: {
      type: Number,
      default: 9,
    },
    isParticipantPortalField: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: "Routing number",
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      counter: 0,
      text: this.value,
      requiredInputRules: [(v) => !!v || "Please enter " + this.label],
      defaultInputRules: [
        (v) => {
          let isNumber = !Number.isNaN(v);
          let inputValue = isNumber ? String(v) : v;
          let incorrectLengthError = "Must be " + this.maxLength + " digits.";
          let isEmptyString = inputValue === "";
          let isNotLongEnough =
            typeof inputValue == "string" &&
            inputValue.length != this.maxLength;
          return isNotLongEnough && !isEmptyString
            ? incorrectLengthError
            : true;
        },
      ],
      errors: null,
    };
  },
  computed: {
    inputLabel: function () {
      let inputLabel = "";
      let optionalText =
        this.required || this.hideOptionalText ? "" : " (optional)";

      if (this.showLabel) {
        inputLabel = this.label + optionalText;
      }

      return inputLabel;
    },
    inputRules: function () {
      return this.required
        ? this.requiredInputRules.concat(this.defaultInputRules)
        : this.defaultInputRules;
    },
  },
  watch: {
    value(newValue) {
      if (this.text != newValue) {
        this.text = newValue;
      }
    },
    errorMessages(errors) {
      this.errors = errors;
    },
  },
};
</script>

<style lang="scss">
.routing-number.is-participant-portal-field {
  .routing-number-header-style {
    margin-left: 10px;
    margin-bottom: 8px;
    font-family: "Open Sans", sans-serif;
    color: $neutral70;
    font-size: 12px;
    font-weight: 600;
  }

  .v-input--is-focused {
    .v-input__slot {
      border-width: 1px !important;
      border-color: $primary !important;
      box-shadow: 0px 0px 0px 3px $primary10 !important;
    }
  }

  div.v-input:not(.error--text, .v-input--is-focused) {
    fieldset {
      border-color: $neutral10 !important;
    }
  }

  div.v-text-field {
    border-radius: 6px !important;
  }

  div.v-text-field__slot {
    margin-top: auto;
    margin-bottom: auto;
    height: 20px;
    font-family: "Open Sans", sans-serif;
    input {
      color: $neutral70 !important;
      font-size: 14px;
      width: 276px;
      height: 20px !important;
    }

    input::placeholder {
      font-family: "Open Sans", sans-serif !important;
      color: $neutral30 !important;
      font-weight: 400 !important;
      font-size: 14px;
    }
  }

  .v-input__slot {
    min-height: 40px !important;
    height: 20px;
  }

  .v-input--is-focused {
    .v-input__slot {
      color: $primary !important;
      caret-color: $primary !important;
      box-shadow: 0px 0px 0px 3px $primary10 !important;
      fieldset {
        border-width: 1px !important;
      }
    }
  }

  .pc-routing-number {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}

.routing-number.is-general-field {
  .pc-routing-number {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}
</style>
