<template>
  <pc-modal
    :show="show"
    @close="closeModal"
    :title="title"
    :api-response="apiResponse"
    id-prefix="uploadModal"
  >
    <v-form ref="uploadForm" v-model="isUploadFormValid">
      <v-row>
        <v-col class="py-1">
          <v-file-input
            v-model="file"
            label="File"
            id="fileInput"
            :accept="acceptFileTypes"
            :color="$colors.penChecksTeal"
          ></v-file-input>
        </v-col>
      </v-row>
      <slot></slot>
    </v-form>
    <template v-slot:modal-buttons>
      <a
        v-if="templateFileName"
        :href="`/templates/${templateFileName}`"
        style="text-decoration: none"
        download
        class="mr-3"
      >
        <pc-button text depressed id="downLoadTemplateButton"
          >Download Template</pc-button
        >
      </a>
      <pc-button text depressed @click="uploadFile" id="uploadFileButton"
        >Upload</pc-button
      >
    </template>
  </pc-modal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
    },
    apiResponse: {},
    acceptFileTypes: {
      type: String,
    },
    templateFileName: {
      type: String,
    },
  },

  data: function () {
    return {
      file: null,
      isUploadFormValid: false,
    };
  },

  methods: {
    uploadFile() {
      this.$refs.uploadForm.validate();
      if (!this.isUploadFormValid) return;

      this.$emit("uploadFile", this.file);
    },
    closeModal() {
      this.$emit("close");
    },
  },

  watch: {
    show(show) {
      if (show) {
        this.file = null;
      }
    },
  },
};
</script>

<style lang="scss"></style>
