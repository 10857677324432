<template>
  <v-app id="auth-app">
    <pc-singleton-modals id="singleton-auth-modals"></pc-singleton-modals>
    <pc-singleton-overlay id="singleton-auth-overlay"></pc-singleton-overlay>
    <pc-singleton-snackbar id="singleton-auth-snackbar"></pc-singleton-snackbar>

    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  components: {},

  data: () => ({}),

  mounted: function () {
    window.pendo.initialize();
    if (
      window.amplifyRuntimeConfig.get("VUE_APP_ENVIRONMENT") === "production"
    ) {
      var script = document.createElement("script");
      script.setAttribute(
        "src",
        "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      );
      script.setAttribute("type", "text/javascript");
      script.setAttribute("charset", "UTF-8");
      script.setAttribute(
        "data-domain-script",
        "28bd93ae-59e3-4a78-b05f-c11fa59cf688"
      );
      document.head.appendChild(script);
    }
  },
};
</script>

<style lang="scss">
#auth-app {
  font-family: "Roboto", sans-serif;

  // Vuetify v2.4 changed styling for nested grid items, causing spacing across the
  // app to be affected. A solution was found here that restores our previous spacing:
  // https://github.com/vuetifyjs/vuetify/issues/12848
  .row {
    margin-top: 0;
    margin-bottom: 0;
  }

  .row + .row {
    margin-top: 0;
  }
}
</style>
