<template>
  <v-text-field
    v-model="formattedText"
    :rules="rules"
    :label="label ? label + (required ? '' : ' (optional)') : ''"
    :validate-on-blur="true"
    class="percentage-text-field"
    :disabled="disabled"
    :color="$colors.penChecksTeal"
    @focus="isFocused = true"
    @blur="inputBlurred"
    @change="$emit('change', floatValue)"
    @keyup="keyUp"
  ></v-text-field>
</template>
<script>
export default {
  props: {
    value: {},
    label: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    minValue: {
      type: Number,
    },
    maxValue: {
      type: Number,
    },
    allowZero: {
      type: Boolean,
    },
    wholeNumbers: {
      type: Boolean,
    },
  },

  data: function () {
    return {
      formattedText: null,
      isFocused: false,

      rules: [
        () => {
          if (this.required && this.floatValue == null)
            return "Please enter an value.";
          return true;
        },
        () => {
          if (!this.allowZero && this.floatValue == 0)
            return "Please enter a non-zero value.";
          return true;
        },
        () => {
          if (!this.allowNegative && this.floatValue < 0)
            return "Please enter a positive value.";
          return true;
        },
        () => {
          if (
            this.minValue != null &&
            this.floatValue != null &&
            this.floatValue < this.minValue
          )
            return (
              "Must be at least " +
              Math.roundProperly(this.minValue * 100, 2) +
              "%"
            );
          return true;
        },
        () => {
          if (
            this.maxValue != null &&
            this.floatValue != null &&
            this.floatValue > this.maxValue
          )
            return (
              "Must not exceed " +
              Math.roundProperly(this.maxValue * 100, 2) +
              "%"
            );
          return true;
        },
      ],
    };
  },

  computed: {
    floatValue: function () {
      if (this.formattedText == null) return null;
      var percentageFloat =
        parseFloat(Number.numberFormatHelper(this.formattedText)) * 0.01;
      return Math.roundProperly(percentageFloat, 3);
    },
  },

  watch: {
    value: function () {
      if (this.value != this.floatValue) {
        this.formattedText = parseFloat(this.value) * 100 + "";
        this.formatText();
      }
    },
    floatValue: function () {
      if (this.value !== this.floatValue) {
        this.$emit("input", this.floatValue);
      }
    },
  },

  methods: {
    keyUp: function (e) {
      this.$emit("keyup", e);

      this.textChanged(e);
    },
    textChanged: function (e) {
      var selectionStart = e.target.selectionStart;
      var selectionEnd = e.target.selectionEnd;
      var value = e.target.value;

      if (
        this.formattedText == null ||
        (this.formattedText != null && this.formattedText.trim() == "")
      ) {
        this.formattedText = null;
        return;
      }

      var isPasting = (e.ctrlKey || e.metaKey) && e.key.toLowerCase() == "v";

      // Dont format if not at end of string (or pasting)
      if (
        !isPasting &&
        (selectionStart != selectionEnd || selectionStart < value.length)
      ) {
        return;
      }

      if (e.key != "." && e.keyCode != 8) {
        this.formatText();
      }

      this.setCursorPosition(selectionEnd);
    },
    inputBlurred: function () {
      this.isFocused = false;
      this.formatText();
    },
    setCursorPosition(selectionEnd) {
      if (this.$children[0].$refs.input && !Number.isNaN(selectionEnd)) {
        this.$nextTick(() => {
          this.$children[0].$refs.input.selectionEnd = selectionEnd;
        });
      }
    },
    formatText: function () {
      if (this.formattedText)
        this.formattedText = Number.numberFormatHelper(this.formattedText, {
          commas: true,
        });

      if (this.formattedText != null && this.formattedText.toString() == "NaN")
        this.formattedText = null;

      if (this.formattedText != null && this.formattedText != "") {
        this.formattedText = Math.roundProperly(this.formattedText, 2) + " %";
      }
    },
  },

  mounted: function () {
    this.formattedText = parseFloat(this.value) * 100 + "";
    this.formatText();
  },
};
</script>

<style lang="scss"></style>
