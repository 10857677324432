<template>
  <v-select
    v-model="selectedValue"
    :items="items"
    :item-disabled="itemDisabled"
    :loading="loading"
    :hide-selected="hideSelected"
    :item-text="itemText"
    :item-value="itemValue"
    :rules="rules"
    :clearable="clearable"
    :label="label"
    :prepend-icon="prependIcon"
    :multiple="true"
    :disabled="disabled"
    :color="$colors.penChecksTeal"
    :item-color="$colors.penChecksDarkTeal"
    :menu-props="{
      contentClass: 'pc-dropdown-multiple-menu',
      closeOnContentClick: false,
      offsetY: true,
    }"
    :dense="dense"
    class="pc-dropdown-multiple"
    return-object
    @change="$emit('change', selectedValue)"
  >
    <template v-if="enableSelectAll" v-slot:prepend-item>
      <v-list-item ripple @mousedown.prevent @click="toggleSelectAll">
        <v-list-item-action>
          <pc-checkbox v-model="allItemsSelected"></pc-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ selectAllText }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>

    <template v-slot:selection="{ item, index }">
      <span v-if="allItemsSelected && index === 0">
        {{ selectAllText === "Select all" ? "All selected" : selectAllText }}
      </span>
      <span
        v-if="!allItemsSelected"
        class="pc-dropdown-multiple-item"
        :id="item[itemText].toCamelCase() + 'ChipSelection'"
      >
        {{ item[itemText] }}<span v-if="!isLastSelectedElement(index)">,</span>
      </span>
    </template>

    <template v-slot:item="{ eslintIgnore_active, item, on, attrs }">
      <v-list-item
        v-on="on"
        v-bind="attrs"
        v-slot:default="{ active }"
        :ripple="false"
        class="pc-dropdown-multiple-option"
      >
        <v-list-item-action>
          <pc-checkbox
            :value="active"
            @input="(val) => (active = val)"
          ></pc-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <span class="pc-dropdown-multiple-option-text">{{
              item[itemText]
            }}</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: Array,
    },
    label: {
      type: String,
      default: "Select",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
    },
    hideSelected: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemDisabled: {
      type: Function,
    },
    enableSelectAll: {
      type: Boolean,
      default: false,
    },
    selectAllText: {
      type: String,
      default: "Select all",
    },
  },

  data: function () {
    return {
      selectedValue: this.value, // ? { code: this.value } : null,
      allItemsSelected: false,
    };
  },

  watch: {
    value(newValue) {
      this.selectedValue = newValue;
    },
    selectedValue(newValue) {
      this.$emit("input", newValue);
      this.$emit("select", newValue);

      if (newValue.length !== this.items.length) {
        this.allItemsSelected = false;
      }
    },
  },

  methods: {
    isLastSelectedElement(itemIndex) {
      let isLastSelectedElement = false;
      let areSelectedElements =
        this.value && this.value.length && this.value.length > 0;
      let lastSelectedItemIndex = areSelectedElements
        ? this.value.length - 1
        : undefined;

      if (areSelectedElements && itemIndex == lastSelectedItemIndex)
        isLastSelectedElement = true;

      return isLastSelectedElement;
    },
    toggleSelectAll() {
      this.$nextTick(() => {
        if (
          this.selectedValue != null &&
          this.items.length === this.selectedValue.length
        ) {
          this.selectedValue = [];
          this.allItemsSelected = false;
        } else {
          this.selectedValue = this.items.slice();
          this.allItemsSelected = true;
        }
      });
    },
  },
  mounted: function () {
    if (this.enableSelectAll) {
      this.toggleSelectAll();
    }
  },
};
</script>

<style lang="scss">
.pc-dropdown-multiple-item {
  padding-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $penChecksDarkGray;
}

.pc-dropdown-multiple-menu {
  .v-list .v-list-item--active {
    background-color: #ebf3f5 !important;
    color: transparent;
  }

  .v-list-item__action {
    margin-right: 12px !important;
  }

  .v-list-item__title {
    color: $penChecksDarkGray !important;
  }
}

.pc-dropdown-multiple-menu
  .theme--light.v-list-item:hover:not(:active):not(:focus):not(.v-list-item--disabled):not(.v-list-item--active) {
  background-color: $penChecksLight !important;
  color: $penChecksDarkGray !important;
}

.pc-dropdown-multiple-menu {
  label:not(.error--text).v-label--active {
    color: $penChecksDarkTeal !important;
  }
}

.v-list-item--disabled {
  .v-icon {
    color: $penChecksMediumGray !important;
  }
}

.pc-dropdown-multiple-option-text {
  color: $penChecksDarkGray !important;
}
</style>
