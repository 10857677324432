<template>
  <div
    :class="
      isParticipantPortalField
        ? 'money-text-field is-participant-portal-field'
        : 'money-text-field is-general-field'
    "
  >
    <div
      v-if="isParticipantPortalField && showHeader"
      class="money-text-field-header-style"
    >
      <span v-if="required" class="error-text">*</span>{{ header }}
    </div>
    <v-text-field
      v-model="formattedText"
      :rules="rules"
      :label="
        isParticipantPortalField
          ? ''
          : showLabel
          ? label + (required || hideOptionalText ? '' : ' (optional)')
          : ''
      "
      :validate-on-blur="validateBlur"
      :hide-details="hideDetails"
      :prefix="prefix"
      class="money-text-field"
      :class="{ large: large, 'text-right': textRight }"
      :disabled="disabled"
      :clearable="clearable"
      :dense="dense"
      :color="$colors.penChecksTeal"
      :tabindex="tabindex"
      @focus="isFocused = true"
      @blur="inputBlurred"
      @keyup="keyUp"
      @change="$emit('change', floatValue)"
      ref="moneytextfield"
      :outlined="isParticipantPortalField"
    ></v-text-field>
  </div>
</template>
<script>
export default {
  name: "PcMoneyTextFieldReskinned",

  props: {
    value: {},
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: Number,
    },
    minAmount: {
      type: Number,
    },
    maxAmount: {
      type: Number,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    allowNegative: {
      type: Boolean,
      default: false,
    },
    allowZero: {
      type: Boolean,
      default: false,
    },
    wholeNumbers: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    hideDollarSign: {
      type: Boolean,
      default: false,
    },
    textRight: {
      type: Boolean,
      default: false,
    },
    hideOptionalText: {
      type: Boolean,
    },
    isParticipantPortalField: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: "",
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    validateBlur: {
      type: Boolean,
      default: true,
    },
  },

  data: function () {
    return {
      formattedText: null,
      isFocused: false,

      rules: [
        () => {
          if (this.required && this.floatValue == null)
            return "Please enter a value.";
          return true;
        },
        () => {
          if (this.disabled) return true;

          if (!this.allowZero && this.floatValue == 0)
            return "Please enter a non-zero value.";
          return true;
        },
        () => {
          if (!this.allowNegative && this.floatValue < 0)
            return "Please enter a positive value.";
          return true;
        },
        () => {
          if (
            this.minAmount != null &&
            this.floatValue != null &&
            this.floatValue < this.minAmount
          )
            return "Amount must be at least " + this.formattedMinAmount;
          return true;
        },
        () => {
          if (
            this.maxAmount != null &&
            this.floatValue != null &&
            this.floatValue > this.maxAmount
          )
            return this.isParticipantPortalField
              ? "Amounts don’t add up to your total"
              : "Amount may not exceed " + this.formattedMaxAmount;
          return true;
        },
      ],
    };
  },

  computed: {
    prefix: function () {
      return (this.isFocused || this.formattedText) && !this.hideDollarSign
        ? "$"
        : null;
    },
    formattedMaxAmount: function () {
      return this.$options.filters.currency(this.maxAmount);
    },
    formattedMinAmount: function () {
      return this.$options.filters.currency(this.minAmount);
    },
    floatValue: function () {
      if (this.formattedText == null || this.formattedText == "-") return null;
      return parseFloat(Number.numberFormatHelper(this.formattedText));
    },
  },

  watch: {
    value: function () {
      if (this.value != this.floatValue) {
        this.formattedText = this.value;
        this.formatText(true);
      }
    },
    floatValue: function () {
      this.$emit("input", this.floatValue);
    },
  },

  methods: {
    keyUp: function (e) {
      this.$emit("keyup", e);

      this.textChanged(e);
    },
    textChanged: function (e) {
      var selectionStart = e.target.selectionStart;
      var selectionEnd = e.target.selectionEnd;
      var value = e.target.value;

      if (value == "-") {
        this.formattedText = "-";
        return;
      }

      if (this.formattedText != null && this.formattedText.trim() == "") {
        this.formattedText = null;
        return;
      }

      var isPasting = (e.ctrlKey || e.metaKey) && e.key.toLowerCase() == "v";

      // Dont format if not at end of string (or pasting)
      if (
        !isPasting &&
        (selectionStart != selectionEnd || selectionStart < value.length)
      ) {
        return;
      }

      if (e.key != "." && e.keyCode != 8) {
        // keycode 8 = backspace
        this.formatText();
      }
    },
    inputBlurred: function () {
      this.isFocused = false;
      this.formatText(true); // Force 2 decimals when you blur
    },
    formatText: function (force2Decimals = false) {
      if (parseFloat(this.formattedText) == 0 || this.formattedText)
        this.formattedText = Number.numberFormatHelper(this.formattedText, {
          commas: true,
          limit2Decimals: !force2Decimals,
          force2Decimals: force2Decimals,
          stripDecimals: this.wholeNumbers,
        });

      if (this.formattedText != null && this.formattedText.toString() == "NaN")
        this.formattedText = null;
    },
  },

  mounted: function () {
    this.formattedText = this.value;
    this.formatText(true);
  },
};
</script>

<style lang="scss">
.money-text-field.is-participant-portal-field {
  .money-text-field-header-style {
    margin-left: 10px;
    margin-bottom: 8px;
    font-family: "Open Sans", sans-serif;
    color: $neutral70;
    font-size: 12px;
    font-weight: 600;
  }

  .v-input--is-focused {
    .v-input__slot {
      border-width: 1px !important;
      border-color: $primary !important;
      box-shadow: 0px 0px 0px 3px $primary10 !important;
    }
  }

  div.v-input:not(.error--text, .v-input--is-focused) {
    fieldset {
      border-color: $neutral10 !important;
    }
  }

  div.v-text-field {
    border-radius: 6px !important;
  }

  div.v-text-field__slot {
    margin-top: auto;
    margin-bottom: auto;
    height: 20px;
    font-family: "Open Sans", sans-serif;
    input {
      color: $neutral70 !important;
      font-size: 14px;
      height: 20px !important;
    }

    input::placeholder {
      font-family: "Open Sans", sans-serif !important;
      color: $neutral30 !important;
      font-weight: 400 !important;
      font-size: 14px;
    }
  }

  .v-input__slot {
    min-height: 40px !important;
    height: 20px;
  }

  .v-input--is-focused {
    .v-input__slot {
      color: $primary !important;
      caret-color: $primary !important;
      box-shadow: 0px 0px 0px 3px $primary10 !important;
      fieldset {
        border-width: 1px !important;
      }
    }
  }
}

.money-text-field.is-general-field {
  .large {
    input {
      font-size: 20px !important;
    }
  }
  .text-right {
    input {
      text-align: right;
    }
  }
}
</style>
