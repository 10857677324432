import { render, staticRenderFns } from "./PcDropdown.vue?vue&type=template&id=563c5d6e"
import script from "./PcDropdown.vue?vue&type=script&lang=js"
export * from "./PcDropdown.vue?vue&type=script&lang=js"
import style0 from "./PcDropdown.vue?vue&type=style&index=0&id=563c5d6e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
installComponents(component, {VCombobox,VDivider,VListItem})
