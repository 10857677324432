<template>
  <v-icon
    :dark="dark"
    :id="id"
    :disabled="disabled"
    :color="color"
    :size="size"
    :style="styles"
    >{{ classPrefix }} {{ iconClass }}</v-icon
  >
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    duotone: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    },
    id: {
      type: String,
    },
    width: {
      type: [String, Number],
    },
    height: {
      type: [String, Number],
    },
    size: {},
  },

  data: () => {
    return {
      iconClasses: {
        // SERVICES
        "pc-service-payments": "fa-usd-circle",
        "pc-service-tax-only": "fa-chart-pie-alt",
        "pc-service-location-only": "fa-map-marker-alt",
        "pc-service-rollover": "fa-sack-dollar",
        "pc-service-uncashed-checks": "fa-money-check-alt",

        // ENTITIES
        "pc-entity-account": "fa-city",
        "pc-entity-plan": "fa-folders",
        "pc-entity-order": "fa-concierge-bell",
        "pc-entity-order-line": "fa-list-ul",
        "pc-entity-recurring-order": "fa-sync",
        "pc-entity-recurring-summary": "fa-table",
        "pc-entity-payment": "fa-money-bill-wave",
        "pc-entity-document": "fa-file",
        "pc-entity-user": "fa-user",
        "pc-entity-participant": "fa-address-card",
        "pc-entity-fee": "fa-usd-square",
        "pc-entity-payment-instruction": "fa-file-invoice-dollar",
        "pc-entity-payment-batch": "fa-box-usd",
        "pc-entity-document-batch": "fa-archive",
        "pc-entity-deposit-batch": "fa-hand-holding-usd",
        "pc-entity-refund": "fa-hands-usd",
        "pc-entity-agreement-version": "fa-file-signature",

        // DEPARTMENTS/WORKFLOWS
        "pc-workflow-trust-department": "fa-search-dollar",
        "pc-workflow-distributions-department": "fa-envelope-open-dollar",
        //  'pc-workflow-alpha-omega': 'fa-omega',
        "pc-workflow-accounts-division": "fa-chart-line",
        "pc-workflow-taxes-department": "fa-chart-pie-alt",
        "pc-workflow-printing": "fa-print",
        "pc-quality-team": "fa-vial",

        // INTERFACE
        "pc-notification-bell": "fa-bell",
        "pc-close-x": "fa-times",
        "pc-circle": "fa-circle",
        "pc-account-credit": "fa-piggy-bank",
        "pc-alert": "fa-exclamation-circle",
        "pc-error": "fa-exclamation-circle",
        "pc-pause": "fa-pause-circle",
        "pc-unpause": "fa-play-circle",
        "pc-receive": "fa-inbox-in",
        "pc-add": "fa-plus",
        "pc-unbalanced": "fa-balance-scale-right",
        "pc-balanced": "fa-balance-scale",
        "pc-upload": "fa-cloud-upload-alt",
        "pc-download": "fa-arrow-to-bottom",
        "pc-vertical-ellipsis": "fa-ellipsis-v",
        "pc-arrow-left": "fa-arrow-left",
        "pc-arrow-right": "fa-arrow-right",
        "pc-compress": "fa-compress-alt",
        "pc-expand": "fa-expand-alt",
        "pc-angle-right": "fa-chevron-right",
        "pc-angle-left": "fa-chevron-left",
        "pc-angle-double-left": "fa-chevron-double-left",
        "pc-reissue": "fa-repeat",
        "pc-scroll-up": "fa-chevron-up",
        "pc-scroll-down": "fa-chevron-down",
        "pc-log-out": "fa-sign-out",
        "pc-external-link": "fa-external-link",
        "pc-note": "fa-sticky-note",
        "pc-refresh": "fa-sync-alt",
        "pc-empty": "fa-square",
        "pc-money": "fa-usd-square",
        "pc-search": "fa-search",
        "pc-checkmark": "fa-check",
        "pc-checkmark-circle": "fa-check-circle",
        "pc-transactions": "fa-list",
        "pc-check": "fa-money-check",
        "pc-automatic": "fa-magic",
        "pc-link": "fa-link",
        "pc-associate": "fa-chart-network",
        "pc-dropdown-menu-arrow": "fa-caret-down",
        "pc-permission": "fa-shield",
        "pc-role": "fa-user-tag",
        "pc-features": "fa-list",
        "pc-add-user": "fa-user-plus",
        "pc-suspicious": "fa-user-secret",
        "pc-reports": "fa-analytics",
        "pc-home": "fa-home",
        "pc-history": "fa-history",
        "pc-settings": "fa-cog",
        "pc-developer": "fa-tools",
        "pc-help": "fa-question-circle",
        "pc-edit": "fa-pencil",
        "pc-view": "fa-eye",
        "pc-save": "fa-save",
        "pc-copy": "fa-copy",
        "pc-undo": "fa-undo",
        "pc-cancel": "fa-times-circle",
        "pc-delete": "fa-trash",
        "pc-filter": "fa-filter",
        "pc-restore": "fa-trash-undo",
        "pc-secure": "fa-lock-alt",
        "pc-phone": "fa-phone",
        "pc-email": "fa-envelope",
        "pc-sms": "fa-sms",
        "pc-message": "fa-comment-dots",
        "pc-users": "fa-users",
        "pc-pending-request": "fa-hourglass-half",
        "pc-send": "fa-arrow-circle-right",
        "pc-send-back": "fa-reply",
        "pc-resend": "fa-share",
        "pc-participant-verified": "fa-badge-check",
        "pc-file-export": "fa-arrow-to-top",
        "pc-file-import": "fa-arrow-to-bottom",
        "pc-dashboard": "fa-tachometer-fast",
        "pc-ledger": "fa-abacus",
        "pc-staff-member": "fa-user-crown",
        "pc-disable-user": "fa-user-lock",
        "pc-enable-user": "fa-user-unlock",
        "pc-owner-user": "fa-crown",
        "pc-legal": "fa-file-contract",
        "pc-ui-components": "fa-palette",
        "pc-export-bank-form": "fa-file-export",
        "pc-warning": "fa-exclamation-triangle",
        "pc-notice-management": "fa-newspaper",
        "pc-notice-approve": "fa-box-open",
        "pc-notice-archive": "fa-file-check",
        "pc-notice-reject": "fa-parking-circle-slash",
        "pc-info-circle": "fa-info-circle",
        "pc-calendar": "fa-calendar",
        "pc-circle-notch-spin": "fa-circle-notch fa-spin",
        "pc-spinner": "fa-spinner-third fa-spin fa-swap-opacity",
        "pc-user-group": "fa-user-friends",
      },
    };
  },

  computed: {
    classPrefix: function () {
      if (this.duotone) return "fad";
      if (this.outline) return "far";
      if (this.light) return "fal";
      return "fas";
    },
    iconClass: function () {
      if (this.iconClasses[this.name]) return this.iconClasses[this.name];
      return null;
    },
    styles: function () {
      let styles = "";

      if (this.width) {
        styles = styles + "width: " + this.width + "px;";
      }

      if (this.height) {
        styles = styles + "height: " + this.height + "px";
      }

      return styles;
    },
  },
};
</script>

<style lang="scss" scoped>
.fad:after {
  background-color: transparent !important;
  content: "\10f3f4" !important;
  display: block !important;
  opacity: 1 !important;
  position: static !important;
  -webkit-transform: unset !important;
}
</style>
