<template>
  <v-row>
    <v-col class="pt-0">
      <v-autocomplete
        :color="$colors.penChecksTeal"
        v-model="selectedParents"
        :items="parentItems"
        item-text="text"
        item-value="value"
        :menu-props="{ offsetY: true }"
        :dense="dense"
        :deletable-chips="deletableChips"
        :small-chips="smallChips"
        :multiple="multiple"
        :label="parentLabel"
        @change="handleParentChange"
      ></v-autocomplete>
    </v-col>
    <v-col class="pt-0">
      <v-autocomplete
        :color="$colors.penChecksTeal"
        v-model="selectedChildren"
        :items="childItems"
        item-text="text"
        item-value="id"
        :menu-props="{ offsetY: true }"
        :dense="dense"
        :deletable-chips="deletableChips"
        :small-chips="smallChips"
        :multiple="multiple"
        :label="childLabel"
        return-object
        @change="emitFilterValue"
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
    },
    parentLabel: {
      type: String,
      default: "Search",
    },
    childLabel: {
      type: String,
      default: "Search",
    },
    items: {
      type: Array,
      required: true,
    },
    dense: {
      type: Boolean,
    },
    multiple: {
      type: Boolean,
    },
    smallChips: {
      type: Boolean,
    },
    deletableChips: {
      type: Boolean,
      default: true,
    },
  },

  data: function () {
    return {
      selectedParents: [],
      selectedChildren: [],
    };
  },

  computed: {
    // The items prop can contain two objects in the array that have a duplicate value property.
    // This caused a bug where the "Status Details" dropwdown options would not be correct. See TECH-5904 for more detials.
    // This computed property adds a unique id to each item to avoid this problem.
    itemsWithAddedId() {
      const itemsWithAddedId = [];

      this.items.forEach((item) => {
        const itemWithId = {
          ...item,
          id: `${item.parent ? item.parent + "/" : ""}${item.value}`,
        };

        itemsWithAddedId.push(itemWithId);
      });

      return itemsWithAddedId;
    },
    parentItems() {
      const parentItems = [];

      this.itemsWithAddedId.forEach((item) => {
        if (this.isParent(item)) {
          parentItems.push(item);
        }
      });

      return parentItems;
    },
    childItems() {
      if (this.selectedParents.length === 0) return [];

      const childItems = [];

      this.itemsWithAddedId.forEach((item) => {
        // skip if item is a parent
        if (this.isParent(item)) return;

        // if the item's parent is selected, create new child item with updated display text
        if (this.selectedParents.includes(item.parent)) {
          const parentText = this.$options.filters.sentenceCase(
            item.id.split("/")[0]
          );

          const childText = this.$options.filters.sentenceCase(
            item.id.split("/")[1]
          );

          const childItemWithUpdatedDisplayText = {
            ...item,
            text: `${parentText} / ${childText}`,
          };

          childItems.push(childItemWithUpdatedDisplayText);
        }
      });

      return childItems;
    },
  },

  methods: {
    handleParentChange() {
      // When a parent is de-selected, filter out selectedChildren whose parent is no longer selected
      const newSelectedChildren = this.selectedChildren.filter((child) =>
        this.selectedParents.includes(child.parent)
      );

      this.selectedChildren = newSelectedChildren;
      this.emitFilterValue();
    },
    initSelectedValues() {
      if (!this.value || this.value.length === 0) return;

      this.value.forEach((filterValue) => {
        const foundItem = this.itemsWithAddedId.find(
          (item) => item.id === filterValue
        );

        if (this.isParent(foundItem)) {
          // selectedParents values are the item.value property
          this.selectedParents.push(foundItem.value);
        } else {
          // selectedChildren values are the entire item object because there are various
          // operations where properties of the selected child are needed
          this.selectedChildren.push(foundItem);
        }
      });
    },
    emitFilterValue() {
      const newFilterValue = [...this.selectedParents];

      this.selectedChildren.forEach((child) => {
        newFilterValue.push(child.id);
      });

      this.$emit("input", newFilterValue);
    },
    isParent(item) {
      // if item doesn't have a parent, it's a parent item
      return !item.parent;
    },
  },

  mounted: function () {
    this.initSelectedValues();
  },
};
</script>

<style lang="scss" scoped></style>
