<template>
  <div class="pc-breadcrumbs">
    <span
      v-for="(item, $index) in items"
      :key="item.value"
      class="pc-breadcrumbs__item"
    >
      <pc-icon
        v-if="$index > 0"
        name="pc-angle-right"
        size="12"
        style="margin-right: 10px"
      ></pc-icon>

      <router-link v-if="item.to" :to="item.to">{{ item.text }}</router-link>
      <a v-else-if="item.href" :href="item.href">{{ item.text }}</a>
      <span v-else :class="{ active: $index == items.length - 1 }">{{
        item.text
      }}</span>
    </span>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    return {};
  },

  mounted: function () {},
};
</script>

<style lang="scss">
.pc-breadcrumbs {
  .pc-breadcrumbs__item {
    font-size: 12px;
    margin-right: 10px;

    a {
      color: $penChecksMediumGray;
      text-decoration: none;
    }

    span.active {
      color: $penChecksTeal !important;
      font-weight: 700;

      border-bottom: solid $penChecksNavyBlue 1px;
    }
  }
}
</style>
