<template>
  <v-radio
    :color="color"
    :disabled="disabled"
    :id="id"
    :value="value"
    :readonly="readonly"
    @mouseup="$emit('click')"
    class="pc-radio-button"
  >
    <template v-slot:label>
      <template v-if="hasSlot('label')">
        <slot name="label"></slot>
      </template>
      <template v-else>
        <div class="pc-radio-label-container">
          <div class="pc-radio-label-content">
            {{ label }}
          </div>
          <div class="pc-radio-description" v-if="description">
            {{ description }}
          </div>
        </div>
      </template>
    </template>
  </v-radio>
</template>

<script>
export default {
  props: {
    value: {},
    disabled: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    description: {
      type: String,
    },
    id: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#3D8A9E",
    },
  },
  methods: {
    hasSlot: function (name) {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
  },
};
</script>

<style lang="scss" scoped>
.pc-radio-button {
  display: flex;
  align-items: flex-start;
}

.pc-radio-label-content {
  padding-top: 2px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 20px;
  display: block;
}

.pc-radio-description {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 22px;
  color: $penChecksDarkGray;
  display: block;
  margin-top: 3px;
}

.pc-radio-label-container {
  padding-left: 4px;
  text-align: left;
}

.theme--light.v-radio--is-disabled {
  .pc-radio-label-content {
    color: rgba(0, 0, 0, 0.38);
  }

  .pc-radio-description {
    color: rgba(0, 0, 0, 0.38);
  }
}
</style>
