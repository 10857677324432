<template>
  <v-row class="py-0 my-o">
    <v-col class="col-4 mr-4">
      <v-autocomplete
        :color="$colors.penChecksTeal"
        v-model="selectedColumns"
        :items="availableColumns"
        :menu-props="{ maxHeight: '400', offsetY: true }"
        class="ml-6 mb-0 mt-0 pa-0"
        @focus="columnsFocused = true"
        @blur="columnsFocused = false"
        multiple
      >
        <template
          v-if="!columnsFocused"
          v-slot:selection="{ eslintIgnore_item, index }"
          >{{
            index == 0
              ? "Show/Hide Columns (" +
                selectedColumns.length +
                " of " +
                availableColumns.length +
                " showing)"
              : ""
          }}</template
        >
        <template v-else v-slot:selection="{}"></template>
      </v-autocomplete>
    </v-col>
    <v-col>
      <pc-button @click="resetColumnsToDefault" outlined small
        >Reset Columns to Default</pc-button
      >
    </v-col>
  </v-row>
</template>

<script>
import SearchRequestHandler from "@/shared/lib/client-sdk/search/search-request-handler";

export default {
  name: "PcFieldSelector",

  props: {
    searchRequestHandler: {
      type: SearchRequestHandler,
      require: true,
    },
  },

  data: () => {
    return {
      selectedColumns: [],
      columnsFocused: false,
    };
  },

  watch: {
    selectedColumns: {
      handler: function () {
        this.saveToLocalStorage();

        this.$emit("selectedColumnsChanged", this.selectedColumns);
      },
      deep: true,
    },
  },

  computed: {
    localStorageKeyPrefix: function () {
      var url = window.location.href;

      if (url.includes("?")) url = url.split("?")[0];

      if (url.includes("#query:")) url = url.split("#query:")[0];

      // Remove any IDs from the URL, so it doesn't change each time
      url = url.replace(/\b[a-zA-Z0-9]{32}\b/gi, "{id}");

      var requestUrl = this.searchRequestHandler.requestUrl;

      if (requestUrl.includes("?")) requestUrl = requestUrl.split("?")[0];

      if (requestUrl.includes("#query:"))
        requestUrl = requestUrl.split("#query:")[0];

      // Remove any IDs from the URL, so it doesn't change each time
      requestUrl = requestUrl.replace(/\b[a-zA-Z0-9]{32}\b/gi, "{id}");

      return (
        "adminSearch_" +
        this.searchRequestHandler.modelClassName +
        "_" +
        url +
        "_" +
        requestUrl +
        "_"
      );
    },
    availableColumns: function () {
      var columns = [];

      for (
        var i = 0;
        i < this.searchRequestHandler.searchableFields.length;
        i++
      ) {
        const field = this.searchRequestHandler.searchableFields[i];

        if (field.isFieldDisplayedInResults)
          columns.push({
            value: field.resultKey,
            text: field.displayName,
          });
      }

      return columns;
    },
  },

  methods: {
    resetColumnsToDefault: function () {
      this.selectedColumns = [];
      for (
        var i = 0;
        i < this.searchRequestHandler.searchableFields.length;
        i++
      ) {
        const field = this.searchRequestHandler.searchableFields[i];

        if (
          field.isFieldDisplayedInResults &&
          field.isFieldDisplayedInResultsByDefault
        )
          this.selectedColumns.push(field.resultKey);
      }
    },
    searchComplete: function () {
      if (this.selectedColumns.length == 0) {
        this.loadFromLocalStorage();
      }

      if (this.selectedColumns.length == 0) {
        this.resetColumnsToDefault();
      }

      this.saveToLocalStorage();
    },

    saveToLocalStorage: function () {
      window.localStorage.setItem(
        this.localStorageKeyPrefix + "_selectedColumns",
        JSON.stringify(this.selectedColumns)
      );
    },
    loadFromLocalStorage: function () {
      var selectedColumns = window.localStorage.getItem(
        this.localStorageKeyPrefix + "_selectedColumns"
      );

      if (
        selectedColumns &&
        JSON.parse(selectedColumns) &&
        JSON.parse(selectedColumns).length > 0
      ) {
        this.selectedColumns = JSON.parse(selectedColumns);
      }
    },
  },

  mounted: function () {
    this.searchRequestHandler.onSearchComplete = this.searchComplete.bind(this);
    this.searchComplete();
  },
};
</script>

<style lang="scss"></style>
