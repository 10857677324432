import Vue from "vue";

var moment = require("moment");

Vue.filter("date", function (value, format = "MM/DD/YYYY") {
  if (!value) return "";

  return moment(value).format(format);
});

Vue.filter("formatDate", function (value, format = "MM/DD/YYYY") {
  if (!value) return "";

  return moment(value).format(format);
});

Vue.filter("formatDateAndTime", function (value, format = "MM/DD/YYYY LT") {
  if (!value) return "";

  return moment(value).format(format);
});

Vue.filter(
  "formatDateAndFullTime",
  function (value, format = "MM/DD/YYYY LTS") {
    if (!value) return "";

    return moment(value).format(format);
  }
);

Vue.filter("relativeDate", function (value) {
  if (!value) return "";

  return moment.utc(value).fromNow();
});

Vue.filter("yearsAgo", function (value, includeDecimal) {
  return Date.yearsAgo(value, includeDecimal);
});

Date.yearsAgo = function (value, includeDecimal) {
  if (value == null || value.trim() == "") return null;

  var newDate = Date.toIsoDateString(value);

  if (newDate) {
    var years = moment().diff(moment(newDate), "years");

    if (includeDecimal) {
      var months = moment().diff(moment(newDate), "months");
      months = months - years * 12;

      var decimal = "";
      if (months == 1 || months == 2) decimal = "." + months;
      if (months >= 3 && months <= 9) decimal = "." + (months - 1);
      if (months == 10 || months == 11) decimal = "." + (months - 2);

      years = years + decimal;
    }
    return years;
  }

  return null;
};

Date.toIsoDateString = function (dateString) {
  var components = Date.dateComponents(dateString, true);
  if (components) {
    if (components["year"] && components["month"] && components["day"]) {
      return (
        components["year"] + "-" + components["month"] + "-" + components["day"]
      );
    } else if (components["year"] && components["month"]) {
      return components["year"] + "-" + components["month"];
    } else if (components["year"]) {
      return components["year"];
    }
  }

  return null;
};

Date.toDateStringWithSlashes = function (dateString) {
  var components = Date.dateComponents(dateString, false);
  if (components) {
    if (components["month"] && components["day"] && components["year"]) {
      return (
        components["month"] + "/" + components["day"] + "/" + components["year"]
      );
    } else if (components["month"] && components["day"]) {
      return components["month"] + "/" + components["day"];
    } else if (components["month"]) {
      return components["month"];
    }
  }

  return null;
};

Date.dateComponents = function (dateString, assumeIso) {
  if (!dateString || dateString.trim() == "") return null;

  var components = {
    year: null,
    month: null,
    day: null,
  };

  if ((assumeIso && !dateString.includes("/")) || dateString.includes("-")) {
    if (dateString.includes("T"))
      dateString = dateString.substr(0, dateString.indexOf("T"));

    let numbersOnly = dateString.replace(/[^\d-]/g, "");

    let values = numbersOnly.split("-");

    if (values.length > 2) {
      components["day"] = String(values[2]);
    }

    if (values.length > 1) {
      components["month"] = values[1];

      if (components["day"])
        components["month"] = parseInt(components["month"])
          .toString()
          .padStart(2, "0")
          .substr(0, 2);
    }

    components["year"] = values[0];

    if (components["month"])
      components["year"] = parseInt(components["year"])
        .toString()
        .padStart(4, "0")
        .substr(0, 4);
  } else if (
    (!assumeIso && !dateString.includes("-")) ||
    dateString.includes("/")
  ) {
    let numbersOnly = dateString.replace(/[^\d/]/g, "");

    let values = numbersOnly.split("/");

    if (values.length > 2) {
      components["year"] = String(values[2]);
    }

    if (values.length > 1) {
      components["day"] = values[1];

      if (components["year"])
        components["day"] = parseInt(components["day"])
          .toString()
          .padStart(2, "0")
          .substr(0, 2);
    }

    components["month"] = values[0];

    if (components["day"])
      components["month"] = parseInt(components["month"])
        .toString()
        .padStart(2, "0")
        .substr(0, 2);
  }

  if (components["year"] || components["month"] || components["day"]) {
    components["year"] =
      !components["year"] || components["year"].startsWith("N")
        ? null
        : components["year"];
    components["month"] =
      !components["month"] || components["month"].startsWith("N")
        ? null
        : components["month"];
    components["day"] =
      !components["day"] || components["day"].startsWith("N")
        ? null
        : components["day"];
  }

  if (components["year"] || components["month"] || components["day"])
    return components;

  return null;
};
