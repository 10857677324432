"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import InboundParsedFilePdfPageLineBlock from "@/shared/lib/client-sdk/models/inbound-parsed-file-pdf-page-line-block";

class InboundParsedFilePdfExtractedImage extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "InboundParsedFilePdfExtractedImage";
  }

  static getApiPathForSearch() {}

  getApiPathForCreate() {}

  getApiPathForRefreshUpdateAndDelete() {}

  getAdminPath() {}

  get searchResultTitle() {
    return this.imageType;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  get relationships() {
    return {
      textBlocks: InboundParsedFilePdfPageLineBlock,
    };
  }
}

export default InboundParsedFilePdfExtractedImage;
