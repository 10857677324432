import { render, staticRenderFns } from "./PcDropdownMultiple.vue?vue&type=template&id=bb58785a"
import script from "./PcDropdownMultiple.vue?vue&type=script&lang=js"
export * from "./PcDropdownMultiple.vue?vue&type=script&lang=js"
import style0 from "./PcDropdownMultiple.vue?vue&type=style&index=0&id=bb58785a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VDivider,VListItem,VListItemAction,VListItemContent,VListItemTitle,VSelect})
