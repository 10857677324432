import ApiRequest from "@/shared/lib/client-sdk/api-request";
import $ from "jquery";

function getFeatureFlag(code) {
  const requestUrl =
    window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
    "/public/feature-flag";

  const requestData = $.param({ Code: code });

  const result = new Promise((resolve, reject) => {
    ApiRequest.send("GET", requestUrl, requestData).onComplete((response) => {
      if (response.successful) {
        resolve(response.result);
      } else {
        reject(console.warn("There was an error checking the feature flag."));
      }
    });
  });

  return result;
}

export default getFeatureFlag;
