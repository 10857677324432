<template>
  <span class="copyable-static-value">
    <pc-tooltip value="Click to copy">
      <a href="javascript:void(0)" @click.stop.prevent="valueClicked">
        <slot></slot>
      </a>
    </pc-tooltip>
    <input
      type="text"
      v-model="valueToCopy"
      v-if="copying"
      style="opacity: 0; width: 10px; height: 1px; position: absolute"
    />
  </span>
</template>

<script>
import $ from "jquery";

export default {
  data: function () {
    return {
      copying: false,
    };
  },
  methods: {
    valueClicked: function () {
      this.copying = true;

      this.$nextTick(() => {
        $(this.$el).children("input").focus();
        $(this.$el).children("input").select();
        document.execCommand("copy");
        this.copying = false;

        this.$snackbar.show("'" + this.valueToCopy + "' copied to clipboard.");
      });
    },
  },

  computed: {
    valueToCopy: function () {
      return this.$slots.default[0].text.trim();
    },
  },

  mounted: function () {},
};
</script>

<style lang="scss">
.copyable-static-value {
  a,
  a:link,
  a:visited {
    text-decoration: none;
    color: inherit;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-color: $penChecksGray;
  }

  &.has-error {
    color: $penChecksRed;
  }
}
</style>
