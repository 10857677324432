<template>
  <div class="pc-toggle">
    <div v-if="label" class="pc-label-toggle">{{ label }}</div>
    <v-btn-toggle
      activeClass="pc-btn-toggle--active"
      v-model="selectedIndex"
      class="pc-btn-group-toggle"
    >
      <v-btn
        v-for="item in items"
        :key="item.value"
        :ripple="false"
        :width="buttonsWidth"
        class="pc-btn-toggle"
        :disabled="disabled"
        :id="setId(item.text, item.value)"
      >
        <span class="pc-toggle-btn-text">{{ item.text }} </span>
      </v-btn>
    </v-btn-toggle>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
    },
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
    },
    buttonsWidth: {
      type: Number,
      default: 120,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setId: function (itemText, itemValue) {
      let camelCasedItemText = itemText ? itemText.toCamelCase() : undefined;
      let camelCasedItemValue = itemValue ? itemValue.toCamelCase() : undefined;
      let toggleButtonSufix = camelCasedItemText
        ? camelCasedItemText
        : camelCasedItemValue;
      let toggleButtonId = toggleButtonSufix
        ? toggleButtonSufix + "ToggleButton"
        : undefined;
      return toggleButtonId;
    },
  },
  computed: {
    selectedIndex: {
      get() {
        let valueIndex = null;
        if (!this.value) return valueIndex;
        this.items.forEach((item, itemIndex) => {
          if (stringsAreEqual(item.value, this.value)) {
            valueIndex = itemIndex;
          }
        });
        return valueIndex;
      },
      set(value) {
        if (this.items[value]) {
          this.$emit("input", this.items[value].value);
        }
      },
    },
  },
};

const stringsAreEqual = function (firstString, secondString) {
  return firstString.trim().toLowerCase() === secondString.trim().toLowerCase();
};
</script>

<style lang="scss" scoped>
.pc-label-toggle {
  font-size: 12px;
  color: $penChecksDarkGray;
  margin-bottom: 4px;
}

.pc-btn-toggle {
  height: 32px !important;
  padding: 12px !important;
  margin: 0px !important;
  min-width: 64px !important;
  background-color: transparent;
  transition-duration: 0s;
}

.pc-btn-toggle--active {
  background-color: $penChecksLightTeal !important;
  color: white !important;
}

.pc-btn-group-toggle {
  padding: 0px !important;
  margin: 0px !important;
  border-radius: 4px;
  border-color: transparent !important;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: $penChecksTeal !important;
}

.pc-toggle-btn-text {
  color: $penChecksTeal;
}
</style>
