<template>
  <v-combobox
    :search-input.sync="search"
    :color="$colors.penChecksMediumGray"
    :items="items"
    :label="label"
    :hide-no-data="slotName != 'no-data'"
    :menu-props="{
      contentClass: 'pc-dropdown-menu',
      closeOnContentClick: true,
    }"
    @focus="$emit('focus')"
    :item-text="itemText"
    :item-value="itemValue"
    ref="combobox"
    :clearable="clearable"
    class="pc-dropdown"
    v-model="selectValue"
    :loading="loading"
    v-click-outside-catch="onClickOutside"
    :error="clickOutsideError || error"
    :error-messages="errorMessages"
    @click="onInputClicked"
    @click:clear="onInputClear"
    :disabled="disabled"
    :placeholder="placeholder"
    @keydown.enter="$emit('enter', search)"
    :id="idPrefix"
    :dense="dense"
    :required="required"
    :rules="dropdownRules"
  >
    <template :slot="slotName">
      <div
        v-if="showAddItemButton"
        @click="onClickOutside"
        class="pc-dropdown-add-item"
        :id="setId('AddItemMenuList')"
      >
        {{ search }}
        <pc-button
          @click="onAddItemClicked"
          :tooltipText="'Adds new item'"
          icon-text
        >
          <pc-icon name="pc-add"></pc-icon>
          ADD NEW
        </pc-button>
      </div>

      <div @click="onClickOutside">
        <v-divider
          v-if="search && addNewItem && showAddItemButton"
          class="pc-dropdown-divider"
        ></v-divider>
        <div>
          <span
            v-if="listTitle"
            class="pc-dropdown-list-title"
            :id="setId('MenuListTitle')"
          >
            {{ listTitle }}
          </span>
        </div>
        <div
          v-if="slotName === 'no-data'"
          class="pc-dropdown-no-results"
          :id="setId('MenuNoDataText')"
        >
          <span class="pc-dropdown-no-data">{{ noDataText }}</span>
        </div>
      </div>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item
        :disabled="item.disabled"
        class="disabledItem"
        @click="onListItemClicked(item)"
      >
        <pc-tooltip
          v-if="item.disabled"
          :value="item.disabledToolTipText"
          bottom
        >
          <div
            :id="setId('MenuListOption')"
            class="disabled-pc-dropdown-list-item"
          >
            {{ item[itemText] }}
          </div>
        </pc-tooltip>
        <div v-else :id="setId('MenuListOption')" class="pc-dropdown-list-item">
          {{ item[itemText] }}
        </div>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import PcButton from "@/shared/components/new-components/PcButton";

export default {
  component: {
    PcButton,
  },
  props: {
    label: {
      type: String,
    },
    items: {
      type: Array,
    },
    listTitle: {
      type: String,
      default: "",
    },
    addNewItem: {
      type: Boolean,
      default: false,
    },
    value: {
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: function () {
        return [];
      },
    },
    defaultValue: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: "No results",
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    placeholder: {
      type: String,
      default: "",
    },
    idPrefix: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
    },
  },
  methods: {
    onAddItemClicked: function () {
      this.modelValue = this.search;
      this.selectValue = this.search;
      this.$emit("click:add-item", this.search);
    },
    onListItemClicked: function (item) {
      if (item.disabled) return;
      this.modelValue = item;
      this.selectValue = item;
      this.hideError();
    },
    onClickOutside: function () {
      let modelValue = this.modelValue?.[this.itemText] || this.modelValue;
      let modelIsNotUpdated =
        this.search !== modelValue && this.search != "" && this.search != null;
      let showErrorRegularSelect =
        !this.addNewItem && (this.slotName === "no-data" || modelIsNotUpdated);
      let showErrorAddItemSelect = this.addNewItem && modelIsNotUpdated;

      if (
        (showErrorRegularSelect && this.search != null) ||
        showErrorAddItemSelect
      ) {
        this.clickOutsideError = true;
      }
      if (this.search === "") {
        this.onInputClear();
      }
    },
    hideError: function () {
      this.clickOutsideError = false;
    },
    onInputClicked: function () {
      this.hideError();
      this.slotName = "prepend-item";
    },
    onInputClear: function () {
      this.selectValue = "";
      this.modelValue = "";
    },
    setId: function (idSufix) {
      let completeId = this.idPrefix ? this.idPrefix + idSufix : undefined;
      return completeId;
    },
  },
  data: function () {
    return {
      search: "",
      slotName: "prepend-item",
      selectValue: null,
      clickOutsideError: false,
      defaultRules: [(v) => !!v || "Please select an option"],
    };
  },
  watch: {
    search: function () {
      let prependItemVisible = this.$refs?.combobox?.computedItems.length > 0;
      this.slotName = prependItemVisible ? "prepend-item" : "no-data";
      this.$emit("update:search", this.search);
    },
    value(newValue) {
      this.selectValue = newValue;
    },
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        this.$emit("change", value);
      },
    },
    showAddItemButton: function () {
      return (
        this.search &&
        this.addNewItem &&
        this.search !== this.selectValue?.[this.itemText]
      );
    },
    dropdownRules: function () {
      if (!this.required) return [];
      if (this.rules) return this.rules;

      return this.defaultRules;
    },
  },
  mounted: function () {
    if (this.modelValue) {
      this.selectValue = this.modelValue;
    }
    if (this.defaultValue) {
      this.onListItemClicked(this.defaultValue);
    }
  },
  components: {
    "pc-button": PcButton,
  },
};
</script>

<style lang="scss">
.pc-dropdown-add-item {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100% !important;
  padding-left: 16px !important;
  padding-bottom: 8px;
  padding-right: 8px;
  color: $penChecksDarkGray;
}

.pc-dropdown-list-title {
  font-family: "Roboto", sans-serif;
  font-size: 12px !important;
  color: $penChecksMediumGray;
  font-weight: 500;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.pc-dropdown-divider {
  border-top-width: 1px;
}

.v-application .pc-dropdown-menu .primary--text {
  background-color: $penChecksLightTeal !important;
  color: transparent !important;
}

.pc-dropdown-menu .theme--light.v-list-item:hover {
  background-color: $penChecksLight !important;
  color: transparent !important;
}

.pc-dropdown-menu .theme--light.v-list-item:focus {
  background-color: #d8e8ec !important;
  color: transparent !important;
}

.pc-dropdown-list-item {
  color: $penChecksDarkGray;
}

.pc-dropdown {
  label:not(.error--text).v-label--active {
    color: $penChecksDarkTeal !important;
  }
}

.pc-dropdown input {
  color: $penChecksDarkGray !important;
}

.pc-dropdown-no-results {
  color: $penChecksMediumGray;
  font-size: 16px;
  margin-left: 16px;
  font-family: "Roboto";
  font-weight: 500;
  margin-top: 5px;
}

.pc-dropdown-no-data {
  color: $penChecksDarkGray;
}

.disabledItem {
  pointer-events: all !important;
}

.disabledItem span {
  width: 100%;
}

.v-tooltip__content {
  text-align: center;
}

.disabled-pc-dropdown-list-item {
  color: rgba(0, 0, 0, 0.38);
}
</style>
