var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"static-address-value",class:{ 'has-error': _vm.required && !_vm.isValid }},[_c('div',{class:{
        'static-address-value-participant-style': _vm.isParticipantPortalField,
      }},[(_vm.label)?_c('strong',[_vm._v(" "+_vm._s(_vm.label)),_c('br')]):_vm._e(),(_vm.isAllMissing)?[(_vm.flagMissingData)?_c('span',{staticClass:"missing"},[_vm._v(" MISSING ")]):_c('span',[_vm._v(" None ")])]:[_c('div',{staticClass:"d-flex",class:{
            'static-address-value-address-style': _vm.isParticipantPortalField,
          }},[(_vm.attributeValue('attentionTo'))?_c('div',{staticClass:"mr-2"},[_vm._v(" Attention: ")]):_vm._e(),_c('div',[(_vm.attributeValue('attentionTo'))?[_c('div',{attrs:{"id":_vm.setId('AttentionToStaticValue')}},[_vm._v(" "+_vm._s(_vm.attributeValue("attentionTo"))+" ")])]:_vm._e(),(_vm.attributeValue('addressLine1'))?[_c('div',{class:{
                  'static-address-value-addressline1-value-style':
                    _vm.isParticipantPortalField,
                },attrs:{"id":_vm.setId('AddressLine1StaticValue')}},[_vm._v(" "+_vm._s(_vm.attributeValue("addressLine1"))+" ")])]:(_vm.flagMissingData)?_c('div',{staticClass:"missing"},[_vm._v(" [no address line 1] ")]):_vm._e(),(true || _vm.attributeValue('addressLine2'))?[_c('div',{class:{
                  'static-address-value-addressline2-value-style':
                    _vm.isParticipantPortalField,
                },attrs:{"id":_vm.setId('AddressLine2StaticValue')}},[_vm._v(" "+_vm._s(_vm.attributeValue("addressLine2"))+" ")]),(!_vm.isParticipantPortalField)?_c('br'):_vm._e()]:_vm._e(),(_vm.attributeValue('city'))?[_c('span',{attrs:{"id":_vm.setId('CityAddressStaticValue')}},[_vm._v(" "+_vm._s(`${_vm.attributeValue("city")}, `)+" ")])]:(_vm.flagMissingData)?_c('span',{staticClass:"missing"},[_vm._v(" [no city], ")]):_vm._e(),(_vm.attributeValue('region'))?[_c('span',{attrs:{"id":_vm.setId('RegionAddressStaticValue')}},[_vm._v(_vm._s(_vm.attributeValue("region")))])]:(_vm.flagMissingData && _vm.countryCode == 'US')?_c('span',{staticClass:"missing"},[_vm._v(" [no state] ")]):_vm._e(),(_vm.attributeValue('postalCode'))?[(_vm.isParticipantPortalField)?_c('span',[_vm._v(",")]):_vm._e(),_c('span',{attrs:{"id":_vm.setId('PostalCodeStaticValue')}},[_vm._v(_vm._s(` ${_vm.attributeValue("postalCode")}`))])]:(
                _vm.flagMissingData &&
                _vm.attributeValue('countryCode') &&
                _vm.attributeValue('countryCode').toLowerCase() == 'us'
              )?[(_vm.isParticipantPortalField)?_c('span',[_vm._v(",")]):_vm._e(),_c('span',{staticClass:"missing"},[_vm._v(" [no postal code] ")])]:_vm._e(),(_vm.attributeValue('countryCode'))?[(!_vm.isParticipantPortalField)?_c('br'):_vm._e(),(_vm.isParticipantPortalField)?_c('span',[_vm._v(", ")]):_vm._e(),_c('span',{attrs:{"id":_vm.setId('CountryCodeStaticValue')}},[_vm._v(_vm._s(_vm.attributeValue("countryCode")))])]:(_vm.flagMissingData)?[(_vm.isParticipantPortalField)?_c('span',[_vm._v(",")]):_vm._e(),_c('span',{staticClass:"missing"},[(!_vm.isParticipantPortalField)?_c('br'):_vm._e(),_vm._v("[no country] ")])]:_vm._e()],2)])]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }