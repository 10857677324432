"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";

import Account from "@/shared/lib/client-sdk/models/account";
import Plan from "@/shared/lib/client-sdk/models/plan";
import Order from "@/shared/lib/client-sdk/models/order";
import OrderLine from "@/shared/lib/client-sdk/models/order-line";
import PaymentInstruction from "@/shared/lib/client-sdk/models/payment-instruction";
import Payment from "@/shared/lib/client-sdk/models/payment";

class Anything extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);

    this._model = null;
  }

  static getModelClassName() {
    return "Anything";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/search-anything"
    );
  }

  getAdminPath() {
    if (!this.model) return;

    return this.model.getAdminPath();
  }

  getClientPath() {
    if (!this.model) return;

    return this.model.getClientPath();
  }

  get model() {
    if (!this._model) {
      if (this.type == "account") {
        this._model = new Account(this.object);
      } else if (this.type == "plan") {
        this._model = new Plan(this.object);
      } else if (this.type == "order") {
        this._model = new Order(this.object);
      } else if (this.type == "orderLine") {
        this._model = new OrderLine(this.object);
      } else if (this.type == "paymentInstruction") {
        this._model = new PaymentInstruction(this.object);
      } else if (this.type == "payment") {
        this._model = new Payment(this.object);
      }
    }

    return this._model;
  }

  get searchResultTitle() {
    if (!this.model) return undefined;

    return this.model.searchResultTitle;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    if (!this.model) return undefined;

    return this.model.searchResultSubtitle;
  }

  get searchResultCreatedAt() {
    if (!this.model) return undefined;

    return this.model.searchResultCreatedAt;
  }

  get searchResultStatus() {
    if (!this.model) return undefined;

    return this.model.searchResultStatus;
  }
}

export default Anything;
