<template>
  <span
    style="display: inline-flex"
    class="pc-button-container"
    :class="{
      'with-menu': hasSlot('menu-items'),
      subtle: subtle,
      'full-width': block,
    }"
  >
    <pc-tooltip
      :disabled="!tooltip || tooltip.trim() == ''"
      v-model="tooltipText"
      :bottom="tooltipBottom"
      :right="tooltipRight"
      :top="tooltipTop"
      :left="tooltipLeft"
      style="width: 100%"
    >
      <v-btn
        class="pc-button"
        :class="{ small: small, normal: !small && !large, large: large }"
        :color="buttonColor"
        :x-small="small"
        :small="!large && !small"
        :text="text"
        :to="to"
        :outlined="outlined"
        :dark="!text && !outlined"
        :disabled="disabled"
        :style="buttonStyle"
        :target="target"
        :href="href"
        :block="block"
        :id="id"
        @click="clicked"
        @click.middle="clickedMiddle"
        elevation="0"
      >
        <slot></slot>
      </v-btn>

      <v-menu bottom left offset-y v-if="hasSlot('menu-items')">
        <template v-slot:activator="{ on }">
          <v-btn
            :class="{ small: small, normal: !small && !large, large: large }"
            :x-small="small"
            :small="!large && !small"
            :outlined="outlined"
            :dark="!text && !outlined"
            :disabled="disabled"
            :style="buttonStyle"
            :color="buttonColor"
            :block="block"
            :id="id + '_menu'"
            v-on="on"
            style="width: 20px !important; min-width: 20px !important"
            class="pa-0 pc-button-show-menu"
            elevation="0"
          >
            <pc-icon name="pc-dropdown-menu-arrow" size="12"></pc-icon>
          </v-btn>
        </template>
        <v-list>
          <slot name="menu-items"></slot>
        </v-list>
      </v-menu>
    </pc-tooltip>
  </span>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
    },
    larger: {
      type: Boolean,
    },
    large: {
      type: Boolean,
    },
    text: {
      type: Boolean,
    },
    to: {},
    outlined: {
      type: Boolean,
    },
    participantPortal: {
      type: Boolean,
    },
    subtle: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    color: {
      type: String,
    },
    id: {
      type: String,
    },
    fixedWidth: {
      type: Number,
    },
    block: {
      type: Boolean,
    },
    tooltip: {
      type: String,
    },
    tooltipBottom: {
      type: Boolean,
    },
    tooltipRight: {
      type: Boolean,
    },
    tooltipLeft: {
      type: Boolean,
    },
    tooltipTop: {
      type: Boolean,
    },
    href: {
      type: String,
    },
    target: {
      type: String,
    },
  },

  data: () => {
    return {};
  },

  computed: {
    buttonColor: function () {
      return this.color || this.$colors.penChecksTeal;
    },
    buttonStyle: function () {
      var style = "";
      if (this.fixedWidth || this.larger) {
        style = this.fixedWidth
          ? "width: " + this.fixedWidth + "px !important; "
          : "";
        style += this.larger ? "height: 40px !important; " : "";
      }

      if (this.disabled)
        return (
          style +
          "background-color: " +
          $colors.penChecksLightGray +
          " !important; color: " +
          $colors.penChecksGray +
          " !important"
        );

      if (this.outlined)
        return (
          style +
          "background-color: " +
          $colors.white +
          " !important; border: 1px solid " +
          this.color +
          ";"
        );

      if (this.participantPortal)
        return style + "color: " + $colors.neutral90 + " !important; " + ";";

      return style;
    },
    tooltipText: function () {
      if (this.tooltip && this.tooltip.trim() != "") return this.tooltip;

      return "";
    },
  },

  methods: {
    clicked: function (event) {
      if (event.ctrlKey) {
        this.$emit("ctrlClick", event);
      } else {
        this.$emit("click", event);
      }
    },
    clickedMiddle: function (event) {
      this.$emit("middleClick", event);
    },
    hasSlot(name) {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
  },
};
</script>

<style lang="scss">
.pc-button-container.full-width,
.pc-button-container.full-width > span {
  width: 100%;
}

.pc-button-container.with-menu {
  .pc-button {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 0px;
  }

  .pc-button-show-menu {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.v-btn.v-size--x-small {
  height: 24px !important;
  font-size: 12px !important;
}

.v-btn.v-size--small {
  height: 32px !important;
  font-size: 14px !important;
}

.v-btn.v-size--default {
  height: 46px !important;
  font-size: 14px !important;
}
.v-btn {
  letter-spacing: 0px;
}
</style>
