<template>
  <v-text-field
    :id="id"
    v-model="password"
    :rules="passwordRules"
    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
    :type="showPassword ? 'text' : 'password'"
    :label="label + (required ? '' : ' (optional)')"
    :disabled="disabled"
    :color="$colors.penChecksTeal"
    @click:append="showPassword = !showPassword"
    validate-on-blur
    :hint="validateFormat ? hint : ''"
    :persistent-hint="persistentHint"
    @keyup.enter="$emit('enter', $event)"
  ></v-text-field>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      default: "Password",
    },
    id: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    validateFormat: {
      type: Boolean,
    },
    hint: {
      type: String,
      default:
        "Minimum 8 characters, one capital letter, one number, one special character",
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      showPassword: false,
      password: this.value,
      passwordRules: [
        (v) =>
          (this.required ? !!v : true) ||
          "Please enter " + this.label.toLowerCase(),
        (v) =>
          (this.validateFormat
            ? v
              ? /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(
                  v
                )
              : true
            : true) || this.hint,
      ],
    };
  },

  watch: {
    password(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.password = newValue;
    },
  },

  methods: {},
};
</script>

<style lang="scss"></style>
