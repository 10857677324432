import Vue from "vue";
import Vuex from "vuex";
import User from "@/shared/lib/client-sdk/models/user";
import $ from "jquery";
import SystemPropertiesModule from "@/shared/store/modules/system-properties.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authenticatedUser: null,
    isLoadingAuthenticatedUser: true,
  },
  mutations: {
    setAuthenticatedUser(state, user) {
      state.authenticatedUser = user;
    },
    removeAuthenticatedUser(state) {
      state.authenticatedUser = null;
    },
    isLoadingAuthenticatedUser(state, loading) {
      state.isLoadingAuthenticatedUser = loading;
    },
  },
  actions: {
    refreshNationalRegistryAuthenticatedUser(context, callback) {
      context.commit("isLoadingAuthenticatedUser", true);

      User.fetchNationalRegistryAuthenticatedUser(
        function (user) {
          context.commit("isLoadingAuthenticatedUser", false);

          if (!user) {
            context.commit("removeAuthenticatedUser");
          } else {
            context.commit("setAuthenticatedUser", user);
          }

          if ($.isFunction(callback)) callback(user);
        }.bind(this)
      );
    },
  },
  modules: {
    systemProperties: SystemPropertiesModule,
  },
});
