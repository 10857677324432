"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import OrderLineDocument from "@/shared/lib/client-sdk/models/order-line-document";

class OrderLineDetailDistributionService extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "OrderLineDetailDistributionService";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-lines"
    );
  }

  static getOutstandingLoanTypes(serviceCode) {
    var types = [
      {
        value: "loanIsAnOffset",
        text: "Loan is an offset",
      },
      {
        value: "loanIsADeemedDistribution",
        text: "Loan is a deemed distribution",
      },
    ];
    if (!serviceCode || serviceCode != "premierDistributions")
      types.push({
        value: "loanHasBeenAcceptedByNewEmployerPlanAsRollover",
        text: "Loan has been accepted by new employer plan as rollover",
      });
    return types;
  }

  getApiPathForCreate() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/orders/" +
      this.orderId +
      "/order-lines"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-line-detail-distribution-service/" +
      this.id
    );
  }

  getAdminPath() {
    return "/orders/" + this.id;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultTitle() {
    return this.id;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  get relationships() {
    return {
      firstDistributionNoticeDocument: OrderLineDocument,
      secondDistributionNoticeDocument: OrderLineDocument,
    };
  }

  get hasTaxAuthority() {
    return (
      (this.taxAuthorityStateCode && this.taxAuthorityStateCode.length > 0) ||
      (this.otherTaxAuthority && this.otherTaxAuthority.length > 0)
    );
  }

  get isDistributionFromRoth() {
    return this.retirementAccountTypeCode == "roth";
  }

  calculateGrossTaxableAmount() {
    const rothBasisAmount =
      this.rothBasisAmount && this.rothBasisAmount != null
        ? parseFloat(this.rothBasisAmount)
        : 0;
    const rothBalance =
      this.rothBalance && this.rothBalance != null
        ? parseFloat(this.rothBalance)
        : 0;
    const traditionalPreTaxAmount =
      this.traditionalPreTaxAmount && this.traditionalPreTaxAmount != null
        ? parseFloat(this.traditionalPreTaxAmount)
        : 0;
    // const traditionalPostTaxAmount =
    //   this.traditionalPostTaxAmount && this.traditionalPostTaxAmount != null
    //     ? parseFloat(this.traditionalPostTaxAmount)
    //     : 0;

    if (this.isDistributionFromRoth)
      return Math.max(0, rothBalance - rothBasisAmount);

    return Math.safeCurrencyValue(traditionalPreTaxAmount);
  }

  calculateGrossUntaxableAmount() {
    const rothBasisAmount =
      this.rothBasisAmount && this.rothBasisAmount != null
        ? parseFloat(this.rothBasisAmount)
        : 0;
    const rothBalance =
      this.rothBalance && this.rothBalance != null
        ? parseFloat(this.rothBalance)
        : 0;
    // const traditionalPreTaxAmount =
    //   this.traditionalPreTaxAmount && this.traditionalPreTaxAmount != null
    //     ? parseFloat(this.traditionalPreTaxAmount)
    //     : 0;
    const traditionalPostTaxAmount =
      this.traditionalPostTaxAmount && this.traditionalPostTaxAmount != null
        ? parseFloat(this.traditionalPostTaxAmount)
        : 0;

    if (this.isDistributionFromRoth)
      return Math.min(rothBalance, rothBasisAmount);

    return Math.safeCurrencyValue(traditionalPostTaxAmount);
  }

  calculateGrossDistributionAmount() {
    return Math.addAmounts(
      this.calculateGrossTaxableAmount(),
      this.calculateGrossUntaxableAmount()
    );
  }

  get isDistributionReasonDeath() {
    let distributionReasonCode = this.distributionReasonCode;
    return (
      distributionReasonCode == "death" ||
      distributionReasonCode == "rmdDeathBenefitToBeneficiary"
    );
  }
}

export default OrderLineDetailDistributionService;
