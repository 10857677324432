<template>
  <div class="static-file-value" :style="label ? 'padding: 12px;' : ''">
    <template v-if="label">
      <template v-if="singleLine">
        <strong>{{ label }}:</strong>
      </template>
      <template v-else>
        <strong>{{ label }}</strong>
        <br />
      </template>
    </template>

    <template>
      <pc-button
        v-if="isDownloadable"
        :disabled="!fileId"
        text
        small
        @click.stop="downloadFile()"
      >
        {{ buttonText ? buttonText : "Download" }}
        <pc-download-icon class="pl-2 paddingIcon" v-if="showIcons" size="16" />
      </pc-button>
      <pc-button
        v-else-if="isPrintable"
        :disabled="!fileId"
        text
        small
        @click.stop="printFile()"
      >
        {{ buttonText ? buttonText : "Print" }}
        <span v-if="showIcons" class="pl-2 paddingIcon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <rect
              width="16"
              height="16"
              fill="white"
              style="mix-blend-mode: multiply"
            />
            <path
              d="M14 4.5H12.5V1.5H3.5V4.5H2C1.73478 4.5 1.48043 4.60536 1.29289 4.79289C1.10536 4.98043 1 5.23478 1 5.5V10.5C1 10.7652 1.10536 11.0196 1.29289 11.2071C1.48043 11.3946 1.73478 11.5 2 11.5H3.5V14.5H12.5V11.5H14C14.2652 11.5 14.5196 11.3946 14.7071 11.2071C14.8946 11.0196 15 10.7652 15 10.5V5.5C15 5.23478 14.8946 4.98043 14.7071 4.79289C14.5196 4.60536 14.2652 4.5 14 4.5ZM4.5 2.5H11.5V4.5H4.5V2.5ZM11.5 13.5H4.5V8.5H11.5V13.5ZM14 10.5H12.5V7.5H3.5V10.5H2V5.5H14V10.5Z"
              fill="#0075DB"
            />
          </svg>
        </span>
      </pc-button>
      <pc-button
        v-else-if="isAccountHolder"
        text
        id="downloadAgreement"
        small
        @click.stop="downloadFile()"
      >
        Download agreement
        <pc-download-icon class="pl-2 paddingIcon" v-if="showIcons" size="16" />
      </pc-button>
      <pc-button
        v-else
        :disabled="!fileId"
        text
        small
        @click.stop="viewFile()"
        >{{ buttonText ? buttonText : "View" }}</pc-button
      >
      <pc-button
        :disabled="!fileId"
        v-if="allowsDelete"
        text
        small
        @click="$emit('delete')"
      >
        <pc-icon name="pc-delete" :size="12"></pc-icon>
      </pc-button>
      <span v-if="suffix">{{ suffix }}</span>
    </template>
  </div>
</template>
<script>
import PcDownloadIcon from "@/shared/components/design-system/PcDownloadIcon";
import PcExportIcon from "@/shared/components/design-system/PcExportIcon.vue";

export default {
  name: "PcStaticFileValueReskinned",

  components: {
    PcDownloadIcon,
    PcExportIcon,
  },

  props: {
    label: {
      type: String,
    },
    buttonText: {
      type: String,
    },
    suffix: {
      type: String,
    },
    fileId: {
      type: String,
    },
    showIcons: {
      type: Boolean,
    },
    isDownloadable: {
      type: Boolean,
    },
    isPrintable: {
      type: Boolean,
    },
    allowsDelete: {
      type: Boolean,
    },
    singleLine: {
      type: Boolean,
    },
    isAccountHolder: {
      type: Boolean,
    },
  },

  methods: {
    downloadFile() {
      window.location.href =
        window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
        "/public/files/" +
        this.fileId +
        "/download";
    },
    printFile() {
      var w = window.open(
        window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
          "/public/files/" +
          this.fileId +
          "/view"
      );
      w.print();
    },
    viewFile() {
      window.open(
        window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
          "/public/files/" +
          this.fileId +
          "/view"
      );
    },
  },
};
</script>

<style lang="scss">
.static-file-value {
  font-size: 14px;
}
.paddingIcon {
  padding-top: 5px;
}

#downloadAgreement {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: $neutral90 !important;
  text-decoration: none;
  text-transform: unset !important;
}
</style>
