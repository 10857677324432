"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";

class InboundParsedFilePdfPageLineBlock extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "InboundParsedFilePdfPageLineBlock";
  }

  static getApiPathForSearch() {}

  getApiPathForCreate() {}

  getApiPathForRefreshUpdateAndDelete() {}

  getAdminPath() {}

  get searchResultTitle() {
    return this.textContent;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }
}

export default InboundParsedFilePdfPageLineBlock;
