<template>
  <v-overlay
    :value="show"
    color="#ffffff"
    :opacity="opacity"
    style="z-index: 99999"
    :class="{ 'singleton-overlay-style': isParticipantPortalField }"
  >
    <div
      style="width: 400px"
      :style="'background-color: ' + $colors.white"
      class="pa-10 text-center"
      v-if="!isParticipantPortalField"
    >
      <div v-if="text" :style="'color: ' + $colors.penChecksDark" class="mb-4">
        {{ text }}
      </div>

      <v-progress-linear
        indeterminate
        :color="$colors.penChecksTeal"
      ></v-progress-linear>
    </div>
    <div v-else class="singleton-overlay-main-div-style">
      <v-row class="singleton-overlay-progress-row-style">
        <v-col class="singleton-overlay-progress-col-style">
          <div>
            <v-progress-circular
              indeterminate
              :size="48"
              :width="2"
              class="singleton-overlay-progress-style"
            >
            </v-progress-circular>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="text" class="singleton-overlay-text-row-style">
        <v-col class="singleton-overlay-text-col-style">
          <div>
            <span>{{ text }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="subtext" class="singleton-overlay-subtext-row-style">
        <v-col class="singleton-overlay-subtext-col-style">
          <div>
            <span>{{ subtext }}</span>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-overlay>
</template>

<script>
import Singletons from "@/shared/plugins/singletons";
import $ from "jquery";

export default {
  components: {},

  data: () => ({
    show: false,
    text: null,
    color: null,
    opacity: null,
    isParticipantPortalField: false,
  }),

  methods: {
    /**
     * Shows a fullscreen take over overlay.
     *
     * options (object or text)
     *  object { text, color, opacity }
     */
    showOverlay: function (options = {}) {
      // If we pass a single string, just use that as the message text
      if ($.type(options) === "string") options = { text: options };

      if (!$.isPlainObject(options)) options = {};

      this.show = true;
      this.text = options["text"] || null;
      this.subtext = options["subtext"] || null;
      this.color = options["color"] || null;
      this.opacity = options["opacity"] || 0.8;
      this.isParticipantPortalField =
        options["isParticipantPortalField"] || false;
    },
    /**
     * Hide the full screen overlay
     */
    hideOverlay: function () {
      this.show = false;
    },
  },

  mounted: function () {
    Singletons.Events.$on("showOverlay", this.showOverlay.bind(this));
    Singletons.Events.$on("hideOverlay", this.hideOverlay.bind(this));
  },
};
</script>

<style lang="scss">
.singleton-overlay-main-div-style {
  font-family: "Open Sans", sans-serif;

  .row {
    margin-left: 0px;
    margin-right: 0px;

    padding: 0px;
  }
  .col {
    margin-left: 0px;
    margin-right: 0px;

    padding: 0px;
  }

  height: auto;
  width: 100%;
  position: fixed;
  top: 87px;
  left: 0;
  bottom: 0;
  padding: 100px;
  background-color: $white;
  border-top: 1px solid $neutral10 !important;

  .singleton-overlay-progress-row-style {
    .singleton-overlay-progress-col-style {
      div {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;

        .singleton-overlay-progress-style {
          svg {
            color: $primary;

            circle.v-progress-circular__underlay {
              stroke: $primary10;
            }
            circle.v-progress-circular__overlay {
              stroke-linecap: round;
            }
          }
        }
      }
    }
  }

  .singleton-overlay-text-row-style {
    margin-top: 32px !important;

    .singleton-overlay-text-col-style {
      color: $neutral90 !important;
      font-size: 24px;
      font-weight: 600;
      text-align: center;

      div {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .singleton-overlay-subtext-row-style {
    margin-top: 24px !important;

    .singleton-overlay-subtext-col-style {
      color: $neutral70 !important;
      font-size: 16px;
      font-weight: 400;
      text-align: center;

      div {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.singleton-overlay-style {
  div.v-overlay--active {
    backdrop-filter: none !important;
    div.v-overlay__scrim {
      opacity: 0.01 !important;
      background-color: $white !important;
      border-collapse: $white !important;
      backdrop-filter: none !important;
    }
  }
}
</style>
