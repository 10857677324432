import { get as lodashGet } from "lodash";

export default class RuntimeConfiguration {
  constructor(properties) {
    this.properties = properties;
  }

  set(config) {
    this.runtimeConfig = config;
  }

  get(property) {
    return lodashGet(this.properties, property);
  }
}
