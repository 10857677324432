<template>
  <v-text-field
    :id="id"
    v-model="text"
    :type="type"
    :rules="
      required
        ? requiredInputRules.concat(defaultInputRules)
        : defaultInputRules
    "
    :label="inputLabel"
    :placeholder="placeholder"
    :validate-on-blur="true"
    :disabled="disabled"
    :clearable="clearable"
    :dense="dense"
    :errorMessages="errors"
    :color="$colors.penChecksTeal"
    :hint="hint"
    :persistent-hint="persistentHint"
    :append-icon="appendIcon"
    v-on="$listeners"
    @keyup.enter="$emit('enter', $event)"
    @change="$emit('change', text)"
    @click:clear="$emit('clear')"
    @blur="$emit('blur')"
    @focus="errors = null"
    ref="textfield"
    class="pc-routing-number"
  ></v-text-field>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    isContractNumber: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    appendIcon: {
      type: String,
    },
    id: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    errorMessages: {
      type: Array,
    },
    dense: {
      type: Boolean,
    },
    hint: {
      type: String,
    },
    persistentHint: {
      type: Boolean,
    },
    hideOptionalText: {
      type: Boolean,
    },
    maxLength: {
      type: Number,
      default: 9,
    },
  },
  data: function () {
    return {
      counter: 0,
      text: this.value,
      requiredInputRules: [
        (v) => !!v || "Please enter " + this.label.toLowerCase(),
      ],
      defaultInputRules: [
        (v) => {
          if (this.isContractNumber && v) {
            var numberOfDigits = v.trim().length;

            if (!this.isNumeric(v))
              return "Please only enter numbers in this field";

            if (this.maxLength < numberOfDigits)
              return "Number cannot not exceed " + this.maxLength + " digits";

            return true;
          } else {
            let isNumber = !Number.isNaN(v);
            let inputValue = isNumber ? String(v) : v;
            let incorrectLengthError = "Must be " + this.maxLength + " digits.";
            let isEmptyString = inputValue === "";
            let isNotLongEnough =
              typeof inputValue == "string" &&
              inputValue.length != this.maxLength;
            return isNotLongEnough && !isEmptyString
              ? incorrectLengthError
              : true;
          }
        },
      ],
      errors: null,
    };
  },
  methods: {
    isNumeric: function (value) {
      return /^\d+$/.test(value);
    },
  },
  computed: {
    inputLabel: function () {
      let inputLabel = "";
      let optionalText =
        this.required || this.hideOptionalText ? "" : " (optional)";

      if (this.showLabel) {
        inputLabel = this.label + optionalText;
      }

      return inputLabel;
    },
    inputRules: function () {
      return this.required
        ? this.requiredInputRules.concat(this.defaultInputRules)
        : this.defaultInputRules;
    },
  },
  watch: {
    value(newValue) {
      if (this.text != newValue) {
        this.text = newValue;
      }
    },
    errorMessages(errors) {
      this.errors = errors;
    },
  },
};
</script>

<style lang="scss">
.pc-routing-number {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
