import $ from "jquery";

// hijack the window.history.pushState function
if (history.pushState) {
  var existingHistoryPushState = history.pushState;

  window.onpushstate = null;
  history.pushState = function () {
    existingHistoryPushState.apply(history, arguments);
    if ($.isFunction(window.onpushstate)) window.onpushstate();
  };
}

class UrlHash {
  constructor() {
    this.value = null;
    this.suffix = null;

    window.onhashchange = this.changeDetected.bind(this);
    window.onpopstate = this.changeDetected.bind(this);
    window.onpushstate = this.changeDetected.bind(this);

    this.changeDetected();
  }

  changeDetected() {
    var fullValue =
      this.value && this.suffix ? this.value + ":" + this.suffix : this.value;

    if (fullValue != location.hash) {
      var previousSuffix = this.suffix;

      if (location.hash.includes(":")) {
        var pieces = location.hash.split(":");
        this.value = pieces[0];
        this.suffix = pieces[1];
      } else {
        this.value = location.hash;
        this.suffix = null;
      }

      // console.warn("HASH CHANGED TO:", this.value)

      if (previousSuffix != this.suffix) {
        // console.warn("HASH SUFFIX CHANGED TO:", this.suffix)
      }
    }
  }

  set(newHash, replaceHistoryInsteadOfAdding) {
    if (!newHash.startsWith("#")) {
      console.error("To set a hash, it must start with a hashtag", newHash);
      return;
    }

    if (window.location.hash == newHash) return; // Don't do anything if the hash hasn't changed

    if (history.pushState && !replaceHistoryInsteadOfAdding) {
      history.pushState(null, null, newHash);
    } else {
      if (replaceHistoryInsteadOfAdding) {
        window.location.replace(newHash);
      } else {
        window.location.hash = newHash;
      }
    }
  }
}

const UrlHashPlugin = {
  install(Vue) {
    const hashManager = Vue.observable(new UrlHash());
    Vue.prototype.$urlHash = hashManager;
    window.$urlHash = hashManager;
  },
};

export default UrlHashPlugin;
