"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import Plan from "@/shared/lib/client-sdk/models/plan";
import Account from "@/shared/lib/client-sdk/models/account";
import Order from "@/shared/lib/client-sdk/models/order";
import OrderLineAssociation from "@/shared/lib/client-sdk/models/order-line-association";
import Deposit from "@/shared/lib/client-sdk/models/deposit";
import $ from "jquery";

class DepositLink extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);

    this._hasFetchedAssociationsAtLeastOnce = false;
    this._isFetchingAssociations = false;
    this._associations = [];

    this._isFetchingAssociatableOrders = false;
    this._associatableOrders = [];
  }

  static getModelClassName() {
    return "DepositLink";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/deposit-links"
    );
  }

  getApiPathForCreate() {
    if (!this.depositId) {
      console.error("Cannot create link without deposit id");
      return;
    }

    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/trust/deposits/" +
      this.depositId +
      "/links"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/trust/deposit-links/" +
      this.id
    );
  }

  getAdminPath() {
    return "/deposit-links/" + this.id;
  }

  get searchResultTitle() {
    return (
      this.type +
      ": " +
      (this.type == "plan" ? this.plan.displayName : this.account.displayName)
    );
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return this.amount;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  get relationships() {
    return { plan: Plan, account: Account, deposit: Deposit };
  }

  get hasFetchedAssociationsAtLeastOnce() {
    return this._hasFetchedAssociationsAtLeastOnce;
  }

  get isFetchingAssociations() {
    return this._isFetchingAssociations;
  }

  get associations() {
    return this._associations;
  }

  fetchAssociations(callback) {
    if (this._isFetchingAssociations) {
      if ($.isFunction(callback)) callback();

      return;
    }

    this._isFetchingAssociations = true;

    var requestUrl =
      this.getApiPathForRefreshUpdateAndDelete() + "/associations";

    ApiRequest.send("GET", requestUrl, null, OrderLineAssociation).onComplete(
      function (response) {
        this._hasFetchedAssociationsAtLeastOnce = true;
        this._isFetchingAssociations = false;

        if (response.successful) {
          this._associations = response.result;

          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  get isFetchingAssociatableOrders() {
    return this._isFetchingAssociatableOrders;
  }

  get associatableOrders() {
    return this._associatableOrders;
  }

  fetchOrdersThatCanBeAssociated(callback) {
    if (this._isFetchingAssociatableOrders) {
      if ($.isFunction(callback)) callback();

      return;
    }

    this._isFetchingAssociatableOrders = true;

    var requestUrl =
      this.getApiPathForRefreshUpdateAndDelete() + "/associateable-orders";

    ApiRequest.send("GET", requestUrl, null, Order).onComplete(
      function (response) {
        this._isFetchingAssociatableOrders = false;

        if (response.successful) {
          this._associatableOrders = response.result;

          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  automaticallyAssociateOrderLinesInOrder(
    order,
    autoAssociateTo,
    associationAmountMethod,
    associationLimit,
    callback
  ) {
    var requestUrl =
      this.getApiPathForRefreshUpdateAndDelete() +
      "/auto-associate-to-order/" +
      order.id;

    requestUrl +=
      "?autoAssociateTo=" +
      autoAssociateTo +
      "&associationAmountMethod=" +
      associationAmountMethod +
      "&associationLimit" +
      associationLimit;

    ApiRequest.send("POST", requestUrl, null, DepositLink).onComplete(
      function (response) {
        if (response.successful && response.result != null) {
          this._hasBeenRefreshedFromApiAtLeastOnce = true;
          this._loadAttributes(response.result);
          this._makeClean();
        }

        if (response.successful) {
          if ($.isFunction(callback)) callback(response, null);
        } else {
          if ($.isFunction(callback)) callback(response, response.message);
        }
      }.bind(this)
    );
  }
}

export default DepositLink;
