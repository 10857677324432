"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import $ from "jquery";

class DistributionType extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "DistributionType";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/distribution-types"
    );
  }

  getApiPathForCreate() {
    console.error("Cannot create distribution types.");
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/distribution-types/" +
      this.code
    );
  }

  getAdminPath() {
    console.error("Cannot go to a distribution type.");
  }

  get searchResultTitle() {
    return this.displayName;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  static fetchDistributionTypes(
    retirementAccountTypeCode,
    distributionReasonCode,
    callback
  ) {
    var requestUrl = this.getApiPathForSearch();

    var queryParams = [];

    if (retirementAccountTypeCode)
      queryParams.push(
        "retirementAccountTypeCode=" + retirementAccountTypeCode
      );

    if (distributionReasonCode)
      queryParams.push("distributionReasonCode=" + distributionReasonCode);

    if (queryParams.length > 0) requestUrl += "?" + queryParams.join("&");

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  static hasCashLumpSumDistributionType(distributionTypes) {
    var lumpSumDistributionTypes =
      this.getCashLumpSumDistributionTypes(distributionTypes);

    return lumpSumDistributionTypes.length > 0;
  }

  static getCashLumpSumDistributionTypes(distributionTypes) {
    var lumpSumDistributionTypes = [];

    if (!distributionTypes || !$.isArray(distributionTypes)) {
      return lumpSumDistributionTypes;
    }

    for (var distributionType of distributionTypes) {
      if (distributionType.isLumpSum) {
        lumpSumDistributionTypes.push(distributionType);
      }
    }

    return lumpSumDistributionTypes;
  }

  static hasRolloverDistributionType(distributionTypes) {
    var rolloverDistributionTypes =
      this.getRolloverDistributionTypes(distributionTypes);

    return rolloverDistributionTypes.length > 0;
  }

  static getRolloverDistributionTypes(distributionTypes) {
    var rolloverDistributionTypes = [];

    if (!distributionTypes || !$.isArray(distributionTypes)) {
      return rolloverDistributionTypes;
    }

    const traditionalIRA = distributionTypes.find(
      (b) => b.isRolloverToTraditional == true
    );
    if (traditionalIRA) {
      traditionalIRA.displayName = "Traditional IRA";
      rolloverDistributionTypes.push(traditionalIRA);
    }

    const rolloverToRoth = distributionTypes.find(
      (b) => b.isRolloverToRoth == true
    );
    if (rolloverToRoth) {
      rolloverToRoth.displayName = "Roth IRA";
      rolloverDistributionTypes.push(rolloverToRoth);
    }

    const newEmployer = distributionTypes.find(
      (b) => b.isRolloverToNewEmployer == true
    );
    if (newEmployer) {
      newEmployer.displayName = "New Employer Plan";
      rolloverDistributionTypes.push(newEmployer);
    }

    return rolloverDistributionTypes;
  }
}

export default DistributionType;
