<template>
  <div
    :class="
      isParticipantPortalField
        ? 'date-text-field is-participant-portal-field'
        : 'date-text-field is-general-field'
    "
  >
    <div
      v-if="isParticipantPortalField && showHeader"
      class="date-text-field-header-style"
    >
      <span v-if="required && !isAccountHolderField" class="error-text">*</span
      >{{ header }}
    </div>
    <v-text-field
      v-model="formattedDate"
      :rules="required ? dateRules : []"
      :label="
        isParticipantPortalField ? '' : label + (required ? '' : ' (optional)')
      "
      :validate-on-blur="true"
      :placeholder="localPlaceHolder"
      :disabled="disabled"
      :clearable="clearable"
      maxlength="10"
      :dense="dense"
      :color="$colors.penChecksTeal"
      @blur="blur"
      @keyup="textChanged"
      :suffix="suffix"
      @keyup.enter="$emit('enter', $event)"
      @focus="localPlaceHolder = placeholder"
      :outlined="isParticipantPortalField"
    ></v-text-field>
  </div>
</template>
<script>
var moment = require("moment");

export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "MM/DD/YYYY",
    },
    required: {
      type: Boolean,
    },
    beforeToday: {
      type: Boolean,
    },
    afterToday: {
      type: Boolean,
    },
    dateOfBirth: {
      type: Boolean,
    },
    returnSlashed: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    showAge: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
    isParticipantPortalField: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: "Date",
    },
    isAccountHolderField: {
      type: Boolean,
      default: false,
    },
    errorLabel: {
      type: String,
      default: "",
    },
  },

  data: function () {
    return {
      formattedDate: null,
      dateRules: [
        () => {
          if (this.required && !this.formattedDate)
            return this.isAccountHolderField
              ? this.errorLabel
              : "Field cannot be left blank";
          return true;
        },
        () => {
          if (
            this.formattedDate &&
            this.dateOfBirth &&
            this.isValid &&
            (moment(this.properDateValue).diff(moment().startOf('day'), "days") > 0 ||
              moment(this.properDateValue).diff(moment(), "years") < -130)
          )
            return "Please enter a valid date of birth";
          return true;
        },
        () => {
          if (
            this.formattedDate &&
            this.beforeToday &&
            this.isValid &&
            moment(this.properDateValue).diff(moment(), "days") >= 0
          )
            return "Date must be in the past";
          return true;
        },
        () => {
          if (
            this.formattedDate &&
            this.afterToday &&
            this.isValid &&
            moment(this.properDateValue).diff(moment(), "days") <= 0
          )
            return "Date must be in the future";
          return true;
        },
        () => {
          if (this.formattedDate && !this.isValid && this.dateOfBirth) {
            this.formattedDate = null;
            return "Please enter a valid date of birth";
          }
          if (this.formattedDate && !this.isValid && !this.dateOfBirth) {
            this.formattedDate = null;
            return "Please enter a valid date";
          }
          return true;
        },
      ],
      localPlaceHolder: "MM/DD/YYYY",
    };
  },

  watch: {
    formattedDate() {
      this.$emit("input", this.properDateValue);
    },
    value() {
      if (this.value != this.properDateValue) {
        this.formattedDate = this.value;
        this.formatText();
      }
    },
  },

  computed: {
    suffix: function () {
      if (
        this.showAge &&
        this.isValid &&
        moment(this.properDateValue).diff(moment(), "days") < 0
      ) {
        return Date.yearsAgo(this.formattedDate, true) + " yrs";
      }
      return null;
    },
    properDateValue: function () {
      if (!this.isValid) return null;

      var value = this.returnSlashed
        ? Date.toDateStringWithSlashes(this.formattedDate)
        : Date.toIsoDateString(this.formattedDate);
      if (value.length == 10) return value;

      return null;
    },

    isValid: function () {
      if (this.formattedDate == null || this.formattedDate.trim() == "")
        return false;

      var isoString = Date.toIsoDateString(this.formattedDate);

      if (isoString && isoString.length == 10)
        return moment(isoString).isValid();

      return false;
    },
  },

  methods: {
    textChanged: function (e) {
      var selectionStart = e.target.selectionStart;
      var selectionEnd = e.target.selectionEnd;
      var value = e.target.value;

      if (this.formattedDate != null && this.formattedDate.trim() == "") {
        this.formattedDate = null;
        return;
      }

      var isPasting = (e.ctrlKey || e.metaKey) && e.key.toLowerCase() == "v";

      // Dont format if not at end of string (or pasting)
      if (
        !isPasting &&
        (selectionStart != selectionEnd || selectionStart < value.length)
      ) {
        return;
      }

      if (e.keyCode != 8) {
        // keycode 8 = backspace
        this.formatText();
      }
    },
    blur: function () {
      this.$emit("blur");
      this.localPlaceHolder = "";

      if (this.formattedDate && this.formattedDate.length == 8) {
        var start = this.formattedDate.substr(0, 6);

        var year = this.formattedDate.substr(6, 2);
        var intYear = parseInt(year);
        var currentYear = parseInt(new Date().getFullYear() - 2000);

        if (intYear > currentYear) {
          this.formattedDate = start + "19" + year;
        } else {
          this.formattedDate = start + "20" + year;
        }
      }

      this.formatText();
    },
    formatText: function () {
      if (this.formattedDate == null || this.formattedDate.trim() == "") return;

      var numbersOnly = this.formattedDate.replace(/[^\d/]/g, "");

      var pieces = numbersOnly.split("/");
      var month = pieces[0];
      var day = pieces.length > 1 ? pieces[1] : null;
      var year = pieces.length > 2 ? pieces[2] : null;

      if (month.length > 2) {
        day = month.substr(2);
        month = month.substr(0, 2);
      }

      if (day && day.length > 2) {
        year = day.substr(2);
        day = day.substr(0, 2);
      }

      if (day !== null && month && parseInt(month) != 0) {
        month = parseInt(month).toString().padStart(2, "0").substr(0, 2);
      }
      if (year !== null && day && parseInt(day) != 0) {
        day = parseInt(day).toString().padStart(2, "0").substr(0, 2);
      }

      this.formattedDate = month;

      if (month.length == 2 || day) this.formattedDate += "/";

      if (day) {
        this.formattedDate += day;

        if (day.length == 2 || year) this.formattedDate += "/";

        if (year) {
          this.formattedDate += year;
        }
      }
    },
  },

  mounted: function () {
    this.localPlaceHolder = this.placeholder;
    if (this.value) {
      this.formattedDate = Date.toDateStringWithSlashes(this.value);
    }

    this.formatText();
  },
};
</script>

<style lang="scss">
.date-text-field.is-participant-portal-field {
  .date-text-field-header-style {
    margin-left: 10px;
    margin-bottom: 8px;
    font-family: "Open Sans", sans-serif;
    color: $neutral70;
    font-size: 12px;
    font-weight: 600;
  }

  .v-input--is-focused {
    .v-input__slot {
      border-width: 1px !important;
      border-color: $primary !important;
      box-shadow: 0px 0px 0px 3px $primary10 !important;
    }
  }

  div.v-input:not(.error--text, .v-input--is-focused) {
    fieldset {
      border-color: $neutral10 !important;
    }
  }

  div.v-text-field {
    border-radius: 6px !important;
  }

  div.v-text-field__slot {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 20px;
    font-family: "Open Sans", sans-serif;
    input {
      color: $neutral70 !important;
      font-size: 14px;
      height: 20px !important;
    }

    input::placeholder {
      font-family: "Open Sans", sans-serif !important;
      color: $neutral30 !important;
      font-weight: 400 !important;
      font-size: 14px;
    }
  }

  .v-input__slot {
    min-height: 40px !important;
    height: 20px;
  }

  .v-input--is-focused {
    .v-input__slot {
      color: $primary !important;
      caret-color: $primary !important;
      box-shadow: 0px 0px 0px 3px $primary10 !important;
      fieldset {
        border-width: 1px !important;
      }
    }
  }
}
</style>
