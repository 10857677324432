function notifyPendo(user) {
  if (!user) return;

  let pendoVisitor = user.pendoVisitor;
  let pendoAccount = user.pendoAccount;

  if (pendoAccount) {
    window.pendo.initialize({ visitor: pendoVisitor, account: pendoAccount });
  } else {
    window.pendo.initialize({ visitor: pendoVisitor });
  }
}

export default notifyPendo;
