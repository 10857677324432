<template>
  <div
    class="static-address-value"
    :class="{ 'has-error': required && !isValid }"
  >
    <strong v-if="label"> {{ label }}<br /> </strong>

    <template v-if="isAllMissing">
      <span v-if="flagMissingData" class="missing"> MISSING </span>
      <span v-else> None </span>
    </template>
    <template v-else>
      <div class="d-flex">
        <div v-if="attributeValue('attentionTo')" class="mr-2">Attention:</div>
        <div>
          <template v-if="attributeValue('attentionTo')">
            <div :id="setId('AttentionToStaticValue')">
              {{ attributeValue("attentionTo") }}
            </div>
          </template>

          <template v-if="attributeValue('addressLine1')">
            <div :id="setId('AddressLine1StaticValue')">
              {{ attributeValue("addressLine1") }}
            </div>
          </template>
          <div v-else-if="flagMissingData" class="missing">
            [no address line 1]
          </div>

          <template v-if="attributeValue('addressLine2')">
            <div :id="setId('AddressLine2StaticValue')">
              {{ attributeValue("addressLine2") }}
            </div>
          </template>

          <template v-if="attributeValue('city')">
            <span :id="setId('CityAddressStaticValue')">
              {{ `${attributeValue("city")}, ` }}
            </span>
          </template>
          <span v-else-if="flagMissingData" class="missing"> [no city], </span>

          <template v-if="attributeValue('region')">
            <span :id="setId('RegionAddressStaticValue')">{{
              attributeValue("region")
            }}</span>
          </template>
          <span
            v-else-if="flagMissingData && countryCode == 'US'"
            class="missing"
          >
            [no state]
          </span>

          <template v-if="attributeValue('postalCode')">
            <span :id="setId('PostalCodeStaticValue')">{{
              ` ${attributeValue("postalCode")}`
            }}</span>
          </template>
          <span
            v-else-if="
              flagMissingData &&
              attributeValue('countryCode') &&
              attributeValue('countryCode').toLowerCase() == 'us'
            "
            class="missing"
          >
            [no postal code]
          </span>

          <template v-if="attributeValue('countryCode')">
            <br /><span :id="setId('CountryCodeStaticValue')">{{
              attributeValue("countryCode")
            }}</span>
          </template>
          <span v-else-if="flagMissingData" class="missing">
            <br />[no country]
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BaseModel from "@/shared/lib/client-sdk/base-model";

export default {
  props: {
    label: {
      type: String,
    },
    model: {
      type: [BaseModel, Object],
      required: true,
    },
    required: {
      type: Boolean,
    },
    flagMissingData: {
      // If true, the missing values will show as "missing"
      type: Boolean,
    },
    attributePrefix: {
      type: String,
    },
    attentionToAttribute: {
      type: String,
      default: "attentionTo",
    },
    addressLine1Attribute: {
      type: String,
      default: "addressLine1",
    },
    addressLine2Attribute: {
      type: String,
      default: "addressLine2",
    },
    cityAttribute: {
      type: String,
      default: "city",
    },
    stateCodeAttribute: {
      type: String,
      default: "stateCode",
    },
    otherRegionAttribute: {
      type: String,
      default: "otherRegion",
    },
    postalCodeAttribute: {
      type: String,
      default: "postalCode",
    },
    countryCodeAttribute: {
      type: String,
      default: "countryCode",
    },
    idPrefix: {
      type: String,
    },
  },

  data: function () {
    return {
      countries: [],
    };
  },

  computed: {
    isValid: function () {
      const usCountryCodes = ["US", "USA"];

      var addressLine1 = this.attributeValue("addressLine1");
      var city = this.attributeValue("city");
      var countryCode = this.attributeValue("countryCode");

      var postalCode = usCountryCodes.includes(countryCode)
        ? this.attributeValue("postalCode")
        : "";

      var region = usCountryCodes.includes(countryCode)
        ? this.attributeValue("stateCode")
        : this.attributeValue("otherRegion");

      var result = usCountryCodes.includes(countryCode)
        ? !!addressLine1 && !!city && !!postalCode && !!countryCode && !!region
        : !!addressLine1 && !!city && !!countryCode && !!region;

      return result;
    },
    isAllMissing: function () {
      const usCountryCodes = ["US", "USA"];

      var addressLine1 = this.attributeValue("addressLine1");
      var city = this.attributeValue("city");
      var countryCode = this.attributeValue("countryCode");

      var postalCode = usCountryCodes.includes(countryCode)
        ? this.attributeValue("postalCode")
        : "";

      var region = usCountryCodes.includes(countryCode)
        ? this.attributeValue("stateCode")
        : this.attributeValue("otherRegion");

      var result = usCountryCodes.includes(countryCode)
        ? !addressLine1 && !city && !postalCode && !countryCode && !region
        : !addressLine1 && !city && !countryCode && !region;

      return result;
    },
    countryCode: function () {
      var attributeName = this.countryCodeAttribute;

      if (this.attributePrefix)
        attributeName = this.attributePrefix + attributeName.toPascalCase();

      var value = this.model[attributeName]
        ? this.model[attributeName].toString().trim()
        : null;

      return value;
    },
    stateCode: function () {
      var attributeName = this.stateCodeAttribute;

      if (this.attributePrefix)
        attributeName = this.attributePrefix + attributeName.toPascalCase();

      var value = this.model[attributeName]
        ? this.model[attributeName].toString().trim()
        : null;

      if (value) value = value.toUpperCase();

      return value;
    },
  },

  methods: {
    setId: function (idSufix) {
      return this.idPrefix ? this.idPrefix + idSufix : undefined;
    },
    attributeName: function (attribute) {
      attribute =
        attribute == "region"
          ? this.countryCode == "US" || (!this.countryCode && this.stateCode)
            ? "stateCode"
            : "otherRegion"
          : attribute;

      var attributeName = this[attribute + "Attribute"];
      var prefix = this.attributePrefix;

      if (prefix) attributeName = prefix + attributeName.toPascalCase();

      return attributeName.toCamelCase();
    },
    attributeValue: function (attribute) {
      if (attribute == "countryCode") {
        var prefix = this.countryCodeAttribute
          .toLowerCase()
          .replace("countrycode", "");

        var displayValue = prefix
          ? this.model[`${prefix}Country`]?.["alpha3Code"]
          : this.model["country"]?.["alpha3Code"];

        if (displayValue) return displayValue;
        else return this.countryCode;
      }
      if (attribute == "stateCode") return this.stateCode;

      var attributeName = this.attributeName(attribute);
      return this.model[attributeName]
        ? this.model[attributeName].toString().trim()
        : null;
    },
  },
};
</script>

<style lang="scss">
.static-address-value {
  color: $penChecksDarkGray;
  font-size: 14px;

  &.has-error {
    .missing {
      font-style: italic;
      color: $penChecksRed;
    }
  }
}
</style>
