var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isParticipantPortalField
        ? 'text-field is-participant-portal-field'
        : 'text-field is-general-field'},[(_vm.isParticipantPortalField && _vm.showHeader)?_c('div',{staticClass:"text-field-header-style"},[(_vm.required && !_vm.isAccountHolderField)?_c('span',{staticClass:"error-text"},[_vm._v("*")]):_vm._e(),_vm._v(_vm._s(_vm.header)+" ")]):_vm._e(),_c('v-text-field',_vm._g({ref:"textfield",attrs:{"id":_vm.id,"rules":_vm.required ? _vm.textRules.concat(_vm.printabilityRules) : _vm.printabilityRules,"type":_vm.type,"label":_vm.isParticipantPortalField
          ? ''
          : _vm.showLabel
          ? _vm.label + (_vm.required || _vm.hideOptionalText ? '' : ' (optional)')
          : '',"placeholder":_vm.placeholder,"validate-on-blur":_vm.validateBlur,"disabled":_vm.disabled,"clearable":_vm.clearable,"dense":_vm.dense,"maxLength":_vm.maxLength,"errorMessages":_vm.errors,"color":_vm.$colors.penChecksTeal,"hint":_vm.hint,"persistent-hint":_vm.persistentHint,"append-icon":_vm.appendIcon,"outlined":_vm.isParticipantPortalField},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('enter', $event)},"change":function($event){return _vm.$emit('change', _vm.text)},"click:clear":function($event){return _vm.$emit('clear')},"blur":_vm.handleBlur,"focus":function($event){_vm.errors = null}},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}},_vm.$listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }