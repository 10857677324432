<template>
  <v-text-field
    v-model="emailAddress"
    :rules="emailAddressRules"
    :label="label + (required || hideOptionalText ? '' : ' (optional)')"
    validate-on-blur
    :error-messages="errors"
    :color="$colors.penChecksTeal"
    :dense="dense"
    :disabled="disabled"
    :clearable="clearable"
    :required="required"
    @keyup.enter="$emit('enter', $event)"
    @focus="inputFocused"
  ></v-text-field>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      default: "Email Address",
    },
    required: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    multiple: {
      type: Boolean,
    },
    errorMessages: {
      type: Array,
    },
    hideOptionalText: {
      type: Boolean,
    },
    differentOf: {
      type: String,
      default: null,
      required: false,
    },
  },

  data: function () {
    return {
      emailAddress: this.value,
      emailAddressRules: [
        (v) =>
          (this.required ? !!v : true) ||
          "Please enter " + this.label.toLowerCase(),
        (v) =>
          (!this.multiple && v ? /[^\s]+@[^\s]+\.[^\s]+/.test(v) : true) ||
          "Please enter valid " + this.label.toLowerCase(),
        (v) =>
          (this.multiple && v
            ? /([^\s]+@[^\s]+\.[^\s]+,?\s*)+/.test(v)
            : true) || "Please enter valid email addresses.",
        (v) =>
          (this.differentOf && v ? this.differentOf != v : true) ||
          "Email is duplicated",

        (v) =>
          (v ? this.couldBeEmailAddress(v) : true) ||
          "Email address must be valid if specified.",
      ],
      errors: null,
    };
  },

  watch: {
    emailAddress(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.emailAddress = newValue;
    },
    errorMessages(errors) {
      this.errors = errors;
    },
  },

  methods: {
    couldBeEmailAddress(str) {
      if (!str.trim()) return false;

      str = str.trim();

      if (str[0] === "@" || str[str.length - 1] === "@") return false;

      // If it doesn't contain a single '@' character, it's not valid
      if (str.split("@").length - 1 !== 1) return false;

      // Spaces are allowed within email addresses as long as the string is enclosed in quotes
      // For now, if it contains a space, return false to prevent downstream issues
      if (str.includes(" ")) return false;

      return true;
    },
    inputFocused: function () {
      this.errors = null;
      this.$emit("focus");
    },
  },
};
</script>

<style lang="scss"></style>
