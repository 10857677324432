<template>
  <div>
    <checkmark-filled-16 class="checkmark-icon outer-icon-fill" />
  </div>
</template>

<script>
import { CheckmarkFilled16 } from "@carbon/icons-vue";

export default {
  name: "PcCheckmark",

  components: {
    "checkmark-filled-16": CheckmarkFilled16,
  },
};
</script>

<style lang="scss" scoped>
svg.outer-icon-fill {
  fill: $penChecksLightTeal !important;
}

/* Use the `data-icon-path` attribute selector to target the inner path
where we want to set the fill to `black`. We also set `opacity` to `1` so
that this inner-path is visible. */
svg.outer-icon-fill [data-icon-path="inner-path"] {
  fill: $penChecksDarkTeal !important;
  opacity: 1;
}
</style>
