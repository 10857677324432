"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import Account from "@/shared/lib/client-sdk/models/account";
import Institution from "@/shared/lib/client-sdk/models/institution";
import Order from "@/shared/lib/client-sdk/models/order";
import OrderLine from "@/shared/lib/client-sdk/models/order-line";
import BankTransaction from "@/shared/lib/client-sdk/models/bank-transaction";
import DepositLink from "@/shared/lib/client-sdk/models/deposit-link";

import InboundParsedFilePdfExtractedImage from "@/shared/lib/client-sdk/models/inbound-parsed-file-pdf-extracted-image";
import $ from "jquery";

class Deposit extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);

    this._hasFetchedBankTransactionsAtLeastOnce = false;
    this._hasFetchedDepositLinksAtLeastOnce = false;
    this._isFetchingBankTransactions = false;
    this._isFetchingDepositLinks = false;
    this._bankTransactions = [];
    this._depositLinks = [];
  }

  static getModelClassName() {
    return "Deposit";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/trust/deposits"
    );
  }

  getApiPathForCreate() {}

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/trust/deposits/" +
      this.id
    );
  }

  getAdminPath() {
    return "/trust/deposits/" + this.id;
  }

  get searchResultTitle() {
    return this.markedDepositDate + " $" + this.amount;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  getImagesWithType(imageType) {
    if (!this.images) return [];
    return this.images.filter((x) => x.imageType == imageType);
  }

  getCheckFrontImage() {
    var images = this.getImagesWithType("checkFrontImage");
    if (images && images.length > 0) return images[0];
  }

  getCheckBackImage() {
    var images = this.getImagesWithType("checkBackImage");
    if (images && images.length > 0) return images[0];
  }

  getOtherImages() {
    if (!this.images) return [];
    return this.images.filter(
      (x) => x.imageType != "checkFrontImage" && x.imageType != "checkBackImage"
    );
  }

  get relationships() {
    return {
      institution: Institution,
      masterAccount: Account,
      subaccount: Account,
      order: Order,
      orderLine: OrderLine,
      images: InboundParsedFilePdfExtractedImage,
    };
  }

  get markedDepositDate() {
    return this.checkDate;
  }

  get hasFetchedBankTransactionsAtLeastOnce() {
    return this._hasFetchedBankTransactionsAtLeastOnce;
  }
  get hasFetchedDepositLinksAtLeastOnce() {
    return this._hasFetchedDepositLinksAtLeastOnce;
  }

  get isFetchingBankTransactions() {
    return this._isFetchingBankTransactions;
  }

  get isFetchingLinks() {
    return this._isFetchingDepositLinks;
  }

  get bankTransactions() {
    return this._bankTransactions;
  }

  get links() {
    return this._depositLinks;
  }

  getSuggestedPlanLinks(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/trust/deposits/" +
      this.id +
      "/suggested-links";

    ApiRequest.send("GET", requestUrl).onComplete(function (response) {
      if (response.successful) {
        if ($.isFunction(callback)) {
          callback(response.result, null);
        }
      } else {
        if ($.isFunction(callback)) {
          callback(null, response);
        }
      }
    });
  }

  fetchBankTransactions(callback) {
    if (this._isFetchingBankTransactions) {
      if ($.isFunction(callback)) callback();

      return;
    }

    this._isFetchingBankTransactions = true;

    var requestUrl =
      this.getApiPathForRefreshUpdateAndDelete() + "/bank-transactions";

    ApiRequest.send("GET", requestUrl, null, BankTransaction).onComplete(
      function (response) {
        this._hasFetchedBankTransactionsAtLeastOnce = true;
        this._isFetchingBankTransactions = false;

        if (response.successful) {
          this._bankTransactions = response.result;

          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  fetchLinks(callback) {
    if (this._isFetchingDepositLinks) {
      if ($.isFunction(callback)) callback();

      return;
    }

    this._isFetchingDepositLinks = true;

    var requestUrl = this.getApiPathForRefreshUpdateAndDelete() + "/links";

    ApiRequest.send("GET", requestUrl, null, DepositLink).onComplete(
      function (response) {
        this._hasFetchedDepositLinksAtLeastOnce = true;
        this._isFetchingDepositLinks = false;

        if (response.successful) {
          this._depositLinks = response.result;

          // Sort so that unassociated links are higher up

          this._depositLinks.sort((a, b) =>
            a.remainderToAssociate > b.remainderToAssociate
              ? -1
              : b.remainderToAssociate > a.remainderToAssociate
              ? 1
              : 0
          );

          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  update(callback) {
    var requestUrl = this.getApiPathForRefreshUpdateAndDelete();

    ApiRequest.send("PATCH", requestUrl, this).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  static getTrustSummary(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/trust/summary";

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }
}

export default Deposit;
