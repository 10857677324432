"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import $ from "jquery";
import OrderLine from "@/shared/lib/client-sdk/models/order-line";
import Plan from "@/shared/lib/client-sdk/models/plan";
import Service from "@/shared/lib/client-sdk/models/service";
import Account from "@/shared/lib/client-sdk/models/account";
import User from "@/shared/lib/client-sdk/models/user";

class Order extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);

    this._isFetchingAssociateableOrderLines = false;
    this._associateableOrderLines = [];
  }

  static getModelClassName() {
    return "Order";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") + "/public/orders"
    );
  }

  getApiPathForCreate() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/accounts/" +
      this.accountId +
      "/orders"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/orders/" +
      this.id
    );
  }

  getAdminPath() {
    if (this.isBulkOrder && this.isEnhancedIraService && this.hasOrderUploads) {
      return `/accounts/${this.masterAccountId}/bulk-orders/${this.id}#bulk-operations`;
    }

    return "/orders/" + this.id;
  }

  getClientPath() {
    return "/" + this.masterAccountId + "/orders/" + this.id;
  }

  get relationships() {
    return {
      plan: Plan,
      service: Service,
      subaccount: Account,
      masterAccount: Account,
      createdByUser: User,
      submittedByUser: User,
      approvedByUser: User,
    };
  }

  touch(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/orders/" +
      this.id +
      "/touch";

    ApiRequest.send("PATCH", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  get searchResultTitle() {
    if (this.friendlyId.length >= 15) {
      return (
        this.friendlyId.substr(0, 2) +
        "-" +
        this.friendlyId.substr(2, 6) +
        "-" +
        this.friendlyId.substr(8, 3) +
        "-" +
        this.friendlyId.substr(11)
      );
    } else {
      return this.friendlyId;
    }
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return [
      this.serviceDisplayName,
      Number.withCommas(this.totalOrderLines) + " participants",
    ];
  }

  get searchResultCreatedAt() {
    return this.createdAt;
  }

  get searchResultStatus() {
    return this.status;
  }

  get requiresDistributionServiceDetail() {
    return (
      this.isDistributionsService ||
      this.isRolloverService ||
      this.isUncashedChecksService
    );
  }

  get requiresTaxServiceDetail() {
    return this.isTaxService;
  }

  get orderLineName() {
    if (this.isVendorDistributionsService) return "payee";

    return "participant";
  }

  get isTaxService() {
    return (
      this.serviceCode == "taxFormsOnly" ||
      this.serviceCode == "taxPaymentsAndForms"
    );
  }

  get isTaxFormsOnlyService() {
    return this.serviceCode == "taxFormsOnly";
  }

  get isTaxPaymentsAndFormsService() {
    return this.serviceCode == "taxPaymentsAndForms";
  }

  get isDistributionsService() {
    return (
      this.serviceCode == "premierDistributions" ||
      this.serviceCode == "expressDistributions" ||
      this.serviceCode == "stockBuyBackDistributions"
    );
  }

  get isPremierDistributionsService() {
    return this.serviceCode == "premierDistributions";
  }

  get isExpressDistributionsService() {
    return this.serviceCode == "expressDistributions";
  }

  get isStockBuyBackDistributionsService() {
    return this.serviceCode == "stockBuyBackDistributions";
  }

  get isAddressSearchService() {
    return this.serviceCode == "addressSearch";
  }

  get isVendorDistributionsService() {
    return this.serviceCode == "vendorDistributions";
  }

  get isRolloverService() {
    return (
      this.serviceCode == "expressAutomaticRollovers" ||
      this.serviceCode == "enhancedRollovers" ||
      this.serviceCode == "premierRollovers"
    );
  }

  get isUncashedChecksService() {
    return (
      this.serviceCode == "uncashedChecksQualified" ||
      this.serviceCode == "uncashedChecksNonQualified"
    );
  }

  get isUncashedChecksQualifiedService() {
    return this.serviceCode == "uncashedChecksQualified";
  }

  get isUncashedChecksNonQualifiedService() {
    return this.serviceCode == "uncashedChecksNonQualified";
  }

  get isRecurringBenefitService() {
    return this.serviceCode == "recurringBenefits";
  }

  get isEnhancedIraService() {
    return this.serviceCode == "enhancedIra";
  }

  get serviceAllowsPaymentInstructionsToBeEntered() {
    return this.isDistributionsService;
  }

  get sumClientPaidFees() {
    return Math.addAmounts(
      this.totalRequestorFeesPaidBySubaccount,
      this.totalOtherFeesPaidByMasterAccount,
      this.totalOtherFeesPaidBySubaccount
    );
  }

  get sumMasterAccountPaidFees() {
    return this.totalOtherFeesPaidByMasterAccount;
  }

  get sumSubaccountPaidFees() {
    return Math.addAmounts(
      this.totalRequestorFeesPaidBySubaccount,
      this.totalOtherFeesPaidBySubaccount
    );
  }

  get requiresBankAccountVerification() {
    return (
      this.isPremierDistributionsService ||
      this.isExpressDistributionsService ||
      this.isUncashedChecksService ||
      this.isStockBuyBackDistributionsService ||
      this.isRecurringBenefitService
    );
  }

  buildRelatedObjects() {
    this._masterAccount = this._masterAccount || new Account();
    this._plan = null;
  }

  getFeeDependencyData(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/orders/" +
      this.id +
      "/fee-dependency-data";

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  static uploadParticipants(file, orderId, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/orders/" +
      orderId +
      "/upload-participants";

    let formData = new FormData();
    formData.append("file", file);

    ApiRequest.upload(requestUrl, formData, OrderLine).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  checkUploadStatus(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/orders/" +
      this.id +
      "/upload-status";

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  checkSubstatus(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/orders/" +
      this.id +
      "/substatus";

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  addSupplementDocument(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order/" +
      this.id +
      "/supplement-document";

    let formData = new FormData();
    formData.append("file", this.supplementDocument);

    this._currentOperation = "saving";

    ApiRequest.upload(requestUrl, formData).onComplete(
      function (response) {
        this._currentOperation = null;
        if (response.successful) {
          this.supplementDocument = null;
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  deleteSupplementDocument(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order/" +
      this.id +
      "/supplement-document";

    ApiRequest.send("DELETE", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          this.supplementDocumentStoredFileId = null;
          this.supplementDocument = null;

          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  get isFetchingAssociateableOrderLines() {
    return this._isFetchingAssociateableOrderLines;
  }

  get associateableOrderLines() {
    return this._associateableOrderLines;
  }

  fetchOrderLinesThatCanBeAssociated(callback) {
    if (this._isFetchingAssociateableOrderLines) {
      if ($.isFunction(callback)) callback();

      return;
    }

    this._isFetchingAssociateableOrderLines = true;

    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/trust/orders/" +
      this.id +
      "/associatable-order-lines";

    ApiRequest.send("GET", requestUrl, null, OrderLine).onComplete(
      function (response) {
        this._isFetchingAssociateableOrderLines = false;

        if (response.successful) {
          this._associateableOrderLines = response.result;

          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  fetchOrderLinesForAssociation(callback) {
    if (this._isFetchingAssociateableOrderLines) {
      if ($.isFunction(callback)) callback();

      return;
    }

    this._isFetchingAssociateableOrderLines = true;

    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/trust/orders/" +
      this.id +
      "/order-lines-needing-association";

    ApiRequest.send("GET", requestUrl, null, OrderLine).onComplete(
      function (response) {
        this._isFetchingAssociateableOrderLines = false;

        if (response.successful) {
          this._associateableOrderLines = response.result;

          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  fetchOrderFundingSources(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/trust/orders/" +
      this.id +
      "/funding-sources";

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  createOrdersAndMoveOrderLines(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/orders/" +
      this.id +
      "/bulk-orders";

    ApiRequest.send("POST", requestUrl, null, Order).onComplete(
      function (response) {
        if (response.successful) {
          response.result.copyAttributesTo(this);
          this._makeClean();

          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  submitForProcessing(callback) {
    this._progressOrderStatus(callback, "submit");
  }

  submitForReview(callback) {
    this._progressOrderStatus(callback, "submit-for-approval");
  }

  cancel(callback) {
    this._progressOrderStatus(callback, "cancel");
  }

  reopen(callback) {
    this._progressOrderStatus(callback, "reopen");
  }

  approve(callback) {
    this._progressOrderStatus(callback, "approve");
  }

  _progressOrderStatus(callback, command) {
    var requestUrl = this.getApiPathForRefreshUpdateAndDelete() + "/" + command;

    ApiRequest.send("POST", requestUrl, null, Order).onComplete(
      function (response) {
        if (response.successful) {
          response.result.copyAttributesTo(this);
          this._makeClean();

          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  deleteAllOrderLines(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/orders/" +
      this.id +
      "/order-lines";

    ApiRequest.send("DELETE", requestUrl).onComplete(
      function (response) {
        this._isFetchingAssociateableOrderLines = false;

        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  getRecurringPaymentOrderForCurrentMonth(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/recurring/plans/" +
      this.planId +
      "/current-month-payment-orders";

    ApiRequest.send("GET", requestUrl, null, null).onComplete((response) => {
      if (
        response.successful &&
        response.result &&
        response.result.length > 0
      ) {
        if ($.isFunction(callback)) {
          var recurringPaymentOrder = new Order(response.result[0].id);
          callback(recurringPaymentOrder, null);
        }
      } else {
        if ($.isFunction(callback)) callback(null, response.message);
      }
    });
  }

  getBulkOrderErrorNotificationInfo(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/bulk-orders/" +
      this.id +
      "/getBulkOrderErrorNotificationInfo";

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  sendBulkOrderErrorNotification(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/bulk-orders/" +
      this.id +
      "/sendBulkOrderErrorNotification";

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }
}

export default Order;
