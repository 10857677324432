"use strict";

import User from "./user";
import BaseModel from "@/shared/lib/client-sdk/base-model";

class EntityDocument extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "EntityDocument";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/documents"
    );
  }

  getApiPathForCreate() {
    console.error(
      "Cannot create document directly, must be created on an entity."
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/documents/" +
      this.id
    );
  }

  getAdminPath() {
    console.error("Cannot go to an entity document.");
  }

  // If the API returns a relationship as a sub-object, this tells the base model which class to use for them when it laods it in.
  get relationships() {
    return { user: User };
  }
}

export default EntityDocument;
