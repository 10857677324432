<template>
  <div
    :class="
      isParticipantPortalField
        ? 'percentage-text-field is-participant-portal-field'
        : 'percentage-text-field is-general-field'
    "
  >
    <div
      v-if="isParticipantPortalField && showHeader"
      class="percentage-text-field-header-style"
    >
      <span v-if="required" class="error-text">*</span>{{ header }}
    </div>
    <v-text-field
      v-model="formattedText"
      :rules="rules"
      :label="label ? label + (required ? '' : ' (optional)') : ''"
      :validate-on-blur="validateBlur"
      class="percentage-text-field"
      :disabled="disabled"
      :color="$colors.penChecksTeal"
      @focus="isFocused = true"
      @blur="inputBlurred"
      @change="$emit('change', floatValue)"
      @keyup="keyUp"
      :dense="dense"
      :outlined="isParticipantPortalField"
    ></v-text-field>
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    label: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    minValue: {
      type: Number,
    },
    maxValue: {},
    allowZero: {
      type: Boolean,
    },
    wholeNumbers: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    isParticipantPortalField: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: "",
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    validateBlur: {
      type: Boolean,
      default: true,
    },
  },

  data: function () {
    return {
      formattedText: null,
      isFocused: false,

      rules: [
        () => {
          if (this.required && this.floatValue == null)
            return "Please enter an value.";
          return true;
        },
        () => {
          if (this.disabled) return true;

          if (!this.allowZero && this.floatValue == 0)
            return "Please enter a non-zero value.";
          return true;
        },
        () => {
          if (!this.allowNegative && this.floatValue < 0)
            return "Please enter a positive value.";
          return true;
        },
        () => {
          if (
            this.minValue != null &&
            this.floatValue != null &&
            this.floatValue < this.minValue
          )
            return (
              "Must be at least " +
              Math.roundProperly(this.minValue * 100, 2) +
              "%"
            );
          return true;
        },
        () => {
          if (
            this.maxValue != null &&
            this.floatValue != null &&
            this.floatValue > this.maxValue
          )
            return (
              "Must not exceed " +
              Math.roundProperly(this.maxValue * 100, 2) +
              "%"
            );
          return true;
        },
      ],
    };
  },

  computed: {
    floatValue: function () {
      if (this.formattedText == null) return null;
      return parseFloat(Number.numberFormatHelper(this.formattedText)) * 0.01;
    },
  },

  watch: {
    value: function () {
      if (this.value != this.floatValue) {
        this.formattedText = parseFloat(this.value) * 100 + "";
        this.formatText();
      }
    },
    floatValue: function () {
      this.$emit("input", this.floatValue);
    },
  },

  methods: {
    keyUp: function (e) {
      this.$emit("keyup", e);

      this.textChanged(e);
    },
    textChanged: function (e) {
      var selectionStart = e.target.selectionStart;
      var selectionEnd = e.target.selectionEnd;
      var value = e.target.value;

      if (
        this.formattedText == null ||
        (this.formattedText != null && this.formattedText.trim() == "")
      ) {
        this.formattedText = null;
        return;
      }

      var isPasting = (e.ctrlKey || e.metaKey) && e.key.toLowerCase() == "v";

      // Dont format if not at end of string (or pasting)
      if (
        !isPasting &&
        (selectionStart != selectionEnd || selectionStart < value.length)
      ) {
        return;
      }

      if (e.key != "." && e.keyCode != 8) {
        this.formatText();
      }

      this.setCursorPosition(selectionEnd);
    },
    inputBlurred: function () {
      this.isFocused = false;
      this.formatText();
    },
    setCursorPosition(selectionEnd) {
      if (this.$children[0].$refs.input && !Number.isNaN(selectionEnd)) {
        this.$nextTick(() => {
          this.$children[0].$refs.input.selectionEnd = selectionEnd;
        });
      }
    },
    formatText: function () {
      if (this.formattedText)
        this.formattedText = Number.numberFormatHelper(this.formattedText, {
          commas: true,
        });

      if (this.formattedText != null && this.formattedText.toString() == "NaN")
        this.formattedText = null;

      if (this.formattedText != null && this.formattedText != "") {
        this.formattedText = Math.roundProperly(this.formattedText, 2) + " %";
      }
    },
  },

  mounted: function () {
    this.formattedText = parseFloat(this.value) * 100 + "";
    this.formatText();
  },
};
</script>

<style lang="scss">
.percentage-text-field.is-participant-portal-field {
  .percentage-text-field-header-style {
    margin-left: 10px;
    margin-bottom: 8px;
    font-family: "Open Sans", sans-serif;
    color: $neutral70;
    font-size: 12px;
    font-weight: 600;
  }

  div.v-input:not(.error--text, .v-input--is-focused) {
    fieldset {
      border-color: $neutral10 !important;
    }
  }

  div.v-text-field {
    border-radius: 6px !important;
  }

  div.v-text-field__slot {
    margin-top: auto;
    margin-bottom: auto;
    height: 20px;
    font-family: "Open Sans", sans-serif;
    input {
      color: $neutral70 !important;
      font-size: 14px;
      height: 20px !important;
    }

    input::placeholder {
      font-family: "Open Sans", sans-serif !important;
      color: $neutral30 !important;
      font-weight: 400 !important;
      font-size: 14px;
    }
  }

  .v-input__slot {
    min-height: 40px !important;
    height: 20px;
  }

  .v-input--is-focused {
    .v-input__slot {
      color: $primary !important;
      caret-color: $primary !important;
      box-shadow: 0px 0px 0px 3px $primary10 !important;
      fieldset {
        border-width: 1px !important;
      }
    }
  }
}
</style>
