<template>
  <div class="fixed-header-layout">
    <div v-if="model && !model.hasBeenRefreshedFromApiAtLeastOnce()">
      <div align="center" class="mt-12">
        <v-progress-circular indeterminate size="72"></v-progress-circular>
        <div style="margin-top: 30px; padding: 10px 30px">Loading...</div>
      </div>
    </div>

    <div :style="'opacity: ' + opacity">
      <v-app-bar
        app
        elevation="0"
        height="auto"
        :style="
          'top: 48px;' +
          (hideHeaderSeparator
            ? ''
            : ' border-bottom: solid ' + $colors.penChecksLightGray + ' 1px;')
        "
      >
        <v-container class="my-0 py-0">
          <v-row>
            <v-col>
              <template v-if="hasSlot('title')">
                <div :id="titleId">
                  <slot name="title"></slot>
                </div>
              </template>
              <template v-else-if="title">
                <h2 :id="titleId">{{ title }}</h2>
              </template>

              <template v-if="subtitle">
                <h3>{{ subtitle }}</h3>
              </template>
              <template v-else-if="breadcrumbs && breadcrumbs.length > 0">
                <pc-breadcrumbs :items="breadcrumbs"></pc-breadcrumbs>
              </template>
            </v-col>
            <v-col v-if="hasSlot('page-top-right-content')" class="text-right">
              <slot name="page-top-right-content"></slot>
            </v-col>
            <v-col v-else-if="titleDetail" class="text-right">
              <span class="titleDetail">{{ titleDetail }}</span>
            </v-col>
          </v-row>
          <div
            id="banner"
            v-if="hasSlot('banner')"
            style="margin: auto; max-width: 1400px"
            class="px-7"
          >
            <slot name="banner"></slot>
          </div>
          <div
            id="advisor-navigation"
            v-if="hasSlot('advisor-navigation')"
            :style="
              'margin: 20px -150px; border-top: solid ' +
              $colors.penChecksLightGray +
              ' 1px;'
            "
          >
            <slot name="advisor-navigation"></slot>
          </div>

          <v-row class="tabs-row" v-if="tabs && tabs.length > 0">
            <v-col class="mb-0 pb-0">
              <v-tabs
                class="horizontal-tabs"
                v-model="selectedTabIndex"
                background-color="#FFFFFF00"
                :color="$colors.penChecksDarkTeal"
                elevation="0"
                light
                height="24"
                @change="handleTabChange"
              >
                <v-tab
                  :id="tab.text.toCamelCase() + 'HeaderTab'"
                  v-for="tab in tabs"
                  :key="tab.value"
                  v-model="tab.value"
                  @click="selectItem(tab.value)"
                  >{{ tab.text }}</v-tab
                >
              </v-tabs>
            </v-col>
            <v-col
              class="col-5"
              style="position: relative"
              v-if="hasSlot('tabs-right-content')"
            >
              <div
                style="position: absolute; right: 0; bottom: 0"
                class="mr-3 mb-2"
              >
                <slot name="tabs-right-content"></slot>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-app-bar>

      <div id="main-content-container">
        <v-container
          :style="'margin-top: ' + headerHeight + 'px;'"
          class="py-4"
        >
          <div
            v-if="showYearEndProcessingNotice"
            id="notices"
            class="text-notices-container d-flex flex-column flex-md-row justify-space-between align-md-center px-4 py-4 mt-5"
          >
            <div class="pr-2 mb-4 mb-md-0">
              The 2024 Year End processing deadlines are approaching. To
              guarantee a 2024 processing, service orders must be received
              before their specific deadlines.
            </div>
            <a
              class="notice-link d-flex align-center flex-shrink-0"
              text
              medium
              href="/Amplify2024YearEndProcessingDeadlines.pdf"
              target="_blank"
            >
              <span>SEE SERVICE DEADLINES</span>
              <pc-icon
                :color="$colors.penChecksOrange"
                name="pc-arrow-right"
                size="14"
                class="ml-2"
              />
            </a>
          </div>

          <v-row v-if="tabs && tabs.length > 0">
            <v-col class="mx-4 pa-0">
              <div style="margin: auto">
                <v-window
                  v-model="selectedTabIndex"
                  :class="selectedTabIndex === 1 && 'overflow-visible'"
                >
                  <slot></slot>
                </v-window>
              </div>
            </v-col>
          </v-row>
          <template v-else>
            <v-row v-if="innerTitle || hasSlot('inner-right-content')">
              <v-col>
                <span class="inner-title">{{ innerTitle }}</span>
              </v-col>
              <v-col
                class="col-5"
                style="position: relative"
                v-if="hasSlot('inner-right-content')"
              >
                <div
                  style="position: absolute; right: 0; bottom: 0"
                  class="mr-3 mb-2"
                >
                  <slot name="inner-right-content"></slot>
                </div>
              </v-col>
            </v-row>
            <v-window v-model="selectedTabIndex">
              <slot></slot>
            </v-window>
          </template>
        </v-container>
      </div>
    </div>

    <div v-if="hasSlot('footer')">
      <div style="height: 150px; position: relative">
        <div
          style="
            position: absolute;
            width: 100%;
            justify-content: center;
            display: flex;
            top: 20px;
          "
        >
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import BaseModel from "@/shared/lib/client-sdk/base-model";

export default {
  components: {},

  props: {
    value: {
      type: String,
    },
    model: {
      type: BaseModel,
    },
    title: {
      type: String,
    },
    titleDetail: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    breadcrumbs: {
      type: Array,
    },
    innerTitle: {
      type: String,
    },
    titleId: {
      type: String,
      default: "",
    },
    hideHeaderSeparator: {
      type: Boolean,
      default: false,
    },
    tabOnChange: {
      type: Function,
      default: null,
    },
  },

  data: function () {
    return {
      headerHeight: 56,
      selectedTabIndex: 0,
      tabs: [],
      bannerObserver: null,
      allowTabChange: true,
      previousTab: 0,
    };
  },

  watch: {
    value: function () {
      this.$emit("change");

      this.selectedTabIndex = this.tabs.findIndex(
        (x) => x.value === this.value
      );
    },
    "$urlHash.value": function () {
      this.$emit("input", this.$urlHash.value);
      this.$emit("change");

      this.selectedTabIndex = this.tabs.findIndex(
        (x) => x.value === this.$urlHash.value
      );
    },
  },

  computed: {
    opacity: function () {
      if (this.model && !this.model.hasBeenRefreshedFromApiAtLeastOnce())
        return 0;

      return 1;
    },
    isAdminOrClient() {
      return (
        window.amplifyRuntimeConfig
          .get("VUE_APP_ADMIN_BASE_URL")
          .includes(window.location.hostname) ||
        window.amplifyRuntimeConfig
          .get("VUE_APP_CLIENT_BASE_URL")
          .includes(window.location.hostname)
      );
    },
    showYearEndProcessingNotice() {
      var isExpired =
        new Date() >=
        this.$store.getters.getSystemPropertyAsDate(
          "YearEndProcessingBannerRemovalDate"
        );
      return !isExpired && this.isAdminOrClient;
    },
  },

  methods: {
    async handleTabChange(clickedTab) {
      this.selectedTabIndex = clickedTab;
      await this.$nextTick();
      if (!this.allowTabChange) this.selectedTabIndex = this.previousTab;
      else this.previousTab = this.selectedTabIndex;
    },
    hasSlot(name) {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
    refreshChildren: function () {
      this.tabs = this.$childrenWithTag("pc-horizontal-tabs-item");
    },
    selectItem: function (itemValue) {
      if (this.tabOnChange != null && !this.tabOnChange(itemValue)) {
        this.allowTabChange = false;
        return;
      }
      this.allowTabChange = true;
      this.previousTab = this.selectedTabIndex;
      if (itemValue && itemValue.startsWith("#")) {
        this.$urlHash.set(itemValue);
      } else {
        this.selectedTabIndex = this.tabs.findIndex(
          (x) => x.value === itemValue
        );
        this.$emit("input", this.tabs[this.selectedTabIndex].value);
      }
    },
    updateHeaderHeight: function () {
      this.$nextTick(() => {
        var header = $(this.$el).find(".v-toolbar__content");
        this.headerHeight = header[0].clientHeight + 8;
      });
    },
  },

  mounted: function () {
    if (this.model && !this.model.hasBeenRefreshedFromApiAtLeastOnce())
      this.model.refresh();

    this.updateHeaderHeight();

    this.$nextTick(() => {
      let tabIndexFromUrlHash = this.tabs.findIndex(
        (x) => x.value === this.$urlHash.value
      );
      if (tabIndexFromUrlHash != -1) {
        this.selectedTabIndex = tabIndexFromUrlHash;
      }
    });

    if (this.hasSlot("banner")) {
      this.bannerObserver = new MutationObserver(() => {
        this.updateHeaderHeight();
      });

      this.bannerObserver.observe($("#banner")[0], {
        childList: true,
      });
    }
  },

  beforeDestroy: function () {
    if (this.bannerObserver) {
      this.bannerObserver.disconnect();
    }
  },
};
</script>

<style lang="scss" scoped>
.fixed-header-layout {
  .v-toolbar__content {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }

  .inner-title {
    color: $penChecksMediumGray;
    font-size: 16px;
  }
}

.horizontal-tabs {
  .v-tab {
    text-transform: none !important;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 4px;
    margin-right: 20px;
    margin-left: 0px;
    min-width: 0px;
    letter-spacing: 0px;
  }
}

::v-deep #main-content-container {
  margin: auto;

  .v-window__container {
    overflow: auto;
  }
}

.text-notices-container {
  background: #fff5ed;
  color: $penChecksOrange;
  border: 1px solid $penChecksOrange;
  border-radius: 4px;
  font-size: 13px;
}

.notice-link {
  text-decoration: none;
  color: $penChecksOrange !important;
  font-weight: 500;
}

// needed to properly display .tutorial-tooltip in PcEditOrderLines so it overflows outside its parent element
.overflow-visible {
  overflow: visible;

  ::v-deep .v-responsive {
    overflow: visible;
  }
}

.titleDetail {
  font-size: 14px;
  color: $penChecksDarkGray;
}
</style>
