<template>
  <div
    :class="
      isParticipantPortalField
        ? 'phone-number-text-field is-participant-portal-field'
        : 'phone-number-text-field is-general-field'
    "
  >
    <v-container class="ma-0 pa-0" v-if="model && extensionAttribute">
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0">
          <div
            v-if="isParticipantPortalField && showHeader"
            class="phone-number-text-field-header-style"
          >
            <span v-if="required && !isAccountHolderField" class="error-text"
              >*</span
            >{{ header }}
          </div>
          <v-text-field
            v-model="formattedPhoneNumber"
            :rules="phoneNumberRules"
            :label="
              isParticipantPortalField
                ? ''
                : label + (required || hideOptionalText ? '' : ' (optional)')
            "
            :placeholder="placeholder"
            :required="required"
            :disabled="disabled"
            :clearable="clearable"
            :dense="dense"
            :id="id"
            :validate-on-blur="validateBlur"
            :color="$colors.penChecksTeal"
            @blur="blur"
            @keyup="keyup"
            @keyup.enter="enter($event)"
            :outlined="isParticipantPortalField"
          ></v-text-field>
        </v-col>
        <v-col class="ma-0 pa-0 ml-3" :class="extensionColClass">
          <v-text-field
            class="pa-3"
            v-model="model[extensionAttribute]"
            :label="isParticipantPortalField ? '' : 'Ext. (optional)'"
            :placeholder="isParticipantPortalField ? 'Ext. (optional)' : ''"
            type="number"
            :disabled="disabled"
            :dense="dense"
            :id="id + 'ext'"
            :color="$colors.penChecksTeal"
            :outlined="isParticipantPortalField"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <div v-else>
      <div
        v-if="isParticipantPortalField && showHeader"
        class="phone-number-text-field-header-style"
      >
        <span v-if="required && !isAccountHolderField" class="error-text"
          >*</span
        >{{ header }}
      </div>
      <v-text-field
        v-model="formattedPhoneNumber"
        :rules="phoneNumberRules"
        :label="
          isParticipantPortalField
            ? ''
            : label + (required || hideOptionalText ? '' : ' (optional)')
        "
        :placeholder="isParticipantPortalField ? placeholder : ''"
        :required="required"
        :disabled="disabled"
        :clearable="clearable"
        :dense="dense"
        :id="id"
        :validate-on-blur="validateBlur"
        :color="$colors.penChecksTeal"
        @blur="blur"
        @keyup="keyup"
        @keyup.enter="enter($event)"
        :outlined="isParticipantPortalField"
      >
        <template v-if="isParticipantPortalField" v-slot:prepend-inner>
          <pc-phone-icon class="phone-number-text-field-icon-style" />
        </template>
      </v-text-field>
    </div>
  </div>
</template>
<script>
import BaseModel from "@/shared/lib/client-sdk/base-model";
import Vue from "vue";
import libphonenumber from "google-libphonenumber";
import PcPhoneIcon from "@/shared/components/design-system/PcPhoneIcon.vue";
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

export default {
  name: "PcPhoneNumberTextFieldReskinned",

  components: {
    PcPhoneIcon,
  },
  props: {
    value: {
      type: String,
    },
    model: {
      type: BaseModel,
    },
    phoneNumberAttribute: {
      type: String,
    },
    extensionAttribute: {
      type: String,
    },
    extensionColClass: {
      type: String,
      default: "col-6",
    },
    label: {
      type: String,
      default: "Phone Number",
    },
    placeholder: {
      type: String,
      default: "Phone number",
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    id: {
      type: String,
    },
    clearable: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
    hideOptionalText: {
      type: Boolean,
    },
    isParticipantPortalField: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: "Phone Number",
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    validateBlur: {
      type: Boolean,
      default: true,
    },
    isAccountHolderField: {
      type: Boolean,
      default: false,
    },
    errorLabel: {
      type: String,
      default: "",
    },
  },

  data: function () {
    return {
      formattedPhoneNumber: this.value,
      hasValidProperPhoneNumber: false,
      phoneNumberRules: [
        () => {
          if (this.required && !this.formattedPhoneNumber)
            return this.isAccountHolderField
              ? this.errorLabel
              : "Field cannot be left blank";
          return true;
        },
        () => {
          if (this.formattedPhoneNumber && !this.hasValidProperPhoneNumber)
            return "Please enter a valid phone number";
          return true;
        },
      ],
    };
  },

  watch: {
    formattedPhoneNumber() {
      this.$emit("input", this.properPhoneNumberValue);
      if (this.model && this.phoneNumberAttribute) {
        this.model[this.phoneNumberAttribute] = this.properPhoneNumberValue;
      }
      let phoneNumber = "";
      try {
        // Keep the evaluation similar to PhoneNumber Class in C# Project
        phoneNumber = phoneUtil.parse(this.formattedPhoneNumber, "US");
        this.hasValidProperPhoneNumber = phoneUtil.isValidNumber(phoneNumber);
      } catch (error) {
        this.hasValidProperPhoneNumber = false;
      }

      if (this.properPhoneNumberValue.length != 12)
        this.hasValidProperPhoneNumber = false;
    },
    value() {
      if (this.value != this.properPhoneNumberValue) {
        this.formattedPhoneNumber = this.value;
        this.formatText();
      }
    },
    model: {
      deep: true,
      handler() {
        if (this.value) return;

        if (
          this.model[this.phoneNumberAttribute] != this.properPhoneNumberValue
        ) {
          this.formattedPhoneNumber = this.model[this.phoneNumberAttribute];
          this.formatText();
        }
      },
    },
  },

  computed: {
    properPhoneNumberValue: function () {
      if (
        this.formattedPhoneNumber == null ||
        this.formattedPhoneNumber.trim() == ""
      )
        return "";

      return "+1" + this.formattedPhoneNumber.replace(/[^\d]/g, "");
    },
  },

  methods: {
    textChanged: function (e) {
      var selectionStart = e.target.selectionStart;
      var selectionEnd = e.target.selectionEnd;
      var value = e.target.value;

      if (
        this.formattedPhoneNumber != null &&
        this.formattedPhoneNumber.trim() == ""
      ) {
        this.formattedPhoneNumber = "";
        return;
      }

      var isPasting = (e.ctrlKey || e.metaKey) && e.key.toLowerCase() == "v";

      // Dont format if not at end of string (or pasting)
      if (
        !isPasting &&
        (selectionStart != selectionEnd || selectionStart < value.length)
      ) {
        return;
      }

      if (e.keyCode != 8) {
        // keycode 8 = backspace
        this.formatText();
      }
    },

    formatText: function () {
      if (
        this.formattedPhoneNumber == null ||
        this.formattedPhoneNumber.trim() == ""
      )
        return;

      if (this.formattedPhoneNumber.startsWith("+1"))
        this.formattedPhoneNumber = this.formattedPhoneNumber.substr(2);

      var numbersOnly = this.formattedPhoneNumber.replace(/[^\d]/g, "");

      this.formattedPhoneNumber = "(" + numbersOnly.substr(0, 3);

      if (numbersOnly.length > 3) {
        this.formattedPhoneNumber += ") " + numbersOnly.substr(3, 3);
      }

      if (numbersOnly.length > 6) {
        this.formattedPhoneNumber += "-" + numbersOnly.substr(6, 4);
      }
    },

    enter: function (e) {
      e.stopPropagation();
      this.$emit("enter", e);
    },

    blur: function () {
      this.formatText();
      this.$emit("blur");
    },

    keyup: function (e) {
      this.textChanged(e);
      this.$emit("keyup");
    },
  },

  mounted: function () {
    if (this.model) {
      if (typeof this.model[this.phoneNumberAttribute] == "undefined")
        Vue.set(this.model, this.phoneNumberAttribute, null);

      if (typeof this.model[this.extensionAttribute] == "undefined")
        Vue.set(this.model, this.extensionAttribute, null);
    }

    this.formattedPhoneNumber =
      this.model && this.phoneNumberAttribute
        ? this.model[this.phoneNumberAttribute]
        : this.value;

    this.formatText();
  },
};
</script>

<style lang="scss">
.phone-number-text-field.is-participant-portal-field {
  .phone-number-text-field-header-style {
    margin-left: 10px;
    margin-bottom: 8px;
    font-family: "Open Sans", sans-serif;
    color: $neutral70;
    font-size: 12px;
    font-weight: 600;
  }

  .v-input__prepend-inner {
    margin-top: 12px !important;

    .phone-number-text-field-icon-style {
      color: $primary;
      margin-right: 4px;
    }
  }

  .v-input--is-focused {
    .v-input__slot {
      border-width: 1px !important;
      border-color: $primary !important;
      box-shadow: 0px 0px 0px 3px $primary10 !important;
    }
  }

  div.v-input:not(.error--text, .v-input--is-focused) {
    fieldset {
      border-color: $neutral10 !important;
    }
  }

  div.v-text-field {
    border-radius: 6px !important;
  }

  div.v-text-field__slot {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 20px;
    font-family: "Open Sans", sans-serif;
    input {
      color: $neutral70 !important;
      font-size: 14px;
      height: 20px !important;
    }

    input::placeholder {
      font-family: "Open Sans", sans-serif !important;
      color: $neutral30 !important;
      font-weight: 400 !important;
      font-size: 14px;
    }
  }

  .v-input__slot {
    min-height: 40px !important;
    height: 20px;
  }

  .v-input--is-focused {
    .v-input__slot {
      color: $primary !important;
      caret-color: $primary !important;
      box-shadow: 0px 0px 0px 3px $primary10 !important;
      fieldset {
        border-width: 1px !important;
      }
    }
  }
}
</style>
