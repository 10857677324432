"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import $ from "jquery";

class AccountUser extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "AccountUser";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/account-users"
    );
  }

  getApiPathForCreate() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/accounts/" +
      this.accountId +
      "/users/" +
      this.userId +
      "/invite"
    );
  }

  getApiPathForAccept() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/accounts/" +
      this.accountId +
      "/users/" +
      this.userId +
      "/accept"
    );
  }

  getApiPathForReject() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/accounts/" +
      this.accountId +
      "/users/" +
      this.userId +
      "/reject"
    );
  }
  getApiPathForCancel() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/accounts/" +
      this.accountId +
      "/users/" +
      this.userId +
      "/cancel"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users-invitations/" +
      this.id
    );
  }

  getAdminPath() {
    return "/accounts/" + this.accountId;
  }

  getFullName() {
    var firstName = this.userFirstName ? this.userFirstName : "";
    var lastName = this.userLastName ? this.userLastName : "";

    return firstName + " " + lastName;
  }

  getRole() {
    let userHasRole = this.role !== undefined;
    let userIsAccountOwner = !!this.isAccountOwner;
    let roleDisplayName = "";

    if (!userHasRole) roleDisplayName = " Owner";
    if (userHasRole && userIsAccountOwner) roleDisplayName = " Owner";
    if (userHasRole && !userIsAccountOwner)
      roleDisplayName = " " + this.role.displayName;

    return roleDisplayName;
  }

  addAccountUser(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/accounts/" +
      this.accountId +
      "/users/" +
      this.userId +
      "/account-user";

    var requestData = {
      isAccountOwner: true,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  updateAccountUserDetails(requestData, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/account-user-details";

    ApiRequest.send("PATCH", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  acceptClientAgreement(agreed, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/accounts/" +
      this.accountId +
      "/users/" +
      this.userId +
      "/client-agreement";

    var requestData = {
      hasAgreed: agreed,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  setUserAccessAccount(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/" +
      this.userId +
      "/set-last-accessed-account/" +
      this.accountId;

    ApiRequest.send("POST", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  getAccountUsers(activeUsersOnly = false, callback) {
    const queryParam = activeUsersOnly ? "?activeUsersOnly=true" : "";

    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/accounts/" +
      this.accountId +
      "/users" +
      queryParam;

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  getAccountUsersForUser(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/accounts/" +
      this.userUuid +
      "/user";

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }
}

export default AccountUser;
