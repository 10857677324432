"use strict";

import SearchRequestHandler from "@/shared/lib/client-sdk/search/search-request-handler";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import $ from "jquery";

class BaseModelList {
  constructor(parentModel) {
    this.parentModel = parentModel;
    this.currentOperation = null;
    this._searchRequestHandler = null;

    this.items = [];
  }

  /***
   * Must be overridden by child class
   */

  getModelClassName() {
    console.error(
      this.constructor.name + " needs to implement getModelClassName()"
    );
  }

  getApiPathForSearch() {
    console.error(
      this.constructor.name + " needs to implement getApiPathForSearch()"
    );
  }

  getApiPathForAdd() {
    console.error(
      this.constructor.name + " needs to implement getApiPathForAdd()"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    console.error(
      this.constructor.name +
        " needs to implement getApiPathForRefreshUpdateAndDelete()"
    );
  }

  searchRequestHandler() {
    if (!this._searchRequestHandler) {
      var className = this.getModelClassName();
      var searchRequestUrl = this.getApiPathForSearch();

      if (!className || !searchRequestUrl) return;

      this._searchRequestHandler = new SearchRequestHandler(className);
      this._searchRequestHandler.setRequestUrl(searchRequestUrl);
    }

    return this._searchRequestHandler;
  }

  get isBusy() {
    if (this.currentOperation) return true;

    const searchRequestHandler = this.searchRequestHandler();
    if (searchRequestHandler && searchRequestHandler.isBusy) return true;

    return false;
  }

  get isRefreshing() {
    return this.currentOperation == "refreshing";
  }

  get isAdding() {
    return this.currentOperation == "adding";
  }

  get isDeleting() {
    return this.currentOperation == "deleting";
  }

  refresh(callback, operation = "refreshing") {
    var searchRequestHandler = this.searchRequestHandler();

    if (searchRequestHandler) {
      this.currentOperation = operation;

      searchRequestHandler.search((searchResult) => {
        this.items = searchResult.results;

        if ($.isFunction(callback)) callback(this.items);

        this.currentOperation = null;
      });
    }
  }

  add(newModel, callback) {
    this.currentOperation = "adding";

    var requestUrl = this.getApiPathForAdd(newModel);
    var postBody = newModel.getAttributes();
    ApiRequest.send("POST", requestUrl, postBody).onComplete(
      function () {
        this.refresh(callback, "adding");
      }.bind(this)
    );
  }

  delete(modelToDelete, callback) {
    this.currentOperation = "deleting";

    var index = this.items.indexOf(modelToDelete);
    if (index >= 0) this.items.splice(index, 1);

    var requestUrl = this.getApiPathForRefreshUpdateAndDelete(modelToDelete);
    ApiRequest.send("DELETE", requestUrl).onComplete(
      function () {
        this.refresh(callback, "deleting");
      }.bind(this)
    );
  }
}

export default BaseModelList;
