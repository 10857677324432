<template>
  <div>
    <div
      :style="'border-bottom: solid ' + $colors.penChecksLightGray + ' 1px;'"
    >
      <v-container class="pb-0">
        <v-row>
          <v-col class="pb-0">
            <v-tabs
              class="horizontal-tabs"
              v-model="selectedTabIndex"
              background-color="#FFFFFF00"
              :color="$colors.penChecksDarkTeal"
              elevation="0"
              light
              height="24"
            >
              <v-tab
                v-for="tab in tabs"
                :key="tab.value"
                v-model="tab.value"
                @click="selectItem(tab.value)"
                >{{ tab.text | titleCase }}</v-tab
              >
            </v-tabs>
          </v-col>
          <v-col style="position: relative">
            <div
              class="mr-5 mb-2 float-right"
              style="position: absolute; right: 0; bottom: 0"
            >
              <slot name="right-content"></slot>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container>
      <v-row>
        <v-col class="py-5 mx-4">
          <!-- MAIN TAB CONTENT -->
          <div style="margin: auto; max-width: 1400px">
            <v-window v-model="selectedTabIndex">
              <slot></slot>
            </v-window>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
  },

  data: () => {
    return {
      tabs: [],
      selectedTabIndex: 0,
      observer: null,
    };
  },

  watch: {
    value: function () {
      this.$emit("change");

      this.selectedTabIndex = this.tabs.findIndex(
        (x) => x.value === this.value
      );
    },
    "$urlHash.value": function () {
      this.$emit("input", this.$urlHash.value);
      this.$emit("change");

      this.selectedTabIndex = this.tabs.findIndex(
        (x) => x.value === this.$urlHash.value
      );
    },
  },

  methods: {
    refreshTabValues: function () {
      this.$nextTick(() => {
        this.tabs = [];

        for (var i in this.$slots.default) {
          var vnode = this.$slots.default[i];

          if (
            vnode.componentInstance &&
            vnode.componentInstance.$el.className.includes("tab-item") &&
            vnode.componentInstance.value
          ) {
            this.tabs.push({
              value: vnode.componentInstance.value,
              text: vnode.componentInstance.text,
              instance: vnode.componentInstance,
            });
          }
        }

        var indexValue = this.value
          ? this.tabs.findIndex((x) => x.value === this.value)
          : 0;
        this.selectedTabIndex = indexValue >= 0 ? indexValue : 0;

        if (!this.value && this.tabs.length > 0)
          this.$emit("input", this.tabs[0].value);
      });
    },
    selectItem: function (itemValue) {
      if (itemValue && itemValue.startsWith("#")) {
        this.$urlHash.set(itemValue);
      } else {
        this.selectedTabIndex = this.tabs.findIndex(
          (x) => x.value === itemValue
        );
        this.$emit("input", this.tabs[this.selectedTabIndex].value);
      }
    },
  },

  mounted: function () {
    this.refreshTabValues();
  },
};
</script>

<style lang="scss">
.horizontal-tabs {
  .v-tab {
    text-transform: none !important;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 4px;
    margin-left: 20px;
    min-width: 0px;
    letter-spacing: 0px;
  }
}
</style>
