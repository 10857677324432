"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import $ from "jquery";

import Payment from "@/shared/lib/client-sdk/models/payment";
import PaymentInstruction from "@/shared/lib/client-sdk/models/payment-instruction";

class PaymentBatchPaymentInstruction extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "PaymentBatchPaymentInstruction";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/payment-batch-payment-instructions"
    );
  }

  getApiPathForCreate() {
    console.error(
      "Cannot create a payment batch payment instruction directly, it is created as part of a payment batch."
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    console.error("Cannot update a payment batch payment instruction.");
  }

  getAdminPath() {
    return this.paymentId ? "/payments/" + this.paymentId : null;
  }

  get searchResultTitle() {
    return this.flagName;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  get relationships() {
    return {
      payment: Payment,
      paymentInstruction: PaymentInstruction,
    };
  }

  send(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/payment-batch-payment-instructions/" +
      this.id +
      "/send";

    ApiRequest.send("POST", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  cancel(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/payment-batch-payment-instructions/" +
      this.id +
      "/cancel";

    ApiRequest.send("POST", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }
}

export default PaymentBatchPaymentInstruction;
