<template>
  <v-app id="admin-app">
    <pc-singleton-modals id="singleton-admin-modals"></pc-singleton-modals>
    <pc-singleton-overlay id="singleton-admin-overlay"></pc-singleton-overlay>
    <pc-singleton-snackbar
      id="singleton-admin-snackbar"
    ></pc-singleton-snackbar>

    <div
      v-if="
        initialAuthenticationRequestComplete &&
        $store.state.authenticatedUser.isStaffMember()
      "
      class="fill-height"
    >
      <!-- Only show the application if we've authenticated successfully a staff member -->
      <router-view></router-view>
    </div>
  </v-app>
</template>

<script>
import User from "@/shared/lib/client-sdk/models/user";
import notifyPendo from "@/shared/lib/notifyPendo";

export default {
  data: () => ({
    initialAuthenticationRequestComplete: false,
  }),

  mounted: function () {
    window.pendo.initialize();
    if (!User.isCurrentlyAuthenticated()) {
      document.location =
        window.amplifyRuntimeConfig.get("VUE_APP_LOGIN_URL") +
        "?returnUrl=" +
        encodeURI(window.location.href);
    }

    // Initalize with the progress overlay
    this.$overlay.show("Loading...");

    // The first thing we want to do is refresh our authenticated user.
    User.fetchAuthenticatedUser(
      function (user) {
        if (user && user.isStaffMember()) {
          this.$store.commit("setAuthenticatedUser", user);
          this.initialAuthenticationRequestComplete = true;
          notifyPendo(user);
          this.$overlay.hide();
          this.$mixpanel.identify(user.emailAddress);
          this.$mixpanel.people.set({
            "User Type": user.type,
            $name: user.firstName + " " + user.lastName,
            $email: user.emailAddress,
          });
        } else {
          document.location =
            window.amplifyRuntimeConfig.get("VUE_APP_LOGIN_URL") +
            "?returnUrl=" +
            encodeURI(window.location.href);
        }
      }.bind(this)
    );

    this.$cache.fetchAndCacheLookupData();
  },
};
</script>

<style lang="scss">
#admin-app {
  font-family: "Roboto", sans-serif;

  // Vuetify v2.4 changed styling for nested grid items, causing spacing across the
  // app to be affected. A solution was found here that restores our previous spacing:
  // https://github.com/vuetifyjs/vuetify/issues/12848
  .row {
    margin-top: 0;
    margin-bottom: 0;
  }

  .row + .row {
    margin-top: 0;
  }
}

// Fixing an issue with vuetify, for some reason the text box doesn't align with the drop down menus
.v-input--dense .v-text-field__slot,
.v-input--dense .v-select__slot {
  height: 32px !important;
}

th > a,
td > a {
  color: $penChecksTeal !important;
}

.requiredField:before {
  content: " *";
  color: #d32f2f;
  margin-right: 5px;
  margin-top: 5px;
  font-size: 15px;
}

.requiredFieldText .requiredField:before {
  vertical-align: top;
}

.requiredFieldText {
  vertical-align: text-top;
  font-size: 13px;
  color: #989898;
}
</style>
