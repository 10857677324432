<template>
  <v-window-item v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </v-window-item>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
  },

  data: () => {
    return {
      parentWindow: null,
    };
  },

  mounted: function () {
    this.parentWindow = this.$parentWithTag("pc-window");
    if (this.parentWindow) {
      this.parentWindow.refreshChildren();
    }
  },

  destroyed: function () {
    if (this.parentWindow) this.parentWindow.refreshChildren();
  },
};
</script>

<style lang="scss"></style>
