var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scrolling-prompts"},[_c('div',{staticClass:"text-center fill-width",style:('cursor: pointer; opacity: ' +
        (this.value > 0 ? 1 : 0) +
        '; transition: opacity 0.3s ease;'),on:{"click":function($event){return _vm.$emit('input', _vm.value - 1)}}},[_c('pc-icon',{attrs:{"name":"pc-scroll-up","color":_vm.$colors.penChecksGray}})],1),_c('div',{style:('position: relative; transition: height 1.2s ease; height: ' +
        _vm.visibleHeight +
        'px; overflow: hidden; background-color: ' +
        _vm.backgroundColor)},[_c('div',{style:('position: absolute; transition: top 1.2s ease; top: ' + _vm.top + 'px;')},[_vm._t("default")],2),_c('div',{style:('position: absolute; bottom: 0px;width: 100%; height: 100px; background: linear-gradient(180deg, ' +
          _vm.backgroundColor +
          '00, ' +
          _vm.backgroundColor +
          'FF;')})])])
}
var staticRenderFns = []

export { render, staticRenderFns }