"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import $ from "jquery";

class Role extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "Role";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") + "/public/roles"
    );
  }

  static fetchRoles(callback) {
    var requestUrl = this.getApiPathForSearch();

    ApiRequest.send("GET", requestUrl, null, Role).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  getApiPathForCreate() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") + "/private/roles"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/roles/" +
      this.id
    );
  }

  getAdminPath() {
    console.error("Cannot go to a role.");
  }

  get searchResultTitle() {
    return this.displayName;
  }

  // Returns a string, or an array of strings (for a multi-line subtitle)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }
}

export default Role;
