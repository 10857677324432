import Vue from "vue";

Vue.filter("intelligentChipColor", function (value) {
  // Based on the value in the chip, this will select what it chooses is the best color to represent that value

  value = value ? value.toString().toLowerCase() : "";

  if (value === "false" || value === "no") return "#E87926";

  if (value == "staffmember") return "#98B83C";

  if (value == "client") return "#3D8A9E";

  if (value == "participant") return "#113B61";

  return "";
});
