import Vue from "vue";
import VueRouter from "vue-router";

var router = {};

if (window.location.host.match(/auth.*\.penchecks\.com/g) !== null) {
  Vue.use(VueRouter);

  const routes = [
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/login",
      name: "Login",
      meta: { title: "Login" },
      component: () => import("../views/users/PcLogin.vue"),
    },
    {
      path: "/sign-up/:emailAddress",
      name: "Sign up",
      meta: { title: "Sign up" },
      component: () => import("../views/users/PcSignUp.vue"),
    },
    {
      path: "/user-invitation/:accountUserUuid([a-zA-Z0-9]{32})",
      name: "User Invite",
      meta: { title: "User Invite" },
      component: () => import("../views/users/PcUserInvite.vue"),
    },
    {
      path: "/change-email-address/:emailAddress/:token",
      name: "Change Email Address",
      meta: { title: "Change Email Address" },
      component: () => import("../views/users/PcChangeEmailAddress.vue"),
    },
    {
      path: "/forgot-password",
      name: "Forgot Password",
      meta: { title: "Forgot Password" },
      component: () => import("../views/users/PcForgotPassword.vue"),
    },
    {
      path: "/reset-password/:email_address/:reset_password_token",
      name: "Reset Password",
      meta: { title: "Reset Password" },
      component: () => import("../views/users/PcResetPassword.vue"),
    },
    {
      path: "/confirm-email-address",
      name: "Confirm Email Address",
      meta: { title: "Confirm Email Address" },
      component: () => import("../views/users/PcConfirmEmailAddress.vue"),
    },
    {
      path: "/logout",
      name: "Logout",
      meta: { title: "Logout" },
      component: () => import("../views/users/PcLogout.vue"),
    },
    {
      path: "/file-download/:orderUuid([a-zA-Z0-9]{32})/:fileType",
      name: "File Download",
      meta: { title: "File Download" },
      component: () =>
        import("../views/fileDownload/PcBulkOrderFileDownload.vue"),
    },
    {
      path: "/noticecontactinformation",
      name: "Notice Contact Information",
      meta: { title: "Notice Contact Information" },
      component: () =>
        import(
          "../views/noticeContactInformation/PcNoticeContactInformation.vue"
        ),
    },
  ];

  router = new VueRouter({
    mode: "history",
    routes,
  });

  router.afterEach((to) => {
    if (to.meta && to.meta.title) {
      document.title = to.meta.title + " | PenChecks";
    } else {
      document.title = "PenChecks";
    }

    if (router.app && router.app.$gtag) {
      router.app.$gtag.pageview({
        page_title: document.title,
        page_path: to.path,
        page_location: window.location.href,
      });
    }
  });
}

export default router;
