const noticeNameCodes = {
  serviceCodes: [
    {
      acronym: "PRM",
      name: "Premier",
    },
    {
      acronym: "EXP",
      name: "Express",
    },
    {
      acronym: "IRA",
      name: "ARO (IRA)",
    },
    {
      acronym: "TSA",
      name: "ARO (TSA)",
    },
    {
      acronym: "NQU",
      name: "Non-Qualified Uncashed Check",
    },
    {
      acronym: "QUC",
      name: "Qualified Uncashed Check",
    },
    {
      acronym: "EMC",
      name: "Enhanced IRA Mailing Campaign",
    },
  ],
  noticeTypes: [
    {
      acronym: "WL",
      name: "Welcome Notice",
    },
    {
      acronym: "PF",
      name: "Paper Notice",
    },
    {
      acronym: "IN",
      name: "Invite Notice",
    },
    {
      acronym: "CC",
      name: "Combined with Cash Out Notice",
    },
    {
      acronym: "U7",
      name: "7K under Rollover only Notice",
    },
    {
      acronym: "17",
      name: "1K-7K Rollover Notice",
    },
  ],
  noticeRules: [
    {
      acronym: "PIT",
      name: "Plan Is Terminating",
      colorClass: "teal-text",
    },
    {
      acronym: "PNT",
      name: "Plan Is Not Terminating",
      colorClass: "teal-text",
    },
    {
      acronym: "APF",
      name: "Allows Partial Funding",
      colorClass: "orange-text",
    },
    {
      acronym: "NPF",
      name: "Not allows Partial Funding",
      colorClass: "orange-text",
    },
    {
      acronym: "ROL",
      name: "Automatic Rollover",
      colorClass: "purple-text",
    },
    {
      acronym: "RTP",
      name: "Return assets back to Plan",
      colorClass: "purple-text",
    },
    {
      acronym: "CON",
      name: "Contact me",
      colorClass: "purple-text",
    },
    {
      acronym: "O7k",
      name: "Over $7K",
      colorClass: "blue-text",
    },
    {
      acronym: "U7k",
      name: "Under $7k",
      colorClass: "blue-text",
    },
    {
      acronym: "IJS",
      name: "Is Joint Survivor annuity",
      colorClass: "pink-text",
    },
    {
      acronym: "NJS",
      name: "Not Joint Survivor annuity",
      colorClass: "pink-text",
    },
    {
      acronym: "NRA",
      name: "Is NonResident Alien",
      colorClass: "green-text",
    },
    {
      acronym: "NNR",
      name: "Is Not NonResident Alien",
      colorClass: "green-text",
    },
  ],
  specialtyRulesAndFlags: [
    {
      acronym: "ACC",
      name: "Notice is attached to Account(s)",
    },
    {
      acronym: "PLN",
      name: "Notice is attached to Plan(s)",
    },
    {
      acronym: "NSD",
      name: "No Special Deliveries",
    },
    {
      acronym: "SWR",
      name: "Special Withholding Rules",
    },
    {
      acronym: "TPF",
      name: "Termination Percentage Field",
    },
  ],
};

export default noticeNameCodes;
