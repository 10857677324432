"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import PaymentBatchPaymentInstruction from "@/shared/lib/client-sdk/models/payment-batch-payment-instruction";

import $ from "jquery";

var moment = require("moment");

class PaymentBatch extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "PaymentBatch";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/payment-batches"
    );
  }

  getApiPathForCreate() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/payment-batches"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/payment-batches/" +
      this.id
    );
  }

  getAdminPath() {
    return "/payment-batches/" + this.id;
  }

  get searchResultTitle() {
    return null;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  get relationships() {
    return {
      paymentBatchPaymentInstructions: PaymentBatchPaymentInstruction,
    };
  }

  static getEffectiveDate(paymentBatchType, targetEffectiveDate, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/payment-batches/effective-date?paymentBatchType=" +
      paymentBatchType;

    if (targetEffectiveDate) {
      targetEffectiveDate = moment
        .utc(targetEffectiveDate)
        .format("YYYY-MM-DD");
      requestUrl += "&targetEffectiveDate=" + targetEffectiveDate;
    }

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  enterAtBank(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/payment-batches/" +
      this.id +
      "/enter-at-bank";

    ApiRequest.send("POST", requestUrl, null, PaymentBatch).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  print(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/payment-batches/" +
      this.id +
      "/print";

    ApiRequest.send("POST", requestUrl, null, PaymentBatch).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  send(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/payment-batches/" +
      this.id +
      "/send";

    ApiRequest.send("POST", requestUrl, null, PaymentBatch).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }
}

export default PaymentBatch;
