"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";

class AssetTrackingLedgerEntry extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "AssetTrackingLedgerEntry";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/asset-tracking-ledger-entry"
    );
  }

  getApiPathForCreate() {
    // console.error("Cannot create annuity types.")
  }

  getApiPathForRefreshUpdateAndDelete() {
    // return window.amplifyRuntimeConfig.get('VUE_APP_API_BASE_URL') + "/public/annuity-types/" + this.code
  }

  getAdminPath() {
    if (this.orderLineId) return "/order-lines/" + this.orderLineId + "#ledger";
  }

  get fullName() {
    var names = [];
    if (this.participantFirstName && this.participantFirstName.trim() != "")
      names.push(this.participantFirstName.trim());
    if (this.participantLastName && this.participantLastName.trim() != "")
      names.push(this.participantLastName.trim());

    return names.join(" ");
  }

  get searchResultTitle() {
    return this.fullName;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return " Friendly Id: " + this.orderLineFriendlyId;
  }

  get searchResultCreatedAt() {
    return this.orderLineCreatedAt;
  }

  get searchResultStatus() {
    return this.orderLineStatus;
  }
}

export default AssetTrackingLedgerEntry;
