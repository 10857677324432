<template>
  <pc-tooltip
    :disabled="!$getStatusTooltip(entity, value, statusDetail)"
    :value="$getStatusTooltip(entity, value, statusDetail)"
  >
    <span v-if="value" class="d-inline-block">
      <v-chip
        :class="{
          'pc-chip': true,
          'pc-chip-white-background': hasWhiteBackground,
        }"
        :color="$getStatusBackgroundColor(value)"
        :text-color="$getStatusTextColor(value)"
        :outlined="isChipOutlined"
        :style="'background-color: ' + $getStatusBackgroundColor(value)"
        :x-small="xSmall"
        :small="!medium && !xSmall"
        :large="large"
      >
        <slot v-if="hasSlot('default')"> </slot>
        <template v-else>
          <span
            v-if="value && value.toLowerCase() == 'cancellationrequestedatbank'"
          >
            Stop Payment
          </span>
          <span v-else-if="value && value == 'ira/tsa'">IRA/TSA</span>
          <span v-else-if="value && value == 'ira'">IRA</span>
          <span v-else-if="value && value == 'tsa'">TSA</span>
          <span v-else>
            {{ value | sentenceCase }}
          </span>
        </template>
      </v-chip>
    </span>
  </pc-tooltip>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    statusDetail: {
      type: String,
      default: "",
    },
    entity: {
      type: String,
      default: "",
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    valueTheme: function () {
      return this.$getStatusThemeName(this.value);
    },
    isChipOutlined: function () {
      return (
        this.valueTheme === "lightValue" ||
        this.valueTheme === "mediumGrayValue"
      );
    },
    hasWhiteBackground: function () {
      return this.valueTheme === "lightValue";
    },
  },
  methods: {
    hasSlot: function (name) {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
  },
};
</script>

<style lang="scss">
.pc-chip {
  height: 24px !important;
  padding: 12px;
  min-width: 48px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px !important;
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 12px !important;
  width: min-content;
}

.pc-chip.v-chip.v-chip--outlined {
  border-width: 1px;
  border-style: solid;
}

.theme--light.v-chip.pc-chip:hover::before {
  opacity: 0;
}

.pc-chip-white-background {
  background-color: $white !important;
}

.v-chip.v-chip--outlined.v-chip.v-chip.pc-chip-white-background {
  background-color: $white !important;
}

td {
  .pc-chip {
    margin: 0px;
  }
}
</style>
