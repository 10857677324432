<template>
  <v-tooltip
    :disabled="disabled"
    :bottom="realBottom"
    :right="right"
    :left="left"
    :top="top"
    :max-width="maxWidth"
  >
    <template v-slot:activator="{ on }">
      <span v-on="on" :class="{ 'avoid-overflow': avoidOverflow }">
        <slot></slot>
      </span>
    </template>
    <span v-html="value"></span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    avoidOverflow: {
      type: Boolean,
      default: false,
      required: false,
    },
    maxWidth: {
      type: Number,
      default: 300,
      required: false,
    },
  },

  computed: {
    realBottom: function () {
      if (!this.right && !this.left && !this.top) return true;

      return this.bottom;
    },
  },
};
</script>

<style lang="scss" scoped>
.avoid-overflow {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  color: $penChecksDarkGray;
}
</style>
