<template>
  <div v-if="this.size && this.size == 16">
    <export-16 :class="iconColorClass" />
  </div>
  <div v-else-if="this.size && this.size == 20">
    <export-20 :class="iconColorClass" />
  </div>
  <div v-else-if="this.size && this.size == 24">
    <export-24 :class="iconColorClass" />
  </div>
  <div v-else-if="this.size && this.size == 32">
    <export-32 :class="iconColorClass" />
  </div>
  <div v-else><export-16 :class="iconColorClass" /></div>
</template>

<script>
import { Export16, Export20, Export24, Export32 } from "@carbon/icons-vue";

export default {
  name: "PcExportIcon",

  components: {
    "export-16": Export16,
    "export-20": Export20,
    "export-24": Export24,
    "export-32": Export32,
  },

  props: {
    size: {
      type: [Number, String],
      validator: function (value) {
        return [16, "16", 20, "20", 24, "24", 32, "32"].includes(value);
      },
      required: false,
      default: 16,
    },
    iconColorClass: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
