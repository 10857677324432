<template>
  <pc-tooltip
    :disabled="!tooltip || tooltipText.trim() == ''"
    v-model="tooltipText"
    :bottom="tooltipBottom"
    :right="tooltipRight"
    :top="tooltipTop"
    :left="tooltipLeft"
    class="pc-tooltip"
  >
    <v-btn
      :outlined="outlined"
      :large="large"
      :dark="!text && !outlined && !disabled"
      :color="buttonColor"
      :icon="icon"
      :text="text || iconText"
      :target="target"
      :href="href"
      :block="block"
      :id="id"
      :to="to"
      @click="clicked"
      @click.middle="clickedMiddle"
      :elevation="0"
      :disabled="disabled"
    >
      <slot></slot>
    </v-btn>
  </pc-tooltip>
</template>

<script>
export default {
  props: {
    tooltip: {
      type: Boolean,
    },
    tooltipText: {
      type: String,
    },
    tooltipBottom: {
      type: Boolean,
    },
    tooltipRight: {
      type: Boolean,
    },
    tooltipLeft: {
      type: Boolean,
    },
    tooltipTop: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    },
    text: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    iconText: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
    },
    target: {
      type: String,
    },
    id: {
      type: String,
    },
    block: {
      type: Boolean,
    },
    to: {
      type: [String, Object],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonColor: function () {
      return this.color || this.$colors.penChecksTeal;
    },
  },
  methods: {
    clicked: function (event) {
      if (event.ctrlKey) {
        this.$emit("ctrlClick", event);
      } else {
        this.$emit("click", event);
      }
    },
    clickedMiddle: function (event) {
      this.$emit("middleClick", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.pc-tooltip {
  width: 100%;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 32px !important;
  padding: 0 24px !important;
  min-width: 96px;
  max-width: 332px;
  border-radius: 4px;
}

.v-btn:not(.v-btn--round).v-size--default.v-btn--text {
  padding: 0 8px !important;
  min-width: 44px !important;
  max-width: 332px;
}

.v-btn:not(.v-btn--round).v-size--large {
  height: 48px !important;
  min-width: 96px !important;
  padding: 0 24px !important;
  border-radius: 4px;
}

.v-btn.v-size--default.v-btn--icon {
  height: 40px !important;
  width: 40px !important;
}
</style>

<style>
.v-btn--icon.v-size--default .v-icon,
.v-btn--fab.v-size--default .v-icon {
  height: 16px !important;
  font-size: 16px !important;
  width: 24px;
  margin: 0px !important;
}

.v-btn > .v-btn__content .v-icon {
  height: 14px;
  font-size: 14px;
  margin-right: 5px;
}
</style>
