<template>
  <pc-modal :show="show" size="small" centerButtons @close="handleClose" id="closeSurveyViewButton">
    <div class="title" id="surveyViewTitleText" >{{ title }}</div>
    <div class="text pb-5 mx-auto">
      {{ text }}
    </div>
    <template v-slot:modal-buttons>
      <pc-button id="startSurveyButton" @click="handleStartSurvey">Start Survey</pc-button>
    </template>
  </pc-modal>
</template>

<script>
import ApiRequest from "@/shared/lib/client-sdk/api-request";

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    eventType: {
      type: String,
      required: true,
      validator: (value) => {
        return [
          "PREMSUBMIT", // premier distribution
          "EXPRESSSUBMIT", // express distribution
          "EXPRESSIRA", // Express Automatic Rollover IRA
          "BESUBMIT", // Any payment instruction submission on the participant portal
        ].includes(value);
      },
    },
  },

  data: function () {
    return {
      clientSurveyUrl: "https://formstack.io/3B3DA?",
      participantSurveyUrl: "https://formstack.io/11F20?",
      paramToEnableSurveyTestingMode: "FSGFShortAnswer627=True",
      logSurveyEventUrl: `${window.amplifyRuntimeConfig.get(
        "VUE_APP_API_BASE_URL"
      )}/public/set-survey-flag?eventType=${this.eventType}`,
      urlParams: {
        masterClientId: {
          key: "Feedback__c.Amplify_Master_Client_Id__c=",
          value: this.$store.state.masterAccount?.id,
        },
        accountType: {
          key: "Feedback__c.Amplify_Account_Type__c=",
          value: this.$store.state.masterAccount?.accountType?.displayName,
        },
        lastName: {
          key: "Feedback__c.Last_Name__c=",
          value: this.$store.state.authenticatedUser.lastName,
        },
        firstName: {
          key: "Feedback__c.First_Name__c=",
          value: this.$store.state.authenticatedUser.firstName,
        },
        email: {
          key: "Feedback__c.Email__c=",
          value: this.$store.state.authenticatedUser.emailAddress,
        },
        eventType: {
          key: "Feedback__c.Amplify_Use_Case__c=",
          value: this.eventType,
        },
      },
      clientPortalTitle: "How Can We Make Amplify Better For You?",
      clientPortalText:
        "We value your feedback and would appreciate you taking a quick (about 2 minute) survey to let us know how we can give you the best experience going forward.",
      participantPortalTitle: "How Was Your Experience?",
      participantPortalText:
        "We value your feedback and would appreciate you taking a quick (about 2 minute) survey to let us know how we can improve the Amplify user expereince.",
    };
  },
  computed: {
    title() {
      if (this.isOnParticipantPortal) return this.participantPortalTitle;

      return this.clientPortalTitle;
    },
    text() {
      if (this.isOnParticipantPortal) return this.participantPortalText;

      return this.clientPortalText;
    },
    surveyUrl() {
      let url = this.isOnParticipantPortal
        ? this.participantSurveyUrl
        : this.clientSurveyUrl;

      url +=
        window.amplifyRuntimeConfig.get("VUE_APP_ENVIRONMENT") !==
          "production" && `${this.paramToEnableSurveyTestingMode}&`;

      Object.keys(this.urlParams).forEach((param, index) => {
        const isLastParam = index + 1 === Object.keys(this.urlParams).length;

        url += `${this.urlParams[param].key}${this.urlParams[param].value}${
          isLastParam ? "" : "&"
        }`;
      });

      return url;
    },
    isOnParticipantPortal() {
      if (
        window.amplifyRuntimeConfig
          .get("VUE_APP_PARTICIPANT_BASE_URL")
          .includes(window.location.hostname)
      )
        return true;

      return false;
    },
  },
  methods: {
    handleStartSurvey() {
      window.open(this.surveyUrl, "_blank");
      this.$emit("close");
      const requestUrl = this.logSurveyEventUrl + "&wasDismissed=false";
      ApiRequest.send("PATCH", requestUrl);
    },
    handleClose() {
      this.$emit("close");
      const requestUrl = this.logSurveyEventUrl + "&wasDismissed=true";
      ApiRequest.send("PATCH", requestUrl);
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 18px !important;
  font-weight: bold;
  color: $penChecksTeal;
  text-align: center;
  padding-bottom: 23px;
}

.text {
  font-size: 14px;
  font-weight: bold;
  color: $penChecksDark;
  text-align: center;
  max-width: 375px;
}
</style>
