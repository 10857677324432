import Vue from "vue";
import Vuex from "vuex";
import SystemPropertiesModule from "@/shared/store/modules/system-properties.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {
    setAppVersion(state, appVersion) {
      state.appVersion = appVersion;
    },
  },
  actions: {},
  modules: {
    systemProperties: SystemPropertiesModule,
  },
});
