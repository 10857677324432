<template>
  <div class="pc-check-example">
    <div class="date-text">
      {{ dateText }}
    </div>
    <div class="payee-text">
      {{ payee }}
    </div>
    <div class="amount-text">
      {{ amount | currency }}
    </div>
    <div class="memo-text">
      {{ memo }}
    </div>
    <div class="signature-text">
      {{ signature }}
    </div>
    <div class="baseline-text">
      {{ baseLineText }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      type: String,
    },
    payee: {
      type: String,
      default: "PenChecks Trust Company of America",
    },
    memo: {
      type: String,
      default: "Plan name/beneficiary",
    },
    signature: {
      type: String,
      default: " ",
    },
    baseLineText: {
      type: String,
      default: "123456789 012345678 01",
    },
    amount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    dateText: function () {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      return mm + "/" + dd + "/" + yyyy;
    },
  },
  methods: {
    hasSlot(name) {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
  },
};
</script>

<style lang="scss">
.pc-check-example {
  box-shadow: 0px 3px 6px #00000014;
  border: 1px solid $penChecksLightGray;
  min-height: 150px;
  position: relative;
  min-width: 350px;
  max-width: 350px;

  .date-text {
    position: absolute;
    right: 30px;
    top: 10px;
    font-size: 14px;
    color: $penChecksDarkGray;
    padding-bottom: 4px;
    padding-right: 6px;
    padding-right: 6px;
    border-bottom: solid $penChecksGray 2px;
    width: 100px;
    text-align: right;
    font-weight: 300;
  }

  .payee-text {
    position: absolute;
    right: 30px;
    top: 44px;
    left: 30px;

    font-size: 14px;
    color: $penChecksTeal;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;
    border-bottom: solid $penChecksGray 2px;

    text-align: left;
  }

  .amount-text {
    position: absolute;
    right: 30px;
    top: 72px;
    left: 30px;

    font-size: 16px;
    color: $penChecksTeal;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;
    border-bottom: solid $penChecksGray 2px;

    text-align: right;
  }

  .memo-text {
    position: absolute;
    width: 190px;
    top: 100px;
    left: 30px;

    font-size: 14px;
    color: $penChecksTeal;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;
    border-bottom: solid $penChecksGray 2px;

    text-align: left;
  }

  .signature-text {
    position: absolute;
    width: 75px;
    top: 100px;
    right: 30px;
    height: 22px;

    font-size: 14px;
    color: $penChecksTeal;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;
    border-bottom: solid $penChecksGray 2px;

    text-align: right;
  }

  .baseline-text {
    position: absolute;
    width: 155px;
    top: 125px;
    left: 30px;

    font-size: 10px;
    color: $penChecksGray;
    padding-left: 6px;
  }
}
</style>
