<template>
  <div v-if="this.size && this.size == 16">
    <phone-16 :class="iconColorClass" />
  </div>
  <div v-else-if="this.size && this.size == 20">
    <phone-20 :class="iconColorClass" />
  </div>
  <div v-else-if="this.size && this.size == 24">
    <phone-24 :class="iconColorClass" />
  </div>
  <div v-else-if="this.size && this.size == 32">
    <phone-32 :class="iconColorClass" />
  </div>
  <div v-else><phone-16 :class="iconColorClass" /></div>
</template>

<script>
import { Phone16, Phone20, Phone24, Phone32 } from "@carbon/icons-vue";

export default {
  name: "PcPhoneIcon",

  components: {
    "phone-16": Phone16,
    "phone-20": Phone20,
    "phone-24": Phone24,
    "phone-32": Phone32,
  },

  props: {
    size: {
      type: [Number, String],
      validator: function (value) {
        return [16, "16", 20, "20", 24, "24", 32, "32"].includes(value);
      },
      required: false,
      default: 16,
    },
    iconColorClass: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
