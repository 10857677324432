"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";

class InboundRawFile extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "InboundRawFile";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/inbound-raw-files"
    );
  }

  getApiPathForCreate() {
    console.error("Cannot create inbound raw file.");
  }

  getApiPathForRefreshUpdateAndDelete() {
    console.error("Cannot manipulate an inbound raw file.");
  }

  getAdminPath() {
    console.error("Not supported.");
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultTitle() {
    return null;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }
}

export default InboundRawFile;
