<template>
  <v-textarea
    :id="id"
    v-model="text"
    :rules="required ? textRules : []"
    :type="type"
    :label="
      showLabel
        ? label + (required || hideOptionalText ? '' : ' (optional)')
        : ''
    "
    :placeholder="placeholder"
    :validate-on-blur="true"
    :disabled="disabled"
    :clearable="clearable"
    :dense="dense"
    :errorMessages="errors"
    :color="$colors.penChecksTeal"
    :hint="hint"
    :persistent-hint="persistentHint"
    :append-icon="appendIcon"
    v-on="$listeners"
    :rows="4"
    @keyup.enter="$emit('enter', $event)"
    @change="$emit('change', text)"
    @click:clear="$emit('clear')"
    @blur="$emit('blur')"
    @focus="errors = null"
    ref="textarea"
  ></v-textarea>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
    },
    appendIcon: {
      type: String,
    },
    id: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    errorMessages: {
      type: Array,
    },
    dense: {
      type: Boolean,
    },
    hint: {
      type: String,
    },
    persistentHint: {
      type: Boolean,
    },
    hideOptionalText: {
      type: Boolean,
    },
  },

  data: function () {
    return {
      text: this.value,
      textRules: [
        (v) =>
          !!v ||
          "Please enter " +
            (this.label.toUpperCase() == this.label
              ? this.label
              : this.label.toLowerCase()),
      ],
      errors: null,
    };
  },

  watch: {
    text(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      if (this.text != newValue) {
        this.text = newValue;
      }
    },
    errorMessages(errors) {
      this.errors = errors;
    },
  },

  mounted: function () {
    this.$emit("mounted");
  },
};
</script>

<style lang="scss"></style>
