<template>
  <!-- Similar implementation as ApiEntitySearch.vue with the following differences
 - Uses cache-items so no additional request are made because lookup data is loaded all at once
 - has item-value code instead of id
  - does not use chips to display the selected entries (typically only one item is selected)-->
  <v-autocomplete
    v-model="selectedValue"
    :menu-props="{ offsetY: true }"
    :items="items"
    :loading="searchRequestHandler.isBusy"
    hide-no-data
    hide-selected
    item-text="searchResultTitle"
    item-value="code"
    :rules="required ? selectRules : []"
    :label="label"
    :prepend-icon="prependIcon"
    :clearable="!required"
    :chips="multiple"
    :outlined="outlined"
    :multiple="multiple"
    :color="$colors.penChecksTeal"
    :item-color="$colors.penChecksTeal"
    :dense="dense"
    return-object
    :disabled="disabled"
    @change="$emit('change', selectedValue)"
    class="api-lookup-data-search-reskinned"
  >
    <template v-slot:item="data">
      <div class="pc-api-entity-search-item">
        <span :title="extractTextFromHtml(data.item.searchResultTitle)">
          <v-list-item-title>
            <span v-html="data.item.searchResultTitle"></span>
          </v-list-item-title>
        </span>
        <v-list-item-subtitle v-if="data.item.searchResultSubtitle">
          <span
            v-for="(subtitle, $index) in asArray(
              data.item.searchResultSubtitle
            )"
            :key="$index"
          >
            <span
              :title="extractTextFromHtml(data.item.searchResultTitle)"
              v-html="subtitle"
            ></span>
          </span>
        </v-list-item-subtitle>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import $ from "jquery";
import SearchRequestHandler from "@/shared/lib/client-sdk/search/search-request-handler";

export default {
  name: "PcApiLookupDataSearchReskinned",

  components: {},

  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      default: "Search",
    },
    entityType: {
      type: String,
      required: true,
    },
    dense: {
      type: Boolean,
    },
    multiple: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
    },
    prependIcon: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    cachePrefix: {
      type: String,
      default: "LookupData",
    },
    privateSearchFilter: {
      type: String,
    },
  },

  data: function () {
    return {
      searchRequestHandler: new SearchRequestHandler(this.entityType),
      items: this.$cache.get(this.cachePrefix + "_" + this.entityType, []),
      selectedValue: this.value ? { code: this.value } : null,
      selectRules: [(v) => !!v || "Please enter " + this.label.toLowerCase()],
    };
  },

  watch: {
    selectedValue(newValue) {
      this.$emit("input", newValue ? newValue.code : null);
      this.$emit("select", newValue);
    },
    value() {
      this.findSelectedValue();
    },
  },

  methods: {
    findSelectedValue: function () {
      if (this.value) {
        for (var i = 0; i < this.items.length; i++) {
          if (this.items[i].code == this.value) {
            this.selectedValue = this.items[i];
            return;
          }
        }
      }

      this.selectedValue = null;
    },
    asArray(value) {
      return $.isArray(value) ? value : [value];
    },
    extractTextFromHtml: function (text) {
      let textWithoutHtml = "";
      if (text && typeof text == "string")
        textWithoutHtml = text.replace(/<[^>]+>/g, "");
      return textWithoutHtml;
    },
  },

  mounted: function () {
    if (this.items.length == 0) {
      this.searchRequestHandler.returnObjects = true;
      this.searchRequestHandler.returnAbbreviatedResponses = true;

      if (this.privateSearchFilter != null)
        this.searchRequestHandler.privateSearchFilters = [
          this.privateSearchFilter,
        ];

      this.searchRequestHandler.search(
        function (response) {
          if (response && response.results) {
            this.items = response.results;
            this.$cache.set(
              this.cachePrefix + "_" + this.entityType,
              this.items
            );
            this.findSelectedValue();
          }
        }.bind(this)
      );
    } else {
      this.findSelectedValue();
    }
  },
};
</script>

<style lang="scss">
.api-lookup-data-search-reskinned {
  div.v-select__slot {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 20px !important;

    div.v-input__append-inner {
      margin-top: -2px !important;
    }
  }
}
</style>
