<template>
  <pc-tooltip
    v-if="errorMessages.length > 0"
    :value="errorMessages.join('<br>')"
  >
    <span class="error-bullet">{{ errorMessages.length }}</span>
  </pc-tooltip>
</template>

<script>
export default {
  components: {},

  props: {
    fieldsRequiringDataFix: {
      type: String,
    },
  },

  computed: {
    errorMessages: function () {
      var errors = [];

      if (!this.fieldsRequiringDataFix) {
        return errors;
      }

      try {
        var data = JSON.parse(this.fieldsRequiringDataFix);

        for (var key in data) {
          var section = data[key];

          if (typeof section == "string") {
            errors.push(section);
            continue;
          }

          for (var key2 in section) errors.push(section[key2]);
        }
      } catch (err) {
        return;
      }

      return errors;
    },
  },
};
</script>

<style lang="scss">
.error-bullet {
  height: 25px;
  width: 25px;
  padding: 2px;
  background-color: $penChecksRed;
  color: white;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
}
</style>
