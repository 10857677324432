import Vue from "vue";
import Service from "@/shared/lib/client-sdk/models/service";
import DistributionReason from "@/shared/lib/client-sdk/models/distribution-reason";
import DistributionType from "@/shared/lib/client-sdk/models/distribution-type";
import Country from "@/shared/lib/client-sdk/models/country";
import State from "@/shared/lib/client-sdk/models/state";
import PaymentMethod from "@/shared/lib/client-sdk/models/payment-method";
import EnumDisplay from "@/shared/lib/client-sdk/models/enum-display";

class Cache {
  constructor() {
    this.items = {};
  }

  set(key, value) {
    Vue.set(this.items, key, value);
  }

  get(key, defaultValue) {
    if (typeof this.items[key] != "undefined") {
      return this.items[key];
    }

    return defaultValue;
  }

  clear() {
    this.items = {};
  }

  fetchAndCacheLookupData() {
    this._fetchAndCacheLookupDataFor(Service);
    this._fetchAndCacheLookupDataFor(DistributionReason);
    this._fetchAndCacheLookupDataFor(DistributionType);
    this._fetchAndCacheLookupDataFor(State);
    this._fetchAndCacheLookupDataFor(Country);
    this._fetchAndCacheLookupDataFor(PaymentMethod);
    this._fetchAndCacheLookupDataFor(EnumDisplay);

    // this._fetchAndCacheLookupDataFor(CompanyType);
    // this._fetchAndCacheLookupDataFor(AccountType);
    // this._fetchAndCacheLookupDataFor(PlanType);
    // this._fetchAndCacheLookupDataFor(Institution);
    // this._fetchAndCacheLookupDataFor(AnnuityType);

    // TODO: incomplete
    // NonWorkingDay
    // Role
    // Permission
    // ReferralSource
  }

  _fetchAndCacheLookupDataFor(modelClass) {
    const lookupKey = "LookupData_" + modelClass.getModelClassName();

    modelClass.fetchAll(
      function (items) {
        if (items) {
          this.set(lookupKey, items);
        } else {
          console.warn(
            "Could not get lookup data",
            modelClass.getModelClassName()
          );
        }
      }.bind(this)
    );
  }
}

const ObjectCachePlugin = {
  install(Vue) {
    const cache = Vue.observable(new Cache());
    Vue.prototype.$cache = cache;
    window.$cache = cache;
  },
};

export default ObjectCachePlugin;
