<template>
  <v-expansion-panel class="pc-expansion-panel">
    <v-expansion-panel-header
      :color="headerBackgroundColor"
      :style="headerStyle"
      class="pc-expansion-panel-header"
    >
      <slot name="header"></slot>
      <template v-slot:actions>
        <slot name="actions"></slot>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pc-expansion-panel-content">
      <slot></slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
export default {
  props: {
    headerFontColor: {
      type: String,
      default: undefined,
    },
    headerBackgroundColor: {
      type: String,
      default: undefined,
    },
    headerFontSize: {
      type: String,
      default: "14px",
    },
  },
  computed: {
    headerStyle: function () {
      let headerStyle = "";

      if (this.headerFontColor) {
        headerStyle = "color: " + this.headerFontColor + ";";
      }

      if (this.headerFontSize) {
        headerStyle = headerStyle + "font-size: " + this.headerFontSize + ";";
      }

      return headerStyle;
    },
  },
};
</script>
<style lang="scss">
.pc-expansion-panel {
  border: 1px solid #e0e0e0;
}

.pc-expansion-panel-header {
  padding-top: 6px;
  padding-bottom: 0px;

  .v-expansion-panel-header__icon {
    padding: 12px;
    padding-right: 0px;

    i {
      color: $penChecksTeal !important;
    }
  }
}

.pc-expansion-panel-content {
  .v-expansion-panel-content__wrap {
    padding-left: 12px;
    padding-right: 12px;
  }
}
</style>
