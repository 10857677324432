<template>
  <pc-card
    :id="idPrefix"
    :outer-title="title"
    :inner-title="innerTitle"
    :text-left="textLeft"
    fill-width
    content-class="pa-0 ma-0"
    class="pc-data-table"
    :class="{ 'no-hover': noHover }"
    :no-border="noBorder"
  >
    <v-row dense>
      <v-col
        class="text-left"
        style="max-width: 400px"
        v-if="withSearchTextField"
      >
        <pc-text-field
          :id="setId('SearchField')"
          class="ml-4 mt-2"
          clearable
          dense
          append-icon="mdi-magnify"
          :label="searchLabel"
          v-model="searchText"
          hide-optional-text
          @clear="searchCleared()"
          @enter="searchWithText()"
          @blur="leftSearchField()"
        ></pc-text-field>
      </v-col>
      <v-col class="text-left flex-grow-1">
        <slot name="top-middle-content"></slot>
      </v-col>
      <v-col class="text-right pt-3 flex-grow-0">
        <div class="table-actions-style">
          <slot v-if="hasSlot('bulk-actions')" name="bulk-actions"></slot>
          <slot v-if="hasSlot('bulk-delete')" name="bulk-delete"></slot>
          <slot v-if="hasSlot('bulk-filter')" name="bulk-filter"></slot>
          <pc-button
            v-if="!withoutRefresh"
            @click="searchRequestHandler.search()"
            :disabled="isExporting || searchRequestHandler.isBusy"
            text
            small
            subtle
            tooltip="Refresh table data"
            ><pc-icon name="pc-refresh" size="16"></pc-icon>Refresh</pc-button
          >
          <pc-button
            v-if="withDataExport"
            @click="exportData"
            :disabled="
              isExporting || searchRequestHandler.isBusy || !showDataSection
            "
            text
            small
            subtle
            class="mr-4"
            tooltip="Download all data"
            ><pc-icon name="pc-download" size="16"></pc-icon>Export</pc-button
          >
        </div>
      </v-col>
    </v-row>

    <v-row class="py-0 my-0" v-if="hasSlot('action-buttons')">
      <v-col class="mt-0">
        <slot name="action-buttons"></slot>
      </v-col>
    </v-row>

    <v-data-table
      v-if="showDataSection"
      :headers="realHeaders"
      :items="searchRequestHandler.searchResult.results"
      item-key="id"
      v-model="selected"
      :options.sync="searchOptions"
      :loading="searchRequestHandler.isBusy"
      :calculate-widths="true"
      :loading-text="loadingText"
      :server-items-length="searchRequestHandler.searchResult.totalResults"
      :show-select="isSelectable"
      :color="$colors.penChecksRed"
      :items-per-page="showAllResults ? -1 : 10"
      :footer-props="{
        'items-per-page-options': showAllResults
          ? [-1]
          : [25, 50, 100, 250, 500],
        showCurrentPage: true,
      }"
      class="elevation-0"
      :item-class="itemClass"
    >
      <template slot="no-data">
        <v-row class="py-0 my-0" v-if="hasSlot('no-data')">
          <v-col class="mt-0">
            <slot name="no-data"></slot>
          </v-col>
        </v-row>
      </template>

      <!-- just here to add id to tbody -->
      <template v-slot:body.prepend>
        <span :id="setId('Body')"></span>
      </template>

      <template
        v-slot:footer.page-text
        v-if="searchRequestHandler.fetchingFullCount"
      >
        <v-progress-circular
          v-if="searchRequestHandler.fetchingFullCount"
          indeterminate
          :color="$colors.penChecksTeal"
          size="12"
          class="mr-2"
        ></v-progress-circular>
      </template>

      <!-- Setup the format for columns that have defined the slot as part of table -->
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        v-slot:[scopedSlotName]="item"
      >
        <slot :name="scopedSlotName" v-bind="item"></slot>
      </template>

      <!-- Setup the format for columns that have not defined the slot as part of table -->
      <template
        v-for="header in headersWithoutSlots"
        v-slot:[header.slotName]="{ item, value }"
      >
        <!-- Callback for each cell -->
        <template v-if="header.slotName == 'item.recordNumber'">
          <slot v-bind:item="item" name="item-record-number">
            <small
              :style="'color: ' + $colors.penChecksDarkGray"
              :key="header.value"
            >
              {{
                1 +
                searchRequestHandler.searchResult.results.indexOf(item) +
                (searchRequestHandler.searchResult.page - 1) *
                  searchRequestHandler.searchResult.resultsPerPage
              }}
            </small>
          </slot>
        </template>

        <template v-else-if="header.slotName == 'item.dataErrors'">
          <slot v-bind:item="item" name="item-data-errors">
            <pc-error-bullet
              :fieldsRequiringDataFix="item.fieldsRequiringDataFix"
              :key="header.value"
            ></pc-error-bullet>
          </slot>
        </template>

        <template v-else-if="header.slotName == 'item.actions'">
          <div class="itemActionButtonContainer" :key="header.value">
            <slot v-bind:item="item" name="item-action-buttons"></slot>

            <pc-button
              @click="clickedItem(item)"
              @middleClick="openInNewTab(item)"
              @ctrlClick="openInNewTab(item)"
              :key="header.value"
              :color="$colors.penChecksGray"
              class="hover-action-button"
              small
            >
              <pc-icon name="pc-arrow-right" size="14"></pc-icon>
            </pc-button>
          </div>
        </template>

        <template v-else-if="header.slotName == 'item.noticeDisplayName'">
          <slot v-bind:item="item" name="item-notice-display-name">
            <pc-notice-name
              v-if="item.noticeId !== null"
              :noticeKey="item.noticeDisplayName"
              isPremierNotice
            >
            </pc-notice-name>

            <div
              v-else-if="item.documentTemplateId !== null"
              :key="item.documentTemplateId"
            >
              {{ item.documentTemplateDisplayName }}
            </div>
          </slot>
        </template>

        <pc-copyable
          v-else-if="header.searchableField.resultDataType == 'internalId'"
          :key="'internalId_' + header.value"
          >{{ value }}</pc-copyable
        >

        <pc-copyable
          v-else-if="header.searchableField.resultDataType == 'uuid'"
          :key="'uuid_' + header.value"
          >{{ value }}</pc-copyable
        >

        <pc-copyable
          v-else-if="header.searchableField.resultDataType == 'friendlyId'"
          :key="'friendlyId_' + header.value"
          >{{ value | friendlyId }}</pc-copyable
        >

        <template
          v-else-if="header.searchableField.resultDataType == 'objectLink'"
        >
          <a
            :href="objectLink(item, header.searchableField)"
            :key="'objectLink_' + header.value"
            >{{ value }}</a
          >
        </template>

        <pc-status-chip
          v-else-if="
            header.searchableField.resultDataType == 'statusChip' && value
          "
          :key="'statusChip_' + header.value"
          :value="value"
          :statusDetail="item && item.substatus ? item.substatus : ''"
          :entity="entityType"
          x-small
        ></pc-status-chip>

        <pc-status-detail
          v-else-if="
            header.searchableField.resultDataType == 'substatusBullet' && value
          "
          :key="'substatusBullet_' + header.value"
          :detail="value"
          :status="item && item.status ? item.status : undefined"
        >
        </pc-status-detail>

        <template
          v-else-if="header.searchableField.resultDataType == 'boolean'"
        >
          <span
            v-if="value === true || value === 1"
            :key="'booleanf_' + header.value"
            >Yes</span
          >
          <span
            v-else-if="value === false || value === 0"
            :key="'booleant_' + header.value"
            >No</span
          >
        </template>

        <template
          v-else-if="header.searchableField.resultDataType == 'titleText'"
          >{{ value | titleCase }}</template
        >

        <pc-tax-id
          :value="value"
          :key="'titleText_' + header.value"
          :taxIdType="item.taxIdType"
          v-else-if="
            header.searchableField.resultDataType == 'socialSecurityNumber'
          "
        ></pc-tax-id>

        <template v-else-if="header.searchableField.resultDataType == 'month'">
          <!-- Display month only: eg "Dec 2020" -->
          {{ value | formatDate }}
        </template>

        <template v-else-if="header.searchableField.resultDataType == 'date'">
          <!-- Display date only: eg "Dec 12, 2020" -->
          {{ value | formatDate }}
        </template>

        <template
          v-else-if="
            value && value.searchResultTitle && typeof value == 'object'
          "
          >{{ value.searchResultTitle }}</template
        >

        <template
          v-else-if="header.searchableField.resultDataType == 'currencyAmount'"
          >{{ value | currency }}</template
        >

        <template
          v-else-if="
            header.searchableField.resultDataType == 'numberWithCommas'
          "
          >{{ value | numberWithCommas }}</template
        >

        <template
          v-else-if="header.searchableField.resultDataType == 'percentage'"
          >{{ value | percentage }}</template
        >

        <template
          v-else-if="header.searchableField.resultDataType == 'storedFile'"
        >
          <template v-if="value">
            <a
              :href="'/files/' + value + '/download'"
              target="_blank"
              :key="header.value"
              >Download</a
            >
          </template>
        </template>

        <template
          v-else-if="header.searchableField.resultDataType == 'phoneNumber'"
          >{{ value | phoneNumber }}</template
        >

        <template
          v-else-if="header.searchableField.resultDataType == 'dateTime'"
        >
          <!-- Display date & time.  eg. "Dec 12, 2020, 5:12 pm" -->
          {{ value | formatDate }}
        </template>

        <div
          v-else-if="header.searchableField.resultDataType == 'relativeDate'"
          :key="header.value"
        >
          <!-- show the date as a relative date format ('MMM D, YYYY, hh:mm a') -->
          {{ value | formatDate }}
          <br />
          <small>{{ value | relativeDate }}</small>
        </div>

        <template v-else>
          <!-- Plain text return -->
          {{ value }}
        </template>
      </template>

      <template
        v-slot:item.actions="{ item }"
        v-if="($listeners && $listeners['click:row']) || hasSlot('actions')"
      >
        <div v-if="hasSlot('actions')" class="d-flex">
          <slot name="actions" v-bind:item="item"></slot>
        </div>
        <div v-else>
          <pc-button
            class="action-buttons"
            :color="$colors.penChecksGray"
            small
            @click="$emit('click:row', item)"
            @middleClick="$emit('middleClick:row', item)"
            @ctrlClick="$emit('middleClick:row', item)"
          >
            <pc-icon name="pc-arrow-right" size="14"></pc-icon>
          </pc-button>
        </div>
      </template>
    </v-data-table>
  </pc-card>
</template>

<script>
import SearchRequestHandler from "@/shared/lib/client-sdk/search/search-request-handler";

export default {
  props: {
    searchRequestHandler: {
      type: SearchRequestHandler,
      required: true,
    },
    title: {
      type: String,
    },
    innerTitle: {
      type: String,
      required: false,
    },
    exportTitle: {
      type: String,
      required: false,
    },
    textLeft: {
      type: Boolean,
      required: false,
      default: false,
    },
    headers: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
    },
    noHover: {
      type: Boolean,
    },
    loadingText: {
      type: String,
      default: "Searching...",
    },
    withoutRefresh: {
      type: Boolean,
    },
    withSearchTextField: {
      type: Boolean,
    },
    withDataExport: {
      type: Boolean,
    },
    withBackgroundExport: {
      type: Boolean,
      default: false,
    },
    initialSearchText: {
      type: String,
    },
    searchLabel: {
      type: String,
      default: "Search",
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    localStorageKey: {
      type: String,
    },
    idPrefix: {
      type: String,
    },
    isSelectable: {
      type: Boolean,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    showAllResults: {
      type: Boolean,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    itemClass: {
      type: Function,
      default: null,
    },
    showDataSection: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      searchText: "",
      isExporting: false,
      initialSearchComplete: false,
      unfilteredSearchResult: null,
      bypassSearchOptionsWatcher: true,
      searchOptions: {
        page: 1,
        itemsPerPage: 25,
        mustSort: true,
        sortBy: [],
        sortDesc: [],
      },
      selected: [],
    };
  },
  watch: {
    initialSearchText: function () {
      this.searchText = this.initialSearchText ? this.initialSearchText : "";
    },
    searchRequestHandler: function () {
      this.initializeSearchRequestHandler();
    },
    searchOptions: {
      handler: function () {
        if (
          !this.bypassSearchOptionsWatcher &&
          this.searchRequestHandler.hasSearchedAtLeastOnce &&
          !this.searchRequestHandler.isBusy
        ) {
          this.searchRequestHandler.page = this.searchOptions.page;
          this.searchRequestHandler.resultsPerPage =
            this.searchOptions.itemsPerPage;

          this.searchRequestHandler.sortBy = null;
          this.searchRequestHandler.sortDirection = null;

          if (this.searchOptions.sortBy.length > 0) {
            var sortByHeader = this.realHeaders.find(
              (o) => o.value == this.searchOptions.sortBy[0]
            );

            if (sortByHeader) {
              var fieldName =
                sortByHeader.searchFieldName || sortByHeader.value;
              this.searchRequestHandler.sortBy = fieldName;

              this.searchRequestHandler.sortDirection =
                this.searchOptions.sortDesc.length > 0
                  ? this.searchOptions.sortDesc[0]
                    ? "desc"
                    : "asc"
                  : null;
            }
          }

          this.searchRequestHandler.search();
        }

        this.bypassSearchOptionsWatcher = false;
      },
      deep: true,
    },
    selectedItems: function (items) {
      this.selected = items;
    },
    selected: function () {
      this.$emit("select", this.selected);
    },
    "searchRequestHandler.searchResult.totalResults": function (count) {
      this.$emit("total", count);
    },
  },
  computed: {
    localStorageKeyPrefix: function () {
      var url = window.location.href;

      if (url.includes("?")) url = url.split("?")[0];

      if (url.includes("#query:")) url = url.split("#query:")[0];

      // Remove any IDs from the URL, so it doesn't change each time
      url = url.replace(/\b[a-zA-Z0-9]{32}\b/gi, "{id}");

      var requestUrl = this.searchRequestHandler.requestUrl;

      if (requestUrl.includes("?")) requestUrl = requestUrl.split("?")[0];

      if (requestUrl.includes("#query:"))
        requestUrl = requestUrl.split("#query:")[0];

      // Remove any IDs from the URL, so it doesn't change each time
      requestUrl = requestUrl.replace(/\b[a-zA-Z0-9]{32}\b/gi, "{id}");

      return (
        "dataTableSearch_" +
        this.searchRequestHandler.modelClassName +
        "_" +
        url +
        "_" +
        requestUrl +
        "_"
      );
    },
    realHeaders: function () {
      var newHeaders = JSON.parse(JSON.stringify(this.headers));

      for (var i = 0; i < newHeaders.length; i++) {
        var header = newHeaders[i];
        header.sortable = this.sortable || Boolean(header.searchFieldName);
      }

      if (
        (this.$listeners && this.$listeners["click:row"]) ||
        this.hasSlot("actions")
      ) {
        newHeaders.push({
          text: " ",
          sortable: false,
          value: "actions",
          align: "end",
        });
      }

      return newHeaders;
    },
    // Get the list of columns that have not defined slot into the table
    headersWithoutSlots: function () {
      var headersWithoutSlotsArray = [];

      for (var index = 0; index < this.headers.length; index++) {
        const field = this.headers[index];

        // Normally the Slot name has the format 'item.' concatenated with the field value
        if (!this.hasSlot("item." + field.value)) {
          headersWithoutSlotsArray.push(field);
        }
      }

      return headersWithoutSlotsArray;
    },
  },
  methods: {
    hasSlot(name) {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
    setId: function (idSufix) {
      return this.idPrefix && idSufix ? this.idPrefix + idSufix : undefined;
    },
    initializeSearchRequestHandler: function () {
      if (this.searchText && this.searchText.trim() != "") {
        this.searchRequestHandler.generalFilter = this.searchText;
      }
      this.searchRequestHandler.tableComponentCallback =
        this.searchComplete.bind(this);
    },
    leftSearchField: function () {
      if (!this.searchText || this.searchText.trim() == "") {
        this.resetSearchFilter();
      }
    },
    exportData: function () {
      var customColumns = "";
      var separator = "";
      for (var index = 0; index < this.headers.length; index++) {
        const field = this.headers[index];

        var fieldName = !field.column ? field.value : field.column;
        customColumns = customColumns + separator + fieldName;
        separator = ",";
      }

      if (!this.withBackgroundExport) {
        this.isExporting = true;
        this.$overlay.show("Preparing data export...");

        let requestData = {
          customColumns: customColumns,
        };

        this.searchRequestHandler.queueExport(
          requestData,
          (success, exportDownloadUri) => {
            this.$overlay.hide();

            if (success) {
              if (exportDownloadUri) {
                let url =
                  window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
                  "/public" +
                  exportDownloadUri;

                if (
                  window.location.hostname.includes(
                    "unclaimedretirementbenefits.com"
                  )
                ) {
                  url = url.replace(
                    "penchecks.com",
                    "unclaimedretirementbenefits.com"
                  );
                }

                window.location.href = url;
              } else {
                this.$modals.alert({
                  title: "Search Request Export Queued",
                  text: "You will receive an email when your data export is ready to be downloaded.",
                });
              }
            }

            this.isExporting = false;
          }
        );
      } else {
        this.isExporting = true;
        this.$overlay.show("Requesting data export...");

        var reportExportTitle =
          this.exportTitle || this.title || this.innerTitle;
        let requestData = {
          reportTitle: reportExportTitle,
          customColumns: customColumns,
        };

        this.searchRequestHandler.queueBackgroundExport(requestData, () => {
          this.$overlay.hide();
          this.isExporting = false;

          this.$modals.alert({
            title: "Data report is being prepared",
            text: "You will receive an email when the data report is ready to be downloaded.",
          });
        });
      }
    },
    searchWithText: function () {
      if (!this.searchText || this.searchText.trim() == "") {
        this.resetSearchFilter();
      } else {
        this.searchRequestHandler.generalFilter = this.searchText;
        this.searchRequestHandler.search();
      }
    },
    searchCleared: function () {
      this.resetSearchFilter();
    },
    searchComplete: function () {
      this.initialSearchComplete = true;
      this.updateSearchOptionsFromResult();
    },

    updateSearchOptionsFromResult: function () {
      this.bypassSearchOptionsWatcher = true;
      this.$nextTick(() => {
        this.searchOptions.page = this.searchRequestHandler.searchResult.page;
        this.searchOptions.itemsPerPage =
          this.searchRequestHandler.searchResult.resultsPerPage;

        this.searchOptions.sortBy = [];
        this.searchOptions.sortDesc = [];

        if (this.searchRequestHandler.searchResult.sortBy) {
          var field = this.searchRequestHandler.getSearchableFieldByName(
            this.searchRequestHandler.searchResult.sortBy
          );

          if (field) {
            var header = this.realHeaders.find(
              (o) =>
                field.fieldName == o.searchFieldName ||
                field.resultKey == o.value
            );

            if (header) {
              this.searchOptions.sortBy = [header.value];
              this.searchOptions.sortDesc =
                this.searchRequestHandler.searchResult.sortDirection
                  .toLowerCase()
                  .startsWith("desc")
                  ? [header.value]
                  : [];
            }
          }
        }
      });
    },
    objectLink: function (item, searchableField) {
      var idField = searchableField.objectLinkSearchableFieldWithId;
      var modelType = idField.substr(0, idField.length - 2);
      var modelClass = this.searchRequestHandler.searchableModels[modelType];
      var model = new modelClass();

      model.id = item[idField.toCamelCase()];
      return model.getAdminPath();
    },
    resetSearchFilter: function () {
      if (this.searchRequestHandler.generalFilter == null) return;
      this.searchRequestHandler.generalFilter = null;
      this.searchRequestHandler.search();
    },
  },
  mounted() {
    this.searchText = this.initialSearchText ? this.initialSearchText : "";
    this.initializeSearchRequestHandler();
  },
};
</script>

<style lang="scss">
.pc-data-table {
  min-width: 704px;

  .v-card__text {
    padding: 12px;
    margin: 0px;

    .v-progress-linear .primary {
      background-color: $penChecksTeal !important;
      border-color: $penChecksTeal !important;
      color: $penChecksTeal !important;
    }
  }

  tr:hover .action-buttons .v-btn {
    background-color: $penChecksTeal !important;
  }

  tbody td {
    cursor: default !important;
  }

  &.no-hover {
    .v-data-table tr:hover {
      background-color: transparent !important;
    }
  }

  &:not(.no-hover) {
    .v-data-table tr:hover {
      background-color: $penChecksLight !important;
    }
  }

  .v-icon.mdi-checkbox-marked {
    color: $penChecksTeal;
  }

  .v-icon.mdi-minus-box {
    color: $penChecksTeal;
  }

  .table-actions-style {
    display: flex;
    float: right;
  }
}

@media print {
  .pc-data-table {
    min-width: unset;
  }
}
</style>
