<template>
  <div class="pc-big-toggle">
    <v-row>
      <v-col v-for="(item, index) in items" :key="item.value">
        <div
          class="big-toggle-option"
          @click="selected = index"
          :style="'height: ' + height"
          @mouseover="hovering = !disabled"
          @mouseout="hovering = null"
          :class="{
            'elevation-1': hovering == index || selected == index,
            hovering: hovering == index,
            selected: selected == index,
          }"
        >
          <!-- <pc-icon color="realColor" name="item.icon">{{item.icon}}</pc-icon> -->
          <strong :style="'color: ' + realColor">{{ item.title }}</strong>
          <p>{{ item.description }}</p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    items: {
      required: true,
      type: Array,
    },
    color: {
      type: String,
    },
    height: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    },
  },

  data: function () {
    return {
      hovering: null,
      selected: null,
    };
  },

  computed: {
    realColor: function () {
      if (this.color) return this.color;

      return this.$colors.penChecksTeal;
    },
  },

  watch: {},

  methods: {},

  mounted: function () {},
};
</script>

<style lang="scss">
.pc-big-toggle {
  .big-toggle-option {
    border: 1px solid $penChecksGray;
    background-color: $white;
    border-radius: 4px;

    &.hovering {
      cursor: pointer;
    }

    &.selected {
      background-color: $penChecksLightTeal;
    }
  }
}
</style>
