import Vue from "vue";

Vue.directive("click-outside-catch", {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // detect if click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        var editButtonId = binding.arg;

        // edit button was clicked, do not call target function
        if (
          event.target.id == editButtonId || // when button was clicked
          event.target.parentNode?.id == editButtonId || // when button text was clicked
          event.target.parentNode?.parentNode?.id == editButtonId
        ) {
          // when button icon was clicked
          return;
        }

        // a select list item was selected, do not call target function
        if (
          event.target.classList.contains("v-list-item__content") ||
          event.target.parentNode.classList.contains("v-list-item__content") ||
          event.target.classList.contains("v-list-item") ||
          event.target.classList.contains("pc-dropdown-list-item")
        ) {
          return;
        }

        // and if it did, call method provided in attribute value
        if (typeof vnode.context[binding.expression] === "function") {
          vnode.context[binding.expression](event);
        }
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

(function () {
  // make vuetify dialogs movable
  const d = {};
  document.addEventListener("mousedown", (e) => {
    const closestDialog = e.target.closest(
      ".v-dialog.v-dialog--active.singleton-modal.draggable"
    );
    if (
      e.button === 0 &&
      closestDialog != null &&
      e.target.classList.contains("v-card__title")
    ) {
      // element which can be used to move element
      d.el = closestDialog; // element which should be moved
      d.mouseStartX = e.clientX;
      d.mouseStartY = e.clientY;
      d.elStartX = d.el.getBoundingClientRect().left;
      d.elStartY = d.el.getBoundingClientRect().top;
      d.el.style.position = "fixed";
      d.el.style.margin = 0;
      d.oldTransition = d.el.style.transition;
      d.el.style.transition = "none";
    }
  });
  document.addEventListener("mousemove", (e) => {
    if (d.el === undefined) return;
    d.el.style.left =
      Math.min(
        Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
        window.innerWidth - d.el.getBoundingClientRect().width
      ) + "px";
    d.el.style.top =
      Math.min(
        Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
        window.innerHeight - d.el.getBoundingClientRect().height
      ) + "px";
  });
  document.addEventListener("mouseup", () => {
    if (d.el === undefined) return;
    d.el.style.transition = d.oldTransition;
    d.el = undefined;
  });
  setInterval(() => {
    // prevent out of bounds
    const dialog = document.querySelector(
      ".v-dialog.v-dialog--active.singleton-modal.draggable"
    );
    if (dialog === null) return;
    dialog.style.left =
      Math.min(
        parseInt(dialog.style.left),
        window.innerWidth - dialog.getBoundingClientRect().width
      ) + "px";
    dialog.style.top =
      Math.min(
        parseInt(dialog.style.top),
        window.innerHeight - dialog.getBoundingClientRect().height
      ) + "px";
  }, 100);
})();
