<template>
  <div id="notice-name">
    <!-- Premier notice name -->
    <div v-if="isPremierNotice">
      <span>
        {{ first4Codes }}
      </span>
      <span :class="`${isDashes(noticeRuleCodes.code1)} teal-text`">
        {{ `${noticeRuleCodes.code1} ` }}
      </span>
      <span :class="`${isDashes(noticeRuleCodes.code2)} orange-text`">
        {{ `${noticeRuleCodes.code2} ` }}
      </span>
      <span :class="`${isDashes(noticeRuleCodes.code3)} purple-text`">
        {{ `${noticeRuleCodes.code3} ` }}
      </span>
      <span :class="`${isDashes(noticeRuleCodes.code4)} blue-text`">
        {{ `${noticeRuleCodes.code4} ` }}
      </span>
      <span :class="`${isDashes(noticeRuleCodes.code5)} pink-text`">
        {{ `${noticeRuleCodes.code5} ` }}
      </span>
      <span :class="`${isDashes(noticeRuleCodes.code6)} green-text`">
        {{ `${noticeRuleCodes.code6} ` }}
      </span>

      <span
        v-for="(code, index) in distributionReasons_specialtyRulesAndFlags"
        :key="code"
        :class="isSpecialRule(code)"
      >
        {{
          index === distributionReasons_specialtyRulesAndFlags.length - 1
            ? `${code}`
            : `${code} `
        }}
      </span>
    </div>

    <!-- Uncashed check or welcome notice name -->
    <div v-else>
      <span
        v-for="(code, index) in noticeKeyArray"
        :key="code"
        :class="isNonPremierSpecialRule(code)"
      >
        {{ index === noticeKeyArray.length - 1 ? `${code}` : `${code} ` }}
      </span>
    </div>
  </div>
</template>

<script>
import noticeNameCodes from "@/shared/lib/notice-name-codes";

export default {
  props: {
    noticeKey: {
      type: String,
      required: true,
    },
    isPremierNotice: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isSpecialRule(code) {
      const specialtyRuleAcronyms = noticeNameCodes.specialtyRulesAndFlags.map(
        (code) => code.acronym
      );

      if (specialtyRuleAcronyms.includes(code)) {
        return "medium-gray-text";
      }

      return "";
    },
    isNonPremierSpecialRule(code) {
      const nonPremierSpecialRuleCodes = ["PLN", "ACC"];

      if (nonPremierSpecialRuleCodes.includes(code)) {
        return "medium-gray-text";
      }

      return "";
    },
    isDashes(code) {
      if (code === "---") {
        return "dash-spacing";
      }

      return "";
    },
  },
  computed: {
    noticeKeyArray() {
      var noticeNameSplit = this.noticeKey.split(" ");

      // For the premier notices the rules expected a color coding in the name.
      // Adjust the array to keep the rules color coding in the notice name.
      if (this.isPremierNotice) {
        // "PIT" or "PNT"
        if (
          noticeNameCodes.noticeRules[0].acronym != noticeNameSplit[4] &&
          noticeNameCodes.noticeRules[1].acronym != noticeNameSplit[4]
        ) {
          noticeNameSplit.splice(4, 0, "");
        }
        // "APF" or "NPF"
        if (
          noticeNameCodes.noticeRules[2].acronym != noticeNameSplit[5] &&
          noticeNameCodes.noticeRules[3].acronym != noticeNameSplit[5]
        ) {
          noticeNameSplit.splice(5, 0, "");
        }
        // "DID" or "DND"
        if (
          noticeNameCodes.noticeRules[4].acronym != noticeNameSplit[6] &&
          noticeNameCodes.noticeRules[5].acronym != noticeNameSplit[6]
        ) {
          noticeNameSplit.splice(6, 0, "");
        }
        // "O7k" or "U7k"
        if (
          noticeNameCodes.noticeRules[6].acronym != noticeNameSplit[7] &&
          noticeNameCodes.noticeRules[7].acronym != noticeNameSplit[7]
        ) {
          noticeNameSplit.splice(7, 0, "");
        }
        // "IJS" or "NJS"
        if (
          noticeNameCodes.noticeRules[8].acronym != noticeNameSplit[8] &&
          noticeNameCodes.noticeRules[9].acronym != noticeNameSplit[8]
        ) {
          noticeNameSplit.splice(8, 0, "");
        }
        // "NRA" or "NNR"
        if (
          noticeNameCodes.noticeRules[10].acronym != noticeNameSplit[9] &&
          noticeNameCodes.noticeRules[11].acronym != noticeNameSplit[9]
        ) {
          noticeNameSplit.splice(9, 0, "");
        }
      }

      return noticeNameSplit;
    },
    first4Codes() {
      return `${this.noticeKeyArray[0]} ${this.noticeKeyArray[1]} ${this.noticeKeyArray[2]} ${this.noticeKeyArray[3]} `;
    },
    noticeRuleCodes() {
      return {
        code1: this.noticeKeyArray[4],
        code2: this.noticeKeyArray[5],
        code3: this.noticeKeyArray[6],
        code4: this.noticeKeyArray[7],
        code5: this.noticeKeyArray[8],
        code6: this.noticeKeyArray[9],
      };
    },
    distributionReasons_specialtyRulesAndFlags() {
      const arr = this.noticeKeyArray;
      return arr.splice(10);
    },
  },
};
</script>

<style lang="scss">
#notice-name {
  font-size: 16px;
  color: $penChecksDarkGray;
}

.teal-text {
  color: $penChecksDarkTeal;
}

.orange-text {
  color: $penChecksOrange;
}

.purple-text {
  color: $penChecksPurple;
}

.blue-text {
  color: $penChecksBlue;
}

.pink-text {
  color: $penChecksPink;
}

.green-text {
  color: $penChecksDarkerGreen;
}

.medium-gray-text {
  color: $penChecksMediumGray;
}

.dash-spacing {
  letter-spacing: 0.15rem;
}
</style>
