<template>
  <div v-if="this.type == 'down'">
    <div v-if="this.size && this.size == 16">
      <chevron-down-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <chevron-down-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <chevron-down-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <chevron-down-32 :class="iconColorClass" />
    </div>
    <div v-else><chevron-down-16 :class="iconColorClass" /></div>
  </div>
  <div v-else-if="this.type == 'left'">
    <div v-if="this.size && this.size == 16">
      <chevron-left-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <chevron-left-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <chevron-left-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <chevron-left-32 :class="iconColorClass" />
    </div>
    <div v-else><chevron-left-16 :class="iconColorClass" /></div>
  </div>
  <div v-else-if="this.type == 'mini'">
    <div v-if="this.size && this.size == 16">
      <chevron-mini-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <chevron-mini-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <chevron-mini-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <chevron-mini-32 :class="iconColorClass" />
    </div>
    <div v-else><chevron-mini-16 :class="iconColorClass" /></div>
  </div>
  <div v-else-if="this.type == 'right'">
    <div v-if="this.size && this.size == 16">
      <chevron-right-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <chevron-right-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <chevron-right-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <chevron-right-32 :class="iconColorClass" />
    </div>
    <div v-else><chevron-right-16 :class="iconColorClass" /></div>
  </div>
  <div v-else-if="this.type == 'sort'">
    <div v-if="this.size && this.size == 16">
      <chevron-sort-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <chevron-sort-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <chevron-sort-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <chevron-sort-32 :class="iconColorClass" />
    </div>
    <div v-else><chevron-sort-16 :class="iconColorClass" /></div>
  </div>
  <div v-else-if="this.type == 'sort-down'">
    <div v-if="this.size && this.size == 16">
      <chevron-sort-down-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <chevron-sort-down-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <chevron-sort-down-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <chevron-sort-down-32 :class="iconColorClass" />
    </div>
    <div v-else><chevron-sort-down-16 :class="iconColorClass" /></div>
  </div>
  <div v-else-if="this.type == 'sort-up'">
    <div v-if="this.size && this.size == 16">
      <chevron-sort-up-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <chevron-sort-up-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <chevron-sort-up-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <chevron-sort-up-32 :class="iconColorClass" />
    </div>
    <div v-else><chevron-sort-up-16 :class="iconColorClass" /></div>
  </div>
  <div v-else-if="this.type == 'up'">
    <div v-if="this.size && this.size == 16">
      <chevron-up-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <chevron-up-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <chevron-up-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <chevron-up-32 :class="iconColorClass" />
    </div>
    <div v-else><chevron-up-16 :class="iconColorClass" /></div>
  </div>
  <div v-else-if="this.type == 'down-glyph'">
    <chevron-down-glyph :class="iconColorClass" />
  </div>
  <div v-else-if="this.type == 'left-glyph'">
    <chevron-left-glyph :class="iconColorClass" />
  </div>
  <div v-else-if="this.type == 'right-glyph'">
    <chevron-right-glyph :class="iconColorClass" />
  </div>
  <div v-else-if="this.type == 'up-glyph'">
    <chevron-up-glyph :class="iconColorClass" />
  </div>
</template>

<script>
import {
  ChevronDown16,
  ChevronDown20,
  ChevronDown24,
  ChevronDown32,
  ChevronDownGlyph,
  ChevronLeft16,
  ChevronLeft20,
  ChevronLeft24,
  ChevronLeft32,
  ChevronLeftGlyph,
  ChevronMini16,
  ChevronMini20,
  ChevronMini24,
  ChevronMini32,
  ChevronRight16,
  ChevronRight20,
  ChevronRight24,
  ChevronRight32,
  ChevronRightGlyph,
  ChevronSort16,
  ChevronSort20,
  ChevronSort24,
  ChevronSort32,
  ChevronSortDown16,
  ChevronSortDown20,
  ChevronSortDown24,
  ChevronSortDown32,
  ChevronSortUp16,
  ChevronSortUp20,
  ChevronSortUp24,
  ChevronSortUp32,
  ChevronUp16,
  ChevronUp20,
  ChevronUp24,
  ChevronUp32,
  ChevronUpGlyph,
} from "@carbon/icons-vue";

export default {
  name: "PcChevronIcon",

  components: {
    "chevron-down-16": ChevronDown16,
    "chevron-down-20": ChevronDown20,
    "chevron-down-24": ChevronDown24,
    "chevron-down-32": ChevronDown32,
    "chevron-down-glyph": ChevronDownGlyph,
    "chevron-left-16": ChevronLeft16,
    "chevron-left-20": ChevronLeft20,
    "chevron-left-24": ChevronLeft24,
    "chevron-left-32": ChevronLeft32,
    "chevron-left-glyph": ChevronLeftGlyph,
    "chevron-mini-16": ChevronMini16,
    "chevron-mini-20": ChevronMini20,
    "chevron-mini-24": ChevronMini24,
    "chevron-mini-32": ChevronMini32,
    "chevron-right-16": ChevronRight16,
    "chevron-right-20": ChevronRight20,
    "chevron-right-24": ChevronRight24,
    "chevron-right-32": ChevronRight32,
    "chevron-right-glyph": ChevronRightGlyph,
    "chevron-sort-16": ChevronSort16,
    "chevron-sort-20": ChevronSort20,
    "chevron-sort-24": ChevronSort24,
    "chevron-sort-32": ChevronSort32,
    "chevron-sort-down-16": ChevronSortDown16,
    "chevron-sort-down-20": ChevronSortDown20,
    "chevron-sort-down-24": ChevronSortDown24,
    "chevron-sort-down-32": ChevronSortDown32,
    "chevron-sort-up-16": ChevronSortUp16,
    "chevron-sort-up-20": ChevronSortUp20,
    "chevron-sort-up-24": ChevronSortUp24,
    "chevron-sort-up-32": ChevronSortUp32,
    "chevron-up-glyph": ChevronUpGlyph,
  },

  props: {
    size: {
      type: [Number, String],
      validator: function (value) {
        return [16, "16", 20, "20", 24, "24", 32, "32"].includes(value);
      },
      required: false,
      default: 16,
    },
    type: {
      type: String,
      required: true,
      default: "down",
      validator: function (value) {
        return [
          "down",
          "down-glyph",
          "left",
          "left-glyph",
          "mini",
          "right",
          "right-glyph",
          "sort",
          "sort-down",
          "sort-up",
          "up",
          "up-glyph",
        ].includes(value);
      },
    },
    iconColorClass: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
