<template>
  <div class="scrolling-prompts">
    <div
      class="text-center fill-width"
      :style="
        'cursor: pointer; opacity: ' +
        (this.value > 0 ? 1 : 0) +
        '; transition: opacity 0.3s ease;'
      "
      @click="$emit('input', value - 1)"
    >
      <pc-icon name="pc-scroll-up" :color="$colors.penChecksGray"></pc-icon>
    </div>
    <div
      :style="
        'position: relative; transition: height 1.2s ease; height: ' +
        visibleHeight +
        'px; overflow: hidden; background-color: ' +
        backgroundColor
      "
    >
      <div
        :style="
          'position: absolute; transition: top 1.2s ease; top: ' + top + 'px;'
        "
      >
        <slot></slot>
      </div>

      <!-- FADING DIV THAT WILL COVER STUFF UP -->
      <div
        :style="
          'position: absolute; bottom: 0px;width: 100%; height: 100px; background: linear-gradient(180deg, ' +
          backgroundColor +
          '00, ' +
          backgroundColor +
          'FF;'
        "
      ></div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  components: {},

  props: {
    value: {
      required: true, // this is the current prompt index
    },
    backgroundColor: {
      type: String,
      default: "#F5F5F5", // penChecksLight
    },
  },

  data: () => {
    return {
      promptHeights: [],
      observer: null,
    };
  },

  computed: {
    numberOfPrompts: function () {
      return this.promptHeights.length;
    },
    totalHeight: function () {
      const sum = (arr) => arr.reduce((a, b) => a + b, 0);
      return sum(this.promptHeights);
    },
    visibleHeight: function () {
      return this.promptHeights[this.value] + 100;
    },
    top: function () {
      var offset = 0;
      for (var i = 0; i < this.value; i++) {
        if (this.promptHeights[i]) offset += this.promptHeights[i];
      }
      return -offset;
    },
  },

  methods: {
    promptsChanged: function () {
      this.$nextTick(() => {
        this.promptHeights = [];
        for (var i in this.$slots.default) {
          const element = this.$slots.default[i];
          this.promptHeights.push($(element.elm).outerHeight(true));
        }
      });
    },
  },

  mounted: function () {
    const config = { attributes: true, childList: true, subtree: true };

    this.observer = new MutationObserver(this.promptsChanged.bind(this));
    this.observer.observe($(this.$el)[0], config);

    this.promptsChanged();
  },

  beforeDestroy: function () {
    this.observer.disconnect();
  },
};
</script>

<style lang="scss">
.scrolling-prompts {
}
</style>
