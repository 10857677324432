"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";

class DistributionReason extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "DistributionReason";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/distribution-reasons"
    );
  }

  getApiPathForCreate() {
    console.error("Cannot create distribution reasons.");
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/distribution-reasons/" +
      this.code
    );
  }

  getAdminPath() {
    console.error("Cannot go to a distribution reason.");
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultTitle() {
    return this.displayName;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }
}

export default DistributionReason;
