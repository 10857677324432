<template>
  <v-text-field
    ref="textField"
    v-model="formattedSocialSecurityNumber"
    :rules="[validateTaxId]"
    :label="label + (required ? '' : ' (optional)')"
    :error-messages="errorMessages"
    validate-on-blur
    :disabled="disabled"
    :clearable="clearable"
    maxlength="11"
    :dense="dense"
    :loading="loading"
    :color="$colors.penChecksTeal"
    class="multi-line"
    @blur="blur"
    @keyup="textChanged"
  ></v-text-field>
</template>
<script>
export default {
  props: {
    value: {
      // Should be the unmasked social security number
      type: String,
      required: false,
    },
    label: {
      type: String,
      default: "Social Security Number",
    },
    forNonResidentAliens: {
      type: Boolean,
      default: false,
    },
    allowSsnOrNonResidentAlien: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
  },

  data: function () {
    return {
      formattedSocialSecurityNumber: null,
      errorMessages: null,
    };
  },

  watch: {
    formattedSocialSecurityNumber() {
      this.$emit("input", this.nineNumberValue);
    },

    value() {
      if (this.value != this.nineNumberValue) {
        this.formattedSocialSecurityNumber = this.value;
        this.formatText();
        this.$refs.textField.validate();
      }
    },
  },

  computed: {
    nineNumberValue: function () {
      if (
        this.formattedSocialSecurityNumber == null ||
        this.formattedSocialSecurityNumber.trim() == ""
      )
        return null;

      var socialSecurityNumber = this.formattedSocialSecurityNumber
        .replace(/[^\d]/g, "")
        .substr(0, 9);
      if (socialSecurityNumber.length == 9) return socialSecurityNumber;

      return null;
    },
  },

  methods: {
    validateTaxId: function (value) {
      if (this.forNonResidentAliens) return this.validateITIN(value);
      return this.validateSSN(value);
    },
    validateSSN: function (value) {
      if (this.required && !value) return "Please enter a value.";

      if (value && value.length != 11) return this.label + " is invalid.";

      if (!value || value.trim() == "") return true;

      const socialSecurityNumberPattern =
        /^([0-8][0-9]{2})-?([0-9]{2})-?([0-9]{4})/;
      const nonResidentAlienPattern = /^(9[0-9]{2})-?([0-9]{2})-?([0-9]{4})/;
      const ssnOrNonResidentAlienPattern =
        /^([0-9][0-9]{2})-?([0-9]{2})-?([0-9]{4})/;
      var regex;
      if (this.allowSsnOrNonResidentAlien) regex = ssnOrNonResidentAlienPattern;
      else
        regex = this.forNonResidentAliens
          ? nonResidentAlienPattern
          : socialSecurityNumberPattern;
      const matches = value.match(regex);

      if (!matches || matches.length != 4) return this.label + " is invalid.";

      var fullNumber = matches[1] + matches[2] + matches[3];

      // Check for common fake SSNs, and sequences otherwise not covered
      var invalidNumbers = [
        "078051120",
        "219099999",
        "123121234",
        "111223333",
        "111121234",
        "321214321",
      ];

      if (
        matches[1] == "000" ||
        matches[2] == "00" ||
        matches[3] == "0000" || // Any segments with all zeros is invalid
        matches[1] == "666" || // Cannot start with 666
        fullNumber.includes("1234567") || // Sequences of 7 digits
        fullNumber.includes("2345678") ||
        fullNumber.includes("3456789") ||
        fullNumber.includes("9876543") ||
        fullNumber.includes("8765432") ||
        fullNumber.includes("7654321") ||
        fullNumber.match(
          /(012|123|234|345|456|567|678|789)(01|12|23|34|45|56|67|78|89)(0123|1234|2345|3456|4567|5678|6789)/
        ) || // 3 consecutive numbers, 2 consecutive numbers, 4 consecutive numbers
        fullNumber.match(
          /(987|876|765|654|543|432|321|210)(98|87|76|65|54|43|32|21|10)(9876|8765|7654|6543|5432|4321|3210)/
        ) || // 3 reverse consecutive numbers, 2 reverse consecutive numbers, 4 reverse consecutive numbers
        fullNumber.match(/([0-9]{1})\1\1\1\1\1\1/) || // If you have 7 or more of the same digits repeating, probably fake
        fullNumber.match(/([0-9]{3})\1\1/) || // Duplicating a sequence of 3 digits, 3 times (eg. 123123123 or 927927927)
        fullNumber.match(/([0-9]{2})\1\1\1/) || // A pair of numbers, duplicated 4 times (eg. 121212122 or 929292922)
        fullNumber.match(/([0-9])\1\1([0-9])\2([0-9])\3\3\3/) || // First 3 are the same, second 2 are the same, and the last 4 are the same digit
        invalidNumbers.includes(fullNumber)
      ) {
        return this.label + " is invalid.";
      }

      return true;
    },
    validateITIN(val) {
      const errors = [];

      val = (" " + val).trim().replace(/-/g, "");

      function IsBlank(val) {
        if (val.length === 0) {
          errors.push("ITIN must have a value.");
        }
      }

      function IsNineDigits(val) {
        if (val.length > 0 && val.length !== 9 && val !== 'null') {
          errors.push("ITIN must be nine digits.");
        }
      }

      function StartsWithTheNumberNine(val) {
        if (val.length >= 1 && val !== 'null') {
          if (!val.startsWith("9")) {
            errors.push("ITIN must begin with the number 9.");
          }
        }
      }

      IsBlank(val);
      IsNineDigits(val);
      StartsWithTheNumberNine(val);

      this.errorMessages = errors.join("\n");

      return true;
    },
    textChanged: function (e) {
      var selectionStart = e.target.selectionStart;
      var selectionEnd = e.target.selectionEnd;
      var value = e.target.value;

      if (
        this.formattedSocialSecurityNumber != null &&
        this.formattedSocialSecurityNumber.trim() == ""
      ) {
        this.formattedSocialSecurityNumber = null;
        return;
      }

      var isPasting = (e.ctrlKey || e.metaKey) && e.key.toLowerCase() == "v";

      // Dont format if not at end of string (or pasting)
      if (
        !isPasting &&
        (selectionStart != selectionEnd || selectionStart < value.length)
      ) {
        return;
      }

      this.formatText();
    },

    blur: function () {
      this.formatText();
      this.$emit("blur");
    },

    formatText: function () {
      if (
        this.formattedSocialSecurityNumber == null ||
        this.formattedSocialSecurityNumber.trim() == ""
      )
        return;

      var numbersOnlyTextValue = this.formattedSocialSecurityNumber.replace(
        /[^\d]/g,
        ""
      );

      this.formattedSocialSecurityNumber = numbersOnlyTextValue.substr(0, 3);
      if (numbersOnlyTextValue.length > 3)
        this.formattedSocialSecurityNumber +=
          "-" + numbersOnlyTextValue.substr(3, 2);

      if (numbersOnlyTextValue.length > 5)
        this.formattedSocialSecurityNumber +=
          "-" + numbersOnlyTextValue.substr(5, 4);
    },
  },

  mounted: function () {
    this.formattedSocialSecurityNumber = this.value;
    this.formatText();
  },
};
</script>

<style lang="scss" scoped>
.multi-line {
  white-space: pre-line;
}
</style>
