<template>
  <v-select
    v-model="selectedValue"
    :menu-props="{ offsetY: true }"
    :items="filteredItems"
    :loading="searchRequestHandler.isBusy"
    :hide-selected="hideSelected"
    item-text="searchResultTitle"
    :item-value="itemValue"
    :rules="requiredComputed ? selectRules : []"
    :label="requiredComputed ? label : `${label} (optional)`"
    :prepend-icon="prependIcon"
    :clearable="clearable"
    :chips="multiple"
    :id="id"
    :multiple="multiple"
    :color="$colors.penChecksTeal"
    :item-color="$colors.penChecksTeal"
    :dense="dense"
    :disabled="disabled"
    return-object
    @change="$emit('change', selectedValue)"
  >
    <template v-slot:item="data" v-if="!multiple">
      <div class="pc-api-entity-search-item">
        <span :title="extractTextFromHtml(data.item.searchResultTitle)">
          <v-list-item-title>
            <span v-html="data.item.searchResultTitle"></span>
          </v-list-item-title>
        </span>
        <v-list-item-subtitle v-if="data.item.searchResultSubtitle">
          <span
            v-for="(subtitle, $index) in asArray(
              data.item.searchResultSubtitle
            )"
            :key="$index"
          >
            <span
              :title="extractTextFromHtml(subtitle)"
              v-html="subtitle"
            ></span>
          </span>
        </v-list-item-subtitle>
      </div>
    </template>
  </v-select>
</template>

<script>
import SearchRequestHandler from "@/shared/lib/client-sdk/search/search-request-handler";
import $ from "jquery";

export default {
  components: {},

  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      default: "Select",
    },
    itemValue: {
      type: String,
      default: "code",
    },
    entityType: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    multiple: {
      type: Boolean,
    },
    hideSelected: {
      type: Boolean,
    },
    prependIcon: {
      type: String,
    },
    id: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    filterCallback: {
      type: Function,
    },
    excludeValues: {
      type: Array,
      default() {
        return [];
      },
    },
    includeValuesIfMissing: {
      type: Array,
      default() {
        return [];
      },
    },
    // This prop should only be used on "Report taxes to state" fields
    isReportTaxesToStateField: {
      type: Boolean,
    },
  },

  data: function () {
    return {
      searchRequestHandler: new SearchRequestHandler(this.entityType),
      items: this.$cache.get("LookupData_" + this.entityType, []),
      selectedValue: this.value ? { code: this.value } : null,
      selectRules: [(v) => !!v || "Please make a selection."],
    };
  },

  watch: {
    selectedValue(newValue) {
      const selected = newValue
        ? newValue.code
          ? newValue.code
          : newValue.id
        : null;
      //this.$emit("input", newValue ? newValue.code : null);
      this.$emit("input", selected);
      this.$emit("select", newValue);
    },
    value() {
      this.findSelectedValue();
    },
  },

  methods: {
    findSelectedValue: function () {
      if (this.value) {
        for (var i = 0; i < this.filteredItems.length; i++) {
          if (this.filteredItems[i].code == this.value) {
            this.selectedValue = { code: this.value };
            return;
          } else if (this.filteredItems[i].id == this.value) {
            this.selectedValue = { id: this.value };
            return;
          }
        }
      }

      this.selectedValue = null;
    },

    asArray(value) {
      return $.isArray(value) ? value : [value];
    },
    extractTextFromHtml: function (text) {
      let textWithoutHtml = "";
      if (text && typeof text == "string")
        textWithoutHtml = text.replace(/<[^>]+>/g, "");
      return textWithoutHtml;
    },
  },

  computed: {
    filteredItems: function () {
      if (this.items.length == 0) return [];
      var filtered = [...this.items];
      this.includeValuesIfMissing.forEach((includeValue) => {
        if (
          filtered.find(
            (x) => x[this.itemValue] == includeValue[this.itemValue]
          ) == null
        )
          filtered.push(includeValue);
      });
      if (this.excludeValues.length > 0) {
        filtered = this.items.filter((x) => {
          return !this.excludeValues.includes(x.code);
        });
      } else if (this.filterCallback && $.isFunction(this.filterCallback)) {
        filtered = [];
        for (var i = 0; i < this.items.length; i++) {
          var item = this.items[i];
          if (this.filterCallback(item)) filtered.push(item);
        }
      }

      if (this.entityType === "State" && this.isReportTaxesToStateField) {
        const noStateInternational = {
          searchResultTitle: "No state - International",
          code: null,
        };

        filtered.push(noStateInternational);
      }

      return filtered;
    },
    requiredComputed: function () {
      // If this component is being used as a "Report taxes to state" field, we want to allow the value of this field to be null
      if (this.isReportTaxesToStateField) {
        return false;
      }

      // Otherwise, use the "required" prop value
      return this.required;
    },
  },

  mounted: function () {
    if (this.items.length == 0) {
      this.searchRequestHandler.search(
        function (response) {
          if (response && response.results) {
            this.items = response.results;
            this.$cache.set("LookupData_" + this.entityType, this.items);
            this.findSelectedValue();
          }
        }.bind(this)
      );
    } else {
      this.findSelectedValue();
    }
  },
};
</script>

<style lang="scss"></style>
