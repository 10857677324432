<template>
  <v-container class="ma-0 pa-0" v-if="model && extensionAttribute">
    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0">
        <v-text-field
          v-model="formattedPhoneNumber"
          :rules="phoneNumberRules"
          :label="label + (required || hideOptionalText ? '' : ' (optional)')"
          :required="required"
          :disabled="disabled"
          :clearable="clearable"
          :dense="dense"
          :id="id"
          :validate-on-blur="validateOnBlur"
          :color="$colors.penChecksTeal"
          @blur="formatText"
          @keyup="textChanged"
        ></v-text-field>
      </v-col>
      <v-col class="ma-0 pa-0 ml-3" :class="extensionColClass">
        <v-text-field
          class="pa-3"
          v-model="model[extensionAttribute]"
          :rules="extensionRules"
          :label="'Ext.' + (hideOptionalText ? '' : ' (optional)')"
          :disabled="disabled"
          :dense="dense"
          :id="id + 'ext'"
          :color="$colors.penChecksTeal"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
  <v-text-field
    v-else
    v-model="formattedPhoneNumber"
    :rules="phoneNumberRules"
    :label="label + (required || hideOptionalText ? '' : ' (optional)')"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    :dense="dense"
    :id="id"
    :validate-on-blur="validateOnBlur"
    :color="$colors.penChecksTeal"
    @blur="formatText"
    @keyup="textChanged"
  ></v-text-field>
</template>
<script>
import BaseModel from "@/shared/lib/client-sdk/base-model";
import Vue from "vue";
import libphonenumber from "google-libphonenumber";
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

export default {
  props: {
    value: {
      type: String,
    },
    model: {
      type: [BaseModel, Object],
    },
    phoneNumberAttribute: {
      type: String,
    },
    extensionAttribute: {
      type: String,
    },
    extensionColClass: {
      type: String,
      default: "col-6",
    },

    label: {
      type: String,
      default: "Phone Number",
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    id: {
      type: String,
    },
    clearable: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
    hideOptionalText: {
      type: Boolean,
    },
    validateOnBlur: {
      type: Boolean,
      default: true,
    },
  },

  data: function () {
    return {
      formattedPhoneNumber: this.value,
      hasValidProperPhoneNumber: false,
      phoneNumberRules: [
        () => {
          if (this.required && !this.formattedPhoneNumber)
            return "Please enter a phone number.";
          return true;
        },
        () => {
          if (this.formattedPhoneNumber && !this.hasValidProperPhoneNumber)
            return "Please enter a valid phone number.";
          return true;
        },
      ],
      extensionRules: [
        () => {
          const numbersOnlyRegex = new RegExp("^[0-9]*$");
          const extensionValue = this.model[this.extensionAttribute];

          if (extensionValue && !numbersOnlyRegex.test(extensionValue)) {
            return "Extension should be a number.";
          }

          return true;
        },
      ],
    };
  },

  watch: {
    formattedPhoneNumber() {
      this.$emit("input", this.properPhoneNumberValue);
      if (this.model && this.phoneNumberAttribute) {
        this.model[this.phoneNumberAttribute] = this.properPhoneNumberValue;
      }
      let phoneNumber = "";
      try {
        // Keep the evaluation similar to PhoneNumber Class in C# Project
        phoneNumber = phoneUtil.parse(this.formattedPhoneNumber, "US");
        this.hasValidProperPhoneNumber = phoneUtil.isValidNumber(phoneNumber);
      } catch (error) {
        this.hasValidProperPhoneNumber = false;
      }

      if (this.properPhoneNumberValue.length != 12)
        this.hasValidProperPhoneNumber = false;
    },
    value() {
      if (this.value != this.properPhoneNumberValue) {
        this.formattedPhoneNumber = this.value;
        this.formatText();
      }
    },
    model: {
      deep: true,
      handler() {
        if (this.value) return;

        if (
          this.model[this.phoneNumberAttribute] != this.properPhoneNumberValue
        ) {
          this.formattedPhoneNumber = this.model[this.phoneNumberAttribute];
          this.formatText();
        }
      },
    },
  },

  computed: {
    properPhoneNumberValue: function () {
      if (
        this.formattedPhoneNumber == null ||
        this.formattedPhoneNumber.trim() == ""
      )
        return "";

      return "+1" + this.formattedPhoneNumber.replace(/[^\d]/g, "");
    },
  },

  methods: {
    textChanged: function (e) {
      var selectionStart = e.target.selectionStart;
      var selectionEnd = e.target.selectionEnd;
      var value = e.target.value;

      if (
        this.formattedPhoneNumber != null &&
        this.formattedPhoneNumber.trim() == ""
      ) {
        this.formattedPhoneNumber = null;
        return;
      }

      var isPasting = (e.ctrlKey || e.metaKey) && e.key.toLowerCase() == "v";

      // Dont format if not at end of string (or pasting)
      if (
        !isPasting &&
        (selectionStart != selectionEnd || selectionStart < value.length)
      ) {
        return;
      }

      if (e.keyCode != 8) {
        // keycode 8 = backspace
        this.formatText();
      }
    },

    formatText: function () {
      if (
        this.formattedPhoneNumber == null ||
        this.formattedPhoneNumber.trim() == ""
      )
        return;

      if (this.formattedPhoneNumber.startsWith("+1"))
        this.formattedPhoneNumber = this.formattedPhoneNumber.substr(2);

      var numbersOnly = this.formattedPhoneNumber.replace(/[^\d]/g, "");

      this.formattedPhoneNumber = "(" + numbersOnly.substr(0, 3);

      if (numbersOnly.length > 3) {
        this.formattedPhoneNumber += ") " + numbersOnly.substr(3, 3);
      }

      if (numbersOnly.length > 6) {
        this.formattedPhoneNumber += "-" + numbersOnly.substr(6, 4);
      }
    },
  },

  mounted: function () {
    if (this.model) {
      if (typeof this.model[this.phoneNumberAttribute] == "undefined")
        Vue.set(this.model, this.phoneNumberAttribute, null);

      if (typeof this.model[this.extensionAttribute] == "undefined")
        Vue.set(this.model, this.extensionAttribute, null);
    }

    this.formattedPhoneNumber =
      this.model && this.phoneNumberAttribute
        ? this.model[this.phoneNumberAttribute]
        : this.value;

    this.formatText();
  },
};
</script>

<style lang="scss"></style>
