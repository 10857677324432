import { render, staticRenderFns } from "./PcExportIcon.vue?vue&type=template&id=487840a4&scoped=true"
import script from "./PcExportIcon.vue?vue&type=script&lang=js"
export * from "./PcExportIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "487840a4",
  null
  
)

export default component.exports