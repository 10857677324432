import Vue from "vue";
import Vuex from "vuex";
import User from "@/shared/lib/client-sdk/models/user";
import SystemPropertiesModule from "@/shared/store/modules/system-properties.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authenticatedUser: null,
  },
  mutations: {
    setAuthenticatedUser(state, user) {
      state.authenticatedUser = user;
    },
    removeAuthenticatedUser(state) {
      state.authenticatedUser = null;
    },
  },
  actions: {
    refreshAuthenticatedUser(context) {
      User.fetchAuthenticatedUser(
        function (user) {
          if (!user) {
            context.commit("removeAuthenticatedUser");
          } else {
            context.commit("setAuthenticatedUser", user);
          }
        }.bind(this)
      );
    },
  },
  modules: {
    systemProperties: SystemPropertiesModule,
  },
});
