<template>
  <div class="mt-2">
    <div v-if="files.length">
      <div v-if="desiredName" class="center">
        <img class="check-image" src="@/advisor/assets/upload-checkmark.svg" />
        <span class="pl-0 label-before">{{ desiredName }}</span>
      </div>
      <div 
        :class="[
          desiredName ? 'mx-6' : '',
          'preview-container mt-3 py-2 pl-3 pr-2'
        ]"
      >
        <v-row v-for="file in files" :key="file.name" class="my-0">
          <v-col class="py-0 file-label">
            <span class="file-name" :title="file.name">
              {{ file.name }}
            </span>
            <span v-if="!file.hideSize" class="pl-1 file-size">
              {{ parseFloat(file.size / 1000000).toFixed(2) + "MB" }}
            </span>
          </v-col>
          <v-col class="upload-delete py-0">
            <button
              type="button"
              @click="remove(files.indexOf(file))"
              title="Remove file"
            >
              <pc-close-icon size="20" icon-color-class="close-icon-fill-color" />
            </button>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else>
      <div v-if="desiredName" class="center">
        <img class="check-image" src="@/advisor/assets/upload-ellipse.svg" />
        <span class="pl-0 label-before">{{ desiredName }}</span>
      </div>
      <div v-if="error">
        <span 
          :class="[
            desiredName ? 'mx-6' : '',
            'upload-error'
          ]"
        >
          {{ error }}
        </span>
      </div>
      <div
        :class="[
          desiredName ? 'mx-6' : '',
          'dropzone-container mt-3'
        ]"
        :style="
          (highlightError) && 'border-color: #E61E2B;'
        "
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >
        <input
          type="file"
          name="file"
          :id="fileInputId"
          class="hidden-input"
          :multiple="multiple"
          @change="onChange"
          ref="file"
          :accept="fileAcceptList"
        />

        <div class="file-label">
          <div v-if="!isDragging">
            <img class="mt-2" src="@/advisor/assets/upload-image.svg" />
          </div>
          <div v-if="isDragging">Release to drop files here</div>
          <div class="mt-4" v-else>
            <label :for="fileInputId"><u class="file-link">{{ linkName }}</u></label>
            or drag and drop <br />
            <span v-if="allowedExtensions.length">
              {{ allowedExtensions.join(', ') | uppercase }}, 
            </span>
            <span v-else>
              any file
            </span>
            up to {{ sizeLimitMB }}MB
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PcCloseIcon from "@/shared/components/design-system/PcCloseIcon";

const ERRORS = {
  invalidFileType: 'File type not supported. Try again with a different file.',
  invalidSize: 'File too large. Try again with a smaller file.',
  required: 'File upload required.',
};

const JPG_VARIANTS = ['jpg', 'jpeg'];

export default {
  components: {
    PcCloseIcon,
  },

  props: {
    desiredName: {
      type: String,
      default: "",
    },
    linkName: {
      type: String,
      default: "Upload a file",
    },
    sizeLimitMB: {
      type: Number,
      default: 10,
    },
    allowedExtensions: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    fileId: {
      type: String,
      default: "",
    },
    defaultFiles: {
      type: Array,
    }
  },

  data() {
    return {
      isDragging: false,
      files: [],
      error: null,
      highlightError: false,
    };
  },
  methods: {
    onChange(event) {
      this.invalidFileType = false;
      this.invalidSize = false;
      this.required = false;
      this.files = [...this.$refs.file.files];

      this.validate();

      if (!this.error) 
        this.$emit("file-selected", this.files[0], this.fileId);
    },

    validate(emitErrors) {
      this.error = null;
      this.highlightError = false;
      if (!this.files.length) {
        this.error = ERRORS.required;
        this.highlightError = true;
      } 
      else if (!this.files[0].skipValidation){
        const fileSizeMB = this.files[0].size / 1000000;
        const fileType = 
          this.files[0]["type"].includes("/") ? 
          this.files[0]["type"].split("/").pop() : 
          this.files[0]["type"];

        if (
          this.allAllowedExtensions.length && 
          !this.allAllowedExtensions.includes(fileType)
        ) {
          this.error = ERRORS.invalidFileType;
        } else if (fileSizeMB > this.sizeLimitMB) {
          this.error = ERRORS.invalidSize;
        }
      }

      if (this.error){
        this.files = [];
        if(emitErrors) this.$emit("handle-error", this.error, this.fileInputId);
      } 
    },

    remove(i) {
      this.files.splice(i, 1);
      this.invalidFileType = null;
      this.$emit('file-selected', null, this.fileId)
    },

    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },

    dragleave() {
      this.isDragging = false;
    },

    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange(this.$refs.file.files);
      this.isDragging = false;
    },

    cleanFileName(inputString) {
      const lastUnderscoreIndex = inputString.lastIndexOf('_');
      const lastDotIndex = inputString.lastIndexOf('.');

      // Check if both the underscore and dot exist in the string
      if (lastUnderscoreIndex !== -1 && lastDotIndex !== -1 && lastUnderscoreIndex < lastDotIndex) {
          // Concatenate the part before the last underscore with the part after the last dot
          return inputString.substring(0, lastUnderscoreIndex) + inputString.substring(lastDotIndex);
      }
      
      // If the conditions aren't met, return the original string
      return inputString;
  }
  },

  computed: {
    fileInputId: function () {
      return `${this.fileId}FileInput`
    },

    allAllowedExtensions: function () {
      const lowerCaseExtensions = this.allowedExtensions.map(ext => ext.toLowerCase());
      const hasCommonElement = lowerCaseExtensions.some(ext => JPG_VARIANTS.includes(ext));

      if (hasCommonElement) {
        return [...new Set([...lowerCaseExtensions, ...JPG_VARIANTS])];
      }
      return lowerCaseExtensions;
    },

    fileAcceptList() {
      return this.allAllowedExtensions.map(ext => `.${ext}`).join(', ');
    },
  },

  mounted: function () {
    if(!this.defaultFiles) return;
    
    this.defaultFiles.forEach(fileData => {
      if(fileData == null) return;
      
      const file = new File([fileData], this.cleanFileName(fileData.fileName));
      Object.defineProperties(file, {
        'hideSize': {
          value: true
        },
        'skipValidation': {
          value: true
        },
      });
      this.files.push(file)
    });
  },
};
</script>

<style lang="scss">
svg.close-icon-fill-color {
  fill: #30416f !important;
}
.dropzone-container {
  padding: 4rem;
  border: 1px dashed;
  border-color: #aeb9d5;
  max-width: 744px;
  height: 230px;
  padding: 48px 0px 48px 0px;
  gap: 32px;
  border-radius: 8px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  color: #5f6f9c;
  align-self: center;
}
.file-size {
  color: #5f6f9c;
  font-family: "Open Sans";
  font-size: 14px;
}
.file-link {
  color: #0075db;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.preview-container {
  background: #f6f7fc !important;
  border: 1px solid;
  border-color: #d4dbed;
  max-width: 744px;
  border-radius: 6px;
  .file-name {
    font-family: "Open Sans";
    font-size: 14px;
    color: #30416f;
  }
}

.upload-delete {
  text-align: right;
  height: 20px;
}
.check-image {
  vertical-align: middle;
  margin-right: 8px;
  padding-top:2px;
}
.label-before {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #101f48;
}
.upload-error {
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #e61e2b;
  line-height: 19.07px;
}

.center {
  display: flex;
  align-items: center;
}
</style>
