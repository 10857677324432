import Vue from "vue";
import VueRouter from "vue-router";
import RouteObjects from "@/shared/router/route-objects";
import ParticipantStore from "@/participant/store";

var router = {};

if (window.location.host.match(/my.*\.penchecks\.com/g) !== null) {
  Vue.use(VueRouter);

  const routes = [
    {
      path: "/",
      name: "Home",
      meta: { /* title: "Home", */ allowsAnonymousUser: true },
      component: () => import("../views/PcParticipantHome.vue"),
    },
    {
      path: "/login",
      name: "Login",
      meta: { /* title: "Home", */ allowsAnonymousUser: true },
      component: () => import("../views/PcLogin.vue"),
    },
    {
      path: "/start",
      name: "Get Started",
      meta: { /* title: "Home", */ allowsAnonymousUser: true },
      component: () => import("../views/PcStart.vue"),
    },
    {
      path: "/forgot-password",
      name: "Forgot Password",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/PcForgotPassword.vue"),
    },
    {
      path: "/forgot-email",
      name: "Forgot Email",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/PcForgotEmail.vue"),
    },
    {
      path: "/reset-password/:email_address/:reset_password_token",
      name: "Reset Password",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/PcResetPassword.vue"),
    },
    {
      path: "/confirm-email-address",
      name: "Confirm Email Address",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/PcConfirmEmailAddress.vue"),
    },
    {
      path: "/change-email-address/:emailAddress/:token",
      name: "Change Email Address",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/PcChangeEmailAddress.vue"),
    },
    {
      path: "/create-login",
      name: "Create Login",
      component: () => import("../views/PcCreateLogin.vue"),
    },
    {
      path: "/my-distributions",
      name: "My Distributions",
      component: () => import("../views/PcMyDistributions.vue"),
    },
    {
      path: "/my-account",
      name: "My Account",
      component: () => import("../views/PcMyAccount.vue"),
    },
    {
      path: "/my-account/create-login",
      name: "My Account - Create Login",
      component: () => import("../views/PcMyAccount.vue"),
    },
    {
      path: "/my-account/edit-login",
      name: "My Account - Edit Login",
      component: () => import("../views/PcMyAccount.vue"),
    },
    {
      path: "/my-account/edit-login/change-email-address",
      name: "My Account - Edit login - Change email address",
      component: () => import("../views/PcMyAccount.vue"),
    },
    {
      path: "/my-account/edit-login/change-password",
      name: "My Account - Edit login - Change password",
      component: () => import("../views/PcMyAccount.vue"),
    },
    {
      path: "/my-account/edit-my-information",
      name: "My Account - Edit my information",
      component: () => import("../views/PcMyAccount.vue"),
    },
    {
      path: "/my-account/edit-contact",
      name: "My Account - Edit contact",
      component: () => import("../views/PcMyAccount.vue"),
    },
    {
      path: "/distributions/:orderLineUuid([a-zA-Z0-9]{32})/setup",
      name: "Setup Distribution(s)",
      component: () => import("../views/PcDistributionSetup.vue"),
    },
    {
      path: "/distributions/:orderLineUuid([a-zA-Z0-9]{32})/details",
      name: "View Distribution",
      component: () => import("../views/PcDistributionDetails.vue"),
    },
  ];

  router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
  });

  router.objects = new RouteObjects(router);

  router.beforeEach((to, from, next) => {
    function resolveNextRoute() {
      if (to.matched.some((record) => record.meta.allowsAnonymousUser)) {
        if (ParticipantStore.state.authenticatedUser) {
          if (
            to.path != "/my-distributions" &&
            ParticipantStore.state.authenticatedUser.lastPasswordChangeAt !==
              null &&
            to.path != "/confirm-email-address"
          ) {
            next("/my-distributions");
          }
        }

        next();
      } else {
        if (!ParticipantStore.state.authenticatedUser) {
          if (to.path != "/") {
            next("/");
          }
        }

        next();
      }
    }

    // wait for authenticated user to finish loading before resolving route
    if (ParticipantStore.state.isLoadingAuthenticatedUser) {
      ParticipantStore.watch(
        (state) => state.isLoadingAuthenticatedUser,
        (loading) => {
          if (!loading) {
            resolveNextRoute();
          }
        }
      );
    } else {
      resolveNextRoute();
    }
  });

  router.afterEach((to) => {
    if (to.meta && to.meta.title) {
      document.title = to.meta.title + " | PenChecks Participant";
    } else {
      document.title = "PenChecks Participant";
    }

    if (router.app && router.app.$gtag) {
      router.app.$gtag.pageview({
        page_title: document.title,
        page_path: to.path,
        page_location: window.location.href,
      });
    }
  });
}

export default router;
