"use strict";

import BaseModelList from "./base-model-list";

class EntityFlagsList extends BaseModelList {
  constructor(parentModel) {
    super(parentModel);
  }

  getModelClassName() {
    return "EntityFlag";
  }

  getApiPathForSearch() {
    return (
      this.parentModel
        .getApiPathForRefreshUpdateAndDelete()
        .replace("/public/", "/private/") + "/flags"
    );
  }

  getApiPathForAdd(entityFlag) {
    return (
      this.parentModel
        .getApiPathForRefreshUpdateAndDelete()
        .replace("/public/", "/private/") +
      "/flags/" +
      entityFlag.flagName
    );
  }

  getApiPathForRefreshUpdateAndDelete(entityFlag) {
    return (
      this.parentModel
        .getApiPathForRefreshUpdateAndDelete()
        .replace("/public/", "/private/") +
      "/flags/" +
      entityFlag.flagName
    );
  }
}

export default EntityFlagsList;
