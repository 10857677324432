<template>
  <v-snackbar
    v-model="snackbarShow"
    :timeout="-1"
    vertical
    left
    bottom
    :color="$colors.penChecksSemiGray"
    class="snackbar-style"
  >
    <div
      class="pt-2 pr-2"
      @click="closeSnackbarClick"
      style="position: absolute; right: 0; top: 0; cursor: pointer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          d="M24 9.4L22.6 8L16 14.6L9.4 8L8 9.4L14.6 16L8 22.6L9.4 24L16 17.4L22.6 24L24 22.6L17.4 16L24 9.4Z"
          fill="#5F6F9C"
        />
      </svg>
    </div>

    <div class="snackbar-title-group-style mt-4" v-if="showTitle">
      <pc-checkmark-icon
        v-if="snackbarType == 'ok'"
        type="filled"
        :size="16"
        icon-color-class="snackbar-check-color-style mt-1"
      >
      </pc-checkmark-icon>
      <pc-warning-icon
        v-else-if="snackbarType == 'warning'"
        alternate
        type="filled"
        :size="16"
        icon-color-class="snackbar-check-color-style mt-1"
      >
      </pc-warning-icon>

      <div class="snackbar-title-style">
        {{ snackbarTitle }}
      </div>
    </div>

    <p class="snackbar-text-style mt-1 ml-5" v-if="showBody">
      {{ snackbarText }}
    </p>

    <a
      class="snackbar-link-action-style ml-5"
      @click="snackbarActionClick()"
      v-if="showActionButton"
    >
      {{ snackbarButtonText }}
    </a>
  </v-snackbar>
</template>

<script>
import PcCheckmarkIcon from "@/shared/components/design-system/PcCheckmarkIcon.vue";
import PcWarningIcon from "@/shared/components/design-system/PcWarningIcon.vue";

export default {
  components: { PcCheckmarkIcon, PcWarningIcon },

  props: {
    snackbarType: {
      type: String,
      required: true,
      default: "ok",
      validator: function (value) {
        return ["ok", "warning"].includes(value);
      },
    },
    snackbarShow: {
      type: Boolean,
      default: false,
    },
    snackbarTitle: {
      type: String,
      default: "",
    },
    snackbarText: {
      type: String,
      default: "",
    },
    snackbarButtonText: {
      type: String,
      default: "Just now",
    },
  },

  computed: {
    cssVars() {
      var cssBarColor = $colors.black;
      var cssTitleColor = $colors.black;

      // Bar left color
      if (this.snackbarType == "ok") {
        cssBarColor = $colors.success;
      }
      if (this.snackbarType == "warning") {
        cssBarColor = $colors.warning;
      }

      // title color
      if (this.snackbarType == "ok") {
        cssTitleColor = $colors.success;
      }
      if (this.snackbarType == "warning") {
        cssTitleColor = $colors.warning80;
      }

      return {
        cssBarColor: cssBarColor,
        cssTitleColor: cssTitleColor,
      };
    },
    showTitle() {
      return this.snackbarTitle && this.snackbarTitle.length > 0;
    },
    showBody() {
      return this.snackbarText && this.snackbarText.length > 0;
    },
    showActionButton() {
      return this.snackbarButtonText && this.snackbarButtonText.length > 0;
    },
  },

  methods: {
    closeSnackbarClick: function () {
      this.$emit("close");
    },
    snackbarActionClick: function () {
      this.$emit("action");
    },
  },
};
</script>

<style lang="scss">
.snackbar-style {
  font-family: "Open Sans", sans-serif;

  .v-snack__wrapper {
    width: 330px;
    align-items: flex-start;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: $white !important;
    border-left: 2px solid v-bind("cssVars.cssBarColor") !important;
    box-shadow: 0px 4px 16px 0px rgba(119, 119, 119, 0.24) !important;

    .v-snack__content {
      margin-top: 16px;
      margin-left: 16px;
      margin-right: 24px;
      margin-bottom: 12px;
      padding: 0px;

      .snackbar-title-group-style {
        display: flex;
        flex-direction: row;
        margin: 0px !important;
        padding: 0px;

        .snackbar-title-style {
          color: v-bind("cssVars.cssTitleColor");
          font-size: 12px;
          font-weight: 600;
          margin-left: 8px;
        }

        svg.snackbar-check-color-style {
          width: 12px !important;
          height: 12px !important;
          fill: v-bind("cssVars.cssBarColor");
        }
      }

      .snackbar-text-style {
        color: $neutral50 !important;
        font-size: 12px;
        font-weight: 400;
      }

      .snackbar-link-action-style {
        color: $neutral30 !important;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .v-snack__action {
      margin: 0px;
      padding: 0px;
    }
  }
}
</style>
