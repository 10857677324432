<template>
  <v-progress-circular
    indeterminate
    :size="size"
    :color="$colors.penChecksTeal"
  ></v-progress-circular>
</template>
<script>
export default {
  props: {
    small: {
      type: Boolean,
    },
    large: {
      type: Boolean,
    },
  },

  data: function () {
    return {};
  },

  computed: {
    size: function () {
      if (this.small) return 14;
      if (this.large) return 72;

      return 40;
    },
  },

  mounted: function () {},
};
</script>

<style lang="scss"></style>
