<template>
  <div class="text-center">
    <v-dialog v-if="zoom" v-model="zoom" max-width="100%" max-height="100%">
      <img :src="apiBaseUrl + '/public/files/' + fileId + '/view'" />
    </v-dialog>

    <v-skeleton-loader
      v-if="!loaded"
      class="mx-auto"
      type="image"
      loading
    ></v-skeleton-loader>
    <img
      class="pc-image"
      :class="{ hand: zoomOnClick || hasClickHandler }"
      v-show="loaded"
      :src="apiBaseUrl + '/public/files/' + fileId + '/view'"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
import $ from "jquery";

export default {
  components: {},

  props: {
    fileId: {
      type: String,
      required: true,
    },
    width: {},
    height: {},
    zoomOnClick: {
      type: Boolean,
    },
  },

  data: function () {
    return {
      loaded: false,
      zoom: false,
      apiBaseUrl: window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL"),
    };
  },
  computed: {
    hasClickHandler: function () {
      return this.$listeners && this.$listeners.click;
    },
  },

  mounted: function () {
    this.$nextTick(() => {
      var img = $(this.$el).find("img");

      img.on("load", () => {
        this.loaded = true;
      });

      img.on("click", () => {
        if (this.zoomOnClick) this.zoom = true;

        this.$emit("click");
      });
    });
  },
};
</script>

<style lang="scss">
.pc-image {
  &.hand {
    cursor: zoom-in;
  }
}
</style>
