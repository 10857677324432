var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2"},[(_vm.files.length)?_c('div',[(_vm.desiredName)?_c('div',{staticClass:"center"},[_c('img',{staticClass:"check-image",attrs:{"src":require("@/advisor/assets/upload-checkmark.svg")}}),_c('span',{staticClass:"pl-0 label-before"},[_vm._v(_vm._s(_vm.desiredName))])]):_vm._e(),_c('div',{class:[
          _vm.desiredName ? 'mx-6' : '',
          'preview-container mt-3 py-2 pl-3 pr-2'
        ]},_vm._l((_vm.files),function(file){return _c('v-row',{key:file.name,staticClass:"my-0"},[_c('v-col',{staticClass:"py-0 file-label"},[_c('span',{staticClass:"file-name",attrs:{"title":file.name}},[_vm._v(" "+_vm._s(file.name)+" ")]),(!file.hideSize)?_c('span',{staticClass:"pl-1 file-size"},[_vm._v(" "+_vm._s(parseFloat(file.size / 1000000).toFixed(2) + "MB")+" ")]):_vm._e()]),_c('v-col',{staticClass:"upload-delete py-0"},[_c('button',{attrs:{"type":"button","title":"Remove file"},on:{"click":function($event){_vm.remove(_vm.files.indexOf(file))}}},[_c('pc-close-icon',{attrs:{"size":"20","icon-color-class":"close-icon-fill-color"}})],1)])],1)}),1)]):_c('div',[(_vm.desiredName)?_c('div',{staticClass:"center"},[_c('img',{staticClass:"check-image",attrs:{"src":require("@/advisor/assets/upload-ellipse.svg")}}),_c('span',{staticClass:"pl-0 label-before"},[_vm._v(_vm._s(_vm.desiredName))])]):_vm._e(),(_vm.error)?_c('div',[_c('span',{class:[
            _vm.desiredName ? 'mx-6' : '',
            'upload-error'
          ]},[_vm._v(" "+_vm._s(_vm.error)+" ")])]):_vm._e(),_c('div',{class:[
          _vm.desiredName ? 'mx-6' : '',
          'dropzone-container mt-3'
        ],style:((_vm.highlightError) && 'border-color: #E61E2B;'),on:{"dragover":_vm.dragover,"dragleave":_vm.dragleave,"drop":_vm.drop}},[_c('input',{ref:"file",staticClass:"hidden-input",attrs:{"type":"file","name":"file","id":_vm.fileInputId,"multiple":_vm.multiple,"accept":_vm.fileAcceptList},on:{"change":_vm.onChange}}),_c('div',{staticClass:"file-label"},[(!_vm.isDragging)?_c('div',[_c('img',{staticClass:"mt-2",attrs:{"src":require("@/advisor/assets/upload-image.svg")}})]):_vm._e(),(_vm.isDragging)?_c('div',[_vm._v("Release to drop files here")]):_c('div',{staticClass:"mt-4"},[_c('label',{attrs:{"for":_vm.fileInputId}},[_c('u',{staticClass:"file-link"},[_vm._v(_vm._s(_vm.linkName))])]),_vm._v(" or drag and drop "),_c('br'),(_vm.allowedExtensions.length)?_c('span',[_vm._v(" "+_vm._s(_vm._f("uppercase")(_vm.allowedExtensions.join(', ')))+", ")]):_c('span',[_vm._v(" any file ")]),_vm._v(" up to "+_vm._s(_vm.sizeLimitMB)+"MB ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }