<template>
  <v-text-field
    :id="id"
    v-model="postalCode"
    :label="label + (required || hideOptionalText ? '' : ' (optional)')"
    :required="required"
    :rules="rules"
    :disabled="disabled"
    :clearable="clearable"
    :dense="dense"
    :validate-on-blur="true"
    :color="$colors.penChecksTeal"
  ></v-text-field>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      default: "Postal Code",
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
    countryCode: {
      type: String,
      default: "US",
    },
    hideOptionalText: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
  },

  data: function () {
    return {
      postalCode: this.value,
      rules: [
        () => {
          if (
            this.countryCode &&
            this.countryCode.trim() != "" &&
            this.countryCode.toUpperCase().trim() != "US"
          )
            return true;

          if (
            !this.required &&
            (!this.postalCode || this.postalCode.trim() == "")
          )
            return true;

          return this.postalCode && /^\d{5}(-\d{4})?$/.test(this.postalCode)
            ? true
            : "Please enter a valid US postal code.";
        },

        () => {
          if (this.countryCode && this.countryCode.toUpperCase().trim() == "US")
            return true;

          if (
            !this.required &&
            (!this.postalCode || this.postalCode.trim() == "")
          )
            return true;

          return this.postalCode && this.postalCode.length > 3
            ? true
            : "Please enter a valid postal code.";
        },
        (v) =>
          (this.required ? !!v : true) ||
          "Please enter " + this.label.toLowerCase(),
      ],
    };
  },

  watch: {
    postalCode() {
      this.$emit("input", this.postalCode);
    },
    value() {
      this.postalCode = this.value;
    },
  },

  mounted: function () {
    this.postalCode = this.value;
  },
};
</script>

<style lang="scss"></style>
