<template>
  <v-menu
    v-model="showTimePicker"
    offset-y
    :close-on-content-click="false"
    max-width="460"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="displayTextValue"
        :disabled="disabled"
        :clearable="clearable"
        :label="label"
        readonly
        v-on="on"
        @click:clear="clearPressed"
        :dense="dense"
        :required="required"
      ></v-text-field>
    </template>
    <v-time-picker
      v-model="componentValue"
      @change="showTimePicker = false"
      landscape
      scrollable
    ></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {},
    label: {
      type: String,
      default: "Time",
    },
    clearable: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
  },

  data: function () {
    return {
      showTimePicker: false,
      componentValue: null,
    };
  },

  watch: {
    value: function () {
      this.componentValue = this.value;
    },
    componentValue: function () {
      this.$emit("input", this.componentValue);
    },
  },

  computed: {
    displayTextValue: function () {
      var value = this.componentValue;

      if (!value) return null;

      var pieces = value.split(":");
      var hours = parseInt(pieces[0]);
      var minutes = pieces[1];

      var m = "AM";
      if (hours > 12) {
        hours = hours - 12;
        m = "PM";
      }

      return hours + ":" + minutes + " " + m + " (Pacific)";
    },
  },

  methods: {
    clearPressed: function () {
      if (this.range) this.componentValue = [null, null];
      else this.componentValue = null;
    },
  },

  mounted: function () {
    this.componentValue = this.value;
  },
};
</script>

<style lang="scss"></style>
