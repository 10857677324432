<template>
  <v-row class="py-0 my-o">
    <v-col class="fields-col-button-style">
      <v-menu
        v-model="fieldsMenu"
        :close-on-content-click="false"
        :nudge-width="200"
        allow-overflow
        botton
        :offset-y="true"
      >
        <template v-slot:activator="{ on, attrs }">
          <pc-button
            :disabled="disabled"
            outlined
            large
            rounded
            class="fields-button-style"
            v-bind="attrs"
            v-on="on"
          >
            <v-row>
              <span class="fields-span-style">FIELDS</span>
              <pc-status-chip
                class="fields-status-chip-style"
                v-model="fieldsStatusShipText"
              ></pc-status-chip>
              <span v-if="!fieldsMenu" class="material-icons">
                expand_more
              </span>
              <span v-if="fieldsMenu" class="material-icons">
                expand_less
              </span>
            </v-row>
          </pc-button>
        </template>

        <v-card class="fields-main-card-style">
          <v-list>
            <v-list-item class="fields-report-legend-style">
              <span
                >Select the fields that you want to see on this data
                report</span
              >
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item class="fields-report-title-style">
              <span>{{ searchTitle }} fields</span>
            </v-list-item>
            <v-list-item class="fields-report-field-list-style">
              <v-expansion-panels
                v-model="fieldListPanels"
                class="fields-expansion-panels-field-list-style"
              >
                <v-expansion-panel
                  v-model="fieldListPanels"
                  class="fields-panel-field-list-style"
                >
                  <v-expansion-panel-header
                    class="fields-panel-header-field-list-style"
                  >
                    <template v-slot:default="{ open }">
                      <v-row>
                        <v-text-field
                          :label="fieldsSelectedLabel"
                          v-model="fieldFilterText"
                          class="fields-filter-field-style"
                        ></v-text-field>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    class="fields-panel-content-field-list-style"
                  >
                    <v-card>
                      <v-radio-group
                        v-model="shortFieldsOptionSelected"
                        class="fields-radio-options-style"
                      >
                        <v-radio
                          label="Default fields"
                          :value="shortFields.Default"
                        ></v-radio>
                        <v-radio
                          label="All fields"
                          :value="shortFields.All"
                        ></v-radio>
                      </v-radio-group>
                      <v-list class="fields-field-list-style">
                        <v-subheader
                          class="fields-subheader-field-list-style"
                          name="availableFields"
                          >AVAILABLE FIELDS</v-subheader
                        >
                        <v-list-item
                          :class="{
                            'fields-item-field-list-style': true,
                            'fields-item-checked-field-list-style':
                              selectedColumns.includes(item.value),
                          }"
                          v-for="item in filterAvailableColumns"
                          :key="item.value"
                          ><v-checkbox
                            :value="item.value"
                            :label="item.text"
                            v-model="selectedColumns"
                          ></v-checkbox
                        ></v-list-item>
                      </v-list>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-col>
    <v-col
      class="fields-col-divider-style"
      v-if="shortFieldsOptionSelected != shortFields.Default"
    >
      <v-divider vertical></v-divider>
    </v-col>
    <v-col
      class="fields-col-reset-style"
      v-if="shortFieldsOptionSelected != shortFields.Default"
    >
      <div class="fields-div-reset-style">
        <a class="fields-anchor-reset-style" @click="resetColumnsToDefault()"
          ><span class="tableColumnTextLink">Reset to default</span></a
        >
      </div>
    </v-col>
  </v-row>
</template>

<script>
import SearchRequestHandler from "@/shared/lib/client-sdk/search/search-request-handler";

export default {
  name: "PcEnhancedFieldSelector",

  props: {
    searchRequestHandler: {
      type: SearchRequestHandler,
      require: true,
    },
    searchTitle: {
      type: String,
      default: "Report",
    },
    defaultFields: {
      type: Array,
      require: false,
      default: null,
    },
    searchHasBeenGenerated: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      selectedColumns: [],
      fieldsMenu: false,
      fieldListPanels: [],
      shortFields: {
        Default: "defaultFields",
        All: "allFields",
        Custom: "customFields",
      },
      shortFieldsOptionSelected: "customFields",
      fieldFilterText: "",
    };
  },

  watch: {
    "searchRequestHandler.searchableFields": function () {
      this.shortFieldsOptionSelected = this.getShortFieldsSelection();
    },
    selectedColumns: {
      handler: function () {
        this.saveToLocalStorage();
        this.shortFieldsOptionSelected = this.getShortFieldsSelection();

        this.$emit("selectedColumnsChanged", this.selectedColumns);
      },
      deep: true,
    },
    shortFieldsOptionSelected(newShortFieldsSelection) {
      if (newShortFieldsSelection == this.shortFields.Default) {
        this.setupDefaultFields();
      } else if (newShortFieldsSelection == this.shortFields.All) {
        this.setupAllFields();
      }
    },
    fieldsMenu(newFieldsMenu) {
      if (newFieldsMenu == false) {
        this.fieldFilterText = "";
        this.fieldListPanels = [];
      }
    },
  },

  computed: {
    localStorageKeyPrefix: function () {
      var url = window.location.href;

      if (url.includes("?")) url = url.split("?")[0];

      if (url.includes("#query:")) url = url.split("#query:")[0];

      // Remove any IDs from the URL, so it doesn't change each time
      url = url.replace(/\b[a-zA-Z0-9]{32}\b/gi, "{id}");

      var requestUrl = this.searchRequestHandler.requestUrl;

      if (requestUrl.includes("?")) requestUrl = requestUrl.split("?")[0];

      if (requestUrl.includes("#query:"))
        requestUrl = requestUrl.split("#query:")[0];

      // Remove any IDs from the URL, so it doesn't change each time
      requestUrl = requestUrl.replace(/\b[a-zA-Z0-9]{32}\b/gi, "{id}");

      return (
        "reportSearch_" +
        this.searchRequestHandler.modelClassName +
        "_" +
        url +
        "_" +
        requestUrl +
        "_"
      );
    },
    availableColumns: function () {
      var columns = [];

      if (this.defaultFields != null) {
        for (let i = 0; i < this.defaultFields.length; i++) {
          const field = this.defaultFields[i];

          if (field.isFieldDisplayedInResults)
            columns.push({
              value: field.resultKey,
              text: field.displayName,
            });
        }
      }

      for (
        let i = 0;
        i < this.searchRequestHandler.searchableFields.length;
        i++
      ) {
        const field = this.searchRequestHandler.searchableFields[i];
        const exist =
          columns.filter((c) => c.value == field.resultKey).length > 0;

        if (
          field.isFieldDisplayedInResults &&
          !exist &&
          (!field.isFieldSearchableByStaffMembersOnly ||
            this.$store.state.authenticatedUser.isStaffMember())
        )
          columns.push({
            value: field.resultKey,
            text: field.displayName,
          });
      }

      return columns;
    },
    fieldsStatusShipText: function () {
      let defaultText = "";

      if (this.shortFieldsOptionSelected == this.shortFields.Default) {
        defaultText = "default";
      } else if (this.shortFieldsOptionSelected == this.shortFields.All) {
        defaultText = "all";
      } else {
        if (this.selectedColumns) {
          defaultText = this.selectedColumns.length.toString();
        }
      }

      return defaultText;
    },
    filterAvailableColumns: function () {
      var columns = this.availableColumns.filter((col) => {
        return true;
      });

      if (this.fieldFilterText && this.fieldFilterText.trim().length > 0) {
        var filter = this.fieldFilterText.toLowerCase().trim();
        columns = this.availableColumns.filter(function (col) {
          return col.text.toLowerCase().includes(filter);
        });
      }

      return columns;
    },
    fieldsSelectedLabel: function () {
      var fieldFilterLabel = "0 of 0";

      if (this.selectedColumns && this.availableColumns) {
        fieldFilterLabel =
          this.selectedColumns.length + " of " + this.availableColumns.length;
      }
      return fieldFilterLabel;
    },
  },

  methods: {
    saveToLocalStorage: function () {
      if (this.searchHasBeenGenerated == true) {
        window.localStorage.setItem(
          this.localStorageKeyPrefix + "_selectedColumns",
          JSON.stringify(this.selectedColumns)
        );
      }
    },
    resetColumnsToDefault: function () {
      this.selectedColumns = [];

      if (this.defaultFields != null) {
        this.selectedColumns = this.defaultFields.map((df) => df.resultKey);
      } else {
        for (
          var i = 0;
          i < this.searchRequestHandler.searchableFields.length;
          i++
        ) {
          const field = this.searchRequestHandler.searchableFields[i];
          if (
            field.isFieldDisplayedInResults &&
            field.isFieldDisplayedInResultsByDefault
          ) {
            this.selectedColumns.push(field.resultKey);
          }
        }
      }
    },
    loadFromLocalStorage: function () {
      var storedSelectedColumns = window.localStorage.getItem(
        this.localStorageKeyPrefix + "_selectedColumns"
      );

      if (
        storedSelectedColumns &&
        JSON.parse(storedSelectedColumns) &&
        JSON.parse(storedSelectedColumns).length > 0
      ) {
        this.selectedColumns = JSON.parse(storedSelectedColumns);
      }
    },
    searchComplete: function () {
      if (this.selectedColumns) {
        if (this.selectedColumns.length == 0) {
          this.loadFromLocalStorage();
        }

        if (this.selectedColumns.length == 0) {
          this.resetColumnsToDefault();
        }

        this.saveToLocalStorage();
        this.shortFieldsOptionSelected = this.getShortFieldsSelection();
      }
    },
    setupDefaultFields: function () {
      this.resetColumnsToDefault();
    },
    setupAllFields: function () {
      this.selectedColumns = [];

      if (this.defaultFields != null) {
        this.selectedColumns = this.defaultFields.map((df) => df.resultKey);
      }

      for (
        var i = 0;
        i < this.searchRequestHandler.searchableFields.length;
        i++
      ) {
        const field = this.searchRequestHandler.searchableFields[i];
        const exist =
          this.selectedColumns.filter((c) => c == field.resultKey).length > 0;

        if (field.isFieldDisplayedInResults && !exist)
          this.selectedColumns.push(field.resultKey);
      }
    },
    getShortFieldsSelection: function () {
      let fieldsSelection = this.shortFields.Custom;
      if (this.searchRequestHandler?.searchableFields.length > 0) {
        var customFieldsCount = 0;
        if (this.defaultFields != null) {
          customFieldsCount = this.defaultFields.length;
        }

        var listAllFields = [];
        for (
          var i = 0;
          i < this.searchRequestHandler.searchableFields.length;
          i++
        ) {
          const field = this.searchRequestHandler.searchableFields[i];
          if (field.isFieldDisplayedInResults) {
            var exist = false;
            if (this.defaultFields != null) {
              exist =
                this.defaultFields.filter(
                  (df) => df.resultKey == field.resultKey
                ).length > 0;
            }

            if (!exist) {
              listAllFields.push(field.resultKey);
            }
          }
        }

        if (
          this.selectedColumns.length ==
          listAllFields.length + customFieldsCount
        ) {
          fieldsSelection = this.shortFields.All;
        } else {
          var listDefaultFields = [];

          if (this.defaultFields != null) {
            listDefaultFields = this.defaultFields.map((df) => df);
          } else {
            listDefaultFields =
              this.searchRequestHandler.searchableFields.filter(function (
                item
              ) {
                return (
                  item.isFieldDisplayedInResultsByDefault &&
                  item.isFieldDisplayedInResults
                );
              });
          }

          if (this.selectedColumns.length == listDefaultFields.length) {
            var allDefault = true;
            for (var item in listDefaultFields) {
              var field = listDefaultFields[item];
              if (!this.selectedColumns.includes(field.resultKey)) {
                allDefault = false;
                break;
              }
            }
            if (allDefault) {
              fieldsSelection = this.shortFields.Default;
            }
          }
        }
      }

      return fieldsSelection;
    },
  },

  mounted: function () {
    this.searchRequestHandler.onSearchComplete = this.searchComplete.bind(this);
    this.searchComplete();
  },
};
</script>

<style lang="scss">
.fields-button-style {
  padding-left: 15px;
}

span.fields-button-style > span > button.v-btn.v-size--default {
  height: 36px !important;
}

.fields-span-style {
  margin-top: 5px;
}

.fields-status-chip-style + span {
  margin-top: 2px;
}

.fields-report-title-style {
  min-height: 15px !important;
  span {
    color: $penChecksBlue;
  }
}

.fields-main-card-style {
  min-height: 175px;
}

.fields-col-button-style {
  max-width: fit-content;
}

.fields-col-divider-style {
  max-width: 15px;
}

.fields-filter-field-style {
  margin-left: 10px;
}

.fields-report-legend-style {
  min-height: 25px;
}

.fields-panel-content-field-list-style {
  max-height: 200px;
  overflow-y: auto;
  margin-top: -25px;
  > div {
    padding: 10px;
  }
}

.fields-report-field-list-style {
  padding: 0px;
}

.fields-subheader-field-list-style {
  padding-left: 5px;
  font-size: small;
  height: 25px;
}

.fields-panel-field-list-style::before {
  -webkit-box-shadown: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}

.fields-panel-header-field-list-style {
  padding-top: 0px;
}

.fields-item-field-list-style {
  min-height: 30px;
  max-height: 40px;
  div > div > div.v-input__slot > label {
    color: inherit;
  }
}

.fields-item-checked-field-list-style {
  background-color: $penChecksLightBlue;
}

.fields-radio-options-style {
  padding-left: 5px;
  margin-top: -5px;
  margin-bottom: -15px;
}

.fields-div-reset-style {
  margin-top: 6px;
}

.fields-col-reset-style {
  text-align: left;
}
</style>
