<template>
  <v-text-field
    v-model="formattedEmployerIdentificationNumber"
    :rules="[validateEmployerIdentificationNumber]"
    :label="required ? label : label + ' (optional)'"
    :validate-on-blur="true"
    :disabled="disabled"
    :clearable="clearable"
    maxlength="11"
    :dense="dense"
    :color="$colors.penChecksTeal"
    @blur="formatText"
    @keyup="textChanged"
  ></v-text-field>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      default: "Employer Identification Number (EIN)",
    },
    required: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
  },

  data: function () {
    return {
      formattedEmployerIdentificationNumber: null,
    };
  },

  watch: {
    formattedEmployerIdentificationNumber() {
      this.$emit("input", this.nineNumberValue);
    },

    value() {
      if (this.value != this.nineNumberValue) {
        this.nineNumberValue = this.value;
        this.formatText();
      }
    },
  },

  computed: {
    nineNumberValue: function () {
      if (
        this.formattedEmployerIdentificationNumber == null ||
        this.formattedEmployerIdentificationNumber.trim() == ""
      )
        return null;

      var employerIdentificationNumber =
        this.formattedEmployerIdentificationNumber
          .replace(/[^\d]/g, "")
          .substr(0, 9);
      if (employerIdentificationNumber.length == 9)
        return employerIdentificationNumber;

      return null;
    },
  },

  methods: {
    validateEmployerIdentificationNumber: function (value) {
      if (this.required && !value) return "Please enter company EIN.";

      if (value && value.length != 10) return this.label + " is invalid.";

      if (!value || value.trim() == "") return true;

      const pattern = /^\d{2}-\d{7}$/;

      const matches = value.match(pattern);

      if (!matches) "Please enter a valid EIN.";

      return true;
    },

    textChanged: function (e) {
      var selectionStart = e.target.selectionStart;
      var selectionEnd = e.target.selectionEnd;
      var value = e.target.value;

      if (
        this.formattedEmployerIdentificationNumber != null &&
        this.formattedEmployerIdentificationNumber.trim() == ""
      ) {
        this.formattedEmployerIdentificationNumber = null;
        return;
      }

      var isPasting = (e.ctrlKey || e.metaKey) && e.key.toLowerCase() == "v";

      // Dont format if not at end of string (or pasting)
      if (
        !isPasting &&
        (selectionStart != selectionEnd || selectionStart < value.length)
      ) {
        return;
      }

      this.formatText();
    },

    formatText: function () {
      if (
        this.formattedEmployerIdentificationNumber == null ||
        this.formattedEmployerIdentificationNumber.trim() == ""
      )
        return;

      var numbersOnlyTextValue =
        this.formattedEmployerIdentificationNumber.replace(/[^\d]/g, "");

      this.formattedEmployerIdentificationNumber = numbersOnlyTextValue.substr(
        0,
        2
      );

      if (numbersOnlyTextValue.length > 2)
        this.formattedEmployerIdentificationNumber +=
          "-" + numbersOnlyTextValue.substr(2, 7);
    },
  },

  mounted: function () {
    this.formattedEmployerIdentificationNumber = this.value;
    this.formatText();
  },
};
</script>

<style lang="scss"></style>
