<template>
  <div>
    {{ displayName }}
  </div>
</template>

<script>
import SearchRequestHandler from "@/shared/lib/client-sdk/search/search-request-handler";

export default {
  components: {},

  props: {
    value: {
      type: String,
    },
    entityType: {
      type: String,
      required: true,
    },
  },

  data: function () {
    return {
      searchRequestHandler: new SearchRequestHandler(this.entityType),
      items: this.$cache.get("LookupData_" + this.entityType, []),
      selectedValue: this.value ? { code: this.value } : null,
      displayName: "",
    };
  },
  watch: {
    value() {
      this.findSelectedValue();
    },
  },

  methods: {
    findSelectedValue: function () {
      if (this.value) {
        for (var i = 0; i < this.items.length; i++) {
          if (this.items[i].code == this.value) {
            this.displayName = this.items[i].displayName;
            break;
          }
        }
      }
    },
  },

  mounted: function () {
    if (this.items.length == 0) {
      this.searchRequestHandler.search(
        function (response) {
          if (response && response.results) {
            this.items = response.results;
            this.$cache.set("LookupData_" + this.entityType, this.items);
            this.findSelectedValue();
          }
        }.bind(this)
      );
    } else {
      this.findSelectedValue();
    }
  },
};
</script>

<style lang="scss"></style>
