import Vue from "vue";
import VueRouter from "vue-router";
import RouteObjects from "@/shared/router/route-objects";

var router = {};

if (window.location.host.match(/.*unclaimedretirementbenefits.com/g) !== null) {
  Vue.use(VueRouter);

  const routes = [
    {
      path: "/",
      name: "Home",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/PcNationalRegistryHome.vue"),
    },
    {
      path: "/about",
      name: "About",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/PcAbout.vue"),
    },
    {
      path: "/employees",
      name: "Employees",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/PcEmployees.vue"),
    },
    {
      path: "/employers",
      name: "Employers",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/PcEmployers.vue"),
    },
    {
      path: "/providers",
      name: "Providers",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/PcProviders.vue"),
    },
    {
      path: "/faqs",
      name: "FAQs",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/PcFaqs.vue"),
    },
    {
      path: "/contact",
      name: "Contact",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/PcContact.vue"),
    },
    {
      path: "/search",
      name: "Search",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/PcSearch.vue"),
    },
    {
      path: "/login",
      name: "Login",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/account/PcLogin.vue"),
    },
    {
      path: "/register",
      name: "Register",
      meta: { allowsAnonymousUser: true },
      component: () => import("../views/account/PcRegister.vue"),
    },
    {
      path: "/account-creation",
      name: "Create Account",
      meta: {},
      component: () => import("../views/account/PcCreateAccount.vue"),
    },
    {
      path: "/my-account",
      name: "Account",
      meta: {},
      component: () => import("../views/account/PcMyAccount.vue"),
    },
    {
      path: "/new-password",
      name: "New Password",
      meta: { title: "New Password" },
      component: () => import("../views/account/PcNewPassword.vue"),
    },
    {
      path: "/forgot-password",
      name: "Forgot Password",
      meta: { title: "Forgot Password" },
      component: () => import("../views/account/PcForgotPassword.vue"),
    },
    {
      path: "/reset-password/:email_address/:reset_password_token",
      name: "Reset Password",
      meta: { title: "Reset Password" },
      component: () => import("../views/account/PcResetPassword.vue"),
    },
  ];

  router = new VueRouter({
    mode: "history",
    routes,
  });

  router.objects = new RouteObjects(router);

  router.afterEach((to) => {
    if (to.meta && to.meta.title) {
      document.title = to.meta.title + " | National Registry";
    } else {
      document.title = "National Registry";
    }

    if (router.app && router.app.$gtag) {
      router.app.$gtag.pageview({
        page_title: document.title,
        page_path: to.path,
        page_location: window.location.href,
      });
    }

    if (to.path === "/undefined")
      document.location = window.amplifyRuntimeConfig.get(
        "VUE_APP_NATIONAL_REGISTRY_BASE_URL"
      );
  });
}

export default router;
