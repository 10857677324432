"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";

class OrderLineDetailTaxService extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "OrderLineDetailTaxService";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-lines"
    );
  }

  getApiPathForCreate() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/orders/" +
      this.orderId +
      "/" +
      this.participantId +
      "/order-lines"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/order-line-detail-tax-service/" +
      this.id
    );
  }

  getAdminPath() {
    return "/orders/" + this.id;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultTitle() {
    return this.id;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  get rothAccountAge() {
    if (this.rothAccountOpenedInYear) {
      return new Date().getFullYear() - this.rothAccountOpenedInYear;
    }

    return 0;
  }
}

export default OrderLineDetailTaxService;
