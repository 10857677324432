<template>
  <div v-if="this.filled">
    <div v-if="this.size && this.size == 16">
      <close-filled-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <close-filled-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <close-filled-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <close-filled-32 :class="iconColorClass" />
    </div>
    <div v-else><close-filled-16 :class="iconColorClass" /></div>
  </div>
  <div v-else-if="this.outline">
    <div v-if="this.size && this.size == 16">
      <close-outline-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <close-outline-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <close-outline-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <close-outline-32 :class="iconColorClass" />
    </div>
    <div v-else><close-outline-16 :class="iconColorClass" /></div>
  </div>
  <div v-else>
    <div v-if="this.size && this.size == 16">
      <close-16 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 20">
      <close-20 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 24">
      <close-24 :class="iconColorClass" />
    </div>
    <div v-else-if="this.size && this.size == 32">
      <close-32 :class="iconColorClass" />
    </div>
    <div v-else><close-16 :class="iconColorClass" /></div>
  </div>
</template>

<script>
import {
  Close16,
  Close20,
  Close24,
  Close32,
  CloseFilled16,
  CloseFilled20,
  CloseFilled24,
  CloseFilled32,
  CloseOutline16,
  CloseOutline20,
  CloseOutline24,
  CloseOutline32,
} from "@carbon/icons-vue";

export default {
  name: "PcCloseIcon",

  components: {
    "close-16": Close16,
    "close-20": Close20,
    "close-24": Close24,
    "close-32": Close32,
    "close-filled-16": CloseFilled16,
    "close-filled-20": CloseFilled20,
    "close-filled-24": CloseFilled24,
    "close-filled-32": CloseFilled32,
    "close-outline-16": CloseOutline16,
    "close-outline-20": CloseOutline20,
    "close-outline-24": CloseOutline24,
    "close-outline-32": CloseOutline32,
  },

  props: {
    size: {
      type: [Number, String],
      validator: function (value) {
        return [16, "16", 20, "20", 24, "24", 32, "32"].includes(value);
      },
      required: false,
      default: 16,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    iconColorClass: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
