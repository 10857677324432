"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";

import CorporateBankAccount from "@/shared/lib/client-sdk/models/corporate-bank-account";
import BankTransaction from "@/shared/lib/client-sdk/models/bank-transaction";
import Deposit from "@/shared/lib/client-sdk/models/deposit";
import Plan from "@/shared/lib/client-sdk/models/plan";
import Account from "@/shared/lib/client-sdk/models/account";
import OrderLine from "@/shared/lib/client-sdk/models/order-line";
import OrderLineTax from "@/shared/lib/client-sdk/models/order-line-tax";
import OrderLineFee from "@/shared/lib/client-sdk/models/order-line-fee";
import Payment from "@/shared/lib/client-sdk/models/payment";
import TaxRefund from "@/shared/lib/client-sdk/models/tax-refund";
import AssetTrackingLedgerEntry from "@/shared/lib/client-sdk/models/asset-tracking-ledger-entry";

import ApiRequest from "@/shared/lib/client-sdk/api-request";
import $ from "jquery";

class AssetTrackingLedger extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "AssetTrackingLedger";
  }

  static getApiPathForSearch() {
    // return window.amplifyRuntimeConfig.get('VUE_APP_API_BASE_URL') + "/public/annuity-types"
  }

  getApiPathForCreate() {
    // console.error("Cannot create annuity types.")
  }

  getApiPathForRefreshUpdateAndDelete() {
    // return window.amplifyRuntimeConfig.get('VUE_APP_API_BASE_URL') + "/public/annuity-types/" + this.code
  }

  getAdminPath() {
    //
  }

  get relationships() {
    return {
      corporateBankAccount: CorporateBankAccount,
      bankTransaction: BankTransaction,
      deposit: Deposit,
      plan: Plan,
      account: Account,
      orderLine: OrderLine,
      orderLineTax: OrderLineTax,
      orderLineFee: OrderLineFee,
      payment: Payment,
      taxRefund: TaxRefund,
      entries: AssetTrackingLedgerEntry,
    };
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultTitle() {
    return null;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return null;
  }

  get searchResultStatus() {
    return null;
  }

  static getForBankTransaction(bankTransaction, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/bank-transactions/" +
      bankTransaction.id +
      "/asset-tracking-ledger";
    this.getAssetTrackingLedger(requestUrl, callback);
  }

  static getForDeposit(deposit, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/deposits/" +
      deposit.id +
      "/asset-tracking-ledger";
    this.getAssetTrackingLedger(requestUrl, callback);
  }

  static getForPlan(plan, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/plans/" +
      plan.id +
      "/asset-tracking-ledger";
    this.getAssetTrackingLedger(requestUrl, callback);
  }

  static getForAccount(account, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/accounts/" +
      account.id +
      "/asset-tracking-ledger";
    this.getAssetTrackingLedger(requestUrl, callback);
  }

  static getForOrderLine(orderLine, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/order-lines/" +
      orderLine.id +
      "/asset-tracking-ledger";
    this.getAssetTrackingLedger(requestUrl, callback);
  }

  static getForOrderLineTax(orderLineTax, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/order-line-taxes/" +
      orderLineTax.id +
      "/asset-tracking-ledger";
    this.getAssetTrackingLedger(requestUrl, callback);
  }

  static getForOrderLineFee(orderLineFee, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/order-line-fees/" +
      orderLineFee.id +
      "/asset-tracking-ledger";
    this.getAssetTrackingLedger(requestUrl, callback);
  }

  static getForPayment(payment, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/payments/" +
      payment.id +
      "/asset-tracking-ledger";
    this.getAssetTrackingLedger(requestUrl, callback);
  }

  static getForTaxRefund(taxRefund, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/tax-refunds/" +
      taxRefund.id +
      "/asset-tracking-ledger";
    this.getAssetTrackingLedger(requestUrl, callback);
  }

  static getAssetTrackingLedger(requestUrl, callback) {
    ApiRequest.send("GET", requestUrl, null, AssetTrackingLedger).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  getAssetTrackingLedgerEntryOptions(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/asset-tracking-ledger/" +
      this.id +
      "/entry-options";

    ApiRequest.send("GET", requestUrl, null, AssetTrackingLedger).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }
}

export default AssetTrackingLedger;
